export const Helper = {
    getUniqueId(){
        const createId = () => {
            let id = '';
            let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    
            for (let i = 0; i < 5; i++) {
                id += chars.charAt(Math.floor(Math.random() * chars.length));
            }
    
            return id;
        };

        return createId();
    },
    CONSTANTS: {
        EntityState: {
            New: "new",
            Deleted: "deleted"
        }
    }   
}