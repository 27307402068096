import { DataTable } from "primereact/datatable";

import { Column } from 'primereact/column';
import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import Select from "react-select";

const WorkScheduleManager = ({schedules, onScheduleChange}) => {
    const [day, setDay] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [schedule, setSchedule] = useState([]);

    const daysOfWeek = [
        { id: 1, label: "Luni" },
        { id: 2, label: "Marti" },
        { id: 3, label: "Miercuri" },
        { id: 4, label: "Joi" },
        { id: 5, label: "Vineri" },
        { id: 6, label: "Sambata" },
        { id: 7, label: "Duminica" },
        { id: 8, label: "Toata saptamana" }
    ];

    useEffect(() => { 
        if (schedules && schedules.length) {
            const initialSchedule = schedules.map((x) => {
                const day = daysOfWeek.find((d) => d.id == x.day || d.id == x.id);  
                return {
                    id: day.id,
                    day: day.label,
                    interval: `${x.timeStart} - ${x.timeEnd}`
                };
            });
            setSchedule(initialSchedule);
        }
    }, [schedules]);

    useEffect(() => { 
        console.log("HERE");
    
    },
        [schedule, onScheduleChange]);

    const handleAddSchedule = () => {
        if (!day || !startTime || !endTime) { 
            return;
        }

        if (day.id == 8) {
            const allWeek = daysOfWeek.filter(
                (d) => d.id != 8
            );
            const newSchedule = allWeek.map((d) => ({ id: d.id, day: d.label, interval: `${startTime} - ${endTime}` }));
            setSchedule((prev) => { return newSchedule});
        } else {
            if (schedule.some((item) => item.id === day.id)) { 
                return;
            }
            setSchedule((prev) => [
                ...prev,
                { id: day.id, day: day.label, interval: `${startTime} - ${endTime}` },
            ]);
        }

        setDay(null);
        setStartTime("");
        setEndTime("");
    };

    const handleDelete = (dayToDelete) => {
        setSchedule((prev) => prev.filter((item) => item.id !== dayToDelete.id));
    };

    const operationsSchedule = (e) => {
        return (  <div className="list-unstyled hstack gap-1 mb-0">
            <Button onClick={()=> { handleDelete(e); }}   className="btn btn-sm btn-soft-danger">Sterge</Button></div>
        )
    }

    const selectStyles = {
        menu: base => ({
            ...base,
            zIndex: 100
        })
    };

    return (
        <div className="mx-auto">
            <div className="mb-2">
                <label className="block text-sm font-medium mb-2">Alege ziua</label>
                <Select
                    value={day}
                    placeholder="Ziua..."
                    onChange={(e) => setDay(e)}
                    getOptionLabel={(option) => `${option.label}`}
                    getOptionValue={(option) => option.id}
                    styles={selectStyles}
                    options={daysOfWeek}></Select>
            </div> 
            <div> 
                    <label className="block text-sm font-medium mb-2 me-2">De la ora</label>
                    <input
                        type="time"
                        className="w-full p-2 border rounded"
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)}
                    />

                    <label className="block text-sm font-medium mb-2 me-2">Pana la ora</label>
                    <input
                        type="time"
                        className="w-full p-2 border rounded"
                        value={endTime}
                        onChange={(e) => setEndTime(e.target.value)}
                    /> 
                    <button className="btn btn-soft-success me-2" onClick={handleAddSchedule}>Adauga</button>
                </div> 

            <div className="mt-6">
                <DataTable
                    className='.p-datatable-sm'
                    value={schedule || []}
                    emptyMessage="Nu au fost gasite rezultate">
                    <Column field="day" header="Ziua" style={{ minWidth: '12rem' }} />
                    <Column field="interval" header="Intervalul de lucru"style={{width:"fit-content"}} />
                    <Column body={operationsSchedule} style={{ minWidth: '12rem' }} />
                </DataTable>
            </div>
        </div>
    );
};

export default WorkScheduleManager;