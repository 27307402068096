import React from "react"
import { Card, CardBody, CardTitle, Progress } from "reactstrap"

const TopClientsComponent = ({props}) => {

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Top clienti <small>(sumar vizite)</small></CardTitle>
          <div className="text-center">
            <div className="mb-4">
              <i className="bx bx-map-pin text-primary display-4" />
            </div>
            <h5>{props[0].clientName}</h5>
            <p>{props[0].value} {" vizite"}</p>
          </div>

          <div className="table-responsive mt-4">
            <table className="table align-middle table-nowrap">
              <tbody>
                {
                  (props || [])?.filter((x,i)=> i !== 0).map((item, index) => (
                    <tr key={index}>
                      <td style={{ width: "30%" }}>
                        <p className="mb-0">{item.clientName}</p>
                      </td>
                      <td>
                        <h5 className="mb-0">{item.value} { " vizite" }</h5> 
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default TopClientsComponent;
