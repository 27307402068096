import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useRef } from "react";

import { Column } from 'primereact/column';
import { Dialog } from "primereact/dialog";
import { DataTable } from 'primereact/datatable';
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, UncontrolledTooltip, Input, Label, Row } from "reactstrap";
import { Toast } from 'primereact/toast';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";
// FlatPickr
import "flatpickr/dist/themes/material_blue.css";
import { Badge } from "primereact/badge";

import * as Yup from "yup";
import { useFormik } from "formik";
import { CommonService } from "services/CommonService";
import { HealthFacilityService } from "services/HealthFacilityService";
import ZonesComponent from "components/core/zonesComponent";
import { EmployeeService } from "services/EmployeeService";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import WorkScheduleManager from "./components/medicSchedule"

const HealthFacilityRegisterComponent = () => {
    document.title = "Administrare unitate sanitara";
    const { idHealthFacility = -1 } = useParams();

    const [healthFacilityTypes, setHealthFacilityTypes] = useState(null);

    const [zones, setZones] = useState(null);
    const [employeesList, setEmployeesList] = useState([]);
    const [medicsList, setMedicsList] = useState([]);

    const [selectedHealthFacilityType, setSelectedHealthFacilityType] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [selectedMedic, setSelectedMedic] = useState(null);
    const [currentMedic, setCurrentMedic] = useState(null);

    const [viewOnlyActions, setViewOnlyActions] = useState(false);

    const [scheduleDialogVisible, setScheduleDialogVisible] = useState(false);
    const toast = useRef(null);

    // formik
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            idHealthFacility: -1,
            name: '',
            email: '',
            mobile: '',
            address: '',
            idClientType: null,
            idZone: null,
            website: '',
            employees: [],
            medics: []
        },
        validationSchema: Yup.object({
            idHealthFacility: Yup.number().required("Trebuie completat acest camp"),
            name: Yup.string().required("Trebuie completat acest camp").min(3, 'Textul este prea scurt').max(100, "Textul este prea lung."),
            email: Yup.string().nullable().email("Adresa de email nu este valida"),
            idZone: Yup.number().required("Trebuie completat acest camp"),
            website: Yup.string().nullable().min(5, 'Textul este prea scurt').max(50, "Textul este prea lung."),
            mobile: Yup.string().nullable().min(3, 'Textul este prea scurt').max(50, "Textul este prea lung."),
            address: Yup.string().required("Trebuie completat acest camp").min(3, 'Textul este prea scurt').max(255, "Textul este prea lung."),
            idClientType: Yup.number().required("Trebuie completat acest camp"),
        }),
        onSubmit: (e) => { 
            let entity = {
                IdHealthFacility: parseInt(idHealthFacility) || -1,
                Address: e.address,
                Email: e.email,
                Employees: e.employees.length > 0
                    ? (parseInt(idHealthFacility) <= 0
                        // For new health facility, just send employee IDs
                        ? e.employees
                            .filter(f => f.state === "new")
                            .map(f => f.id)
                        // For existing health facility, send full employee objects
                        : e.employees.map(f => ({
                            Id: f.state === "new" ? -1 : f.idAssignment,
                            Value: f.id,
                            State: f.state || "database"  // "new", "deleted", or "database"
                        })))
                    : [],
                IdClientType: e.idClientType,
                IdZone: e.idZone,
                Medics: e.medics.length > 0
                    ? (parseInt(idHealthFacility) > 0
                        // For existing health facility, send full medic objects with state
                        ? e.medics.map(f => ({
                            Value: f.idMedic,
                            Id: f.state === "New" ? -1 : f.id,
                            State: f.state || "Database"  // "New", "Deleted", or "Database"
                        }))
                        // For new health facility, just send medic IDs
                        : e.medics
                            .filter(f => f.state === "New")
                            .map(f => f.idMedic)
                    ) : [],
                Mobile: e.mobile,
                Name: e.name,
                Website: e.website
            };
 
            const isNew = parseInt(idHealthFacility) <= 0;
            const service = isNew ? HealthFacilityService.createHealthFacility : HealthFacilityService.updateHealthFacility;

            service(entity).then(f => {
                const action = isNew ? 'creata' : 'modificata';
                toast.current.show({
                    severity: f.success ? 'success' : 'warn',
                    summary: 'Info',
                    detail: `Unitatea sanitara ${f.success ? `a fost ${action} cu success` : (`nu a putut fi ${action}. Detalii:` + f.details)}`
                });
                if (f.success) {
                    if (isNew) {
                        setViewOnlyActions(true);
                    }
                }
            });
        }
    });

    // change selected location
    const onZoneChangeHandler = (idZone) => {
        formik.setFieldValue("idZone", idZone);

        // get employees list based on selected zone
        EmployeeService.getEmployeesByZones([idZone]).then(emp => {
            setEmployeesList(emp);
        });

        // get medics list based on selected zone
        HealthFacilityService.getHealthFacilityMedics(idZone).then(med => {
            setMedicsList(med);
        });
    };

    useEffect(() => {
        // get locations 
        CommonService.getLocations().then(data => {


            setZones(data);
            HealthFacilityService.getHealthFacilityTypes().then(x => {
                console.log("HealthFacilityTypes:", x);
                setHealthFacilityTypes(x);

                if (parseInt(idHealthFacility) > 0)

                    HealthFacilityService.getHealthFacility(idHealthFacility).then(data => {

                        if (data) {
                            console.log("HealthFacility:", data);

                            // default values
                            formik.setValues(data); 

                            const clientType = x.find(f => f.idClientType == data.idClientType);
                            setSelectedHealthFacilityType(clientType);

                            // get employees list based on selected zone
                            EmployeeService.getEmployeesByZones([data.idZone]).then(emp => {
                                setEmployeesList(emp);
                            });

                            // get medics list based on selected zone
                            HealthFacilityService.getHealthFacilityMedics(data.idZone).then(med => {
                                setMedicsList(med);
                            });
                        }
                    });
            }); 
        }); 
    }, [idHealthFacility]);

    // stergere farmacie
    const handleDeleteHealthFacility = () => {
        const accept = () => {
            HealthFacilityService.deleteHealthFacility(idHealthFacility).then(f => {
                if (f.success) {
                    toast.current.show({ severity: 'success', summary: 'Info', detail: 'Unitatea sanitara a fost stearsa cu success' });
                } else {
                    toast.current.show({ severity: 'warn', summary: 'Info', detail: 'Unitatea sanitara nu a putut fi stearsa. Detalii:' + f.details });
                }
            });
        };

        const refuz = () => { };

        confirmDialog({
            message: 'Confirmi stergerea acestei unitati sanitare ?',
            header: 'Confirmare',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            acceptLabel: "Da",
            rejectLabel: "Nu",
            accept,
            refuz
        });
    }

    // Adauga un nou medic
    const addNewMedicalRepresentant = () => {
        if (selectedMedic) {
            const medic = medicsList.find(f => f.idMedic === selectedMedic.idMedic);

            if (medic) {
                const existingMedic = formik.values.medics.find(f => f.idMedic === medic.idMedic);

                if (existingMedic) {
                    if (existingMedic.state === "Deleted") {
                        const updatedMedics = formik.values.medics.map(e => {
                            if (e.idMedic === existingMedic.idMedic) {
                                return {
                                    ...e,
                                    state: "Database"
                                };
                            }
                            return e;
                        });
                        formik.setFieldValue("medics", updatedMedics);
                    }
                } else {
                    const updatedMedics = [...formik.values.medics, {
                        ...medic,
                        state: "New",
                        id: -1,
                        dateCreated: "Recent..."
                    }];
                    formik.setFieldValue("medics", updatedMedics);
                }
            }
        }
    };

    const addNewEmployee = () => {
        if (selectedEmployee) {
            const employee = employeesList.find(f => f.id === selectedEmployee.id);

            if (employee) {
                const existingEmployee = formik.values.employees.find(f => f.id === employee.id);

                if (existingEmployee) {
                    if (existingEmployee.state === "deleted") {
                        const updatedEmployees = formik.values.employees.map(e => {
                            if (e.id === existingEmployee.id) {
                                return {
                                    ...e,
                                    state: "database",
                                    visible: 1
                                };
                            }
                            return e;
                        });
                        formik.setFieldValue("employees", updatedEmployees);
                    }
                } else {
                    const updatedEmployees = [...formik.values.employees, {
                        ...employee,
                        state: "new",
                        dateCreated: "recent",
                        visible: 1
                    }];
                    formik.setFieldValue("employees", updatedEmployees);
                }
            }
        }
    };

    const handleScheduleChange = (updatedSchedule) => {
        console.log("Well....");

        if (currentMedic) {
            setCurrentMedic(prev => {
                return {
                    ...prev,
                    schedule: updatedSchedule
                }
            })
        }
        console.log("Updated Schedule: ", updatedSchedule);
    };
    const removeSelectedMedicalRepresentant = (options) => {
        return (
            <div className="list-unstyled hstack gap-1 mb-0">
                <li>
                    <Button
                        className="btn btn-sm btn-soft-danger"
                        onClick={(e) => {
                            e.preventDefault(); 

                            const updatedMedics = formik.values.medics.map(medic => {
                                if (medic.idMedic === options.idMedic) {
                                    if (medic.state === "New") { return null; }
                                    return { ...medic, state: "Deleted" };
                                }
                                return medic;
                            }).filter(medic => medic !== null); // Remove any null entries (new medics marked for removal)
 
                            formik.setFieldValue("medics", updatedMedics);
                        }}>Sterge</Button>
                </li>
                <li>
                    <Button onClick={() => {
                        setCurrentMedic(options);
                        setScheduleDialogVisible(true);
                    }} className="btn btn-sm btn-soft-info">Orar</Button>
                </li>
                <li>
                    <Button onClick={() => {
                        const updatedMedics = formik.values.medics.map(medic => {
                            if (medic.idMedic === options.idMedic) {
                                return { ...medic, active: !medic.active };
                            }
                            return medic;
                        }).filter(medic => medic !== null);

                        formik.setFieldValue("medics", updatedMedics);

                    }} className="btn btn-sm btn-success">{options.active ? "Dezactiveaza" : "Activeaza"}</Button>
                </li>
            </div>
        );
    };

    const removeSelectedEmployeeRepresentant = (options) => {
        return (
            <div className="list-unstyled hstack gap-1 mb-0">
                <li>
                    <Link
                        to="#"
                        className="btn btn-sm btn-soft-danger"
                        onClick={(e) => {
                            e.preventDefault();
                            const existingEmployee = formik.values.employees.find(f => f.id === options.id);

                            if (existingEmployee) {
                                if (existingEmployee.state === "new") {
                                    const updatedEmployees = formik.values.employees.filter(w => w.id !== existingEmployee.id);
                                    formik.setFieldValue("employees", updatedEmployees);
                                } else {
                                    const updatedEmployees = formik.values.employees.map(e => {
                                        if (e.id === options.id) {
                                            return {
                                                ...e,
                                                state: "deleted",
                                                visible: 0
                                            };
                                        }
                                        return e;
                                    });
                                    formik.setFieldValue("employees", updatedEmployees);
                                }
                            }
                        }}
                        id={`deletetooltip${options.id}`}
                    >
                        <i className="mdi mdi-delete-outline" />
                        <UncontrolledTooltip
                            placement="top"
                            target={`deletetooltip${options.id}`}
                            delay={{ show: 0, hide: 0 }}
                        >
                            Sterge
                        </UncontrolledTooltip>
                    </Link>
                </li>
            </div>
        );
    };

    const statusFieldBody = (e) => {
        if (e.active)
            return <Badge severity="success" value="Activ" /> 
            return <Badge severity="danger" value="Inactiv" />
    }

    const scheduleDialogFooter = (
        <React.Fragment>
            <Button type="button" className="btn btn-success" icon="bx bx-check" onClick={() => {
                const updatedMedics = formik.values.medics.map(medic => {
                    if (medic.idMedic === currentMedic.idMedic) { 
                        return {
                            ...medic,
                            schedule: currentMedic.schedule
                        };
                    }
                    return medic;
                }).filter(medic => medic !== null); // Remove any null entries (new medics marked for removal)
                formik.setFieldValue("medics", updatedMedics);
                setScheduleDialogVisible(false);
            }}>Salveaza</Button>
        </React.Fragment>
    );
 
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Administrare" breadcrumbItem="Administrare unitate sanitara" />
                    <Toast ref={toast} />
                    <ConfirmDialog />

                    <Form onSubmit={(e) => { e.preventDefault(); console.log("Formik values:", formik.values); formik.handleSubmit(); }}>
                        <Row>
                            <Col lg={8}>
                                <Card>
                                    <CardBody>
                                        <div className="mb-3">
                                            <Label htmlFor="idClientType">Categoria unitatii</Label>
                                            <Select
                                                name="idClientType"
                                                value={selectedHealthFacilityType}
                                                onChange={(e) => {
                                                    setSelectedHealthFacilityType(e);
                                                    formik.setFieldValue("idClientType", e.idClientType);
                                                }}
                                                options={healthFacilityTypes}
                                                placeholder="Alege categoria unitatii sanitare"
                                            />
                                            {formik.touched.idClientType && formik.errors.idClientType && (
                                                <FormFeedback type="invalid" className="d-block">
                                                    {formik.errors.idClientType}
                                                </FormFeedback>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="name">Denumirea unitatii sanitare</Label>
                                            <Input
                                                id="name"
                                                name="name"
                                                type="text"
                                                placeholder="Denumirea unitatii..."
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.name || ""}
                                                invalid={formik.touched.name && formik.errors.name}
                                            />
                                            {formik.touched.name && formik.errors.name && (
                                                <FormFeedback type="invalid">
                                                    {formik.errors.name}
                                                </FormFeedback>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="email">Adresa e-Mail</Label>
                                            <Input
                                                id="email"
                                                name="email"
                                                type="email"
                                                placeholder="Adresa de email contact..."
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.email || ""}
                                                invalid={formik.touched.email && formik.errors.email}
                                            />
                                            {formik.touched.email && formik.errors.email && (
                                                <FormFeedback type="invalid">
                                                    {formik.errors.email}
                                                </FormFeedback>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="mobile">Telefon mobil</Label>
                                            <Input
                                                id="mobile"
                                                name="mobile"
                                                type="text"
                                                placeholder="Telefon contact..."
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.mobile || ""}
                                                invalid={formik.touched.mobile && formik.errors.mobile}
                                            />
                                            {formik.touched.mobile && formik.errors.mobile && (
                                                <FormFeedback type="invalid">
                                                    {formik.errors.mobile}
                                                </FormFeedback>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="website">Website</Label>
                                            <Input
                                                id="website"
                                                name="website"
                                                type="text"
                                                placeholder="Website-ul unitatii..."
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.website || ""}
                                                invalid={formik.touched.website && formik.errors.website}
                                            />
                                            {formik.touched.website && formik.errors.website && (
                                                <FormFeedback type="invalid">
                                                    {formik.errors.website}
                                                </FormFeedback>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="address">Adresa unitatii</Label>
                                            <Input
                                                id="address"
                                                name="address"
                                                type="textarea"
                                                placeholder="Adresa unitatii..."
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.address || ""}
                                                invalid={formik.touched.address && formik.errors.address}
                                            />
                                            {formik.touched.address && formik.errors.address && (
                                                <FormFeedback type="invalid">
                                                    {formik.errors.address}
                                                </FormFeedback>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <ZonesComponent
                                                locations={zones}
                                                handleZoneChange={(zoneId) => {
                                                    formik.setFieldValue("idZone", zoneId);
                                                    onZoneChangeHandler(zoneId);
                                                }}
                                                defaultZone={formik.values.idZone}
                                            />
                                            {formik.touched.idZone && formik.errors.idZone && (
                                                <FormFeedback type="invalid" className="d-block">
                                                    {formik.errors.idZone}
                                                </FormFeedback>
                                            )}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg={4}>
                                <Card>
                                    <CardBody>
                                        <h5 className="card-title mb-3">Sumar activitate</h5>
                                        <div className="mb-3">
                                            {idHealthFacility <= 0 ? (
                                                <div>Nu exista momentan activitate in cadrul acestei unitati sanitare. Trebuie completat formularul alaturat pentru inregistrarea noii unitati.</div>
                                            ) : (
                                                <div>De la data inregistrarii {formik.values.dateCreated || "-"}, au fost planificate {formik.values.tasksCount || "0"} vizite catre aceasta unitate.</div>
                                            )}
                                        </div>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium">
                                                    Personal activ
                                                </p>
                                                <h4 className="mb-0">
                                                    {formik.values.employees?.filter(e => e.state !== "deleted").length || "0"} angajati si {
                                                        formik.values.medics?.filter(m => m.state !== "deleted").length || "0"} medici
                                                </h4>
                                            </div>
                                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                <span className="avatar-title rounded-circle bg-primary">
                                                    <i className={"bx bx-walk font-size-24"}></i>
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg={8}>
                                <Card>
                                    <CardBody>
                                        <h5 className="card-title mb-3">Administrare personal medical</h5>
                                        <Row>
                                            <Col sm={6}>
                                                <Select
                                                    value={selectedMedic}
                                                    onChange={(e) => setSelectedMedic(e)}
                                                    options={medicsList}
                                                    placeholder="Reprezentant medical"></Select>
                                            </Col>
                                            <Col sm={6}>
                                                <Button
                                                    type="button"
                                                    onClick={() => { addNewMedicalRepresentant(); }}
                                                    className="btn btn-soft-success waves-effect waves-light">Adauga</Button>
                                            </Col>
                                        </Row>
                                        <>
                                            <Dialog header="Program de lucru"
                                                footer={scheduleDialogFooter}
                                                visible={scheduleDialogVisible}
                                                style={{ width: '50vw' }}
                                                onHide={() => {

                                                    if (!scheduleDialogVisible) return;
                                                    setScheduleDialogVisible(false);
                                                    setCurrentMedic(null);
                                                }}>
                                                <div>
                                                    <WorkScheduleManager onScheduleChange={handleScheduleChange} schedules={currentMedic?.schedule} />
                                                </div>
                                            </Dialog>
                                        </>
                                        <div className="mb-3">
                                            <DataTable columnResizeMode="expand"
                                                resizableColumns className='.p-datatable-sm'
                                                value={formik.values.medics?.filter(m => m.state !== "Deleted") || []} paginator rows={10}
                                                dataKey="idMedic"
                                                emptyMessage="Nu au fost gasite rezultate">
                                                <Column style={{ minWidth: '6rem' }} body={removeSelectedMedicalRepresentant} />
                                                <Column field="name" header="Numele medicului" style={{ minWidth: '12rem' }} />
                                                <Column body={statusFieldBody} header="Status" style={{ width: "fit-content" }} />
                                                <Column field="jobTitle" header="Functia" style={{ minWidth: '12rem' }} />
                                                <Column field="specializations" header="Specializarea" style={{ minWidth: '12rem' }} />
                                                <Column field="competences" header="Competentele" style={{ minWidth: '12rem' }} />
                                                <Column field="address" header="Adresa" style={{ minWidth: '12rem' }} />
                                                <Column field="dateCreated" header="Data inregistrarii" style={{ minWidth: '12rem' }} /> 
                                            </DataTable>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg={8}>
                                <Card>
                                    <CardBody>
                                        <h5 className="card-title mb-3">Administrare personal</h5>
                                        <Row>
                                            <Col sm={6}>
                                                <Select value={selectedEmployee}
                                                    onChange={(e) => setSelectedEmployee(e)}
                                                    getOptionLabel={(option) => `${option.name} - ${option.jobTitle}`}
                                                    getOptionValue={(option) => option.id}
                                                    options={employeesList} placeholder="Numele angajatului"></Select>
                                            </Col>
                                            <Col sm={6}>
                                                <Button type="button" onClick={() => { addNewEmployee(); }} className="btn btn-soft-success waves-effect waves-light">Adauga</Button>
                                            </Col>
                                        </Row>

                                        <div className="mb-3">
                                            <DataTable columnResizeMode="expand"
                                                resizableColumns className='.p-datatable-sm'
                                                value={formik.values.employees?.filter(e => e.state !== "deleted") || []} paginator rows={10}
                                                dataKey="id"
                                                emptyMessage="Nu au fost gasite rezultate">
                                                <Column field="name" header="Numele angajatului" style={{ minWidth: '12rem' }} />
                                                <Column field="jobTitle" header="Functia" style={{ minWidth: '12rem' }} />
                                                <Column field="dateCreated" header="Data inregistrarii" style={{ minWidth: '12rem' }} />
                                                <Column style={{ minWidth: '6rem' }} body={removeSelectedEmployeeRepresentant} />
                                            </DataTable>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg={8}>
                                <div className="text-end mb-4">
                                    <Button
                                        disabled={idHealthFacility <= 0}
                                        onClick={handleDeleteHealthFacility}
                                        style={{ marginRight: "0.3%" }}
                                        color="danger">
                                        Sterge
                                    </Button>
                                    <Button
                                        type="submit"
                                        color="success"
                                        disabled={viewOnlyActions}>
                                        Salveaza
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default HealthFacilityRegisterComponent;
