import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import { Toast } from 'primereact/toast';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";
import { Column } from 'primereact/column';
import "flatpickr/dist/themes/material_blue.css";
import { Helper } from "services/Helper";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CommonService } from "services/CommonService";
import { PharmacyService } from "services/PharmacyService";
import ZonesComponent from "components/core/zonesComponent";
import { EmployeeService } from "services/EmployeeService";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

const PharmacyRegisterComponent = () => {
    document.title = "Administrare farmacie";

    const { idPharmacy = -1 } = useParams();
    const toast = useRef(null);

    // States
    const [isLoading, setIsLoading] = useState(true);
    const [pharmacyTypes, setPharmacyTypes] = useState([]);
    const [zones, setZones] = useState([]);
    const [medicalRepresentants, setMedicalRepresentants] = useState([]);
    const [salesRepresentants, setSalesRepresentants] = useState([]);
    const [selectedMedicalRepresentant, setSelectedMedicalRepresentant] = useState(null);
    const [selectedSalesRepresentant, setSelectedSalesRepresentant] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: 'contains' }
    });
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            idPharmacy: -1,
            name: '',
            email: '',
            mobile: '',
            address: '',
            idClientType: null,
            idZone: null,
            website: '',
            employees: [],
            pharmacyType: null,
            tasksCount: 0
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Trebuie completat acest camp").min(3, 'Textul este prea scurt').max(150, "Textul este prea lung."),
            email: Yup.string(),
            idZone: Yup.number().required("Trebuie completat acest camp"),
            website: Yup.string().min(5, 'Textul este prea scurt').max(50, "Textul este prea lung."),
            mobile: Yup.string().required("Trebuie completat acest camp").min(3, 'Textul este prea scurt').max(25, "Textul este prea lung."),
            address: Yup.string().required("Trebuie completat acest camp").min(3, 'Textul este prea scurt').max(255, "Textul este prea lung."),
            idClientType: Yup.number().required("Trebuie completat acest camp")
        }),
        onSubmit: async (values) => {
            setSaveDisabled(true);

            const entity = {
                IdPharmacy: (parseInt(idPharmacy) || -1),
                Name: values.name,
                Email: values.email,
                Mobile: values.mobile,
                Address: values.address,
                IdClientType: values.idClientType,
                IdZone: values.idZone,
                Website: values.website,
                Employees: values.employees?.length > 0 ?
                    ((parseInt(idPharmacy) || -1) > 0
                        ? values.employees?.map(x => ({
                                IdAssignment: x.idAssignment,
                                IdEmployeeRM: x.idMedicalRepresentant,
                                IdEmployeeAV: x.idSalesRepresentant,
                                State: x.state
                            }))
                        : values.employees?.filter(f => f.state === Helper.CONSTANTS.EntityState.New)
                            .map(x => ({
                                IdAssignment: -1,
                                IdEmployeeRM: x.idMedicalRepresentant,
                                IdEmployeeAV: x.idSalesRepresentant,
                                State: Helper.CONSTANTS.EntityState.New
                            }))
                    ) : []
            };

            try {
 
                const response = entity.IdPharmacy > 0
                    ? await PharmacyService.updatePharmacy(entity)
                    : await PharmacyService.createPharmacy(entity);
 
                if (response && response.success) {
                    setSaveDisabled(false);
                    toast.current.show({
                        severity: 'success',
                        summary: 'Info',
                        detail: `Unitatea a fost ${entity.IdPharmacy > 0 ? 'actualizata' : 'creata'} cu success`
                    });
                } else {
                    setSaveDisabled(false);
                    toast.current.show({
                        severity: 'warn',
                        summary: 'Info',
                        detail: `Unitatea nu a putut fi ${entity.IdPharmacy > 0 ? 'actualizata' : 'creata'}`
                    });
                }
            } catch (error) { 
                setSaveDisabled(false);
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'A aparut o eroare la salvarea datelor'
                });
            }
        }
    });

    // Effects
    useEffect(() => {
        const initializeData = async () => {
            setIsLoading(true);

            try {
                const [pharmacyTypesData, zonesData] = await Promise.all([
                    PharmacyService.getPharmacyTypes(),
                    CommonService.getLocations()
                ]);

                setPharmacyTypes(pharmacyTypesData);
                setZones(zonesData);

                if (idPharmacy > 0) {
                    const pharmacyData = await PharmacyService.getPharmacy(idPharmacy);

                    if (pharmacyData) {
                        formik.setValues({
                            idPharmacy: pharmacyData.idPharmacy,
                            name: pharmacyData.name,
                            email: pharmacyData.email,
                            mobile: pharmacyData.mobile,
                            address: pharmacyData.address,
                            idClientType: pharmacyData.idClientType,
                            idZone: pharmacyData.idZone,
                            website: pharmacyData.website,
                            employees: pharmacyData.employees || [],
                            pharmacyType: pharmacyTypesData.find(t => t.idClientType === pharmacyData.idClientType),
                            tasksCount: pharmacyData.tasksCount || 0
                        });

                        if (pharmacyData.idZone) {
                            const pharmacyEmployees = await EmployeeService.getEmployeesByZone(pharmacyData.idZone) || [];

                            setSalesRepresentants(pharmacyEmployees.filter(f => f.idJobType === 1));
                            setMedicalRepresentants(pharmacyEmployees.filter(f => f.idJobType === 2)); 
                        }
                    }
                }
            } catch (error) {
                if (toast.current) { 
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Nu s-au putut încărca datele'
                    });
                }
            } finally {
                setIsLoading(false);
            }
        };

        // Delay the initialization slightly to ensure Toast is mounted
        setTimeout(initializeData, 0);
    }, [idPharmacy]);

    // Handlers
    const handleAddNewTeam = () => {

        if (!selectedMedicalRepresentant || !selectedSalesRepresentant) {
            toast.current.show({
                severity: 'warn',
                summary: 'Warning',
                detail: 'Selectați ambii reprezentanți pentru a adăuga echipa'
            });
            return;
        }

        const newTeam = {
            idAssignment: -1,
            id: `${selectedMedicalRepresentant.id}_${selectedSalesRepresentant.id}`,
            idMedicalRepresentant: selectedMedicalRepresentant.id,
            idSalesRepresentant: selectedSalesRepresentant.id,
            medicalRepresentantName: selectedMedicalRepresentant.label,
            salesRepresentantName: selectedSalesRepresentant.label,
            dateCreated: "recent ...",
            state: Helper.CONSTANTS.EntityState.New
        };

        const teamExists = formik.values.employees.some(team =>
            team.idMedicalRepresentant === newTeam.idMedicalRepresentant &&
            team.idSalesRepresentant === newTeam.idSalesRepresentant
        );

        if (!teamExists) {
            formik.setFieldValue('employees', [...formik.values.employees, newTeam]);
            setSelectedMedicalRepresentant(null);
            setSelectedSalesRepresentant(null);
        } else {
            toast.current.show({
                severity: 'warn',
                summary: 'Atentie',
                detail: 'Această echipă există deja'
            });
        }
    };

    const removeSelectedTeam = (team) => { 
        if (team.idAssignment === -1) {
            formik.setFieldValue('employees',
                formik.values.employees.filter(emp =>
                    !(emp.idMedicalRepresentant === team.idMedicalRepresentant &&
                        emp.idSalesRepresentant === team.idSalesRepresentant)
                )
            );
        } else {
            const updatedEmployees = formik.values.employees.map(emp =>
                emp.idAssignment === team.idAssignment
                    ? { ...emp, state: Helper.CONSTANTS.EntityState.Deleted }
                    : emp
            );
            formik.setFieldValue('employees', updatedEmployees);
        }
    };

    const handleDeletePharmacy = () => {
        confirmDialog({
            message: 'Confirmi stergerea acestei unitati ?',
            header: 'Confirmare',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            acceptLabel: "Da",
            rejectLabel: "Nu",
            accept: async () => {
                try {
                    const response = await PharmacyService.deletePharmacy(idPharmacy);
                    if (response.success) {
                        setIsDeleted(true);
                        setSaveDisabled(true);
                    }
                    toast.current.show({
                        severity: response.success ? 'success' : 'warn',
                        summary: 'Info',
                        detail: response.success ? 'Unitatea a fost stearsa' : ('Unitatea nu a putut fi stearsa. Detalii:' + response.details)
                    });
                } catch (error) {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Eroare',
                        detail: 'A apărut o eroare la ștergerea unității'
                    });
                }
            }
        });
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => (
        <div className="flex justify-content-end">
            <IconField iconPosition="left">
                <InputIcon className="pi pi-search" />
                <InputText
                    value={globalFilterValue}
                    onChange={onGlobalFilterChange}
                    placeholder="Cautare ..."
                />
            </IconField>
        </div>
    );

    const operationsDetails = (rowData) => (
        <div className="list-unstyled hstack gap-1 mb-0">
            <Button
                onClick={() => removeSelectedTeam(rowData)}
                className="btn btn-sm btn-danger"
                id={`${rowData.idAssignment}`}>
                <i className="bx bx-trash" />
            </Button>
        </div>
    );

    if (isLoading) {
        return (
            <div className="page-content">
                <Container fluid>
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Incarcare...</span>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <ConfirmDialog />
                <Container fluid>
                    <Breadcrumbs title="Farmacie" breadcrumbItem="Administrare Farmacie" />
                    <Toast ref={toast} />
                    <Form onSubmit={formik.handleSubmit}>
                        <Row>
                            <Col lg={8}>
                                <Card>
                                    <CardBody>
                                        <div className="mb-3">
                                            <Label htmlFor="idClientType">Categoria unitatii</Label>
                                            <Select
                                                name="idClientType"
                                                value={formik.values.pharmacyType}
                                                onChange={(selected) => {
                                                    formik.setFieldValue('pharmacyType', selected);
                                                    formik.setFieldValue('idClientType', selected?.idClientType);
                                                }}
                                                options={pharmacyTypes}
                                                placeholder="Alege categoria unitatii"
                                            />
                                            {formik.touched.idClientType && formik.errors.idClientType && (
                                                <FormFeedback type="invalid" className="d-block">
                                                    {formik.errors.idClientType}
                                                </FormFeedback>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="name">Denumirea unitatii</Label>
                                            <Input
                                                id="name"
                                                name="name"
                                                type="text"
                                                placeholder="Denumirea unitatii..."
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.name}
                                                invalid={formik.touched.name && formik.errors.name}
                                            />
                                            {formik.touched.name && formik.errors.name && (
                                                <FormFeedback type="invalid">{formik.errors.name}</FormFeedback>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="email">Adresa e-Mail</Label>
                                            <Input
                                                id="email"
                                                name="email"
                                                type="email"
                                                placeholder="Adresa de email contact..."
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.email}
                                                invalid={formik.touched.email && formik.errors.email}
                                            />
                                            {formik.touched.email && formik.errors.email && (
                                                <FormFeedback type="invalid">{formik.errors.email}</FormFeedback>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="mobile">Telefon mobil</Label>
                                            <Input
                                                id="mobile"
                                                name="mobile"
                                                type="text"
                                                placeholder="Telefon contact..."
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.mobile}
                                                invalid={formik.touched.mobile && formik.errors.mobile}
                                            />
                                            {formik.touched.mobile && formik.errors.mobile && (
                                                <FormFeedback type="invalid">{formik.errors.mobile}</FormFeedback>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="website">Website</Label>
                                            <Input
                                                id="website"
                                                name="website"
                                                type="text"
                                                placeholder="Website-ul unitatii..."
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.website}
                                                invalid={formik.touched.website && formik.errors.website}
                                            />
                                            {formik.touched.website && formik.errors.website && (
                                                <FormFeedback type="invalid">{formik.errors.website}</FormFeedback>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="address">Adresa unitatii</Label>
                                            <Input
                                                id="address"
                                                name="address"
                                                type="textarea"
                                                placeholder="Adresa farmaciei..."
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.address}
                                                invalid={formik.touched.address && formik.errors.address}
                                            />
                                            {formik.touched.address && formik.errors.address && (
                                                <FormFeedback type="invalid">{formik.errors.address}</FormFeedback>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <ZonesComponent
                                                locations={zones}
                                                handleZoneChange={(zoneId) => {
                                                    formik.setFieldValue('idZone', zoneId);
                                                    if (zoneId) {
                                                        EmployeeService.getEmployeesByZone(zoneId).then(emp => {
                                                            setSalesRepresentants(emp.filter(f => f.idJobType === 1));
                                                            setMedicalRepresentants(emp.filter(f => f.idJobType === 2));
                                                        });
                                                    }
                                                }}
                                                defaultZone={formik.values.idZone}
                                            />
                                            {formik.touched.idZone && formik.errors.idZone && (
                                                <FormFeedback type="invalid" className="d-block">
                                                    {formik.errors.idZone}
                                                </FormFeedback>
                                            )}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg={4}>
                                <Card>
                                    <CardBody>
                                        <h5 className="card-title mb-3">Sumar activitate</h5>
                                        <div className="mb-3">
                                            <div>
                                                De la data inregistrarii, au fost planificate {formik.values.tasksCount} vizite catre aceasta unitate.
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium">Personal activ</p>
                                                <h4 className="mb-0">
                                                    {formik.values.employees.length} echipe
                                                </h4>
                                            </div>
                                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                <span className="avatar-title rounded-circle bg-primary">
                                                    <i className="bx bx-walk font-size-24" />
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg={8}>
                                <Card>
                                    <CardBody>
                                        <h5 className="card-title mb-3">Administrare personal</h5>
                                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginBottom: '1rem' }}>
                                            <Select
                                                value={selectedMedicalRepresentant}
                                                onChange={setSelectedMedicalRepresentant}
                                                options={medicalRepresentants}
                                                placeholder="Reprezentant medical"
                                            />
                                            <Select
                                                value={selectedSalesRepresentant}
                                                onChange={setSelectedSalesRepresentant}
                                                options={salesRepresentants}
                                                placeholder="Reprezentant vanzari"
                                            />
                                            <Button
                                                type="button"
                                                onClick={handleAddNewTeam}
                                                className="btn btn-soft-success waves-effect waves-light"
                                            >
                                                Adauga
                                            </Button>
                                        </div>

                                        <DataTable
                                            value={formik.values.employees?.filter(f => f.state !== Helper.CONSTANTS.EntityState.Deleted)}
                                            paginator
                                            rows={10}
                                            dataKey="idAssignment"
                                            filters={filters}
                                            globalFilterFields={['medicalRepresentantName', 'salesRepresentantName']}
                                            header={renderHeader}
                                            emptyMessage="Nu au fost gasite rezultate"
                                            className="p-datatable-lg"
                                            columnResizeMode="expand"
                                            resizableColumns
                                            style={{ fontSize: "15px" }}>
                                            <Column field="medicalRepresentantName" header="Reprezentant medical" />
                                            <Column field="salesRepresentantName" header="Reprezentant vanzari" />
                                            <Column field="dateCreated" header="Data inregistrarii" />
                                            <Column body={operationsDetails} style={{ minWidth: '12rem' }} />
                                        </DataTable>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg={8}>
                                <div className="text-end mb-4">
                                    {parseInt(idPharmacy) > 0 && (
                                        <Button
                                            onClick={handleDeletePharmacy}
                                            style={{ marginRight: "0.3%" }}
                                            color="danger"
                                            disabled={isDeleted}>
                                            Sterge
                                        </Button>
                                    )}
                                    <Button
                                        type="submit"
                                        color="success"
                                        disabled={saveDisabled || isDeleted}
                                    >
                                        Salveaza
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default PharmacyRegisterComponent;
