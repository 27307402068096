import { get, post } from "../helpers/api_helper"

export const StockRequestService = {
    sendStockRequest(entity) {
        return post(`stock/requests/create`, entity);
    },
    approveStockRequest(entity) {
        return post(`stock/requests/approve`, entity);
    },
    denyStockRequest(entity) {
        return post(`stock/requests/deny`, entity);
    }, 
    cancelStockRequest(entity){
        return post('stock/requests/cancel', entity);
    },
    getStockRequests() {
        return get('stock/requests/employee');
    },
    getEmployeeStock(idEmployee){
        return get(`stock/employee/${idEmployee}`); 
    }
}