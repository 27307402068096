import React, { useEffect, useState, useRef } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Badge } from 'primereact/badge';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import { Link } from 'react-router-dom'

import { Dialog } from "primereact/dialog";
import Select from "react-select";
import { ReportingService } from "services/ReportingService";
import { CommonService } from "services/CommonService";
import { EmployeeService } from "services/EmployeeService";

const InactiveClientsReportComponent = props => {
    document.title = "Raport activitate medici";

    const navigate = useNavigate();
    const [entities, setEntities] = useState([]); 
    const [globalFilter, setGlobalFilter] = useState(null); 

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <IconField iconPosition="left">
                <InputIcon className="bx bx-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cauta..." />
            </IconField>
        </div>
    );

    useEffect(() => {
        ReportingService.getInactiveClients().then(f => {
            console.log("Clients with no activity:", f);
            setEntities(f);
        });
    }, []);

    const clientStatus = (rowData) => {
      
        return <Badge severity="danger" value="Lipsa activitate" />;
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Rapoarte" breadcrumbItem="Activitate clienti" />
                    <Row>
                        <Col xs={12}>
                            <Row>
                                <Col xl={12}>
                                    <Card>
                                        <CardBody>
                                            <DataTable value={entities}
                                                dataKey="id"
                                                paginator
                                                rows={10}
                                                header={header}
                                                rowsPerPageOptions={[5, 10, 25]}
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                currentPageReportTemplate="Afiseaza {first} pana la {last} din {totalRecords} inregistrari"
                                                globalFilter={globalFilter}>  
                                                <Column field="employees" header="Numele angajatului" sortable style={{ minWidth: '16rem' }}></Column>
                                                <Column field="name" header="Denumire client" sortable style={{ minWidth: '16rem' }}></Column>  
                                                <Column field="type" header="Tip client" sortable style={{ minWidth: '16rem' }}></Column>
                                                <Column body={clientStatus} header="Status"sortable style={{ minWidth: '16rem' }}></Column>
                                                <Column field="medicName" header="Numele medicului" sortable style={{ minWidth: '16rem' }}></Column>
                                            </DataTable>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default InactiveClientsReportComponent;
