import { get, post } from "../helpers/api_helper"

export const ProductService = {
    deleteProduct(idProduct){
        return get(`stock/product/delete/${idProduct}`); 
    },
    getStockProducts(){
        return get('stock/products');
    },
    getProductByName(label){
        return get(`stock/product/name/${label}`); 
    },
    getProductById(id){
        return get(`stock/product/${id}`); 
    },
    updateProduct(entity){
        return post(`stock/product/edit`, entity);
    },
    saveProductsList(entity){ 
        return post(`stock/product/add`, entity);
    },
    getProductCategories(){
        return get('nomenclator/product/categories');
    },
    getAllProducts(){  
        return get('nomenclator/products');
    },
    updateStockRequest(entity) { 
        return post(`stock/confirmation`, entity);
    }
}