import React, { useEffect, useState, useRef } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon"; 
import { Dialog } from "primereact/dialog";
import Select from "react-select";
import { ReportingService } from "services/ReportingService"; 

const MedicPrescriptionsReportComponent = props => {
    document.title = "Raport prescriptii medicale";
 
    const [medicalPrescriptions, setMedicPrescriptionsList] = useState([]);
    const [employeeList, setEmployeesList] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [currentStockRequest, setCurrentStockRequest] = useState(null);
    const [informationModal, setInformationModal] = useState(false);
    const [employee, setEmployee] = useState(null);
    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <div className="d-flex gap-2 mb-4">
                <Select
                    options={employeeList}
                    value={employeeList?.find(f=>f.idEmployee == employee?.idEmployee)}
                    onChange={(e) => setEmployee(e)}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.idEmployee}
                    placeholder="Alege un angajat..."></Select>
            </div>
            <IconField iconPosition="left">
                <InputIcon className="bx bx-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cauta..." />
            </IconField>
        </div>
    );

    const actionBodyTemplate = (options) => {
        return (
            <div className="list-unstyled hstack gap-1 mb-0">
                <li>
                    <Button onClick={() => { 
                        setCurrentStockRequest(medicalPrescriptions.find(f=>f.idMedicalPrescription == options.idMedicalPrescription));
                        setInformationModal(!informationModal);
                    }}
                        className="btn btn-sm btn-soft-info"
                        id={`${options.idMedicalPrescription}`}>
                        <i className="bx bx-show-alt" />
                    </Button>
                </li>
            </div>
        );
    }

    useEffect(() => {
        ReportingService.getMedicalPrescriptions().then(f => { 
            setMedicPrescriptionsList(f);
        });
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Rapoarte" breadcrumbItem="Prescriptii medicale" />
                    <Dialog header="Detalii" visible={informationModal} style={{ width: '50vw' }}
                        onHide={() => { if (!informationModal) return; setInformationModal(false); }}>
                        <div>
                            <DataTable
                                value={currentStockRequest?.products || []}
                                dataKey="id"
                                paginator rows={10}
                                rowsPerPageOptions={[5, 10, 25]}>
                                <Column field="products" header="Produsele"></Column>
                                <Column field="quantity" header="Total produse" sortable></Column>
                                <Column field="price" header="Cost total" sortable style={{ minWidth: '10rem' }}></Column>
                            </DataTable>
                        </div>
                    </Dialog>

                    <Row>
                        <Col xs={12}>
                            <Row>
                                <Col xl={12}>
                                    <Card>
                                        <CardBody>
                                            <DataTable value={medicalPrescriptions}
                                                dataKey="idMedicalPrescription"
                                                paginator
                                                rows={10}
                                                header={header}
                                                rowsPerPageOptions={[5, 10, 25]}
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                currentPageReportTemplate="Afiseaza {first} pana la {last} din {totalRecords} inregistrari"
                                                globalFilter={globalFilter}>
                                                <Column field="name" header="Numele medicului" sortable style={{ minWidth: '16rem' }}></Column>
                                                <Column field="cities" header="Oras" sortable style={{ minWidth: '10rem' }}></Column>
                                                <Column field="zones" header="Cartier" sortable style={{ minWidth: '10rem' }}></Column>
                                                <Column field="prescriptions" header="Total prescriptii" sortable style={{ minWidth: '10rem' }}></Column>
                                                <Column field="price" header="Cost total produse" sortable style={{ minWidth: '10rem' }}></Column>
                                                <Column field="quantity" header="Total cantitate produse" sortable style={{ minWidth: '10rem' }}></Column>
                                                <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                                            </DataTable>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default MedicPrescriptionsReportComponent;
