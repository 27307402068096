import React, { useEffect, useRef, useState, useProps } from "react";
import { useMemo } from "react";
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, UncontrolledTooltip, Input, Label, Row } from "reactstrap";

import { Link, useParams } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { Column } from 'primereact/column';
import { SelectButton } from 'primereact/selectbutton';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';

import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// FlatPickr
import "flatpickr/dist/themes/material_blue.css";

import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import { CommonService } from "services/CommonService";
import { EmployeeService } from "services/EmployeeService";

const EmployeeRegisterComponent = () => {
    document.title = "Administrare personal";
    const { idEmployee = -1 } = useParams();

    const [viewOnly, setViewOnly] = useState(false);
    const [viewOnlyDelete, setviewOnlyDelete] = useState(true);
    const [jobTitles, setJobTitles] = useState([]);
    const [locations, setLocations] = useState([]);

    const [employeeZones, setEmployeeZones] = useState([]);
    const [employeeDbZones, setEmployeeDbZones] = useState([]);
    const [deleteZones, setDeletedZones] = useState([]);
    const toast = useRef(null);
    const options = ['Activ', 'Dezactivat']; 
    const [selectedNewZone, setSelectedNewZone] = useState({});

     const [filters, setFilters] = useState({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            zone: { value: null, matchMode: FilterMatchMode.STARTS_WITH }, 
            city: { value: null, matchMode: FilterMatchMode.STARTS_WITH }, 
            county: { value: null, matchMode: FilterMatchMode.STARTS_WITH }
        }); 
    
    // validation
    const dataForm = useFormik({
        initialValues: {
            name: '',
            idJobTitle: -1,
            secondName: '',
            email: '',
            password: '',
            active: true
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Trebuie completat acest camp").min(3, 'Textul este prea scurt').max(20, "Textul este prea lung."),
            idJobTitle: Yup.number().required("Trebuie completat acest camp").nullable(),
            secondName: Yup.string().required("Trebuie completat acest camp").min(3, "Textul este prea scurt").max(50, "Textul este prea lung."),
            email: Yup.string().email("Adresa de email nu este valida").required("Trebuie completat acest camp"), 
            password: idEmployee == -1 ? Yup.string().required("Trebuie completat acest camp").min(10, "Lungimea parolei este de minim 10 caractere").max(50, "Lungimea maxima este de 50 caractere") : Yup.string().nullable()
        }),
        onSubmit: (data) => { 
            try {
                let ef = { 
                    IdEmployee: idEmployee == -1 ? null : idEmployee,
                    FirstName: data.name, 
                    LastName: data.secondName, 
                    IdJobTitle: data.idJobTitle,
                    Email: data.email,
                    Password: data.password,
                    Active: data.active,
                    Zones: employeeZones?.filter(f => f.state == "new").map(r => { return r.idZone }) || []
                };

                if (idEmployee == -1 || idEmployee == null || idEmployee == '') {
                    EmployeeService.createEmployee(ef).then(f => {
                        if (f.success) {
                            toast.current.show({ severity: 'success', summary: 'Info', detail: 'Angajatul a fost adaugat' });
                            setViewOnly(true);
                            setviewOnlyDelete(true);
                        } else {
                            toast.current.show({ severity: 'warn', summary: 'Info', detail: 'Angajatul nu a putut fi adaugat. Detalii:' + f?.details });
                        }
                    });
                }else {
                    ef.DeletedZones = deleteZones?.map(k=> {return k.idZone}) || []; 

                    EmployeeService.updateEmployee(ef).then(f => { 
                        if (f.success) {
                            toast.current.show({ severity: 'success', summary: 'Info', detail: 'Profilul angajatului a fost actualizat.' });                     
                        } else {
                            toast.current.show({ severity: 'warn', summary: 'Info', detail: 'Profilul nu a putut fi actualizat. Detalii:' + f.details });
                        }
                    });
                }
            } catch { }
        }
    });


    useEffect(() => {
        CommonService.getLocations().then(f => { setLocations(f.zones); });
        CommonService.getJobTitles().then(f => {setJobTitles(f); });

        if (idEmployee != -1) {
            setviewOnlyDelete(false);

            EmployeeService.getEmployeeProfile(idEmployee).then(f => { 
                dataForm.setValues(f.employee);
                setEmployeeZones(f.employee.zones);
                setEmployeeDbZones(f.employee.zones);
            });
        }
    }, []);  
    
    // Adauga o zona de lucru noua
    const addNewZoneToEmployee = () => {
        let zone = employeeZones.find(f => f.idZone == selectedNewZone);

        // nu exista 
        if (!zone) {
            // daca s-a sters 
            if (deleteZones.find(f => f.idZone == selectedNewZone)) {
                // stergem de aici
                setDeletedZones(prev=> { return [...deleteZones].filter(f => f.idZone != selectedNewZone)});
            }

            let locationZone = locations.find(f => f.idZone == selectedNewZone);
            let data = [...employeeZones, {
                idZone: locationZone.idZone,
                zone: locationZone.label,
                city: locationZone.city,
                county: locationZone.county,
                date: "recent...", //new Intl.DateTimeFormat('en-GB').format(new Date()),
                status: "Nou",
                state: "new"
            }];
            setEmployeeZones(data);
        }
    }

    // Sterge zona de lucru
    const removeZone = (zone) => { 

        setEmployeeZones(prevZones => {
            const updatedZones = prevZones.filter(f => f.idZone !== zone.idZone);
            return updatedZones;
        });

        // exista -> o stergem (daca nu exista, este noua)
        const dbExists = employeeDbZones.find(f => f.idZone == zone.idZone);
        if (dbExists) {
            setDeletedZones(prev=> {
                return [...prev, zone]
            });
        }
    } 
 
    // sterge angajat
    const deleteEmployee = () => {
        if(idEmployee != -1){ 
            EmployeeService.deleteEmployee(idEmployee).then(f => {
                if (f.success) {
                    toast.current.show({ severity: 'success', summary: 'Info', detail: 'Angajatul a fost sters' });
                    setViewOnly(true);
                } else {
                    toast.current.show({ severity: 'warn', summary: 'Info', detail: 'Angajatul nu a putut fi sters. Detalii:' + f.details });
                }
            });
        }
    }

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <div className="mb-2">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Cautare ..." />
                </IconField>  
                </div>
              <div className="mb-2">
              <Dropdown value={selectedNewZone}
                                                onChange={(e) => setSelectedNewZone(e.value)}
                                                options={locations}  
                                                placeholder="Alege a zona de lucru"
                                                optionLabel={option => ("(" + option.city + ") " + option.label)}
                                                optionValue={option => option.idZone}
                                                filter className="w-full md:w-10rem me-2" />
                  <Button onClick={addNewZoneToEmployee} className="btn-info">Adauga</Button> 
              </div>
            </div>
        );
    };

    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const removeZoneDetails = (options) => {
        return (
            <div className="list-unstyled hstack gap-1 mb-0">
                <li>
                    <Button 
                        onClick={() => { 
                            removeZone(options);
                        }}
                        className="btn btn-sm btn-soft-danger"

                        id={`btnrm_${options.idZone}`}>
                        <i className="bx bx-trash" />
                    </Button>
                </li>
            </div>
        );
    }

    const statusBodyDetails = (options) =>{ 
        return (
            <span className={`badge ${options.status == "Nou" ? "badge-soft-info" : (
                options.status == "Lipsa activitate" ? "badge-soft-danger" : "badge-soft-success"
            )}`}>{options.status ||"Activ"}</span>
        )  
    } 

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Administrare personal" breadcrumbItem={idEmployee == -1 ? "Angajat nou" : "Angajat existent"} />
                    <Toast ref={toast} />
                    
                    <Form onSubmit={dataForm.handleSubmit}>
                        <Row>
                            <Col lg={8}>
                                <Card>
                                    <CardBody>
                                        <div className="mb-3">
                                            <Label htmlFor="idJobTitle">Functia angajatului</Label>
                                            <Select name="idJobTitle" 
                                            value={jobTitles?.find(f=>f.id == dataForm.values.idJobTitle)} 
                                            onChange={(e) => {
                                                dataForm.setFieldValue("idJobTitle", e.id);
                                                 }} 
                                            getOptionLabel={(option) => option.description}
                                            getOptionValue={(option) => option.id}
                                            options={jobTitles} 
                                            placeholder="Alege functia angajatului"></Select>
                                            
                                            {dataForm.touched.idJobTitle && dataForm.errors.idJobTitle ? (
                                                <FormFeedback type="invalid" className="d-block">{dataForm.errors.idJobTitle}</FormFeedback>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="name">Numele angajatului</Label>
                                            <Input
                                                id="name"
                                                name="name"
                                                type="text"
                                                placeholder="Numele angajatului ..."
                                                onChange={dataForm.handleChange}
                                                onBlur={dataForm.handleBlur}
                                                value={dataForm.values.name || ""}
                                            />
                                            {dataForm.touched.name && dataForm.errors.name ? (
                                                <FormFeedback type="invalid" className="d-block">{dataForm.errors.name}</FormFeedback>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="secondName">Prenumele angajatului</Label>
                                            <Input as="textarea"
                                                id="secondName"
                                                name="secondName"
                                                placeholder="Prenumele angajatului ..."
                                                onChange={dataForm.handleChange}
                                                onBlur={dataForm.handleBlur}
                                                value={dataForm.values.secondName || ""}
                                            />
                                            {dataForm.touched.secondName && dataForm.errors.secondName ? (
                                                <FormFeedback type="invalid" className="d-block">{dataForm.errors.secondName}</FormFeedback>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="email">Adresa e-Mail</Label>
                                            <Input as="textarea"
                                                id="email"
                                                name="email"
                                                placeholder="Adresa de e-mail ..."
                                                onChange={dataForm.handleChange}
                                                onBlur={dataForm.handleBlur}
                                                value={dataForm.values.email || ""}
                                            />
                                            {dataForm.touched.email && dataForm.errors.email ? (
                                                <FormFeedback type="invalid" className="d-block">{dataForm.errors.email}</FormFeedback>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="password">Parola access</Label>
                                            <Input as="textarea"
                                                id="password"
                                                rows={3}
                                                name="password"
                                                placeholder="Parola contului ..."
                                                type="password"
                                                onChange={dataForm.handleChange}
                                                onBlur={dataForm.handleBlur}
                                                value={dataForm.values.password || ""}
                                            />
                                            {dataForm.touched.password && dataForm.errors.password ? (
                                                <FormFeedback type="invalid" className="d-block">{dataForm.errors.password}</FormFeedback>
                                            ) : null}
                                        </div>

                                        <div className="mb-3">
                                            <div style={{ fontSize: "11px" }} className="card flex justify-content-center">
                                                <SelectButton 
                                                    value={dataForm.values.active == true ? "Activ" : "Dezactivat"} 
                                                    onChange={(e) => dataForm.setFieldValue("active", e.value == "Activ" ? true : false)} 
                                                    options={options} />
                                            </div> 
                                        </div> 
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg={4}>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium">
                                                    Sumar zone de activitate
                                                </p>
                                                <h4 className="mb-0">{employeeZones.length} locatii active</h4>
                                            </div>
                                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                <span className="avatar-title rounded-circle bg-primary">
                                                    <i className={"bx bx-walk font-size-24"}></i>
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>

                                <Card>
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium text-danger">
                                                    Sumar zone de activitate
                                                </p>
                                                <h4 className="mb-0 text-danger">{deleteZones.length} locatii urmeaza sa fie sterse</h4>
                                            </div>
                                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                <span className="avatar-title rounded-circle bg-primary">
                                                    <i className={"bx bx-trash font-size-24"}></i>
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg={8}>
                                <Card>
                                    <CardBody>
                                        <h5 className="card-title mb-3">Administrare zone de activitate</h5> 
                                        <div className="mb-3">
                                            <Card>
                                                <CardBody>
                                                    <DataTable columnResizeMode="expand"
                                                        resizableColumns className='.p-datatable-lg' style={{ "fontSize": "15px" }}
                                                        size='small' value={employeeZones || []} paginator rows={10}
                                                        dataKey="idZone"  
                                                        globalFilterFields={['county', 'city', 'zone']} header={renderHeader}
                                                        emptyMessage="Nu au fost gasite rezultate"> 
                                                        <Column field="county" header="Judet" />
                                                        <Column field="city" header="Oras" />
                                                        <Column field="zone" header="Cartier" /> 
                                                        <Column field="status" body={statusBodyDetails} header="Status" /> 
                                                        <Column field="date" header="Data inregistrarii" /> 
                                                        <Column body={removeZoneDetails} style={{ minWidth: '12rem' }} />
                                                    </DataTable> 
                                                </CardBody>
                                            </Card>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg={8}> 
                                <div className="text-end mb-4">
                                    <Button hidden={viewOnlyDelete} onClick={() => { deleteEmployee() }} style={{ "marginRight": "0.3%" }} color="danger">Sterge</Button>
                                    <Button disabled={viewOnly} type="submit" className="btn-primary" color="success">Salveaza</Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default EmployeeRegisterComponent;