import React, { useCallback, useEffect, useRef, useState } from "react";
import { Row, Col, Card, CardBody, Button, UncontrolledTooltip, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, Input, InputGroup } from "reactstrap";
import Reciver from "./Reciver";
import Sender from "./Sender";
import { connect } from "react-redux";


//Import Scrollbar
import SimpleBar from "simplebar-react";
import { handleSearchData } from "components/Common/searchFile";
import { IncidentsService } from "services/IncidentsService";

const ChantBox = ({incident,user}) => { 
  const [searchMenu, setSearchMenu] = useState(false);   
  const [text, setText] = useState("");
  const [chatData, setChatData] = useState([
    {
        id: 1,
        name: "Leonard Leonard",
        msg: "Preluare mesaj 1 baza de date - chat - incidente",
        time: "10.00",
        isSender: false,
        lastChat: false,
    },
    {
      id: 2,
      name: "Muzelak Hans",
      msg: "Accept, preluare mesaj din baza de date - mesaj standard - test",
      time: "10.02",
      isSender: true,
      lastChat: false,
  }]); 

  const [messages, setMessages] = useState([...chatData]);

  const messageBox = useRef(null)
  const scrollToBottom = useCallback(() => {
    if (messageBox.current.el) {
      messageBox.current.getScrollElement().scrollTop = messageBox.current.getScrollElement().scrollHeight;
    }
  }, [messageBox]);

  useEffect(() => {
    if ((messages || []).length > 1) {
      scrollToBottom();
    }
  }, [messages, scrollToBottom]);


  const onSendMessage = () => {
    if(!user) return; 

    const name = user.name; 
    const today = new Date();
    const hour = today.getHours();
    const minute = today.getMinutes();

    const senderObj = {
      id: Math.floor(Math.random() * (30 - 20)) + 20,
      name: name,
      msg: text,
      time: `${hour}.${minute}`,
      isSender: true,
    };
    
    IncidentsService.setMessage(text, incident.incident).then(f=> { 
      setMessages([...messages, senderObj]);
      setText("");
    });
  };

  const hendeleSearch = (ele) => {
    handleSearchData({ setState: setMessages, data: chatData, item: ele.value })
  }

  //delete
  const handleDeleteData = (id) => {
    setMessages(messages.filter((data) => data.id.toString() !== id.toString()))
  }

  // search
  const handleChange = (ele) => {
    let search = ele.target.value;
    if (search) {
      handleSearchData({ setState: setMessages, data: chatData, item: search })
    } else {
      setMessages(chatData)
    }
  }

  // copy msg
  const [copyMsg, setCopyMsg] = useState(false)
  const handleCopyMsg = () => {
    setCopyMsg(true)
    setTimeout(() => setCopyMsg(false), 1000)
  }

  // save msg
  const [saveMsg, setSaveMsg] = useState(false)
  const handleSaveMsg = () => {
    setSaveMsg(true)
    setTimeout(() => setSaveMsg(false), 1000)
  }
  return (
    <React.Fragment>
      <Col>
        <Card>
          <CardBody className="border-bottom">
            <Row>
              <Col md="4" xs="9">
                <h5 className="font-size-15 mb-1">{incident?.employeeRM}</h5>
                <p className="text-muted mb-0">
                  <i className="mdi mdi-circle text-success align-middle me-1" />{" "}
                  Online
                </p>
              </Col>
              <Col md="8" xs="3">
                <ul className="list-inline user-chat-nav text-end mb-0">
                  <li className="list-inline-item d-none d-sm-inline-block">
                    <Dropdown isOpen={searchMenu} toggle={() => { setSearchMenu(!searchMenu); }}>
                      <DropdownToggle tag="i" className="btn nav-btn" type="button">
                        <i className="bx bx-search-alt-2" />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end py-0 dropdown-menu-md">
                        <Form className="p-3">
                          <div className="m-0">
                            <InputGroup>
                              <Input type="text" className="form-control" placeholder="Cauta ..."  onChange={handleChange} />
                              <button className="btn btn-primary" type="submit">
                                <i className="mdi mdi-magnify"></i>
                              </button>
                            </InputGroup>
                          </div>
                        </Form>
                      </DropdownMenu>
                    </Dropdown>
                  </li>  
                </ul>
              </Col>
            </Row>
          </CardBody>
          <CardBody className="pb-0">
            <div>
              <div className="chat-conversation">
                <SimpleBar
                  style={{ marginBottom: "1rem", maxHeight: "260px" }}
                  ref={messageBox}>
                  <ul className="list-unstyled">
                    <li>
                      <div className="chat-day-title">
                        <span className="title">Astazi</span>
                      </div>
                    </li>
                    {(messages || []).map((message, index) => (
                      <React.Fragment key={index}>
                        {message["isSender"] ? (
                          <Sender message={message} handleDeleteData={handleDeleteData} handleCopy={handleCopyMsg} handleSaveMsg={handleSaveMsg} />
                        ) : (
                          <Reciver message={message} handleDeleteData={handleDeleteData} handleCopy={handleCopyMsg} handleSaveMsg={handleSaveMsg} />
                        )}
                      </React.Fragment>
                    ))}
                  </ul>
                </SimpleBar>
              </div>
            </div>
          </CardBody>

          <div className="p-3 chat-input-section">
            <Row>
              <Col>
                <div className="position-relative">
                  <input type="text" className="form-control rounded chat-input" placeholder="Scrie un mesaj..." 
                  value={text} onChange={e => setText(e.target.value)} />
                </div>
              </Col>
              <div className="col-auto">
                <Button
                  type="submit"
                  color="primary"
                  className="chat-send w-md "
                  onClick={() => onSendMessage()} >
                  <span className="d-none d-sm-inline-block me-2">Trimite</span>{" "}
                  <i className="mdi mdi-send" />
                </Button>
              </div>
            </Row>
          </div>
        </Card>
      </Col>
    </React.Fragment>
  );
};
 
const mapStateToProps = (state) => ({
  user: state.Login.user, // Replace 'auth' with the correct slice name
});

export default connect(mapStateToProps)(ChantBox);
