import React, { useEffect, useState } from "react";
import { DataTable } from 'primereact/datatable';
import { Card, Col } from "reactstrap"
import { Column } from 'primereact/column';
import { CardBody, Container } from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row } from "reactstrap";
import "primereact/resources/themes/md-light-deeppurple/theme.css";
import { Button } from "primereact/button";
import { ToastContainer } from "react-toastify";
import Breadcrumbs from "components/Common/Breadcrumb";

import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import Spinners from "components/Common/Spinner";
import { ProductService } from "services/ProductService"; 
import { FilterMatchMode } from 'primereact/api'; 

const JobList = ({ user }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [entities, setEntities] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        category: { value: null, matchMode: FilterMatchMode.CONTAINS },
        price: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const [statistics, setStatistics] = useState([
        { id: 1, title: "Total suma stoc disponibil", iconClass: "bx-x", value: 0 }
    ]);

    useEffect(() => {
        ProductService.getStockProducts().then(w => {
            setEntities(w);

            if(w && w.length){
                let totalPrice = w.reduce((sum, item) => sum + item.quantity * item.price, 0);
                
            setStatistics([{ id: 1, 
                title: "Total suma stoc disponibil", 
                iconClass: "bx-x", 
                value: totalPrice.toLocaleString() + " RON" }]);
            }
        }); 
    }, []);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
  
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const operationDetails = (options) => {
        return <Button onClick={() => {
            window.location.href = `/stock/products/edit/${options.idProduct}`;
        }} className="btn btn-sm btn-soft-success" color="success">Modifica</Button>
    }

    const stockStatusDetails = (options) => {
        return options.quantity > 0 ? <span className="badge badge-soft-success">{options.quantity} buc</span>
            : <span className="badge badge-soft-danger">Stoc epuizat</span>
    }

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Cautare ..." />
                </IconField>
            </div>
        );
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Gestiune stoc" breadcrumbItem="Produse" />

                    <Row> {(statistics || []).map((item, key) => (
                        <Col md="4" key={"_col_" + key}>
                            <Card className="mini-stats-wid">
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">
                                                {item.title}
                                            </p>
                                            <h4 className="mb-0">{item.value}</h4>
                                        </div>
                                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle bg-primary">
                                                <i className={"bx " + item.iconClass + " font-size-24"}></i>
                                            </span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                    </Row>
                    {
                        (user && user.isAdmin) ? (
                            <Row  >
                                <Col className="text-end">
                                    <Card className="d-inline-block">
                                        <Button color="success" className="btn-rounded "><Link style={{ color: 'white ' }} to="/stock/products/add">Adauga produse</Link></Button>
                                    </Card>
                                </Col>
                            </Row>) : (<></>)
                    }
                    {
                        isLoading ? <Spinners setLoading={setIsLoading} />
                            :
                            <Row>
                                <Col xs="12">
                                    <Card>
                                        <CardBody>
                                            <DataTable
                                                value={entities || []}
                                                paginator
                                                rows={10}
                                                rowsPerPageOptions={[5, 10, 25]}
                                                currentPageReportTemplate="Afiseaza {first} pana la {last} din {totalRecords} produse" 
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                filters={filters}
                                                dataKey="idProduct"
                                                
                                                onValueChange={(x)=>{
                                                    let sumProducts = x.reduce((sum, item) => sum = sum + (item.quantity * item.price), 0); 
                                                    if(!sumProducts) sumProducts = 0;
                                                    
                                                    setStatistics([{ id: 1, 
                                                        title: "Total suma stoc disponibil", 
                                                        iconClass: "bx-x", 
                                                        value: sumProducts.toLocaleString() + " RON" }]); 
                                               
                                                }}
                                                size='small'
                                                globalFilterFields={['name','category','quantity']}
                                                header={renderHeader}
                                                emptyMessage="Nu au fost gasite rezultate">
                                                <Column sortable field="name" header="Denumire produs" style={{ minWidth: '12rem' }} />
                                                <Column field="category" header="Categorie" style={{ minWidth: '12rem' }} />
                                                <Column sortable 
                                                body={stockStatusDetails} header="Stoc" style={{ minWidth: '12rem' }} />
                                                <Column field="price" header="Pret unitate (RON)" style={{ minWidth: '12rem' }} />
                                                <Column hidden={user && !user.isAdmin} body={operationDetails} style={{ minWidth: '12rem' }} />
                                            </DataTable>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                    }
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    user: state.Login.user, // Replace 'auth' with the correct slice name
});

export default connect(mapStateToProps)(JobList);