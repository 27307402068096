import { get, post } from "../helpers/api_helper"

export const ReportingService = { 
    getTaskDistributedProducts(idTask){
        return get(`reports/task/distributedproducts/${idTask}`);
    },
    getEmployeeStockStatistics(){
        return get(`reports/stockrequests/employee/statistics`);
    },
    getStockStatistics(){
        return get(`reports/stockrequests/statistics`);
    },
    getClientReport(idClient){
        return get(`reports/clients/pharmacy/${idClient}`);
    },
    getClientInsitutionReport(idClient){
        return get(`reports/clients/healthfacility/${idClient}`);
    }, 
    getMedicPlannificationHistory(idMedic){
        return get(`reports/medics/tasks/${idMedic}`);
    },
    getInactiveClients(){
        return get('reports/clients/activity'); 
    },
    getInactiveMedics(){
        return get('reports/medics/activity'); 
    },
    getStockRequests(){ 
        return get('reports/stockrequests'); 
    },
    getTaskHistory(){
        return get('reports/tasks/calendar'); 
    },
    getTaskHistory2(){
        return get('reports/tasks'); 
    },
    getClients(idEmployee){
        return get(`reports/clients?IdEmployee=${idEmployee}`);
    },
    getIncidents(IdEmployee){
        return get(`reports/incidents/${IdEmployee}`); 
    },
    getUnregisteredClients(){ 
        return get('reports/unregisteredclients'); 
    },
    getMedicsPlanificationHistory(){ 
        return get('reports/medics/tasks'); 
    },
    getMedicalPrescriptions(){ 
        return get('reports/medicalprescriptions'); 
    },
    getMedicsDistributedProducts(){ 
        return get('reports/medics/products');  
    }
}