
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import {
    Label, FormFeedback, Form
} from 'reactstrap';

import { Badge } from 'primereact/badge';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { Button } from 'reactstrap';

import Spinners from "components/Common/Spinner";
import "flatpickr/dist/themes/material_blue.css";
import FlatPickr from "react-flatpickr";
import Select from "react-select";
import { TasksService } from 'services/TasksService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { EmployeeService } from 'services/EmployeeService';
import Breadcrumbs from "components/Common/Breadcrumb";
import { RoutesService } from 'services/RoutesService';
import { connect } from "react-redux";


const TaskRoutePlanificationComponent = ({user}) => {
    const [isLoading, setIsLoading] = useState(true);
    document.title = "Planificare ruta de activitate";

    const toast = useRef(null);

    const [currentTask, setCurrentTask] = useState({
        datePlanned: null,
        idEmployee: '',
        idRoute: null,
        institution: false,
        routes: [] // {idRouteDetail: -1, Medics: []}
    });

    const [employeeList, setEmployeeList] = useState([]);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [routesList, setRoutesList] = useState([]);
    const [routeDetails, setRouteDetails] = useState([]);
    const [currentRouteDetail, setCurrentRouteDetail] = useState({});
    const [currentRouteDetailSaved, setCurrentRouteDetailSaved] = useState(null);
    const [allMedicsSelected, setAllMedicsSelected] = useState(false);
    const [commitChangesDisabled, setCommitChangesDisabled] = useState(false);
    const [routePlanificationSaved, setRoutePlanificationSaved] = useState(null);

    useEffect(() => {
        EmployeeService.getEmployees().then(f => { 
            setEmployeeList(f);

            if (f && f.length == 1) { 
                employeeChangedHandler({ idEmployee: f[0].idEmployee });
            }
        });
    }, []);

    // schimbare angajat
    const employeeChangedHandler = (e) => {
        setCurrentTask(prev => {
            return {
                ...prev,
                idRoute: -1,
                idEmployee: e.idEmployee
            }
        });

        setCurrentRouteDetail({});
        setCurrentRouteDetailSaved(null);
        setRouteDetails([]);

        if (e) {
            TasksService.getRoutesByEmployee(e.idEmployee).then(f => { 
                setRoutesList(f);
                setRoutePlanificationSaved(null);
            });
        }
    }

    const onRouteChangeHandler = (e) => {
        if (e) {
            TasksService.getRouteDetails(e.idRoute).then(f => {
                // setam ruta in task
                setCurrentTask(prev => {
                    return {
                        ...prev,
                        idRoute: e.idRoute
                    }
                });
 
                //baga datele in tabel
                setRouteDetails(f);
                setRoutePlanificationSaved(f);
                setCurrentRouteDetailSaved(null);
            });
        }
    }

    // salveaza ruta 
    const commitChanges = () => {
        setFormSubmitted(true);
        setCommitChangesDisabled(true);

        if (currentTask.idEmployee == -1
            || currentTask.idRoute == -1
            || currentTask.datePlanned == null
            || (!routeDetails || routeDetails.length == 0)) {
            setCommitChangesDisabled(false);
            toast.current.show({ severity: 'error', summary: 'Eroare', detail: 'Completati toate campurile obligatorii' });
            return;
        } else { 
            let task = {
                ...currentTask,
                datePlanned: new Date(currentTask.datePlanned).toISOString(),
                idRoute: currentTask.idRoute,
                routes: routeDetails.map(f => {
                    return {
                        idRouteDetail: f.idRouteDetail,
                        medics: f?.medics?.filter(f => f.selected).map(f => f.idMedic)
                    }
                })
            };

            RoutesService.saveRoutePlanification(task).then(f => { 
                if (f.success) {
                    toast.current.show({ severity: 'success', summary: 'Succes', detail: 'Planificarea a fost salvata cu succes' });
                } else {
                    setFormSubmitted(false);
                    setCommitChangesDisabled(false);
                    toast.current.show({ severity: 'error', summary: 'Eroare', detail: 'Eroare la salvarea planificarii: ' + f.message });
                }
            });
        }
    }

    const medicsRowDetails = (options) => {
        const medicsOk = <><Badge severity="success" value={`${options?.medics?.filter(f => f.selected).length} / ${options?.medics?.length}`}></Badge></>;
        const medicsNotOk = <><Badge size='small' severity="danger" value="Lipsa medici"></Badge></>;
        return (
            <div className="list-unstyled hstack gap-1 mb-0">
                <li>
                    {options?.medics?.length > 0 ? medicsOk : medicsNotOk}
                </li>
            </div>
        );
    }

    const operationsDetails = (options) => {
        return (
            <div className="list-unstyled hstack gap-1 mb-0">
                <li>
                    <Button
                        className="btn btn-sm btn-soft-info"
                        onClick={() => {
                      
                            setCurrentRouteDetail(options);
                            setCurrentRouteDetailSaved(options);
                            setVisible(true);
                        }}
                        id={`edit_${options.id}`}>
                        <i className="bx bx-edit" />
                    </Button>
                </li>
                <li>
                    <Button
                        className="btn btn-sm btn-soft-danger"
                        id={`delete_${options.id}`}
                        onClick={() => {
                            setRouteDetails(prev => {
                                return prev.filter(f => f.idRouteDetail != options.idRouteDetail);
                            });
                        }}>
                        <i className="bx bx-trash" />
                    </Button>
                </li>
            </div>
        );
    }
    const [visible, setVisible] = useState(false);

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <span className="font-bold white-space-nowrap">Planifica vizitele</span>
        </div>
    );

    const footerContent = (
        <div className="d-flex justify-content-between">
            <div>
                <input
                    type="checkbox"
                    className="form-check-input me-1"
                    id="checkAllMedics"
                    checked={allMedicsSelected}
                    onChange={(xx) => { 
                        setAllMedicsSelected(xx.target.checked);  
                        if(currentRouteDetail && currentRouteDetail.medics){ 
                            setCurrentRouteDetail(prev => {
                                return {
                                    ...prev,
                                    medics: [...prev.medics].map(f => ({
                                        idMedic: f.idMedic,
                                        selected: xx.target.checked,
                                        name: f.name
                                    }))
                                }
                            });
                        }
                    }}
                />
                <label className="form-check-label" htmlFor="checkAllMedics">
                    Alege toti medicii
                </label>
            </div>

            <Button className="btn btn-success me-1" icon="pi pi-check" onClick={() => {
                setVisible(false);

                setRouteDetails(prev => {
                    console.log("prev", routeDetails);
                    console.log("currentRouteDetail", currentRouteDetail.medics);

                    return prev.map(f => {
                        if (f.idRoute == currentRouteDetail.idRoute && f.idRouteDetail == currentRouteDetail.idRouteDetail) {
                            console.log("found it", f);
                            return {
                                ...f,
                                medics: currentRouteDetail.medics
                            }
                        }
                        return f;
                    });
                });

            }} autoFocus>Salveaza</Button>
        </div>
    );

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Operational" breadcrumbItem="Planifica ruta" />
                    <Toast ref={toast} />
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <Toast ref={toast} />
                                {
                                    isLoading ? <Spinners setLoading={setIsLoading} /> :
                                        <Row>
                                            <Form>
                                                <Col lg={8}>
                                                    <Card>
                                                        <CardBody>
                                                            {
                                                              user && user.isAdmin &&  (
                                                            <div className="mb-3">
                                                                <Label htmlFor="employees">Planifica activitate pentru angajatul</Label>
                                                                <Select name="employees"
                                                                    value={employeeList?.find(f => f.idEmployee == currentTask?.idEmployee)}
                                                                    onChange={(e) => { console.log("hopa..."); employeeChangedHandler(e); }}
                                                                    options={employeeList}
                                                                    getOptionLabel={option => option.name}
                                                                    getOptionValue={option => option.idEmployee}
                                                                    placeholder="Nume si prenume"></Select>

                                                                {formSubmitted && currentTask.idEmployee == -1 ? (
                                                                    <FormFeedback type="invalid" className="d-block">Acest camp este obligatoriu de completat.</FormFeedback>
                                                                ) : null}
                                                            </div>)

}

                                                            <div className="mb-3">
                                                                <Label htmlFor="routes">Alege o ruta</Label>
                                                                <Select name="routes"
                                                                    value={routesList?.find(f => f.idRoute == currentTask?.idRoute) || null}
                                                                    onChange={(e) => { onRouteChangeHandler(e) }}
                                                                    options={routesList || []}
                                                                    getOptionLabel={option => option.label}
                                                                    getOptionValue={option => option.idRoute} 
                                                                    placeholder="Ce ruta alegi ?"></Select>

                                                                {formSubmitted && currentTask.idRoute == -1 ? (
                                                                    <FormFeedback type="invalid" className="d-block">Acest camp este obligatoriu de completat.</FormFeedback>
                                                                ) : null}
                                                            </div>

                                                            <div className="mb-3">
                                                                <Label htmlFor="planificationDate">Planifica vizitele in data de</Label>
                                                                <FlatPickr
                                                                    className="form-control"
                                                                    options={{ dateFormat: "d M, Y" }}
                                                                    placeholder="Alege o data"
                                                                    value={currentTask?.datePlanned}
                                                                    onChange={(e) => {
                                                                        console.log("Data:", e);
                                                                        setCurrentTask(prev => { return { ...prev, datePlanned: new Date(e).toISOString() } })
                                                                    }}
                                                                />

                                                                {formSubmitted && currentTask.datePlanned == null ? (
                                                                    <FormFeedback type="invalid" className="d-block">Acest camp este obligatoriu de completat.</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Form>
                                        </Row>

                                }
                            </CardBody>
                        </Card>

                        <Row>
                            <Dialog visible={visible} modal header={headerElement}
                                footer={footerContent}
                                style={{ width: '50rem' }}
                                onHide={() => { 
                                    if (!visible) return; 
                                    setAllMedicsSelected(false);
                                    setCurrentRouteDetail(currentRouteDetailSaved); 
                                    setVisible(false);
                                }}>
                                <div className="m-0">
                                    <div>
                                        <Row>
                                            <Col lg="12">
                                                <div className="mb-3">
                                                    <label className="control-label">
                                                        Medicii catre care se vor face vizitele
                                                    </label>

                                                    <Select
                                                        placeholder="alege un medic..."
                                                        appendTo="body"
                                                        isMulti={true}
                                                        isClearable={true}
                                                        options={currentRouteDetail?.medics || []}
                                                        getOptionValue={option => option.idMedic}
                                                        getOptionLabel={option => option.name}
                                                        value={currentRouteDetail?.medics?.filter(f => f?.selected) || []}
                                                        closeMenuOnSelect={true}
                                                        onChange={(e) => {
                                                            setCurrentRouteDetail(prev => {
                                                                return {
                                                                    ...prev,
                                                                    medics: [...prev.medics].map(f => ({
                                                                        idMedic: f.idMedic,
                                                                        selected: e?.find(ff => ff.idMedic == f.idMedic) ? true : false,
                                                                        name: f.name
                                                                    }))
                                                                }
                                                            });
                                                        }}
                                                        className="select2-selection"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Dialog>
                            <Card>
                                <CardBody>
                                    <div className='ms-auto'>
                                        <Button onClick={() => {
                                            if (routePlanificationSaved) {
                                                setRouteDetails(routePlanificationSaved);
                                            }
                                        }} className="btn me-2 mb-2" outline><i className="bx bx-user-voice align-middle me-1">
                                            </i> Reia de la inceput planificarea</Button>
                                    </div>

                                    <DataTable columnResizeMode="expand"
                                        resizableColumns
                                        value={routeDetails}
                                        className='.p-datatable-lg' style={{ "fontSize": "15px" }}
                                        size='small' paginator rows={10}
                                        dataKey="idRouteDetail"
                                        emptyMessage="Nu au fost gasite rezultate">
                                        <Column field="client" header="Unitatea" filterPlaceholder="Unitatea" />
                                        <Column field="clientType" header="Tip unitate" filterPlaceholder="Tip unitate" />
                                        <Column field="address" header="Adresa" filterPlaceholder="Adresa" />
                                        <Column field="city" header="Oras" filterPlaceholder="Oras" />
                                        <Column field="zone" header="Zona de lucru" filterPlaceholder="Zona de lucru" />
                                        <Column header="Medici" body={medicsRowDetails} filterPlaceholder="Medici" />
                                        <Column body={operationsDetails} style={{ minWidth: '12rem' }} />
                                    </DataTable>
                                </CardBody>
                            </Card>
                        </Row>

                        <Row className='mb-4'>
                            <Col lg={12}>
                                <div>
                                    <div className="hstack gap-2">
                                        <div className='ms-auto'>
                                            <Button onClick={commitChanges}
                                                disabled={commitChangesDisabled}
                                                className="btn btn-success me-1"><i className="bx bx-user-voice align-middle me-1"></i> Salveaza</Button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </div>
            </div>
        </React.Fragment >
    );
}


const mapStateToProps = (state) => ({
    user: state.Login.user,
});

export default connect(mapStateToProps)(TaskRoutePlanificationComponent); 