import React, { useEffect, useState, useRef } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import { Link } from 'react-router-dom' 
import { Dialog } from "primereact/dialog"; 
import { MedicService } from "services/MedicService"; 

const MedicsPrescriptionComponent = props => {
    document.title = "Raport prescriptii medicale";

    const navigate = useNavigate();
    const [medicalPrescriptions, setMedicPrescriptionsList] = useState([]); 
    const [globalFilter, setGlobalFilter] = useState(null);
    const [currentStockRequest, setCurrentStockRequest] = useState(null);
    const [informationModal, setInformationModal] = useState(false);

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <IconField iconPosition="left">
                <InputIcon className="bx bx-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cauta..." />
            </IconField>
        </div>
    );

    const actionBodyTemplate = (options) => {
        return (
            <div className="list-unstyled hstack gap-1 mb-0">
                <li>
                    <Button onClick={() => {
                        setCurrentStockRequest(medicalPrescriptions.find(f => f.idPrescription == options.idPrescription));
                        setInformationModal(!informationModal);

                    }}
                        className="btn btn-sm btn-soft-info"
                        id={`${options.idPrescription}`}>
                        <i className="bx bx-show-alt" />
                    </Button>
                </li>
                <li>
                    <Button onClick={() => { navigate(`/medic/prescriptions/${options.idPrescription}`); }}
                        className="btn btn-sm btn-danger"
                        id={`${options.idPrescription}`}>
                        <i className="bx bx-edit" />
                    </Button>
                </li>
                <li>
                    <Button disabled={!medicalPrescriptions.find(f => f.idPrescription == options.idPrescription).hasPrescription} onClick={() => {
                        let entity = medicalPrescriptions.find(f => f.idPrescription == options.idPrescription);
                        if(entity.hasPrescription){ 
                            location.href = `/prescriptions/download/${entity.idPrescription}`; 
                        }
                    }}
                        className="btn btn-sm btn-soft-warning"
                        
                        id={`${options.idPrescription}`}>
                        <i className="bx bx-download" />
                    </Button>
                </li>
            </div>
        );
    }

    useEffect(() => {
        MedicService.getMedicalPrescriptions().then(f => { 
            setMedicPrescriptionsList(f);
        });
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Rapoarte" breadcrumbItem="Prescriptii medicale" />
                    <Row className="d-flex justify-content-end" >
                        <Col xs="2" lg="2" md="2">
                            <Card style={{ backgroundColor: 'transparent' }}>
                                <Button color="success" className="btn-rounded "><Link style={{ color: 'white ' }} to="/medic/prescriptions/add">Adauga prescriptie</Link></Button>
                            </Card>
                        </Col>
                    </Row>

                    <Dialog header="Produse prescrise" visible={informationModal} style={{ width: '50vw' }}
                        onHide={() => { if (!informationModal) return; setInformationModal(false); }}>
                        <div>
                            <DataTable
                                value={currentStockRequest?.products || []}
                                dataKey="id"
                                rows={10}
                                rowsPerPageOptions={[5, 10, 25]}>
                                <Column field="label" header="Denumire"></Column>
                                <Column field="quantity" header="Cantitate produse" sortable></Column>
                                <Column field="price" header="Cost total" sortable style={{ minWidth: '10rem' }}></Column>
                            </DataTable>
                        </div>
                    </Dialog>

                    <Row>
                        <Col xs={12}>
                            <Row>
                                <Col xl={12}>
                                    <Card>
                                        <CardBody>
                                            <DataTable value={medicalPrescriptions}
                                                dataKey="idMedicalPrescription"
                                                paginator
                                                rows={10}
                                                header={header}
                                                rowsPerPageOptions={[5, 10, 25]}
                                                emptyMessage="Nu au fost gasite rezultate"
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                currentPageReportTemplate="Afiseaza {first} pana la {last} din {totalRecords} inregistrari"
                                                globalFilter={globalFilter}>
                                                <Column field="medicName" header="Numele medicului" sortable style={{ minWidth: '16rem' }}></Column>
                                                <Column field="specializations" header="Specialitate" sortable style={{ minWidth: '10rem' }}></Column>
                                                <Column field="zone" header="Zona" sortable style={{ minWidth: '10rem' }}></Column>
                                                <Column field="date" header="Data prescriptiei" sortable style={{ minWidth: '10rem' }}></Column>
                                                <Column field="productsCount" header="Cantitate produse" sortable style={{ minWidth: '10rem' }}></Column>
                                                <Column field="employeeName" header="Angajat" sortable style={{ minWidth: '10rem' }}></Column>
                                                <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                                            </DataTable>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default MedicsPrescriptionComponent;
