import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
 
import { Column } from 'primereact/column';
import {Badge} from 'primereact/badge';
import { DataTable } from 'primereact/datatable';
import { Button, Card, CardBody, Col, Container,  Input, Label, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";
// FlatPickr
import "flatpickr/dist/themes/material_blue.css";  
import { ReportingService } from "services/ReportingService";

const MedicPlanificationHistoryComponent = () => {
    document.title = "Raport personal medical";
    const { idMedic = -1 } = useParams();
    const locationOptions = useState([]);
    const competenceOptions = useState([]);
    const specializationOptions = useState([]);
    
    const [entity, setEntity] = useState({  
            idMedic: -1,
            medicType: "",
            name: '',
            email: '',
            mobile: '',  
            locations: [],
            competences: [],
            specializations: [] 
    });

    const [tasks, setTasks] = useState([]);

    // Load initial data
    useEffect(() => {
        if(idMedic > 0)
        ReportingService.getMedicPlannificationHistory(idMedic).then(f=>{
            console.log("Data:", f);
            setEntity(f.medic);
            setTasks(f.tasks);
        });
    }, []);


    const statusTaskColumn = (x) => {
        if(x.status == "Finalizat")
            return <Badge severity="success" value="Finalizat"/>;
        else if(x.status == "In lucru")
            return <Badge severity="info" value="In lucru"/>;
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Rapoarte" breadcrumbItem="Istoric planificari medici" /> 
                        <Row>
                            <Col lg={8}>
                                <Card>
                                    <CardBody>
                                        <div className="mb-3">
                                            <Label htmlFor="idMedicType">Tip specializare medic</Label>
                                            <Select
                                                name="idMedicType" 
                                                value={{value: 1, label: entity?.medicType}} 
                                                placeholder="Alege categoria medicului"
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="name">Numele medicului</Label>
                                            <Input
                                                id="name"
                                                name="name"
                                                type="text"
                                                value={entity.name}
                                                placeholder="Numele medicului..."
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="email">Adresa e-Mail</Label>
                                            <Input
                                                id="email"
                                                name="email"
                                                value={entity.email}
                                                type="email"
                                                placeholder="Adresa de email contact..."
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="mobile">Telefon mobil</Label>
                                            <Input
                                                id="mobile"
                                                name="mobile"
                                                type="text"
                                                placeholder="Telefon contact..."
                                                value={entity.mobile || ""}
                                            />
                                        </div>

                                        <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="competences">Zonele de lucru</Label>
                                                    <Select
                                                        isMulti
                                                        name="competences"
                                                        value={entity.locations || []} 
                                                    /> 
                                                </div>
                                            </Col>
 
                                        <Row className="mb-3">
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="competences">Competențe</Label>
                                                    <Select
                                                        isMulti
                                                        name="competences"
                                                        value={entity.competences || []} 
                                                        placeholder="Lipsa competențe" 
                                                    /> 
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="specializations">Specializări</Label>
                                                    <Select
                                                        isMulti
                                                        name="specializations"
                                                        value={entity.specializations || []}  
                                                        placeholder="Lipsa specializări"  
                                                    /> 
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg={4}> 
                                <Card>
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium">
                                                    Alte informatii
                                                </p>
                                                <h5 className="mb-0">
                                                     Vizite efectuate, {tasks?.length ?? 0}
                                                </h5>
                                            </div>
                                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                <span className="avatar-title rounded-circle bg-primary">
                                                    <i className={"bx bx-walk font-size-24"}></i>
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg={12}> 
                                <Card className="mt-4">
                                    <CardBody>
                                        <h4 className="card-title">Istoric vizite</h4>  
                                        <DataTable
                                            value={tasks || []}
                                            paginator
                                            rows={10}
                                            dataKey="id"
                                            emptyMessage="Nu au fost gasite rezultate">
                                            <Column field="datePlanned" header="Data" style={{ minWidth: '12rem' }} />
                                            <Column field="hour" header="Ora" style={{ minWidth: '12rem' }} />
                                            <Column body={statusTaskColumn} header="Status" style={{ minWidth: '12rem' }} />                          
                                            <Column field="clientName" header="Client" style={{ minWidth: '12rem' }} />     
                                            <Column field="reasons" header="Motivul vizitei" style={{ minWidth: '12rem' }} />
                                            <Column field="contests" header="Concursuri" style={{ minWidth: '12rem' }} />
                                            <Column field="productsCount" header="Produse distribuite" style={{ minWidth: '12rem' }} />
                                            <Column field="productsQuantity" header="Cantitate totala (buc)" style={{ minWidth: '12rem' }} /> 
                                            <Column field="observation" header="Observatii" style={{ minWidth: '12rem' }} />
                                        </DataTable>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row> 
                </Container>
            </div>
        </React.Fragment>
    );
};

export default MedicPlanificationHistoryComponent;
