
import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { connect } from "react-redux";
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { IconField } from 'primereact/iconfield';
import { Badge } from 'primereact/badge';
import { Row } from 'reactstrap';

import Breadcrumbs from "components/Common/Breadcrumb"
import { Container } from "reactstrap"

import { Card } from 'reactstrap';
import { CardBody } from 'reactstrap';
import { InputIcon } from 'primereact/inputicon';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ProductService } from 'services/ProductService';
import { CommonService } from 'services/CommonService';
import { NomenclatorService } from 'services/NomenclatorService';
import { RoutesService } from 'services/RoutesService';
import { EmployeeService } from 'services/EmployeeService';
import { HealthFacilityService } from 'services/HealthFacilityService';
import { PharmacyService } from 'services/PharmacyService';

import Select, { useStateManager } from "react-select";

const RouteDetailsNomenclatorComponent = ({user}) => {
    let emptyEntity = {
        id: null,
        idClientType: null,
        idClient: null,
        idRoute: null
    };

    const [entities, setEntities] = useState(null);
    const [entityDialog, setEntityDialog] = useState(false);
    const [deleteEntityDialog, setDeleteEntityDialog] = useState(false);
    const [entity, setEntity] = useState(emptyEntity);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const [clientTypes, setClientTypes] = useState(null);
    const [clients, setClients] = useState(null);
    const [routes, setRoutes] = useState(null);

    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        RoutesService.getAllRouteDetails().then(f => {
            setEntities(f);

            CommonService.getClientTypes().then(c => {
                setClientTypes(c);
            });
        });
    }, []);

    const openNew = () => {  
        RoutesService.getRoutes(user?.isAdmin ? "-1": user?.id).then(r => {
            setRoutes(r); 
            setEntity(emptyEntity);
            setSubmitted(false);
            setEntityDialog(true);
        }); 
    };

    const hideDialog = () => {
        setSubmitted(false);
        setEntityDialog(false);
    };

    const hideDeleteEntityDialog = () => {
        setDeleteEntityDialog(false);
    };

    const commitChanges = () => {
        setSubmitted(true);

        let _entities = [...entities];

        const successAction = () => {
            setEntities(_entities);
            setEntityDialog(false);
            setEntity(emptyEntity);
            setSubmitted(false);
            toast.current.show({ severity: 'success', summary: 'Confirmare', detail: 'Datele au fost actualizate', life: 3000 });
        }

        const failedAction = () => {
            toast.current.show({ severity: 'error', summary: 'Eroare', detail: 'Datele nu au fost actualizate', life: 3000 });
            setSubmitted(false);
        }


        if (entity.idClientType != null && entity.idClient != null && entity.idRoute != null) {

            let _entity = { ...entity };
            if (_entity.institution)
                _entity.idHealthFacility = entity.idClient;
            else
                _entity.idPharmacy = entity.idClient;
            _entity.idClient = entity.idClient;
            let client = clients.find(o => o.idClient == entity.idClient);
            _entity.client = client.name;

            if (entity.id) {
                const index = findIndexById(entity.id);
                _entities[index] = _entity;

                RoutesService.updateRouteDetails(_entity).then(f => {
                    if (f.success) {
                        successAction();
                    } else {
                        failedAction();
                    }
                });

            } else {
                _entity.id = createId();
                _entities.push(_entity);

                RoutesService.createRouteDetails(_entity).then(f => {
                    if (f.success) {
                        successAction();
                    } else {
                        failedAction();
                    }
                });
            }
        }
    };

    const editEntity = (entity) => { 
        if (entity) {
            console.log(entity);
            
            RoutesService.getRoutes(entity.idEmployee).then(r => {
                setRoutes(r); 

                if (entity.institution) {
                    HealthFacilityService.getHealthFacilityByClientType(entity.idClientType).then(f => {
                        setClients(f);
                        setEntity(entity);
                        setEntityDialog(true);
                    });
                } else {
                    PharmacyService.getPharmaciesByClientType(entity.idClientType).then(f => {
                        setClients(f);
                        setEntity(entity);
                        setEntityDialog(true);
                    });
                }
            });
        }
    };

    const confirmDeleteEntity = (entity) => {
        setEntity(entity);
        setDeleteEntityDialog(true);
    };

    const deleteEntity = () => {
        let _entities = entities.filter((val) => val.id !== entity.id);

        RoutesService.deleteRouteDetails(entity.id).then(f => {
            if (f.success) {
                setEntities(_entities);
                setDeleteEntityDialog(false);
                setEntity(emptyEntity);
                toast.current.show({ severity: 'success', summary: 'Confirmare', detail: 'Datele au fost sterse', life: 3000 });
            } else {
                toast.current.show({ severity: 'error', summary: 'Eroare', detail: 'Datele nu au fost sterse', life: 3000 });
            }
        });
    };

    const findIndexById = (id) => {
        let index = -1;

        for (let i = 0; i < entities.length; i++) {
            if (entities[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        return id;
    };

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="Adauga" icon="bx bx-plus" severity="success" onClick={openNew} />
            </div>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="bx bx-pencil" rounded className="mr-3" style={{ "marginRight": "5px" }}
                 onClick={() => editEntity(rowData)} />
                <Button icon="bx bx-trash" rounded severity="danger" onClick={() => confirmDeleteEntity(rowData)} />
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <IconField iconPosition="left">
                <InputIcon className="bx bx-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cauta..." />
            </IconField>
        </div>
    );

    const entityDialogFooter = (
        <React.Fragment>
            <Button label="Inchide" icon="bx bx-x" outlined style={{ "marginRight": "5px" }} onClick={hideDialog} />
            <Button label="Salveaza" icon="bx bx-check" onClick={commitChanges} />
        </React.Fragment>
    );

    const deleteentityDialogFooter = (
        <React.Fragment>
            <Button label="Nu" icon="bx bx-check" outlined style={{ "marginRight": "5px" }} onClick={hideDeleteEntityDialog} />
            <Button label="Da" icon="bx bx-x" severity="danger" onClick={deleteEntity} />
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Administrare" breadcrumbItem="Detalii rute" />
                <Container fluid>
                    <Toast ref={toast} />
                    <Card>
                        <CardBody>
                            <Row>
                                <div>
                                    <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
                                    <DataTable ref={dt} value={entities}
                                        dataKey="id"
                                        paginator rows={5}
                                        rowGroupMode="rowspan" groupRowsBy="employee"
                                        rowsPerPageOptions={[5, 10, 25]}
                                        globalFilterFields={['label', 'description', 'employee', 'clientType', 'client']}
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Afiseaza {first} pana la {last} din {totalRecords} inregistrari" globalFilter={globalFilter} header={header}>
                                        <Column field="label" header="Denumire ruta" sortable style={{ minWidth: '16rem' }}></Column>
                                        <Column field="description" header="Descriere" sortable style={{ minWidth: '10rem' }}></Column>
                                        <Column field="employee" header="Angajat" sortable style={{ minWidth: '10rem' }}></Column>
                                        <Column field="clientType" header="Tip client" sortable style={{ minWidth: '10rem' }}></Column>
                                        <Column field="client" header="Client" sortable style={{ minWidth: '10rem' }}></Column>
                                        <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                                    </DataTable>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>


                    <Dialog visible={entityDialog}
                    maximizable 
                    style={{ width: '50vw' }} 
                      breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                        header="Detalii" modal className="p-fluid" footer={entityDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="city" className="font-bold">
                                Denumire ruta
                            </label>
                            <Select placeholder="Alege ruta"
                                options={routes || []}
                                styles={
                                    {
                                        menu: base => ({
                                            ...base,
                                            zIndex: 100
                                        })
                                    }
                                }
                                value={routes?.find(o => o.idRoute == entity?.idRoute)}
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.idRoute}
                                onChange={(e) => setEntity(prev => { return { ...prev, idRoute: e.id } })} required />
                            {submitted && entity.idRoute == '' && <small className="p-error">Camp obligatoriu</small>}
                        </div>

                        <div className="field">
                            <label htmlFor="city" className="font-bold">
                                Tip client
                            </label>
                            <Select placeholder="Alege tipul clientului"
                                options={clientTypes || []}
                                styles={
                                    {
                                        menu: base => ({
                                            ...base,
                                            zIndex: 100
                                        })
                                    }
                                }
                                value={clientTypes?.find(o => o.idClientType == entity?.idClientType)}
                                onChange={(e) => { 
                                    setClients([]);
                                    setEntity(prev => {
                                        return {
                                            ...prev,
                                            idClientType: e.id,
                                            institution: e.institution
                                        }
                                    })

                                    if (e.institution) {
                                        HealthFacilityService.getHealthFacilityByClientType(e.id).then(f => { 
                                            setClients(f);
                                        });
                                    } else {
                                        PharmacyService.getPharmaciesByClientType(e.id).then(f => { 
                                            setClients(f);
                                        });
                                    }

                                }
                                } required autoFocus />
                            {submitted && entity.idClientType == '' && <small className="p-error">Camp obligatoriu.</small>}
                        </div>

                        <div className="field">
                            <label htmlFor="city" className="font-bold">
                                Denumire {entity.idClientType <= 0 ? <span>client</span> : (entity?.institution ?
                                    <Badge severity="primary" value="instituție" /> : <Badge severity="success" value="farmacie" />)}
                            </label>
                            <Select placeholder="Alege clientul"
                                styles={{
                                    menu: base => ({
                                        ...base,
                                        zIndex: 101
                                    })
                                }}
                                options={clients || []}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.idClient}
                                value={clients?.find(o => o.idClient == entity?.idClient) || null}
                                onChange={(e) => setEntity(prev => { return { ...prev, idClient: e.idClient } })} required autoFocus />
                            {submitted && entity.idClient == '' && <small className="p-error">Camp obligatoriu.</small>}
                        </div>


                    </Dialog>

                    <Dialog visible={deleteEntityDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirmare" modal footer={deleteentityDialogFooter}
                        onHide={hideDeleteEntityDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {entity && (
                                <span>
                                    Esti de acord sa stergi <b>{entity.label}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>
                </Container>
            </div>
        </React.Fragment>
    );
}
 
const mapStateToProps = (state) => ({
    user: state.Login.user,
});

export default connect(mapStateToProps)(RouteDetailsNomenclatorComponent);