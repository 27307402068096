import React, { useEffect, useState, useRef } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import { ReportingService } from "services/ReportingService";

const TasksReport2Component = props => {
    document.title = "Raport planificari";

    const navigate = useNavigate();
    const [tasks, setTasks] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [statistics, setStatistics] = useState([
        { id: 1, title: "Planificari unitati sanitare", iconClass: "bx-first-aid", value: 0 },
        { id: 2, title: "Planificari farmacii", iconClass: "bx-plus", value: 0 }
    ]);


    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <IconField iconPosition="left">
                <InputIcon className="bx bx-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cauta..." />
            </IconField>
        </div>
    );

    const actionBodyTemplate = (options) => {
        return (
            <div className="list-unstyled hstack gap-1 mb-0">
                <li>
                    <Button
                        onClick={() => navigate(`/tasks/edit/${options.idTask}`)}
                        className="btn btn-sm btn-soft-info"
                        id={`${options.idTask}`}>
                        <i className="bx bx-edit" />
                    </Button>
                </li>
            </div>
        );
    }

    const statusBodyTemplate = (rowData) => {
        if (rowData.status === "Finalizat") {
            return <span className="badge bg-success">{rowData.status}</span>;
        }
        if (rowData.status === "In lucru") {
            return <span className="badge bg-info">{rowData.status}</span>;
        }
        return <span className="badge bg-danger">{rowData.status}</span>;
    }


    useEffect(() => {
        ReportingService.getTaskHistory2().then(f => {
            console.log("Tasks:", f);
            setTasks(f);
            let institutionsCount = 0;
            let pharmaciesCount = 0;

            if (f && f.length) {
                let institutions = f.filter(f => f.institution);
                institutionsCount = institutions.length;
                pharmaciesCount = f.length - institutions.length;
                if (pharmaciesCount < 0) pharmaciesCount = 0;
            }

            let stats = [...statistics];
            stats[0].value = institutionsCount.toLocaleString();
            stats[1].value = pharmaciesCount.toLocaleString();
            setStatistics(stats);
        });
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Rapoarte" breadcrumbItem="Planificari" />
                    <Row> {(statistics || []).map((item, key) => (
                        <Col md="4" key={"_col_" + key}>
                            <Card className="mini-stats-wid">
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">
                                                {item.title}
                                            </p>
                                            <h4 className="mb-0">{item.value}</h4>
                                        </div>
                                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle bg-primary">
                                                <i className={"bx " + item.iconClass + " font-size-24"}></i>
                                            </span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Row>
                                <Col xl={12}>
                                    <Card>
                                        <CardBody>
                                            <DataTable value={tasks}
                                                dataKey="idTask"
                                                paginator rows={10}
                                                header={header}
                                                rowsPerPageOptions={[5, 10, 25]}
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                currentPageReportTemplate="Afiseaza {first} pana la {last} din {totalRecords} inregistrari"
                                                globalFilter={globalFilter}>
                                                <Column field="datePlanned" header="Data planificarii" sortable style={{ width: 'fit-content' }}></Column>
                                                <Column field="client" header="Client" sortable style={{ width: 'fit-content' }}></Column>
                                                <Column field="clientType" header="Tip client" sortable style={{ width: 'fit-content' }}></Column>
                                                <Column body={statusBodyTemplate} header="Status planificare" sortable style={{ width: 'fit-content' }}></Column>
                                                <Column field="city" header="Oras" sortable style={{ width: 'fit-content' }}></Column>
                                                <Column field="zone" header="Zona" sortable style={{ width: 'fit-content' }}></Column>
                                                <Column field="employee" header="Angajat" sortable style={{ width: 'fit-content' }}></Column>
                                                <Column field="jobTitle" header="Functie" sortable style={{ width: 'fit-content' }}></Column>
                                                <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>

                                            </DataTable>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default TasksReport2Component;
