import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

import { Column } from 'primereact/column';
import { Badge } from 'primereact/badge';
import { DataTable } from 'primereact/datatable';
import { Button, Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";
import { ReportingService } from "services/ReportingService";

const ClientReportDetailsComponent = () => {
    document.title = "Raport client";
    const location = useLocation();

    const isInsitution = location.pathname.includes('/reports/clients/institution');

    const { idClient = -1 } = useParams();

    const [entity, setEntity] = useState(null);
    // Load initial data
    useEffect(() => {
        console.log("PATH:", isInsitution);

        if (idClient > 0) {
            if (isInsitution) {
                ReportingService.getClientInsitutionReport(idClient).then(data => {
                    console.log(data);
                    try {
                        setEntity(data);
                    } catch (error) {
                        console.log(error);
                    }
                });
            } else {
                ReportingService.getClientReport(idClient).then(data => {
                    console.log(data);
                    try {
                        setEntity(data);
                    } catch (error) {
                        console.log(error);
                    }
                });
            }
        }
    }, []);


    const statusTaskColumn = (x) => {
        if (x.status == "Finalizata")
            return <Badge severity="success" value="Finalizat" />;
        else if (x.status == "In lucru")
            return <Badge severity="info" value="In lucru" />;
        else return <span>{x.status}</span>
    }

    const actionsBodyTemplate = (x) => {
        return <></>
    }

    const statusMedicBody = (x) => {
        if(x.active)
            return <Badge severity="success" value="Activ" />
        else
        return <Badge severity="danger" value="Inactiv" />
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Rapoarte" breadcrumbItem="Raport client" />
                    <Row>
                        <Col lg={8}>
                            <Card>
                                <CardBody>
                                    <div className="mb-3">
                                        <Label htmlFor="idClientType">Tip client</Label>
                                        <Select
                                            name="idClientType"
                                            value={entity?.clientType}
                                            placeholder="Categoria medicului"
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <Label htmlFor="name">Denumire client</Label>
                                        <Input
                                            id="name"
                                            name="name"
                                            type="text"
                                            value={entity?.name}
                                            placeholder="Denumire client..."
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <Label htmlFor="email">Adresa e-Mail</Label>
                                        <Input
                                            id="email"
                                            name="email"
                                            value={entity?.email}
                                            type="email"
                                            placeholder="Adresa de email contact..."
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <Label htmlFor="mobile">Telefon mobil</Label>
                                        <Input
                                            id="mobile"
                                            name="mobile"
                                            type="text"
                                            placeholder="Telefon contact..."
                                            value={entity?.mobile || ""}
                                        />
                                    </div>

                                    <Col lg={6}>
                                        <div className="mb-3">
                                            <Label htmlFor="competences">Zona de lucru</Label>
                                            <Select
                                                name="competences"
                                                value={entity?.zone}
                                            />
                                        </div>
                                    </Col>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={4}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">
                                                Alte informatii
                                            </p>
                                            <h5 className="mb-0">
                                                Vizite efectuate, {entity?.tasks?.length ?? 0}
                                            </h5>
                                        </div>
                                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle bg-primary">
                                                <i className={"bx bx-walk font-size-24"}></i>
                                            </span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        {
                            !isInsitution ? (<></>) : (
                                <Col lg={12}>
                                    <Card className="mt-4">
                                        <CardBody>
                                            <h4 className="card-title">Medici</h4>
                                            <DataTable
                                                value={entity?.medics || []}
                                                paginator
                                                rows={10}
                                                dataKey="idMedic"
                                                emptyMessage="Nu au fost gasite rezultate">
                                                <Column field="name" header="Nume" style={{ width: 'fit-content' }} />
                                                <Column field="jobTitle" header="Functie" style={{ width: 'fit-content' }} />
                                                <Column field="specializations" header="Specializari" style={{ width: 'fit-content' }} />
                                                <Column field="competences" header="Competente" style={{ width: 'fit-content' }} />
                                                <Column body={statusMedicBody} header="Status" style={{ width: 'fit-content' }} />
                                            </DataTable>
                                        </CardBody>
                                    </Card>
                                </Col>)
                        }

                        <Col lg={12}>
                            <Card className="mt-4">
                                <CardBody>
                                    <h4 className="card-title">Istoric vizite efectuate</h4>
                                    <DataTable
                                        value={entity?.tasks || []}
                                        paginator
                                        rows={10}
                                        dataKey="id"
                                        emptyMessage="Nu au fost gasite rezultate">
                                        <Column field="datePlanned" header="Data" style={{ width: 'fit-content' }} />
                                        <Column field="time" header="Ora" style={{ width: 'fit-content' }} />
                                        <Column body={statusTaskColumn} header="Status" style={{ width: 'fit-content' }} />
                                        <Column field="name" header="Client"
                                            hidden={!isInsitution}
                                            style={{ width: 'fit-content' }} />
                                        <Column field="reasons" header="Motivul vizitei" style={{ width: 'fit-content' }} />
                                        <Column field="contests" header="Concursuri" style={{ width: 'fit-content' }} />
                                        <Column field="products" header="Produse distribuite" style={{ width: 'fit-content' }} />
                                        {/* <Column field="quantity" header="Cantitate totala (buc)" style={{ width: 'fit-content' }} />*/}
                                        <Column field="observation" header="Observatii" style={{ width: 'fit-content' }} /> 
                                        <Column body={actionsBodyTemplate} style={{ width: 'fit-content' }} />
                                    </DataTable>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ClientReportDetailsComponent;
