import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SimpleBar from "simplebar-react";

import { Button, Card, CardBody, Col, Row } from 'reactstrap';
 
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify"; 
import { DashboardService } from "services/DashboardService";
import avatar from "../../assets/images/biovitality/logo_notifications.png"

function NotificationsComponenet({ user }) {
    document.title = "Notificari";

    const [entities, setEntities] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        DashboardService.getNotifications().then(data => {
            setEntities(data);
        });
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid"> 
                    {
                        isLoading ? <Spinners setLoading={setIsLoading} />
                            :
                            <Row>
                                <Col xs="12">
                                    <Col xl="12">
                                        <Card>
                                            <CardBody>
                                                <h4 className="card-title mb-4">Notificari</h4>
                                                <SimpleBar style={{ maxHeight: "390px" }}>
                                                    <ul className="list-group">
                                                        {(entities || []).map((item, index) => (
                                                            <li className="list-group-item border-0" key={index}>
                                                                <div className="d-flex">
                                                                    <div className="flex-shrink-0 me-3">
                                                                        <div className="avatar-xs">
                                                                            <span className="avatar-title rounded-circle bg-light">
                                                                                <img src={avatar}    alt="" height="18" />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-grow-1">
                                                                        <h5 className="font-size-14">{item.label}</h5>
                                                                        <p className="text-muted">{item.description}</p>

                                                                        <div className="float-end">
                                                                            <p className="text-muted mb-0">
                                                                                <i className="mdi mdi-account me-1" /> Administrator
                                                                            </p>
                                                                        </div>  
                                                                        <p className="text-muted mb-0">{new Date(item.dateCreated).toLocaleDateString('en-GB')}</p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </SimpleBar>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Col>
                            </Row>
                    }
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}


const mapStateToProps = (state) => ({
    user: state.Login.user, // Replace 'auth' with the correct slice name
});

export default connect(mapStateToProps)(NotificationsComponenet);