import {get, post} from "../helpers/api_helper"

export const EmployeeService = { 
    getEmployees() { 
        return get("employees");
    },
    getRMEmployees() {
        return get("employees/rm");;
    },
    getEmployeeProfile(idEmployee) {
        return get(`employee/profile/${idEmployee}`); 
    },
    updateEmployee(data) {
        return post(`employee/update`, data);
    },
    getEmployeeStatistiscs(){
        return get('employees/statistics');
    }, 
    deleteEmployee(Id){
        return get(`employee/delete/${Id}`); 
    },
    getEmployeesByZone(IdZone){
        return get(`employees/location/${IdZone}`); 
    },
    getEmployeesByZones(zones){ 
        return post(`employees/locations`, zones);
    },
    getEmployees2(IdZone){ 
        return get(`employees/location/${IdZone}`); 
    },
    createEmployee(employee){
        return post(`employee/create`, employee);
    }
};