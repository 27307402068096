import { get, post } from "../helpers/api_helper"

export const PharmacyService = {
    getPharmacies() {
         return get("pharmacies");
    }, 
    getPharmacyTypes () {
         return get('nomenclator/pharmacy/types');
    },
    getPharmaciesByClientType(idClientType){
        return get(`pharmacy/clienttype/${idClientType}`); 
    },
    getPharmacy(idPharmacy){ 
      return get(`pharmacies/${idPharmacy}`);//, { credentials: "include" }).then(f => f.json());
    },
    deletePharmacy(idPharmacy){
        return get(`pharmacy/delete/${idPharmacy}`);//, { credentials: "include" }).then(f => f.json());
    },
    updatePharmacy(entity){
       return post("pharmacy/update", entity);
    },
    createPharmacy(entity){
        return post("pharmacy/create", entity);
    },
    getPharmacyByEmployee(IdPharmacy){
        return get(`pharmacies/employee/${IdPharmacy}`);
    },
    updatePharmacyByEmployee(entity){
        return post("pharmacies/employee/update", entity);
    }
};