import React from "react"
import { Row, Col, Card } from "reactstrap"

//Import Images
import profileImg from "../../../assets/images/profile-img.png"

const CardWelcome = () => {
  return (
    <React.Fragment>
      <Col xl="4">
        <Card className="bg-primary-subtle">
          <div>
            <Row>
              <Col xs="7">
                <div className="text-primary p-3">
                  <h5 className="text-primary">Bine ai venit!</h5>
                  <p style={{"marginBottom":"17%"}}>Succesul tău depinde de ceea ce faci astăzi, iar astazi deja a început.</p>
                </div>
              </Col>
              <Col xs="5" className="align-self-end">
                <img src={profileImg}  alt="" className="img-fluid" />
              </Col>
            </Row>
          </div>
        </Card>
      </Col>
    </React.Fragment>
  );
}

export default CardWelcome;