import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { apiError, loginSuccess, logoutUserSuccess } from "../store/auth/login/actions"; 
import {useState} from 'react';
 
import { LOGIN_USER, LOGOUT_USER } from "../store/auth/login/actionTypes";
const Authmiddleware = (props) => {
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.Login.user); // Replace "Login" with your reducer slice
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const data = localStorage.getItem("authUser");
    if (data) {
      const parsedData = JSON.parse(data); 
      dispatch(loginSuccess(parsedData)); // Dispatch the Redux action
    }
    setIsInitialized(true); // Mark initialization as complete
  }, [dispatch]);

  // Show a loading screen while rehydrating
  if (!isInitialized) {
    return <div>Incarcare...</div>;
  }
 

  // Redirect to login if no authenticated user exists
  if (!authUser || (authUser && new Date(authUser?.dateExpiration) < Date.now())) {
    return <Navigate to={{ pathname: "/login", state: { from: props.location } }} />;
  }

  // Render children if authenticated
  return <React.Fragment>{props.children}</React.Fragment>;
};

export default Authmiddleware;