import React, { useState } from "react"
import { Col, Card, CardBody, Nav, NavItem, NavLink, Table, Input, Label } from "reactstrap"
import { Link } from "react-router-dom"

//Simple bar
import SimpleBar from "simplebar-react"

const IncidentsOverview = ({props}) => {
  let data = [];
 
  if(props){ 
    data = props;
  }

  return (
    <React.Fragment>
      <Col>
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">Incidente</h4>
            <Nav pills className="bg-light rounded">
              <NavItem>
                <NavLink style={{ cursor: "default" }}
                  className={"active bg-danger"}>
                  Nerezolvate
                </NavLink>
              </NavItem>
            </Nav>

            <div className="mt-4">
              <SimpleBar style={{ maxHeight: "250px" }}>
                <div className="table-responsive">
                  <Table className="table table-nowrap align-middle table-hover mb-0">
                    <tbody>
                      {
                        (data)?.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <h5 className="text-truncate font-size-14 mb-1">
                                <Link to="#" 
                                  style={{cursor:"default"}}
                                  className="text-dark">
                                  {item.clientName}
                                </Link>
                              </h5>
                              <p className="text-muted mb-0">Initiat de catre {item.createdBy} - acum {item.days} zile</p>
                            </td>
                            <td style={{ width: "90px" }}>
                              <div>
                                <ul style={{ "float": "right" }} className="list-inline mb-0 font-size-16">
                                  <li className="list-inline-item">
                                    <Link to={"/tasks/incidents/" + item.idIncident } className="text-info p-1">
                                      <i className="bx bx-search-alt-2" />
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                </div>
              </SimpleBar> 
                    {
                      props && props.length ? (
                        <div className=" bg-transparent">
                        <div className="text-center mt-4">
                          <Link to="/tasks/incidents" className="btn btn-primary waves-effect waves-light btn-sm">
                            Vezi mai multe <i className="mdi mdi-arrow-right ms-1" />
                          </Link>
                        </div>
                      </div>
                      ) : <></>
                    }
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default IncidentsOverview
