import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import Spinners from "components/Common/Spinner";
import { Link } from 'react-router-dom';
import { NomenclatorService } from 'services/NomenclatorService';

const Nomenclator = () => {
    document.title = "Administrare nomenclator";
    const [isLoading, setIsLoading] = useState(true);   
    const [nomenclatorCategories1, setNc1] = useState([
        { id : 1, type:1,  url:"/nomenclator/counties", name : "Judete" , category : 0},
        { id : 2, type:1,  url:"/nomenclator/cities",name : "Orase" , category : 0},
        { id : 3, type:1,  url:"/nomenclator/zones",name : "Cartiere" , category : 0},
        { id : 4, type:1,  url:"/nomenclator/sectors",name : "Sectoare" , category : 0}
    ]);

    const [nomenclatorCategories2, setNc2] = useState([
        { id : 1, type:1, url:"/nomenclator/routes", name : "Rute" , category : 0},
        { id : 2, type:1,  url:"/nomenclator/routedetails",name : "Detalii rute" , category : 0}, 
        { id : 3, type:1,  url:"/nomenclator/planificationtypes",name : "Descriere planificari" , category : 0}
    ]);

    const [nomenclatorCategories3, setNc3] = useState([
        { id : 1, type:1,  url:"/nomenclator/mediccompetences",name : "Competente medici" , category : 0},
        { id : 2, type:1,  url:"/nomenclator/medicspecializations",name : "Specializari medici" , category : 0}, 
        { id : 3, type:1,  url:"/nomenclator/medicjobtypes",name : "Tip medic" , category : 0}
    ]);

    const [nomenclatorCategories4, setnC4] = useState([
        { id : 1, type:1,  url:"/nomenclator/clienttypes",name : "Tip client" , category : 0},
        { id : 2, type:1,  url:"/nomenclator/contests",name : "Concursuri" , category : 0},
        { id : 3, type:1,  url:"/nomenclator/studies",name : "Studii" , category : 0},
        { id : 4, type:1,  url:"/nomenclator/materials",name : "Materiale" , category : 0},
        { id : 5, type:1,  url:"/nomenclator/codification",name : "Codificari" , category : 0}
    ]);

    useEffect(()=>{
        NomenclatorService.getNomenclatorsOverview().then(f=>{
            let nc1 = [...nomenclatorCategories1];
            let nc2 = [...nomenclatorCategories2];
            let nc3 = [...nomenclatorCategories3];
            let nc4 = [...nomenclatorCategories4];
            
            nc1 = nc1.map(item=>{
                switch(item.id){
                    case 1:
                        item.category = f.counties;
                        break;
                    case 2:
                        item.category = f.cities;
                        break;
                    case 3:
                        item.category = f.zones;
                        break;
                    case 4:
                        item.category = f.sectors;
                        break;
                }
                return item;
            });

            setNc1(nc1);

            nc2 = nc2.map(item=>{
                switch(item.id){
                    case 1:
                        item.category = f.routes;
                        break;
                    case 2:
                        item.category = f.routeDetails ?? 0;
                        break;
                    case 3:
                        item.category = f.planificationDetails ?? 0;
                        break;
                }
                return item;
            });
            setNc2(nc2);

            nc3 = nc3.map(item=>{
                switch(item.id){
                    case 1:
                        item.category = f.medicCompetences ?? 0;
                        break;
                    case 2:
                        item.category = f.medicSpecializations ?? 0;
                        break;
                    case 3:
                        item.category = f.medicTypes ?? 0;
                        break;
                }
                return item;
            });
            setNc3(nc3);

            nc4 = nc4.map(item=>{
                switch(item.id){
                    case 1:
                        item.category = f.clientTypes ?? 0;
                        break;
                    case 2:
                        item.category = f.contests ?? 0;
                        break;
                    case 3:
                        item.category = f.studies ?? 0;
                        break;
                    case 4:
                        item.category = f.resources ?? 0;
                        break;
                    case 5:
                        item.category = f.codifications ?? 0;
                        break;
                }
                return item;
            });
                setnC4(nc4);
        });
    },[]);
    
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                {/* Render Breadcrumbs */}
                <Breadcrumbs title="Administrare" breadcrumbItem="Nomenclator" />
                {
                        isLoading ? <Spinners setLoading={setIsLoading} />
                            : 
                            <>
                            
                <Row className="justify-content-center text-center">
                    <Col xl={4}>
                        <div className="mb-4">
                            <h4>Administreaza nomenclatorul</h4>
                            <p className="text-muted">Pentru a putea modifica sau vizualiza informatiile unui nomenclator, trebuie sa apasati in desfasuratorul de mai jos pe numele nomenclatorului.</p>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col xl={3} md={6}>
                        <div className="card jobs-categories">
                            <div className="card-body">
                                {(nomenclatorCategories1 || []).map((item, key) => (
                                    <Link key={key} to={item.url} className="px-3 py-2 rounded bg-light bg-opacity-50 d-block mb-2">{item.name}<span className="badge text-bg-info float-end bg-opacity-100">{item.category}</span></Link>
                                ))}
                            </div>
                        </div>
                    </Col>

                    <Col xl={3} md={6}>
                        <div className="card jobs-categories">
                            <div className="card-body">
                                {(nomenclatorCategories2 || []).map((item, key) => (
                                    <Link key={key} to={item.url} className="px-3 py-2 rounded bg-light bg-opacity-50 d-block mb-2">{item.name}<span className="badge text-bg-info float-end bg-opacity-100">{item.category}</span></Link>
                                ))}
                            </div>
                        </div>
                    </Col>

                    <Col xl={3} md={6}>
                        <div className="card jobs-categories">
                            <div className="card-body">
                                {(nomenclatorCategories3 || []).map((item, key) => (
                                    <Link key={key} to={item.url} className="px-3 py-2 rounded bg-light bg-opacity-50 d-block mb-2">{item.name}<span className="badge text-bg-info float-end bg-opacity-100">{item.category}</span></Link>
                                ))}
                            </div>
                        </div>
                    </Col>

                    <Col xl={3} md={6}>
                        <div className="card jobs-categories">
                            <div className="card-body">
                                {(nomenclatorCategories4 || []).map((item, key) => (
                                    <Link key={key} to={item.url} className="px-3 py-2 rounded bg-light bg-opacity-50 d-block mb-2">{item.name}<span className="badge text-bg-info float-end bg-opacity-100">{item.category}</span></Link>
                                ))}
                            </div>
                        </div>
                    </Col>
                </Row>
                            </>
                }

                </Container>
            </div>

        </React.Fragment>
    );
}

export default Nomenclator;