import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react"; // Import PersistGate
import store, { persistor } from "./store/index"; // Correct import from store/index.js


import { PrimeReactProvider } from "primereact/api";

import "primereact/resources/themes/viva-light/theme.css";
import "theme.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.Fragment>
      <PrimeReactProvider>
        <BrowserRouter>
          {/* Wrap your App with PersistGate to ensure rehydration */}
          <PersistGate loading={<div>Incarcare...</div>} persistor={persistor}>
            <App />
          </PersistGate>
        </BrowserRouter>
      </PrimeReactProvider>
    </React.Fragment>
  </Provider>
);

serviceWorker.unregister();
