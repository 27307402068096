import React, { useEffect, useState, useRef } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from '@fullcalendar/list';
import { Dialog } from "primereact/dialog";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../components/Common/Breadcrumb";

import verification from "../../assets/images/verification-img.png";
import { IncidentsService } from "services/IncidentsService";

const IncidentsCalender = props => {
  document.title = "Administrare incidente";

  const navigate = useNavigate();
  const [events, setEvents] = useState([]);

  const eventsTypes = [
    { type: "bg-danger", id: 1, title: "Incident nou" },
    { type: "bg-warning", id: 2, title: "Luat la cunostinta" },
    { type: "bg-info", id: 3, title: "In curs de solutionare" },
    { type: "bg", id: 4, title: "In asteptare validarii administratorului" },
    { type: "bg-success", id: 5, title: "Solutionat amiabil" },
    { type: "bg-dark", id: 6, title: "Solutionat prin retur" }
  ]

  useEffect(() => {
    IncidentsService.getIncidents().then(f => {
      setEvents(f.map(c => {
        let event = eventsTypes.find(f => f.id == c.idStatus);

        return {
          ...c,
          title_category: ``,
          start: new Date(c.start),
          end: new Date(c.start),
          title: `${c.expirationLevel} ${c.title}`,
          className: `${event.type} text-white`
        }
      }));
    });
  }, []);

  const enLocal = {
    "code": "en-nz",
    "week": {
      "dow": 1,
      "doy": 4
    },
    "buttonHints": {
      "prev": "Previous $0",
      "next": "Next $0",
      "today": "This $0"
    },
    "viewHint": "$0 view",
    "navLinkHint": "Go to $0"
  };

  const toast = useRef(null);
  const [informationModal, setInformationModal] = useState(false);
  const [currentIncident, setCurrentIncident] = useState({});

  const handleEventClick = arg => {
    try {
      const eventData = arg.event;
      const eventEntity = events.find(f => f.id == eventData.id);
      if (eventEntity) {
        setCurrentIncident(eventEntity);
        setInformationModal(!informationModal);
      }
    } catch (e) {
      console.log("Error:", e);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Dialog header="Incident" visible={informationModal} style={{ width: '30vw' }}
            onHide={() => { if (!informationModal) return; setInformationModal(false); }}>
            <div>
              <p>
                Mergi mai departe pentru a vizualiza mai multe informatii cu privire la acest incident alocat clientului <b>{currentIncident.title}</b>
              </p>
              <p className="m-0"><b>
                Inregistrat:</b> {currentIncident?.start?.toLocaleDateString()}
              </p>
              <p className="m-0"><b>
                Status:</b> {currentIncident?.status}
              </p>
              <p className="m-0"><b>
                De catre:</b> {currentIncident?.createdBy}
              </p>
              <p className="m-0"><b>
                Termen produse:</b> {currentIncident?.expirationLevel?.length == 1 ? "3 luni" : currentIncident?.expirationLevel?.length == 2 ?
                 "6 luni" : "Termen expirat"}
              </p>
            </div>
            <div style={{ float: "right" }}>
              <Button onClick={() => { navigate(`/tasks/incidents/${currentIncident.id}`) }}>Mai departe</Button>
            </div>
          </Dialog>

          <Breadcrumbs title="Operational" breadcrumbItem="Administrare incidente" />
          <Row>
            <Col xs={12}>
              <Row>
                <Col xl={3}>
                  <Card>
                    <CardBody>
                      <div id="external-events" className="mt-2">
                        {eventsTypes &&
                          (eventsTypes || [])?.map((category) => (
                            <div
                              className={`${category.type} external-event fc-event text-white`}
                              key={"cat-" + category.id}>
                              <i className="mdi mdi-checkbox-blank-circle font-size-11 me-2" />
                              {category.title}
                            </div>
                          ))}
                      </div>

                      <div>
                        <p><span className="bx bx-star"></span> Intre 3 si 6 luni</p>
                        <p><span className="bx bx-star" /><span className="bx bx-star"></span> Mai putin de 6 luni</p>
                        <p><span className="bx bx-star" />
                          <span className="bx bx-star" /><span className="bx bx-star"></span> Termen BDD expirat</p>
                      </div>
                      <Row className="justify-content-center mt-5">
                        <img src={verification} alt="" className="img-fluid d-block" />
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={9}>
                  <Card>
                    <CardBody>
                      <FullCalendar
                        plugins={[
                          BootstrapTheme,
                          dayGridPlugin,
                          listPlugin,
                          interactionPlugin,
                        ]}
                        slotDuration={"00:15:00"}
                        handleWindowResize={true}
                        themeSystem="bootstrap"
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "dayGridMonth,dayGridWeek,dayGridDay",
                        }}
                        buttonText={{
                          today: "Astazi",
                          month: "Luna",
                          week: "Saptamana",
                          day: "Zi"
                        }}
                        locale={enLocal}
                        displayEventTime={false}
                        events={events}
                        editable={false}
                        droppable={false}
                        selectable={true}
                        eventClick={handleEventClick}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default IncidentsCalender;
