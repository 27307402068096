import React from "react"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";

const ApexRevenue = ({dataColors, series}) => {
  var apexRevenuechartColors =  getChartColorsArray(dataColors); 
  const options = {
    chart: {
      height: 300,
      type: "bar",
      toolbar: {
        show: !1,
      },
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "14%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: !1,
    },
    stroke: {
      show: !0,
      width: 2,
      colors: ["transparent"],
    },
    series: [
      {
        name: "Vizite",
        data: [0,0,0,0,0,0,0,0,0,0,0,0],
      },
    ],
    xaxis: {
      categories: [
        "Ian",
        "Feb",
        "Mar",
        "Apr",
        "Mai",
        "Iun",
        "Iul",
        "Aug",
        "Sep",
        "Oct",
        "Noe",
        "Dec",
      ],
    },
    yaxis: {
      title: {
        text: "(vizite clienti)",
      },
    },
    fill: {
      opacity: 1,
    },
    colors: apexRevenuechartColors,
  }


  return (
    <ReactApexChart options={options} series={series} type="bar" height="330" className="apex-charts" />
  )
}

export default ApexRevenue
