import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Dialog } from 'primereact/dialog';
import { Label, FormFeedback, Form, FormGroup, Input, TabPane, TabContent, NavLink, NavItem, ModalBody, Modal, ModalHeader } from 'reactstrap';
import { Button } from 'reactstrap';
import { Calendar } from 'primereact/calendar';

import "flatpickr/dist/themes/material_blue.css";
import Select from "react-select";
import { InputTextarea } from 'primereact/inputtextarea';
import { MedicService } from 'services/MedicService';

import { Toast } from 'primereact/toast';
import { number } from 'prop-types';

const TaskNewDetailComponent = ({
    employee,
    updateTask,
    updateEmployee,
    task,
    nomenclator,
    toggleNewPlanificationTaskModal, stockRequestModal }) => {
    const toast = useRef(null);

    const emptyPlanificationDetail = {
        idTaskDetail: -1,
        idMedic: null,
        cancelled: false,
        medicName: null,
        datePlanned: null,
        contests: [],
        reasons: [],
        marketing: [],
        products: [],
        quantity: 0,
        product: null,
        comments: ""
    };
    const uniqueEntityIdGenerate = useRef(-99999);
    const [medicsNomenclator, setMedics] = useState([]);
    const [currentPlanificationDetail, setNewPlanificationDetail] = useState(emptyPlanificationDetail);

    const createId = () => {
        uniqueEntityIdGenerate.current = uniqueEntityIdGenerate.current + 1;
        console.log(uniqueEntityIdGenerate.current);
        return uniqueEntityIdGenerate.current;
    };

    // Creeaza o noua planificare
    const createNewPlanificationTask = () => {
        setNewPlanificationDetail(emptyPlanificationDetail);

        if (task.idClient > 0 || task.idClient == -1) {
            console.log("Create new planification task...");
            console.log("Employee ", task.idEmployee, " and client ", task.idClient);
            //todo cahce...daca exista

            initializePlanificationDetail(false, null);

        } else {
            toast.current.show({ severity: 'warn', summary: 'Info', detail: 'Trebuie ales un client din lista' });

        }
    }

    const initializePlanificationDetail = (editMode, planificationDetailTask) => {

        const updatePlanificationDetail = (editMode, planificationDetailTask) => {
            let newEntityId = createId();

            const newPlanification = function (idEntity) {
                setNewPlanificationDetail(prev => {
                    return {
                        ...prev,
                        idTaskDetail: newEntityId
                    }
                });
            };

            // modifica
            if (editMode) {
                setNewPlanificationDetail(planificationDetailTask);
            }
            else {
                // adauga 
                newPlanification(newEntityId);
            }

            toggleNewPlanificationTaskModal();
        }

        // todo nu este institutie :) 
        if (task.idClient > 0) { // adica nu este unitate necunoscuta
            MedicService.getMedicsByEmployee({
                idEmployee: task.idEmployee,
                idHealthFacility: task.idClient
            }).then(f => { 
                setMedics(f);
                updatePlanificationDetail(editMode, planificationDetailTask);
            });
        } else {
            updatePlanificationDetail(editMode, planificationDetailTask);
        }
    }

    // Editeaza o planificare existenta
    // Modifica o planificare existenta
    const editPlanificationDetail = (idPlanificationDetail) => {

        if (idPlanificationDetail) {
            let planificationDetailTask = task.taskDetails.find(f => f.idTaskDetail == idPlanificationDetail);

            if (planificationDetailTask) {
                initializePlanificationDetail(true, planificationDetailTask);
            }
        }
    }

    const addNewProduct = () => {
        if (currentPlanificationDetail.product && currentPlanificationDetail.quantity > 0) {

            let product = employee.stock.find(f => f.idProduct == currentPlanificationDetail.product.idProduct);

            if (product) {
                if (product.quantity >= currentPlanificationDetail.quantity) {
                    updateEmployee(prev => {
                        return {
                            ...prev,
                            stock: [...prev.stock].map(ob => {
                                if (ob.idProduct == product.idProduct)
                                    return {
                                        ...product,
                                        quantity: parseInt((product.quantity - currentPlanificationDetail.quantity)),
                                        label: product.sourceLabel + " - " + (product.quantity - currentPlanificationDetail.quantity) + " buc."
                                    }
                                return ob;
                            })
                        }
                    });


                    // exista deja produsul adaugat
                    if (currentPlanificationDetail.products.some(f => f.idProduct == product.idProduct)) {
                        console.log("Product exists already...");

                        setNewPlanificationDetail(prev => {
                            return {
                                ...prev,
                                products: [...prev.products].map(c => {

                                    if (c.idProduct == product.idProduct) {
                                        let existingProduct = currentPlanificationDetail.products.find(f => f.idProduct == product.idProduct);
                                        let totalQuantity = parseInt(currentPlanificationDetail.quantity) + parseInt(existingProduct.quantity);

                                        return {
                                            ...product,
                                            quantity: totalQuantity,
                                            label: product.sourceLabel + " - " + totalQuantity + " buc"
                                        }
                                    } return c;
                                })
                            }
                        });
                    } else {


                        setNewPlanificationDetail(prev => {
                            return {
                                ...prev,
                                products: [...prev.products, {
                                    ...product,
                                    quantity: parseInt(currentPlanificationDetail.quantity),
                                    label: product.sourceLabel + " - " + currentPlanificationDetail.quantity + " buc"
                                }]
                            }
                        });
                    }

                }
            }
        }
    }

    const removedProducts = (e) => {
        console.log("Remove products:", e);
        console.log("Previous products", currentPlanificationDetail.products);

        let removedProducts = currentPlanificationDetail.products.filter(item => !e.some(a => a.idProduct == item.idProduct));

        updateEmployee(prev => {
            return {
                ...prev,
                stock: [...prev.stock].map(m => {
                    if (removedProducts.some(c => c.idProduct == m.idProduct)) {
                        let removedProduct = removedProducts.find(f => f.idProduct == m.idProduct);

                        let up = {
                            ...m,
                            quantity: parseInt(m.quantity + removedProduct.quantity),
                            label: m.sourceLabel + " - " + (m.quantity + removedProduct.quantity) + " buc"
                        }
                        console.log("UP:", up);
                        return up;
                    }
                    return m;
                })
            }
        });

        setNewPlanificationDetail(prev => {
            return {
                ...prev,
                products: e
            }
        });
    }

    // stergere planificationd detail
    const deletePlanificationDetail = () => {
        let modalOk = true;

        if (modalOk) {
            let entityToDelete = task.taskDetails.find(f => f.idTaskDetail == currentPlanificationDetail.idTaskDetail);
 
            if (entityToDelete) {  
                if (entityToDelete.products) {
                    entityToDelete.products.forEach(element => { 
                        if (element) {
                            updateEmployee(prev => {
                                return {
                                    ...prev,
                                    stock:
                                        [...prev.stock].map(c => {
                                            if (c.idProduct == element.idProduct) { 
                                                let pd = {
                                                    ...c,
                                                    quantity: (c.quantity + element.quantity),
                                                    label: c.sourceLabel + " - " + (c.quantity + element.quantity)
                                                }; 
                                                return pd;
                                            }
                                            return c;
                                        })
                                }
                            });
                        }
                    });
                }

                updateTask(prev => {
                    return {
                        ...prev,
                        taskDetails: [...prev.taskDetails].map(f => {
                            if (f.idTaskDetail === currentPlanificationDetail.idTaskDetail) {
                                return f.idTaskDetail < 0 ? null : { ...f, deleted: true }; 
                            }
                            return f;
                        }).filter(f => f !== null) 
                    }
                });

                setNewPlanificationDetail(emptyPlanificationDetail);
                toggleNewPlanificationTaskModal();
            }
        }
    }

    const cancelPlanificationDetail = () => {
        if (currentPlanificationDetail) {
            setNewPlanificationDetail(prev => {
                return {
                    ...prev,
                    cancelled: !prev.cancelled
                }
            })
        }
    }

    // Salveaza planificarea
    // commit 
    const savePlanificationDetailChanges = () => {

        if (!task.institutionClientType) {
            currentPlanificationDetail.idMedic = 2500;
            currentPlanificationDetail.medicName = "Vizita";
        }

        if (currentPlanificationDetail.idMedic == null || currentPlanificationDetail.idMedic == undefined)
            return;

        // este medic necunoscut
        if (currentPlanificationDetail.idMedic == -1 && currentPlanificationDetail.medicName.length < 4) {
            return;
        }

        if (!currentPlanificationDetail.reasons.length) return;
        if (!currentPlanificationDetail.datePlanned) return;

        // exista acest medic pe o alta planificare
        if (task.taskDetails &&
            task.institutionClientType &&
            task.taskDetails.some(f => f.medicName == currentPlanificationDetail.medicName
                && f.idTaskDetail != currentPlanificationDetail.idTaskDetail)) {
            return;
        } else {
            if (task.taskDetails && task.taskDetails.some(f => f.idTaskDetail == currentPlanificationDetail.idTaskDetail)) {
                //exista deja => actualizam  
                updateTask(prev => {
                    return {
                        ...prev,
                        taskDetails: [...prev.taskDetails].map(c => {
                            if (c.idTaskDetail == currentPlanificationDetail.idTaskDetail) {
                                console.log("Found it:", currentPlanificationDetail);

                                return {
                                    ...c,
                                    hasProducts: currentPlanificationDetail.products?.length ? true : false,
                                    idMedic: currentPlanificationDetail.idMedic,
                                    medicName: currentPlanificationDetail.medicName,
                                    contests: currentPlanificationDetail.contests,
                                    reasons: currentPlanificationDetail.reasons,
                                    marketing: currentPlanificationDetail.marketing,
                                    products: currentPlanificationDetail.products,
                                    comments: currentPlanificationDetail.comments,
                                    datePlanned: currentPlanificationDetail.datePlanned,
                                    cancelled: currentPlanificationDetail.cancelled,
                                    status: currentPlanificationDetail.cancelled ? "Anulare" : "Planificare"
                                }
                            }
                            return c;
                        })
                    }
                });
            } else {
                updateTask(prev => {
                    return {
                        ...prev,
                        taskDetails: [...prev.taskDetails,
                        {
                            ...currentPlanificationDetail,
                            hasProducts: currentPlanificationDetail.products?.length ? true : false,
                            idMedic: currentPlanificationDetail.idMedic,
                            medicName: currentPlanificationDetail.medicName,
                            contests: currentPlanificationDetail.contests,
                            reasons: currentPlanificationDetail.reasons,
                            marketing: currentPlanificationDetail.marketing,
                            products: currentPlanificationDetail.products,
                            comments: currentPlanificationDetail.comments,
                            datePlanned: currentPlanificationDetail.datePlanned,
                            status: "Planificat"
                        }]
                    }
                });
            }

            setNewPlanificationDetail(emptyPlanificationDetail);
            toggleNewPlanificationTaskModal();
        }
    }

    return (
        <React.Fragment>
            <Col lg={12}>
                <h5 className="mb-3">Planificare vizite</h5>
                {
                    !task?.isEnded ? (
                        <div className="flex-shrink-0 mb-3">
                            <Button onClick={createNewPlanificationTask} className="btn btn-soft-info me-1">Adauga vizita noua</Button>
                            <Toast ref={toast} />
                        </div>) : (<></>)
                }
            </Col>
            <Modal
                isOpen={stockRequestModal}
                role="dialog"
                size="lg"
                autoFocus={true}
                centered
                toggle={toggleNewPlanificationTaskModal}>
                <div className="modal-content">
                    <ModalHeader toggle={toggleNewPlanificationTaskModal}>
                        {task?.isEnded ? "Detalii": "Modifica"}
                    </ModalHeader>
                    <ModalBody>
                        <div id="kyc-verify-wizard" className="wizard clearfix">
                            <div className="steps clearfix">
                                <ul>
                                    <NavItem className="current">
                                        <NavLink
                                            className="active"
                                            disabled={false}>
                                            <span className="number">1</span>
                                            Planificarea vizitei
                                        </NavLink>
                                    </NavItem>
                                </ul>
                            </div>

                            <div className="content clearfix">
                                <TabContent activeTab={1} className="twitter-bs-wizard-tab-content">
                                    <TabPane tabId={1}>
                                        <Form onSubmit={(e) => {
                                            e.preventDefault();
                                            return false;
                                        }}>
                                            <Row>
                                                <Col lg="12">
                                                    <Label className='text-info me-3'>Vizita incepe la ora:</Label>
                                                    <span className="clock-icon">🕒</span>

                                                    <input
                                                        type="time"
                                                        className="w-full p-2 border rounded"
                                                        style={{ width: "100px", "border": "unset" }}
                                                        value={currentPlanificationDetail?.datePlanned
                                                            ? (() => {
                                                                const date = new Date(currentPlanificationDetail.datePlanned);
                                                                const hours = String(date.getHours()).padStart(2, "0"); // Ensure two digits
                                                                const minutes = String(date.getMinutes()).padStart(2, "0");
                                                                return `${hours}:${minutes}`;
                                                            })()
                                                            : ""}
                                                        onChange={(e) => {
                                                            console.log("la ora...", e.target.value);
                                                            const selectedTime = e.target.value;
                                                            const existingDate = task?.datePlanned
                                                                ? new Date(task.datePlanned)
                                                                : new Date(); // Default to today if no date exists

                                                            // Set the time while keeping the existing date 
                                                            const [hours, minutes] = selectedTime.split(":").map(Number);

                                                            existingDate.setHours(hours, minutes, 0, 0);
                                                            const localISO = existingDate.getFullYear() + "-" +
                                                                String(existingDate.getMonth() + 1).padStart(2, "0") + "-" +
                                                                String(existingDate.getDate()).padStart(2, "0") + "T" +
                                                                String(existingDate.getHours()).padStart(2, "0") + ":" +
                                                                String(existingDate.getMinutes()).padStart(2, "0") + ":" +
                                                                String(existingDate.getSeconds()).padStart(2, "0");


                                                            setNewPlanificationDetail(prev => ({
                                                                ...prev,
                                                                datePlanned: localISO // Send manually formatted local time to API
                                                            }));
                                                        }}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup className="mb-3">
                                                        {(currentPlanificationDetail.idMedic == -1 ? (
                                                            (task && task.institutionClientType && (<>
                                                                <Label className='text-info'>Medicul</Label>
                                                                <Input
                                                                    maxLength={30}
                                                                    placeholder="Numele noului medic"
                                                                    value={currentPlanificationDetail.medicName}
                                                                    onChange={(e) => {
                                                                        setNewPlanificationDetail(prev => {
                                                                            return {
                                                                                ...prev,
                                                                                medicName: e.target.value
                                                                            }
                                                                        })
                                                                    }}
                                                                /></>
                                                            ))) : (task?.institutionClientType && (<>
                                                                <Label htmlFor="idMedic">Medicul</Label>
                                                                <Select name="idMedic"
                                                                    styles={
                                                                        {
                                                                            menu: base => ({
                                                                                ...base,
                                                                                zIndex: 100
                                                                            })
                                                                        }
                                                                    }
                                                                    options={medicsNomenclator}
                                                                    value={medicsNomenclator?.find(f => f.idMedic == currentPlanificationDetail.idMedic)}
                                                                    onChange={(e) => {
                                                                        setNewPlanificationDetail(prev => {
                                                                            return {
                                                                                ...prev,
                                                                                idMedic: e.idMedic,
                                                                                medicName: e.name
                                                                            }
                                                                        })
                                                                    }}
                                                                    getOptionLabel={option => option.name}
                                                                    getOptionValue={option => option.idMedic}
                                                                    placeholder="Alege un medic"></Select>
                                                            </>)))}

                                                        {(task && task.institutionClientType && <div className="form-switch mb-3">
                                                            <input
                                                                type="checkbox"
                                                                id="switchClientMedicMissing"
                                                                className="form-check-input me-2"
                                                                checked={currentPlanificationDetail?.idMedic == -1}
                                                                onClick={e => {
                                                                    setNewPlanificationDetail(prev => {
                                                                        return {
                                                                            ...prev,
                                                                            idMedic: prev.idMedic == -1 ? null : -1,
                                                                            medicName: ""
                                                                        }
                                                                    })
                                                                }}
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="switchClientMedicMissing">
                                                                Nu gasesc numele medicului
                                                            </label></div>)
                                                        }
                                                    </FormGroup>
                                                </Col>

                                                <Col lg="6">
                                                    <FormGroup className="mb-3">
                                                        <Label
                                                            htmlFor="kyclastname-input"
                                                            className="form-label">
                                                            Scopul vizitei
                                                        </Label>

                                                        <Select
                                                            value={(currentPlanificationDetail.reasons && nomenclator.reasons
                                                                .filter(f => currentPlanificationDetail.reasons
                                                                    .some(c => c.idReason === f.idReason))) || []}
                                                            options={nomenclator.reasons}
                                                            isMulti={true}
                                                            styles={
                                                                {
                                                                    menu: base => ({
                                                                        ...base,
                                                                        zIndex: 100
                                                                    })
                                                                }
                                                            }
                                                            onChange={(e) => {
                                                                setNewPlanificationDetail(prev => {
                                                                    return { ...prev, reasons: e }
                                                                })
                                                            }}
                                                            className="select2-selection"
                                                            placeholder="Alege un scop al vizitei"></Select>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup className="mb-3">
                                                        <Label
                                                            htmlFor="kyclastname-input"
                                                            className="form-label">
                                                            Alege un concurs
                                                        </Label>
                                                        <Select
                                                            isMulti={true}
                                                            styles={
                                                                {
                                                                    menu: base => ({
                                                                        ...base,
                                                                        zIndex: 100
                                                                    })
                                                                }
                                                            }
                                                            value={(currentPlanificationDetail.contests && nomenclator.contests
                                                                .filter(f => currentPlanificationDetail.contests
                                                                    .some(c => c.idContest === f.idContest))) || []}
                                                            options={nomenclator.contests}
                                                            onChange={(e) => {
                                                                setNewPlanificationDetail(prev => {
                                                                    return {
                                                                        ...prev,
                                                                        contests: e
                                                                    }
                                                                })
                                                            }}
                                                            className="select2-selection"
                                                            placeholder="Alege concursurile"></Select>
                                                    </FormGroup>
                                                </Col>

                                                <Col lg="6">
                                                    <FormGroup className="mb-3">
                                                        <Label
                                                            htmlFor="kyclastname-input"
                                                            className="form-label">
                                                            Materiale pentru promovare
                                                        </Label>

                                                        <Select
                                                            options={nomenclator.marketing}
                                                            styles={
                                                                {
                                                                    menu: base => ({
                                                                        ...base,
                                                                        zIndex: 100
                                                                    })
                                                                }
                                                            }
                                                            value={(currentPlanificationDetail.marketing && nomenclator.marketing
                                                                .filter(f => currentPlanificationDetail.marketing
                                                                    .some(c => c.idMedicalResource === f.idMedicalResource))) || []}
                                                            isMulti={true}
                                                            onChange={(e) => {
                                                                console.log("Values of marketing:", e);
                                                                setNewPlanificationDetail(prev => {
                                                                    return {
                                                                        ...prev,
                                                                        marketing: e
                                                                    }
                                                                })
                                                            }}
                                                            className="select2-selection"
                                                            placeholder="Specifica materialele"></Select>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup className="mb-3">
                                                        <Label
                                                            htmlFor="kyclastname-input"
                                                            className="form-label">
                                                            Observatii
                                                        </Label>
                                                        <InputTextarea
                                                            onChange={(w => {
                                                                setNewPlanificationDetail(prev => {
                                                                    return { ...prev, comments: w.target.value }
                                                                })
                                                            })}
                                                            value={currentPlanificationDetail.comments} type="text" className="form-control"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row hidden={task?.isEnded}>
                                                <Col lg="5">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="products">Stoc actual produse</Label>
                                                        <Select
                                                            styles={
                                                                {
                                                                    menu: base => ({
                                                                        ...base,
                                                                        zIndex: 100
                                                                    })
                                                                }
                                                            }
                                                            value={currentPlanificationDetail.product}
                                                            onChange={(w) => {
                                                                setNewPlanificationDetail(prev => {
                                                                    return {
                                                                        ...prev,
                                                                        product: w
                                                                    }
                                                                })
                                                            }}
                                                            options={employee.stock}
                                                            placeholder="Alege un produs"></Select>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="3">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="quantity">Cantitate totala</Label>
                                                        <Input maxLength={3}
                                                            value={currentPlanificationDetail.quantity}
                                                            onChange={(w) => {
                                                                console.log("PRID:", w.target.value);
                                                                setNewPlanificationDetail(prev => {
                                                                    return {
                                                                        ...prev,
                                                                        quantity: w.target.value
                                                                    }
                                                                })
                                                            }}
                                                            placeholder='Ce cantitate ?' />
                                                    </FormGroup>
                                                </Col>

                                                <Col lg="4">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="labelAddProducts">iar acum...</Label>
                                                        <div>
                                                            <Button type="button" onClick={addNewProduct} className="btn-soft-secondary">
                                                                <i className="bx bxs-add-to-queue font-size-16 align-middle me-2"></i>{" "}
                                                                Adaug produsul
                                                            </Button>
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col lg="12">
                                                    <div className="mb-3">
                                                        <label className="control-label">
                                                            Produsele selectionate sunt urmatoarele
                                                        </label>

                                                        <Select
                                                            placeholder="Lista produselor"
                                                            isMulti={true}
                                                            value={currentPlanificationDetail.products}
                                                            onChange={(e) => removedProducts(e)}
                                                            className="select2-selection"
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row hidden={task?.isEnded}>
                                                <Col sm="12" className='d-flex justify-content-end'>
                                                    <Button className='me-1' onClick={savePlanificationDetailChanges} color="success">Salveaza</Button>
                                                    <Button className='me-1' onClick={deletePlanificationDetail} color="danger">Sterge</Button>
                                                    <Button className='me-1' hidden={currentPlanificationDetail?.idTaskDetail <= 0} onClick={cancelPlanificationDetail} color="warning">{currentPlanificationDetail.cancelled ? "Reia" : "Anuleaza"}</Button>
                                                    <Button color="info" onClick={toggleNewPlanificationTaskModal}>Inchide</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </ModalBody>
                </div>
            </Modal>

            {(task?.taskDetails && task.taskDetails?.length) ? <>
                {
                    task.taskDetails.filter(f => !f.deleted).map((a, key) => (
                        <Col key={key} xl={4}>
                            <Card>
                                <CardBody className='pb-0'>
                                    <div className="d-flex">
                                        <div className="flex-shrink-0 me-4">
                                            <div className="avatar-sm">
                                                <span className={`avatar-title rounded-circle ${a.idMedic != 2500 ? "bg-success" : "bg-warning"} text-white font-size-16`}>{a?.medicName ? a?.medicName[0] : ""}</span>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 overflow-hidden">
                                            <h5 className="text-truncate font-size-15"><Link to="#" className="text-dark">{a?.medicName || ""}</Link></h5>
                                            <p className="text-muted mb-4">{a?.products.length ? (a.products.length + " produse") : "Fara produse"}</p>
                                        </div>
                                    </div>
                                </CardBody>

                                <div className="px-4 py-3 border-top">
                                    <ul className="list-inline mb-0">
                                        <li className="list-inline-item me-3">
                                            <span className={`badge ${a?.status == "Planificat" ? 'bg-info' : (a.status == "Finalizat" ? 'bg-success' : 'bg-primary')}`}>{a.status}</span>
                                        </li>{" "}
                                        <li className="list-inline-item me-3">
                                            <i className="bx bx-calendar me-1"></i> La ora {a.datePlanned ? (new Date(a.datePlanned).getHours() + ":" + new Date(a.datePlanned).getMinutes()) : '-'}
                                        </li>
                                        { <li style={{ float: "right" }} className="list-inline-item">
                                                <Button onClick={() => editPlanificationDetail(a.idTaskDetail)} 
                                                color="info" className='btn-sm' 
                                                outline><i className="bx bx-edit me-1"></i> {task && task.isEnded ? "Detalii" : "Modifica"}</Button>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </Card>
                        </Col>
                    ))
                }
            </> : <></>}
        </React.Fragment>);
}

export default TaskNewDetailComponent;