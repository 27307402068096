import React, { useEffect, useState, useRef } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Badge } from 'primereact/badge';
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon"; 
import { ReportingService } from "services/ReportingService"; 

const InactiveMedicReportComponent = props => {
    document.title = "Raport activitate medici";
 
    const [entities, setEntities] = useState([]); 
    const [globalFilter, setGlobalFilter] = useState(null); 

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <IconField iconPosition="left">
                <InputIcon className="bx bx-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cauta..." />
            </IconField>
        </div>
    );

    useEffect(() => {
        ReportingService.getInactiveMedics().then(f => {
            console.log("Medics with no activity:", f); 
            setEntities(f);
        });
    }, []);

    const clientStatus = (rowData) => {
        if(rowData.totalVisits > 0){
            return <Badge severity="info" value="Lipsa activitate in ultimele 3 luni" />;
        }
        return <Badge severity="danger" value="Lipsa activitate" />;
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Rapoarte" breadcrumbItem="Activitate medici" />
                    <Row>
                        <Col xs={12}>
                            <Row>
                                <Col xl={12}>
                                    <Card>
                                        <CardBody>
                                            <DataTable value={entities}
                                                dataKey="idMedic"
                                                paginator
                                                rows={10}
                                                header={header}
                                                rowsPerPageOptions={[5, 10, 25]}
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                currentPageReportTemplate="Afiseaza {first} pana la {last} din {totalRecords} inregistrari"
                                                globalFilter={globalFilter}
                                                emptyMessage="Nu au fost gasite rezultate"> 
                                                <Column field="clientName" header="Unitatile sanitare" sortable style={{ minWidth: '16rem' }}></Column>
                                                <Column field="name" header="Numele medicului" sortable style={{ minWidth: '16rem' }}></Column>
                                                <Column field="totalVisits" header="Total vizite efectuate" sortable style={{ minWidth: '16rem' }}></Column>
                                                <Column header="Status" filter filterPlaceholder="Cauta status" body={clientStatus} sortable={true} style={{ minWidth: '16rem' }}></Column>
                                                <Column field="lastVisit" header="Ultima vizita" sortable style={{ minWidth: '16rem' }}></Column>  
                                            </DataTable>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default InactiveMedicReportComponent;
