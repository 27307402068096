import {get} from "../helpers/api_helper"

export const DashboardService = {
    getDashboardData: function() {
        return get("dashboard");
    },
    
  getNotifications(){
    return get("dashboard/notifications")
  }
};