import React, { useEffect, useState, useRef } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Badge } from 'primereact/badge';
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import Select from "react-select";
import Spinners from "components/Common/Spinner";
import { ReportingService } from "services/ReportingService";
import { EmployeeService } from "services/EmployeeService";

const ClientsReportComponent = props => {
    document.title = "Raport clienti";

    const navigate = useNavigate();
    const [clients, setClients] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [employeeList, setEmployeesList] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [employee, setEmployee] = useState(null);
    const [statistics, setStatistics] = useState([
        { id: 1, title: "Institutii sanitare", iconClass: "bx-first-aid", value: 0 },
        { id: 2, title: "Farmacii", iconClass: "bx-plus", value: 0 }
    ]);

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <div className="mb-2 d-flex gap-2">
                <Select
                    options={employeeList}
                    value={employeeList?.find(e => e.idEmployee === employee?.idEmployee)}
                    onChange={(e) => {
                        setEmployee(e);
                    }}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.idEmployee}
                    placeholder="Alege un angajat..."></Select>
                <Button onClick={() => { changeEmployeeHandler(employee) }} className="btn btn-sm btn-soft-primary">Cauta</Button>
            </div>
            <IconField iconPosition="left">
                <InputIcon className="bx bx-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cauta..." />
            </IconField>

        </div>
    );

    const actionBodyTemplate = (options) => {

        return (
            <div className="list-unstyled hstack gap-1 mb-0">
                <li>
                    <Button
                        onClick={() => {
                            navigate(`/reports/clients${options.insitution ? "/institution" : ""}/${options.idClient}`);
                        }}
                        className="btn btn-sm btn-soft-info"
                        id={`${options.idClient}`}>
                        <i className="bx bx-show-alt" />
                    </Button>
                </li>

            </div>
        );
    }

    const changeEmployeeHandler = (e) => {
        if (e) {
            getClients(e.idEmployee);
        }
    }

    const getClients = (idEmployee) => {
        ReportingService.getClients(idEmployee).then(f => {
            setClients(f);

            let institutionsCount = 0;
            let pharmaciesCount = 0;

            if (f && f.length) {
                let institutions = f.filter(f => f.insitution == true);
                institutionsCount = institutions.length;
                pharmaciesCount = f.length - institutions.length;
                if (pharmaciesCount < 0) pharmaciesCount = 0;
            }

            let stats = [...statistics];
            stats[0].value = institutionsCount;
            stats[1].value = pharmaciesCount;
            setStatistics(stats);
        });
    }

    useEffect(() => {
        EmployeeService.getEmployees().then(f => {
            setEmployeesList(f);
            getClients(-1);
        });
    }, []);

    const visitsBodyTemplate = (e) => {
        if (e.visits > 0)
            return <Badge severity="success" value={e.visits}></Badge>
        return <Badge severity="warning" value="Fara"></Badge>
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Rapoarte" breadcrumbItem="Clienti" />
                    <Row> {(statistics || []).map((item, key) => (
                        <Col md="4" key={"_col_" + key}>
                            <Card className="mini-stats-wid">
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">
                                                {item.title}
                                            </p>
                                            <h4 className="mb-0">{item.value}</h4>
                                        </div>
                                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle bg-primary">
                                                <i className={"bx " + item.iconClass + " font-size-24"}></i>
                                            </span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                    </Row>{
                        isLoading ? <Spinners setLoading={setIsLoading} />
                            :
                            <Row>
                                <Col xs={12}>
                                    <Row>
                                        <Col xl={12}>
                                            <Card>
                                                <CardBody>
                                                    <DataTable value={clients}
                                                        dataKey="idClient"
                                                        paginator rows={10}
                                                        header={header}
                                                        rowsPerPageOptions={[5, 10, 25]}
                                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                        currentPageReportTemplate="Afiseaza {first} pana la {last} din {totalRecords} inregistrari"
                                                        globalFilter={globalFilter}>
                                                        <Column field="clientType" header="Tip client" sortable style={{ width: 'fit-content' }}></Column>
                                                        <Column field="client" header="Denumire client" sortable style={{ minWidth: '10rem' }}></Column>
                                                        <Column field="city" header="Oras" sortable style={{ minWidth: '10rem' }}></Column>
                                                        <Column field="zone" header="Cartier" sortable style={{ minWidth: '10rem' }}></Column>
                                                        <Column body={visitsBodyTemplate} header="Vizite" sortable style={{ minWidth: '10rem' }}></Column>
                                                        <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                                                    </DataTable>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                    }
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ClientsReportComponent;
