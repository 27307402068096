import React, { useEffect, useState } from "react"
import { Container, Row, Col, Button } from "reactstrap"
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import classNames from "classnames"

//Import Components
import ClientStatisticsComponent from "./components/clientsStatisticsComponent"
import WelcomeTile from "./components/welcomeTile"
import MiniWidget from "./components/mini-widget"
import StockStatistics from "./components/stockRequestsOverviewComponent"
import { Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import StackedColumnChart from "./components/tasksGraphComponent"
import TopClientsComponent from "./components/topClientsComponent"
import TasksActivityComponent from "./components/tasksActivityComponent"
import IncidentsOverview from "./components/incidentsComponent"
import { DashboardService } from "services/DashboardService" 

const DashboardComponent = ({user}) => {
   
  const [tasksGraphActivityData, setTasksGraphActivityData] = useState([
    {
      id: 1,
      name: "Vizite farmacii",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
      id: 2,
      name: "Vizite unitati sanitare",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
      id: 3,
      name: "Incidente",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ]);

  const [clientStatisticsData, setClientStatisticsData] = useState([
    {
      id: 1,
      icon: "bx bx-user-check",
      title: "Medici",
      url: "/medic/new/-1",
      value: "0",
      badgeValue: "+ 0%",
      color: "success",
      desc: "Fata de luna trecuta",
    },
    {
      id: 2,
      icon: "bx bx-plus",
      title: "Farmacii",
      url: "/pharmacy/edit/-1",
      value: "0",
      badgeValue: "+ 0%",
      color: "success",
      desc: "Fata de luna trecuta",
    },
    {
      id: 3,
      icon: "bx bx-first-aid",
      title: "Unitati sanitare",
      value: "0",
      url: "/healthfacility/create/-1",
      badgeValue: "0%",
      color: "warning",
      desc: "Fata de luna trecuta",
    },
  ]);

  const [clientsTopStatistics, setTopClientsData] = useState([{
    id: 1,
    clientName: "Client 1",
    value: "0",
    progress: {
      value: "94",
      color: "primary"
    }
  },
  {
    id: 2,
    clientName: "Client 2",
    value: "0",
    progress: {
      value: "82",
      color: "success"
    }
  },
  {
    id: 3,
    clientName: "Client 3",
    value: "0",
    progress: {
      value: "70",
      color: "warning"
    }
  },
  {
    id: 4,
    clientName: "Client 4",
    value: "0",
    progress: {
      value: "70",
      color: "warning"
    }
  }]);

  const [tasksActivityData, setTaskActivityData] = useState([]);

  //meta title
  document.title = "Biovitality | Dashboard";

  const [homePageViewModel, setHomePageViewModel] = useState({
    medicsCount: 0,
    pharmaciesCount: 0,
    healthfacilitiesCount: 0,
    userStatistics: {},
    topClients: [{ clientName: "-", clientVisits: 0 }],
    stockRequests: [],
    incidents: [],
    tasks: [],
    tasksGraph: {}
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log("Authenticated user data:", user);
    const fetchData = async () => {
      try {
        const data = await DashboardService.getDashboardData();
        console.log("Dashboard data:", data);

        // Update state with fetched data
        const updatedStatistics = clientStatisticsData.map(f => {
          if (f.id === 1) return { ...f, value: data.medicsCount };
          if (f.id === 2) return { ...f, value: data.pharmaciesCount };
          return { ...f, value: data.healthFacilitiesCount };
        });

        const clientsTopStatisticsData = [...clientsTopStatistics];
        if (data.topClients) {
          data.topClients.sort((a, b) => b.clientVisits - a.clientVisits)
            .forEach((item, index) => {
              clientsTopStatisticsData[index].clientName = item.clientName;
              clientsTopStatisticsData[index].value = item.clientVisits;
            });
          setTopClientsData(clientsTopStatisticsData);
        }

        const updatedTasksGraphStatistics = tasksGraphActivityData.map(f => {
          if (f.id == 1) return { ...f, data: data.tasksGraph?.graphMedics };
          if (f.id == 2) return { ...f, data: data.tasksGraph?.graphFacilities };
          return { ...f, data: data.tasksGraph?.graphIncidents };
        });
 
        setTasksGraphActivityData(updatedTasksGraphStatistics);
        setTaskActivityData(data.tasks || []);
        setClientStatisticsData(updatedStatistics);
        setHomePageViewModel(data);

      } catch (err) {
        console.error("Error fetching dashboard data:", err); 
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div className="page-content">
        <Container fluid>
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Incarcare...</span>
            </div>
          </div>
        </Container>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>  
          <Breadcrumbs title="Acasa" breadcrumbItem="Dashboard" />
          <Row>
            <WelcomeTile />

            <Col xl="8">
              <Row>
                <MiniWidget reports={clientStatisticsData} />
              </Row>
            </Col>
          </Row>

          <ClientStatisticsComponent model={homePageViewModel.userStatistics} />

          <Row>
            <Col xl="7">
              <Card>
                <CardBody>
                  <div className="d-sm-flex flex-wrap">
                    <h4 className="card-title mb-4">Statistici</h4>
                    <div className="ms-auto">
                      <ul className="nav nav-pills">
                        <li className="nav-item">
                          <Link to="#" className={classNames({ active: "yearly" === "yearly" }, "nav-link")}>
                            Anuale
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <StackedColumnChart periodData={tasksGraphActivityData} />
                </CardBody>
              </Card>
            </Col>
            <Col xl="5">
              <TopClientsComponent props={clientsTopStatistics} />
            </Col>
          </Row>

          <Row>
            <Col xl="4">
              <TasksActivityComponent props={tasksActivityData} />
            </Col>
            
            <Col xl="4">
              <IncidentsOverview props={homePageViewModel.incidents} />
            </Col>

            <Col xl="4">
              <StockStatistics props={homePageViewModel.stockRequests} />
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  user: state.Login.user, // Replace 'auth' with the correct slice name
});

export default connect(mapStateToProps)(DashboardComponent);