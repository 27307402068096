import React, { useEffect, useMemo, useState} from "react";
import { useRef } from "react";

import { connect } from "react-redux";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
    TabContent,
    TabPane,
    NavItem,
    NavLink,
    FormFeedback,
    Label,
    Button,
    Input,
    Form,
    FormGroup,
} from "reactstrap";
import { Toast } from 'primereact/toast';
import Select from "react-select";

import Breadcrumbs from "components/Common/Breadcrumb";

import verificationImg from "../../../assets/images/verification-img.png";
import StockRequestsOverview from "./components/newStockRequest";
import { StockRequestService } from "services/StockRequestService";
import { ProductService } from "services/ProductService"; 

const StockEmployeeRequest = ({user}) => {
    document.title = "Raport de necesitate";

    const [currentProduct, setCurrentProduct] = useState({
        idProduct: -1, 
        quantity: null
    });

    const toast = useRef(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedQuantity, setSelectedQuantity] = useState(null);
    const [reportReason, setSelectedReportReason] = useState(null);

    const [submitted, setSubmitted] = useState(false);

    const [products, setProducts] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [stockRequestModal, setStockRequestModal] = useState(false);
    const [stockRequests, setStockRequests] = useState(null);

    useEffect(() => { 
        ProductService.getAllProducts().then(f => {
            setProductsList(f);
        });

        StockRequestService.getStockRequests().then(f => {                      
            setStockRequests(f);
        }); 
    }, []);

    const toggleStockRequestModal = () => {
        setStockRequestModal(!stockRequestModal);
    };

    const handleInputChange = (e) => {
        // Get the current input value
        let value = e.target.value;

        // If the input is empty or contains only numbers and doesn't start with a leading zero (unless it's just '0')
        if (value === '' || (/^\d+$/.test(value) && (value[0] !== '0' || value === '0'))) {
            return true;
        }

        // Prevent input if it doesn't meet the criteria
        return false;
    };


    const addNewProduct = () => {

        if (!selectedProduct) {
            setCurrentProduct(prev => {
                return {
                    ...prev,
                    idProduct: "Camp obligatoriu."
                }
            }); return;
        }

        if (!selectedProduct) {
            setCurrentProduct(prev => {
                return {
                    ...prev,
                    idProduct: "Camp obligatoriu."
                }
            }); return;
        }

        if (!selectedQuantity || selectedQuantity <= 0) {
            setCurrentProduct(prev => {
                return {
                    ...prev,
                    quantity: "Camp obligatoriu."
                }
            }); return;
        }

        const productFound = productsList.find(f => f.idProduct == selectedProduct.idProduct);
        if (!productFound) {
            setCurrentProduct(prev => {
                return {
                    ...prev,
                    idProduct: "Acest produs nu exista."
                }
            }); return;
        }

        setProducts(prev => {
            return [...prev, {
                id: productFound.idProduct,
                value: parseInt(selectedQuantity),
                label: productFound.label,
                quantity: parseInt(selectedQuantity),
                price: productFound.price
            }]
        });
        setCurrentProduct(prev => { return { ...prev, idProduct: "", quantity: "" } });
    }
    
    // salveaza schimbarile 
    const saveChanges = () => {  
        if(!reportReason || reportReason?.length < 1){
            return;
        }else
        { 
        setSubmitted(true);

        if (products && products.length) {
            StockRequestService.sendStockRequest({
                IdEmployee: "",
                IdRequest: -1,
                Reason: reportReason,
                Products: products
            }).then(f => {
                if (f.success) {
                    toast.current.show({ severity: 'success', summary: 'Info', detail: 'Raportul a fost trimis cu success' });
                    setProducts([]);
                    setSelectedProduct(null);
                    setSubmitted(false);

                    setStockRequests(prev => {return [...prev, {
                        idRequest: -1,
                        dateRequested: "astazi",
                        id: -1,
                        price: products?.reduce((sum, product) => { return sum + (product.quantity * product.price); }, 0),
                        products: products,
                        quantity: products?.length,
                        employeeName: (user && user?.name) + " (" + (user && user?.jobTitle) + ")"
                    }]});
                    setTimeout(() => { 
                    toggleStockRequestModal();
                    }, 2000);
                } else {
                    toast.current.show({ severity: 'warn', summary: 'Info', detail: 'Raportul nu a putut fi transmis' });
                }
            });
        }
       }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid> 
                    <Breadcrumbs title="Gestiune stoc" breadcrumbItem="Rapoarte de necesitate" />
                    <Row className="mt-lg-5">
                        <Col xl="7" sm="8">
                            <Card>
                                <CardBody>
                                    <StockRequestsOverview entities={stockRequests}/>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <CardBody>
                                    <div className="text-center">
                                        <Row className="justify-content-center">
                                            <Col lg="10">
                                                <h4 className="mt-4 fw-semibold">
                                                    Raport de necesitate
                                                </h4>
                                                <p className="text-muted mt-3">
                                                    Pentru cererile de necesar produse este necesar sa
                                                    completati formularul de necesitate apasand pe butonul de mai jos.
                                                </p>
                                                {
                                                <div className="mt-4">
                                                    <Button
                                                        type="button"
                                                        color="primary"
                                                        onClick={() => {
                                                            setProducts([]);
                                                            toggleStockRequestModal();
                                                        }}>
                                                        Apasa aici pentru a incepe
                                                    </Button>
                                                </div>
                                                }
                                            </Col>
                                        </Row>

                                        <Row className="justify-content-center mt-5 mb-5">
                                            <Col sm="6" xs="8">
                                                <div>
                                                    <img
                                                        src={verificationImg}
                                                        alt=""
                                                        className="img-fluid"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                    <Modal
                                        isOpen={stockRequestModal}
                                        role="dialog"
                                        size="lg"
                                        autoFocus={true}
                                        centered
                                        toggle={toggleStockRequestModal}>
                                        <div className="modal-content">
                                            <ModalHeader toggle={toggleStockRequestModal}>
                                                Completeaza cererea
                                            </ModalHeader>
                                            <ModalBody>
                                                <div id="kyc-verify-wizard" className="wizard clearfix">
                                                    <div className="steps clearfix">
                                                        <ul>
                                                            <NavItem className="current">
                                                                <NavLink
                                                                    className="active"
                                                                    disabled={false}>
                                                                    <span className="number">1.</span>
                                                                    Transmiterea raportului
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink disabled={true}>
                                                                    <span className="number">2.</span>
                                                                    Validarea raportului
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink disabled={true}>
                                                                    <span className="number">3.</span>
                                                                    Livrarea produselor
                                                                </NavLink>
                                                            </NavItem>
                                                        </ul>
                                                    </div>
                                                    <div className="content clearfix">
                                                        <Toast ref={toast} />
                                                        <TabContent
                                                            activeTab={1}
                                                            className="twitter-bs-wizard-tab-content">
                                                            <TabPane tabId={1}>
                                                                <Form onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    saveChanges(e);
                                                                    return false;
                                                                }}>
                                                                    <Row>
                                                                        <Col lg="6">
                                                                            <FormGroup className="mb-3">
                                                                                <Label htmlFor="idProduct">Denumirea produslui</Label>
                                                                                <Select 
                                                                                    styles={
                                                                                        {
                                                                                            menu: base => ({
                                                                                                ...base,
                                                                                                zIndex: 100
                                                                                            })
                                                                                        }
                                                                                    }
                                                                                value={selectedProduct} options={productsList} onChange={(w) => setSelectedProduct(w)} 
                                                                                name="idProduct" placeholder="Alege un produs"></Select>
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col lg="6">
                                                                            <FormGroup className="mb-3">
                                                                                <Label
                                                                                    htmlFor="kyclastname-input"
                                                                                    className="form-label">
                                                                                    Pretul produsului
                                                                                </Label>
                                                                                <Input
                                                                                    disabled
                                                                                    value={(selectedProduct?.price || "0") + " RON" || "0 RON"}
                                                                                    placeholder="- RON"
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                />
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row>
                                                                        <Col lg="6">
                                                                            <FormGroup className="mb-3">
                                                                                <Label htmlFor="quantity">Stoc necesar (buc)</Label>
                                                                                <Input
                                                                                    id="quantity"
                                                                                    maxLength={10}
                                                                                    name="quantity" 
                                                                                    value={selectedQuantity}
                                                                                    type="text"
                                                                                    placeholder="Numar bucati produs"
                                                                                    onChange={(e) => { 
                                                                                        if (handleInputChange(e)) {
                                                                                            setSelectedQuantity(e.target.value)
                                                                                        }
                                                                                    }} 
                                                                                />
                                                                                {currentProduct.quantity?.length ? (
                                                                                    <FormFeedback type="invalid" className="d-block">Camp obligatoriu</FormFeedback>
                                                                                ) : null}
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col lg="6">
                                                                            <FormGroup className="mb-3">
                                                                                <Label htmlFor="labelAddProducts" style={{color:"white"}}>-</Label>
                                                                                <div>
                                                                                    <Button type="button" onClick={() => addNewProduct()} className="btn-soft-secondary">
                                                                                        <i className="bx bxs-add-to-queue font-size-16 align-middle me-2"></i>{" "}
                                                                                        Adaug produsul
                                                                                    </Button>
                                                                                </div>
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col lg="12">
                                                                            <div className="mb-3">
                                                                                <label className="control-label">
                                                                                    Produsele mele
                                                                                </label>
                                                                                <Select
                                                                                    placeholder="Toate produsele din raport"
                                                                                    isMulti={true}
                                                                                    value={products}
                                                                                    components={{ Menu: () => {return null;} }}
                                                                                    closeMenuOnSelect={false}
                                                                                    onChange={(e) => {
                                                                                        setProducts(prev => { return [...e] });
                                                                                    }}
                                                                                    className="select2-selection"
                                                                                />
                                                                                {submitted && !products?.length ? (
                                                                                <FormFeedback type="invalid" className="d-block">Lipsa produse in raportul de necesitate</FormFeedback>
                                                                            ) : null}
                                                                            </div> 
                                                                        </Col>
                                                                    </Row>

                                                                    <Row>
                                                                        <Col lg="12">
                                                                             <FormGroup className="mb-3">
                                                                                <Label className="control-label">
                                                                                    Scopul raportului
                                                                                </Label>
                                                                                <Input minLength={1} 
                                                                                value={reportReason}
                                                                                onChange={(e) => {
                                                                                        setSelectedReportReason(e.target.value)}}
                                                                                         maxLength={255} placeholder="Scopul pentru care se cer produsele ?"/>
                                                                                {(products.length && (!reportReason || !reportReason?.length < 1)) ? (
                                                                                    <FormFeedback type="invalid" className="d-block">Camp obligatoriu</FormFeedback>
                                                                                ) : null}
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col lg="12">
                                                                            <div className="text-center"> 
                                                                                <h3>{products?.reduce((sum, product) => { return sum + (product.quantity * product.price); }, 0) || "0"} RON</h3>
                                                                                <p>{products?.length || "0"} produse</p>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Button type="submit" color="success">Trimite raportul</Button>
                                                                    </Row>
                                                                </Form>
                                                            </TabPane>
                                                        </TabContent>
                                                    </div>
                                                </div>
                                            </ModalBody>
                                        </div>
                                    </Modal>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    user: state.Login.user
});

export default connect(mapStateToProps)(StockEmployeeRequest); 