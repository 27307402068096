import React, { useEffect, useMemo, useState } from "react";
//import components
import { connect } from "react-redux";

import { Link } from "react-router-dom";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { ToastContainer } from "react-toastify";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { FilterMatchMode } from 'primereact/api';

import { Button, Col, Row, Card, CardBody } from "reactstrap";
import Spinners from "components/Common/Spinner";
import { MedicService } from "services/MedicService";

const MedicsOverview = ({ user }) => {
    document.title = "Administrare medici";
    const [entities, setEntities] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        zones: { value: null, matchMode: FilterMatchMode.CONTAINS },
        cities: { value: null, matchMode: FilterMatchMode.CONTAINS },
        jobTitle: { value: null, matchMode: FilterMatchMode.CONTAINS },
        specializations: { value: null, matchMode: FilterMatchMode.CONTAINS },
        competences: { value: null, matchMode: FilterMatchMode.CONTAINS },
        jobTitle: { value: null, matchMode: FilterMatchMode.CONTAINS },
        healthFacilitities: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const viewMedicDetails = (options) => {
        return (
            <div className="list-unstyled hstack gap-1 mb-0">
                <li>
                    <Link
                        to={`/medic/${options.employeeEdit ? "employee" : "edit"}/${options.idMedic}`}
                        className="btn btn-sm btn-soft-info"
                        id={`${options.idMedic}`}>
                        <i className="bx bx-search-alt" />
                    </Link>
                </li>
            </div>
        );
    }

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Cautare ..." />
                </IconField>
            </div>
        );
    };

    const header = renderHeader();


    useEffect(() => {
        MedicService.getMedics().then(data => {
            console.log("Medics:", data);
            setEntities(data);
        });
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Gestiune clienti" breadcrumbItem="Medici" />
                    {
                        (user && user.isAdmin) ? (
                            <Row className="d-flex justify-content-end" >
                                <Col xs="2" lg="2" md="2">
                                    <Card style={{ backgroundColor: 'transparent' }}>
                                        <Button color="success" className="btn-rounded "><Link style={{ color: 'white ' }} to="/medic/new/-1">Adauga medic nou</Link></Button>
                                    </Card>
                                </Col>
                            </Row>) : (<></>)
                    }
                    {
                        isLoading ? <Spinners setLoading={setIsLoading} />
                            :
                            <Row>
                                <Col xs="12">
                                    <Card>
                                        <CardBody>
                                            <DataTable
                                                size='small'
                                                value={entities || []} paginator rows={10}
                                                dataKey="idMedic"
                                                filters={filters}
                                                rowsPerPageOptions={[5, 10, 25]}
                                                filterDisplay="row"
                                                currentPageReportTemplate="Afiseaza {first} pana la {last} din {totalRecords} medici" 
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                              
                                                globalFilterFields={['name', 'jobTitle', 'specializations', 'competences', 'cities', 'zones', 'healthFacility']} header={header}
                                                emptyMessage="Nu au fost gasite rezultate">
                                                <Column  sortable field="name" header="Medic" filter filterPlaceholder="Medic"
                                                    style={{ minWidth: "30%" }} />
                                                <Column  sortable field="jobTitle" header="Tip medic" filter filterPlaceholder="Tip medic"
                                                    style={{ minWidth: "30%" }} />
                                                <Column  sortable field="healthFacilitities"
                                                    header="Unitati sanitare" filter filterPlaceholder="Unitati sanitare" style={{ minWidth: "20%" }} />
                                                <Column  sortable field="specializations" header="Specialitate" filter filterPlaceholder="Specialitate" style={{ minWidth: "20%" }} />
                                                <Column  sortable field="cities" header="Orase" filter filterPlaceholder="Oras" style={{ minWidth: "20%" }} />
                                                <Column  sortable field="zones" header="Zone de lucru" filter filterPlaceholder="Zona" style={{ minWidth: "20%" }} />
                                                <Column  sortable body={viewMedicDetails} style={{ minWidth: '12rem' }} />
                                            </DataTable>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                    }
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    user: state.Login.user, // Replace 'auth' with the correct slice name
});

export default connect(mapStateToProps)(MedicsOverview);