import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import Select from 'react-select'

export default function BranchesComponent({ model }) {
    let emptyBranch = {
        idZone: null,
        label: ''
    };
    const testare = [{ value: 21, label: "Leo" }, { value: 22, label: "Mariu" }]
    const [branches, setBranches] = useState([]);
    const [branchDialog, setBranchDialog] = useState(false);
    const [deleteBranchDialog, setDeleteBranchDialog] = useState(false);
    const [branch, setBranch] = useState(emptyBranch);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        console.log("DATA OF BRANCH", model);
        if (model)
            setBranches(model || []);
    }, []);

    const openNewBranchDialog = () => {
        setBranch(emptyBranch);
        setSubmitted(false);
        setBranchDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setBranchDialog(false);
    };

    const hideDeleteBranchDialog = () => {
        setDeleteBranchDialog(false);
    };

    const saveBranch = () => {
        setSubmitted(true);

        if (branch.idZone) {
            let _branches = [...branches];
            let _branch = { ...branch };

            if (branch.idZone) {
                const idZone = branches.find(f => f.idZone == branch.idZone);
                if (idZone) {
                    toast.current.show({ severity: 'warning', summary: 'Informare', detail: 'Exista deja aceasta zona de lucru.', life: 3000 });
                } else {
                    _branches.push(_branch);
                    toast.current.show({ severity: 'success', summary: 'Confirmare', detail: 'Zona de lucru a fost adaugata', life: 3000 });

                }
            }
            setBranches(_branches);
            setBranchDialog(false);
            setBranch(emptyBranch);
        }
    };

    const confirmDeleteBranch = (branch) => {
        setBranch(branch);
        setDeleteBranchDialog(true);
    };

    const deleteBranch = () => {
        let _branches = branches.filter((val) => val.idZone !== branch.idZone);

        setBranches(_branches);
        setDeleteBranchDialog(false);
        setBranch(emptyBranch);
        toast.current.show({ severity: 'success', summary: 'Confirmare', detail: 'Zona a fost marcata pentru stergere', life: 3000 });
    };

    const onInputChange = (e) => {
        let _branch = { ...branch, idZone: e.value, label: e.label, city: "CUCU", zoneLabel: "ADA" }; 
        setBranch(_branch);
    };

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="Adauga zona de lucru" icon="pi pi-plus" severity="success" onClick={openNewBranchDialog} />
            </div>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="bx bxs-trash" rounded outlined severity="danger" onClick={() => confirmDeleteBranch(rowData)} />
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <IconField iconPosition="left">
                <InputIcon className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cauta..." />
            </IconField>
        </div>
    );

    const newZoneDialogFooter = (
        <React.Fragment>
            <Button label="Inchide" icon="pi pi-times" outlined onClick={hideDialog} />
            <Button label="Salveaza" icon="pi pi-check" onClick={saveBranch} />
        </React.Fragment>
    );

    const deletenewZoneDialogFooter = (
        <React.Fragment>
            <Button label="Nu" icon="pi pi-times" outlined onClick={hideDeleteBranchDialog} />
            <Button label="Da" icon="pi pi-check" severity="danger" onClick={deleteBranch} />
        </React.Fragment>
    );

    return (
        <div>
            <Toast ref={toast} />
            <div className="card">
                <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>
                <DataTable ref={dt} size="small" value={branches}
                    dataKey="idZone"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} zones"
                    globalFilter={globalFilter} header={header}>

                    <Column field="city" header="Orasul" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="zoneLabel" header="Zona de lucru" sortable style={{ minWidth: '16rem' }}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            </div>

            <Dialog visible={branchDialog} style={{ width: '32rem'}}
                breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Zona de lucru"
                modal className="p-fluid" footer={newZoneDialogFooter} onHide={hideDialog}> 
                <div className="field">
                    <Select className={classNames({ 'p-invalid': submitted && !branch.idZone })}
                         placeholder="Alege zona de lucru" options={testare} onChange={(e) => { onInputChange(e) }} />
                    {submitted && !branch.idZone && <small className="p-error">Trebuie aleasa o zona de interes.</small>}
                </div>
            </Dialog>

            <Dialog visible={deleteBranchDialog} style={{ width: '32rem' }}
                breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirmare" modal footer={deletenewZoneDialogFooter}
                onHide={hideDeleteBranchDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    {branch && (
                        <span>
                            Esti sigur ca vrei sa stergi zona de lucru <b>{branch.label}</b>?
                        </span>
                    )}
                </div>
            </Dialog>
        </div>
    );
}
