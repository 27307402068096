import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Card, CardBody, CardTitle, Table } from "reactstrap"
import { Link } from "react-router-dom"; 

const TeamMembers = (team) => {
  
  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">Echipa</CardTitle>
        <div className="table-responsive">
          <Table className="table align-middle table-nowrap">
            {team.team && team.team.length > 0 ? (
              team.team.map((item, key) => (
                <tbody key={key}>
                  <tr> 
                  <td style={{ width: "50px" }}>
                    {
                      <div className="avatar-xs">
                        <span className="avatar-title rounded-circle bg-primary text-white font-size-16">
                          {item && item?.name?.length ? item.name[0] : ""}
                        </span>
                      </div>
                    }
                  </td>
                  <td><h5 className="font-size-14 m-0"><Link to="#" className="text-dark">{item?.name + " " + item?.secondName}</Link></h5></td>
                  <td>
                    <div>
                      <div className="badge bg-primary-subtle text-primary font-size-11 me-1">{item?.jobTitle == "Reprezentant Medical" ? "RM" : (item.jobTitle == "Agent Vanzari" ? "AV": "AV&RM")}</div> 
                    </div>
                  </td>
                </tr>
              </tbody>
            ))) : (
              <tbody>
                <tr>
                  <td colSpan="3">Nu sunt membri in echipa</td>
                </tr>
              </tbody>
            )}
          </Table>
        </div>
      </CardBody>
    </Card>
  )
}

TeamMembers.propTypes = {
  team: PropTypes.array,
}

export default TeamMembers
