import React, { useEffect, useState, useRef} from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import { Dialog } from "primereact/dialog";
import { useParams } from 'react-router-dom';

import Select from "react-select";

import { Form, FormFeedback, Input, Label } from "reactstrap";


const PharmacyReportComponent = props => {
    document.title = "Raport farmacie";

    const navigate = useNavigate();
    const { idClient = -1} = useParams();
    const [globalFilter, setGlobalFilter] = useState(null);
    const [entity, setEntity] = useState(null);

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <IconField iconPosition="left">
                <InputIcon className="bx bx-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cauta..." />
            </IconField> 
        </div>
    );


    useEffect(() => {

        if (parseInt(idClient) > 0) {
            ReportingService.getPharmaciesReport(idClient).then(data => {
                console.log(data);
                try {
                    setPharmacies(data);
                } catch (error) {
                    console.log(error);
                }
            });
        }
    }, []);

    const statusBodyTemplate = (rowData) => {
        if (rowData.status === "Finalizat") {
            return <span className="badge bg-success">{rowData.status}</span>;
        }
        if (rowData.status === "In lucru") {
            return <span className="badge bg-info">{rowData.status}</span>;
        }
        return <span className="badge bg-danger">{rowData.status}</span>;
    }

    const actionsBodyTemplate = (rowData) => {
        return <Button
            className="btn btn-sm btn-soft-info"
            onClick={() => { 
              setEntity(rowData);
              setShowModal(true);
            }}>
            <i className="bx bx-search-alt" />
        </Button>
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Rapoarte" breadcrumbItem="Farmacie" />
                    <Dialog header="Detalii farmacie" visible={showModal} modal={true} 
                    footer={<Button onClick={() => setShowModal(false)}>Inchide</Button>}
                    onHide={() => setShowModal(false)}>
                        <div>
                           <DataTable value={entity?.products}>
                                <Column field="label" header="Denumire"  style={{ minWidth: '16rem' }}></Column>
                                <Column field="quantity" header="Cantitate"  style={{ minWidth: '10rem' }}></Column>
                                <Column field="price" header="Pret"  style={{ minWidth: '10rem' }}></Column> 
                           </DataTable>
                        </div>
                    </Dialog>
                    <Row>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col lg={8}>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col lg={3}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="idClientType">Categoria unitatii</Label>
                                                            <Select
                                                                name="idClientType"
                                                                value={entity?.pharmacyType}
                                                                placeholder="Categorie"
                                                            />
                                                        </div> 
                                                         </Col>
                                                    <Col lg={9}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="name">Denumirea farmaciei</Label>
                                                            <Input
                                                                id="name"
                                                                name="name"
                                                                type="text"
                                                                placeholder="Denumirea farmaciei..."
                                                                value={entity?.name}
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="email">Adresa e-Mail</Label>
                                                            <Input
                                                                id="email"
                                                                name="email"
                                                                type="email"
                                                                placeholder="Adresa de email contact..."
                                                                value={entity?.email}
                                                            />
                                                        </div>    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="mobile">Telefon mobil</Label>
                                                            <Input
                                                                id="mobile"
                                                                name="mobile"
                                                                type="text"
                                                                placeholder="Telefon contact..."
                                                                value={entity?.mobile}
                                                            />
                                                        </div>
                                                    </Col>

                                                </Row>


                                                <div className="mb-3">
                                                    <Label htmlFor="website">Website</Label>
                                                    <Input
                                                        id="website"
                                                        name="website"
                                                        type="text"
                                                        placeholder="Website-ul farmaciei..."
                                                        value={entity?.website}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="address">Adresa farmaciei</Label>
                                                    <Input
                                                        id="address"
                                                        name="address"
                                                        type="textarea"
                                                        placeholder="Adresa farmaciei..."
                                                        value={entity?.address}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="idZone">Zona de activitate</Label>
                                                    <Select
                                                        name="idZone"
                                                        value={entity?.idZone}
                                                        placeholder="Zona de activitate"
                                                    />
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col lg={4}>
                                        <Card>
                                            <CardBody>
                                                <h5 className="card-title mb-3">Sumar activitate</h5>
                                                <div className="mb-3">
                                                    <div>
                                                        De la data inregistrarii, au fost planificate {entity?.tasksCount} vizite catre aceasta unitate.
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <Row>
                                <Col xl={12}>
                                    <Card>
                                        <CardBody>
                                            <DataTable value={[]}
                                                dataKey="idClient"
                                                paginator rows={10}
                                                header={header}
                                                rowsPerPageOptions={[5, 10, 25]}
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                currentPageReportTemplate="Afiseaza {first} pana la {last} din {totalRecords} inregistrari"
                                                globalFilter={globalFilter}>
                                                <Column field="datePlanned" header="Data vizitei" sortable style={{ minWidth: '16rem' }}></Column>
                                                <Column field="datePlannedTime" header="Ora" sortable style={{ minWidth: '10rem' }}></Column>
                                                <Column field="reasons" header="Motivul vizitei" sortable style={{ minWidth: '10rem' }}></Column>
                                                <Column field="observations" header="Observatii" sortable style={{ minWidth: '10rem' }}></Column>
                                                <Column body={statusBodyTemplate} header="Status" sortable style={{ minWidth: '10rem' }}></Column>
                                                <Column field="productsCount" header="Produse distribuite" sortable style={{ minWidth: '10rem' }}></Column>
                                                <Column body={actionsBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                                            </DataTable>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default PharmacyReportComponent;
