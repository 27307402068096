import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Badge } from 'primereact/badge'
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Dialog } from 'primereact/dialog';
import { Label, FormFeedback, Form, FormGroup, Input, Container } from 'reactstrap';
import { connect } from "react-redux";

import avatar from "../../assets/images/biovitality/avatar.jpg"
import bg from "../../assets/images/biovitality/bg.png"

import { Toast } from 'primereact/toast';
import { Button } from 'reactstrap';
import "flatpickr/dist/themes/material_blue.css";
import FlatPickr from "react-flatpickr";
import Select from "react-select";
import { EmployeeService } from 'services/EmployeeService';
import { TasksService } from 'services/TasksService'
import { StockRequestService } from 'services/StockRequestService';
import TaskNewDetailComponent from './Overview/components/plannificationDetail'
import { NomenclatorService } from 'services/NomenclatorService';
import { useNavigate } from "react-router-dom";

const TaskComponent = ({ user }) => {
    const { idTask = -1 } = useParams();
    const idUnkownClient = -1;
    const toast = useRef(null);

    let navigate = useNavigate();
    const [currentEmployee, setCurrentEmployee] = useState({
        stock: [],
        medics: [],
        clients: []
    });

    const emptyTask = {
        idTask: -1,
        isEnded: false,
        idClient: -1,
        idClientType: -1,
        datePlanned: null,
        idEmployee: '',
        idClient: null,
        idIncident: null,
        clientName: null,
        institutionClientType: false,
        idZone: null,
        taskDetails: []
    };

    const [currentTask, setCurrentTask] = useState(null);
    const [nomenclator, setNomenclator] = useState({
        employees: [],
        marketing: [],
        reasons: [],
        contests: [],
        zones: {
            cities: [],
            zones: [],
            counties: []
        },
        clientTypes: []
    });

    const [taskIncidents, setTaskIncidents] = useState([]);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [deleteEntityDialog, setDeleteEntityDialog] = useState(false);
    const [confirmIncidentDialog, setCreateIncidentDialog] = useState(false);
    const [confirmTaskEndDialog, setConfirmTaskEndDialog] = useState(false);
    const [stockRequestModal, setStockRequestModal] = useState(false);
    const [disableSaveChanges, setDisableSaveChanges] = useState(false);

    useEffect(() => {
        Promise.all([
            EmployeeService.getEmployees(),
            NomenclatorService.getClientTypes(),
            NomenclatorService.getLocations(),
            NomenclatorService.getContests(),
            NomenclatorService.getPlanificationTypes(),
            NomenclatorService.getMedicalResources()
        ]).then(([employees, clientTypes, zones, contests, reasons, marketing]) => {
            setNomenclator(prev => ({
                ...prev,
                employees,
                clientTypes:
                    user.idJobType == 1 ? clientTypes.filter(clientType => !clientType.institution) : clientTypes,
                zones,
                contests,
                reasons,
                marketing
            }));

            if (employees && employees.length == 1) {
                setCurrentTask(prev => { return { ...prev, idEmployee: employees[0].idEmployee } });
            }
        })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

        if (parseInt(idTask) > 0) {
            // this is edit mode
            try {
                TasksService.getTask(idTask).then(t => {
                    if (!t || (t.hasOwnProperty("success") && !t.success)) {
                        navigate("/tasks");
                        return;
                    }

                    setCurrentTask(t);

                    if (t.incident)
                        setTaskIncidents([t.incident]);

                    TasksService.getClientsFiltered(t.idEmployee, t.idClientType, t.idZone).then(f => {
                        StockRequestService.getEmployeeStock(t.idEmployee).then(s => { 
                            setCurrentEmployee(prev => {
                                return {
                                    ...prev,
                                    stock: s,
                                    clients: f
                                }
                            });
                        });
                    });
                });
            }
            catch {
                navigate("/tasks");
            }
        } else {
            setCurrentTask(emptyTask);
            StockRequestService.getEmployeeStock("-1").then(s => { 
                setCurrentEmployee(prev => {
                    return {
                        ...prev,
                        stock: s
                    }
                });
            });
        }

    }, []);

    // schimbare zona
    const zoneChangedHandler = (e) => {
        setCurrentTask(prev => { return { ...prev, taskDetails: [], idClient: null } });
        setNomenclator(prev => { return { ...prev, clients: [] } });
        setTaskIncidents([]);

        if (currentTask.idEmployee && currentTask.idClientType && e) {
            TasksService.getClientsFiltered(currentTask.idEmployee, currentTask.idClientType, e.idZone).then(f => {
                setCurrentEmployee(prev => {
                    return {
                        ...prev,
                        clients: f
                    }
                });
            });
        }
    }

    // schimbare oras
    const cityChangedHandler = (e) => {
        console.log("city change handler");
        setTaskIncidents([]);

        setCurrentEmployee(prev => {
            return {
                ...prev,
                clients: []
            }
        });

        setCurrentTask(prev => {
            return {
                ...prev,
                taskDetails: [],
                idZone: null,
                idClient: null
            }
        });
    }

    // schimbare angajat
    const employeeChangedHandler = (e) => {
        console.log("employee change handler", e, " client type ", currentTask.idClientType,
            " and zone ", currentTask.idZone);
        setTaskIncidents([]);

        setCurrentEmployee(prev => {
            return {
                ...prev,
                clients: []
            }
        });

        setCurrentTask(prev => {
            return {
                ...prev,
                taskDetails: [],
                idClient: null
            }
        });

        if (e) {
            StockRequestService.getEmployeeStock(e.id).then(s => {
                console.log("Product stock:", s);
                setCurrentEmployee(prev => { return { ...prev, stock: s } });
            });
        }
    }

    // schimbare tip client
    const clientTypeChangedHandler = (e) => {
        setTaskIncidents([]);

        setCurrentEmployee(prev => { return { ...prev, clients: [] } });
        setCurrentTask(prev => { return { ...prev, taskDetails: [], idClient: null } });

        if (currentTask.idEmployee && e && currentTask.idZone)
            TasksService.getClientsFiltered(currentTask.idEmployee, e.idClientType, currentTask.idZone).then(f => {
                setCurrentEmployee(prev => { return { ...prev, clients: f } });
            });
    }

    const toggleNewPlanificationTaskModal = () => {
        setStockRequestModal(!stockRequestModal);
    };

    // sterge task
    const deleteTask = () => {
        setDisableSaveChanges(true);

        if (currentTask.idTask && currentTask.idTask > 0) {
            TasksService.deleteTask(currentTask.idTask).then(f => {
                if (f.success) {
                    toast.current.show({ severity: 'success', summary: 'Info', detail: 'Planificarea a fost stearsa' });
                    setDeleteEntityDialog(false);
                    setDisableSaveChanges(true);
                } else {
                    setDisableSaveChanges(false);
                    toast.current.show({ severity: 'warn', summary: 'Info', detail: 'Planificarea nu a putut fi stearsa' });
                }
            });
        }
    }

    const createIncident = () => {
        if (currentTask && currentTask.idTask > 0) {
            TasksService.createIncident(currentTask.idTask).then(f => {
                if (f && f.success) {
                    toast.current.show({ severity: 'success', summary: 'Succes', detail: 'Incidentul a fost creat pentru aceasta vizita' });
                    let idincident = parseInt(f.details);

                    if (idincident > 0)
                        navigate("/tasks/incidents/" + f.details);
                } else {
                    toast.current.show({ severity: 'error', summary: 'Eroare', detail: 'Eroare la crearea incidentului. Detalii:' + f.details });
                }
            });
        }
    }

    // save changes
    // salveaza planificarea
    const saveChanges = () => {
        if (currentTask && currentTask.idTask <= 0) {
            if (currentTask.idClient == idUnkownClient) {
                if (currentTask.clientName == null || currentTask.clientName == "") {
                    toast.current.show({ severity: 'warn', summary: 'Info', detail: 'Trebuie ales intai un client.' });
                    return;
                }
            }
        }

        setFormSubmitted(true);
        setDisableSaveChanges(true);

        let x = currentTask; 
        let updatedTask = {
            ...x,
            temporaryClientName: x.clientName,
            isUnknownClient: (x.idClient == idUnkownClient),
            taskDetails: x?.taskDetails?.map(m => {
                return {
                    IdTaskDetail: m.idTaskDetail,
                    IdMedic: m.idMedic,
                    Deleted: m.deleted,
                    Cancelled: m.cancelled,
                    TemporaryMedicName: m.medicName,
                    Comments: m.comments,
                    DatePlanned: m.datePlanned,
                    Reasons: m.reasons?.map(r => { return r.idReason }),
                    Contests: m.contests?.map(r => { return r.idContest }),
                    MedicalResources: m.marketing?.map(r => { return r.idMedicalResource }),
                    Products: m.products?.map(r => { return { IdProduct: r.idProduct, Quantity: r.quantity } })
                }
            })
        };

        if (!x.idZone || !x.idClient || !x.idEmployee || !x.datePlanned || !x.idClientType) {
            setDisableSaveChanges(false);
            return;
        }

        if (parseInt(idTask) > 0) {
            updatedTask.IdTask = parseInt(idTask);

            let updatedLabel = updatedTask.EndTask ? "Planificarea a fost incheiata cu success" : "Planificarea a fost salvata cu success";

            TasksService.saveTask(updatedTask).then(f => {
                if (f.success) {
                    toast.current.show({ severity: 'success', summary: 'Info', detail: updatedLabel });
                } else {
                    toast.current.show({ severity: 'warn', summary: 'Info', detail: 'Planificarea nu a putut fi salvata. Detalii: ' + f.details });
                }
                setFormSubmitted(false);
                setDisableSaveChanges(false);
            });

        } else {
            TasksService.createTask(updatedTask).then(f => {
                if (f.success) {
                    setDisableSaveChanges(true);
                    toast.current.show({ severity: 'success', summary: 'Info', detail: 'Planificarea a fost inregistrata cu success' });
                } else {
                    setFormSubmitted(false);
                    toast.current.show({ severity: 'warn', summary: 'Info', detail: 'Planificarea nu a putut fi creata. Detalii: ' + f.details });
                }
            });
        }
    }

    const commitChanges = () => {
        if (currentTask && currentTask.idTask > 0) {
            currentTask.endTask = true;
            saveChanges();
        }
    }

    const hideDeleteEntityDialog = () => {
        setDeleteEntityDialog(false);
    };

    const deleteEntityFooter = (
        <React.Fragment>
            <Button label="Nu" icon="bx bx-check" className='btn-info' style={{ "marginRight": "5px" }} onClick={hideDeleteEntityDialog}>Nu</Button>
            <Button label="Da" icon="bx bx-x" className='btn-danger' onClick={deleteTask}>Da</Button>
        </React.Fragment>
    );

    const confirmIncidentDialogFooter = (
        <React.Fragment>
            <Button label="Nu" icon="bx bx-check" className='btn-info' style={{ "marginRight": "5px" }} onClick={() => { setCreateIncidentDialog(false); }}>Nu</Button>
            <Button label="Da" icon="bx bx-x" className='btn-danger' onClick={createIncident}>Da</Button>
        </React.Fragment>
    );

    const confirmTaskEndDialogFooter = (
        <React.Fragment>
            <Button label="Nu" icon="bx bx-check" className='btn-info' style={{ "marginRight": "5px" }} onClick={() => { setConfirmTaskEndDialog(false); }}>Nu</Button>
            <Button label="Da" icon="bx bx-x" className='btn-danger' onClick={commitChanges}>Da</Button>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={12}>
                            <Card style={{ "backgroundRepeat": "no-repeat", "backgroundImage": `url(${bg})` }} className="mx-n4 mt-n4">
                                <CardBody>
                                    <div className="text-center mb-4">
                                        <img src={avatar}
                                            alt="" className="avatar-lg rounded-circle mx-auto d-block" />
                                        <h5 className="mt-3 mb-1">{currentTask?.clientName ?? "Planificare noua"}</h5>
                                        <p className="text-muted mb-3">{currentTask?.address ?? "adresa unitatii ..."}</p>
                                        <div className="mx-auto">
                                            <span className="badge me-1 text-bg-success">{currentTask?.tasksCount ?? 0} vizite pana in prezent</span>
                                            <span className="badge me-1 text-bg-warning">Ultima vizita, {currentTask?.lastPlannificationDate ?? "..."}</span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Form onSubmit={(e) => {
                        e.preventDefault();
                        setFormSubmitted(true);
                        saveChanges();
                    }} >
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <Toast ref={toast} />
                                        <Dialog visible={deleteEntityDialog}
                                            style={{ width: '32rem' }}
                                            breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                                            header="Confirmare" modal
                                            footer={deleteEntityFooter}
                                            onHide={hideDeleteEntityDialog}>
                                            <div className="confirmation-content">
                                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                                {currentTask && (
                                                    <span>
                                                        Esti de acord sa <Badge severity="danger" value="stergi" /> aceasta planificare ?
                                                    </span>
                                                )}
                                            </div>
                                        </Dialog>

                                        <Dialog visible={confirmIncidentDialog}
                                            style={{ width: '32rem' }}
                                            breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                                            header="Confirmare" modal
                                            footer={confirmIncidentDialogFooter}
                                            onHide={() => { setCreateIncidentDialog(false); }}>
                                            <div className="confirmation-content">
                                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                                {currentTask && (
                                                    <span>
                                                        Esti de acord sa creezi un <Badge severity="warning" value="incident" /> pentru aceasta vizita ?
                                                    </span>
                                                )}
                                            </div>
                                        </Dialog>

                                        <Dialog visible={confirmTaskEndDialog}
                                            style={{ width: '32rem' }}
                                            breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                                            header="Confirmare" modal
                                            footer={confirmTaskEndDialogFooter}
                                            onHide={() => { setConfirmTaskEndDialog(false); }}>
                                            <div className="confirmation-content">
                                                <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                                {currentTask && (
                                                    <span>
                                                        Esti de acord sa <Badge severity="success" value="finalizezi" /> aceasta planificare ?
                                                    </span>
                                                )}
                                            </div>
                                        </Dialog>

                                        <Row>
                                            <Col lg={8}>
                                                <Card>
                                                    <CardBody>
                                                        {
                                                        user && user.isAdmin && (<div className="mb-3">
                                                            <Label htmlFor="employee">Planifica activitate pentru angajatul</Label>
                                                            <Select name="employee"
                                                                isDisabled={idTask != -1}
                                                                value={nomenclator?.employees?.find(f => f.idEmployee == currentTask?.idEmployee)}
                                                                onChange={(e) => {
                                                                    console.log("Employee change:", e);
                                                                    setCurrentTask(prev => { return { ...prev, idEmployee: e.idEmployee } })
                                                                    employeeChangedHandler(e);
                                                                }}
                                                                getOptionLabel={option => `${option.name}`}
                                                                getOptionValue={option => option.idEmployee}
                                                                options={nomenclator?.employees || []} placeholder="Nume si prenume"></Select>

                                                            {formSubmitted && currentTask.idEmployee == -1 ? (
                                                                <FormFeedback type="invalid" className="d-block">Acest camp este obligatoriu de completat.</FormFeedback>
                                                            ) : null}
                                                        </div>)
}

                                                        <div className="mb-3">
                                                            <Label htmlFor="clientTypes">Categorie unitate client</Label>
                                                            <Select name="clientTypes"
                                                                isDisabled={idTask != -1}
                                                                value={nomenclator?.clientTypes.find(f => f.idClientType == currentTask?.idClientType)}
                                                                onChange={(e) => {
                                                                    setCurrentTask(prev => {
                                                                        return {
                                                                            ...prev,
                                                                            idClientType: e.idClientType,
                                                                            institutionClientType: e.institution
                                                                        }
                                                                    })
                                                                    clientTypeChangedHandler(e);
                                                                }}
                                                                options={nomenclator?.clientTypes} placeholder="Ce tip de client ?"></Select>

                                                            {formSubmitted && (currentTask.idClientType == -1 || !currentTask?.idClientType) ? (
                                                                <FormFeedback type="invalid" className="d-block">Acest camp este obligatoriu de completat.</FormFeedback>
                                                            ) : null}
                                                        </div>

                                                        <div className="d-flex mb-3">
                                                            <div className="flex-grow-1 me-3">
                                                                <Label htmlFor="cities">Clientul din orasul</Label>
                                                                <Select name="cities"
                                                                    isDisabled={idTask != -1}
                                                                    value={nomenclator?.zones.cities.find(f => f.idCity == currentTask?.idCity)}
                                                                    onChange={(e) => {
                                                                        setCurrentTask(prev => { return { ...prev, idCity: e.idCity } })
                                                                        cityChangedHandler(e);
                                                                    }}
                                                                    options={nomenclator?.zones.cities || []} placeholder="Oras"></Select>

                                                                {formSubmitted && (currentTask.idCity == -1 || !currentTask.idCity) ? (
                                                                    <FormFeedback type="invalid" className="d-block">Acest camp este obligatoriu de completat.</FormFeedback>
                                                                ) : null}
                                                            </div>

                                                            <div className="flex-grow-1">
                                                                <Label htmlFor="zones">si zona de activitate</Label>
                                                                <Select name="zones"
                                                                    isDisabled={idTask != -1}
                                                                    value={nomenclator?.zones.zones.filter(f => f.idZone == currentTask?.idZone)}
                                                                    options={nomenclator?.zones.zones.filter(f => f.idCity == currentTask?.idCity) || []}
                                                                    onChange={(e) => {
                                                                        setCurrentTask(prev => { return { ...prev, idZone: e.idZone } })
                                                                        zoneChangedHandler(e);
                                                                    }} placeholder="Alege zona"></Select>

                                                                {formSubmitted && (currentTask.idZone == -1 || !currentTask.idZone) ? (
                                                                    <FormFeedback type="invalid" className="d-block">Acest camp este obligatoriu de completat.</FormFeedback>
                                                                ) : null}
                                                            </div>

                                                        </div>

                                                        <div className="d-flex mb-3">
                                                            <div className="flex-grow-1 me-3">
                                                                {currentTask?.idClient == idUnkownClient ? (
                                                                    <div className="mb-3">
                                                                        <Label className='text-info' htmlFor="clientName">Denumirea unitatii (client nou)</Label>
                                                                        <Input
                                                                            maxLength={50}
                                                                            placeholder='Denumirea unitatii'
                                                                            value={currentTask?.clientName}
                                                                            onChange={(e) => {
                                                                                setCurrentTask(prev => {
                                                                                    return {
                                                                                        ...prev,
                                                                                        clientName: e.target.value
                                                                                    }
                                                                                })
                                                                            }}
                                                                        />

                                                                        {formSubmitted && currentTask.clientName == null ? (
                                                                            <FormFeedback type="invalid" className="d-block">Acest camp este obligatoriu de completat.</FormFeedback>
                                                                        ) : null}
                                                                    </div>) : (
                                                                    <div>
                                                                        <Label htmlFor="clients">Alege clientul</Label>
                                                                        <Select name="clients"
                                                                            isDisabled={idTask != -1 ? (!currentTask?.adminEdit) : (idTask != -1)}
                                                                            options={currentEmployee?.clients}
                                                                            value={currentEmployee.clients.length && currentEmployee.clients.filter(f => f.idClient == currentTask?.idClient)}
                                                                            onChange={(e) => {
                                                                                setTaskIncidents(e.incidents || []); 
                                                                                let keepTaskDetails = false;
                                                                                if(currentTask){
                                                                                    if(currentTask.adminEdit && currentTask.newClient && currentTask.idClient == null){
                                                                                        keepTaskDetails = true;
                                                                                    }
                                                                                }
                                                                                setCurrentTask(prev => {
                                                                                    return {
                                                                                        ...prev,
                                                                                        idClient: e.idClient, 
                                                                                        taskDetails: keepTaskDetails ? [...prev.taskDetails] : []
                                                                                    }
                                                                                })
                                                                            }}
                                                                            placeholder="Denumirea unitatii clientului"></Select>

                                                                        {formSubmitted && currentTask.idClient == -1 ? (
                                                                            <FormFeedback type="invalid" className="d-block">Acest camp este obligatoriu de completat.</FormFeedback>
                                                                        ) : null}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {
                                                                !currentTask?.institutionClientType && (
                                                                    <div className="flex-grow-1">
                                                                        <Label htmlFor="incident">In contextul incidentului</Label>
                                                                        <Select name="incident"
                                                                            isDisabled={idTask != -1}
                                                                            value={taskIncidents?.find(f => f.idIncident == currentTask?.idIncident)}
                                                                            getOptionLabel={option => `${option.label}`}
                                                                            getOptionValue={option => option.idIncident}
                                                                            onChange={(e) => {
                                                                                setCurrentTask(prev => {
                                                                                    return {
                                                                                        ...prev,
                                                                                        idIncident: e.idIncident
                                                                                    }
                                                                                })
                                                                            }}
                                                                            options={taskIncidents || []} placeholder="Alege incidentul"></Select>
                                                                    </div>)
                                                            }
                                                        </div>

                                                        <div className="form-switch mb-3">
                                                            <input
                                                                type="checkbox"
                                                                id="switchClientMissing"
                                                                className="form-check-input me-2"
                                                                checked={(currentTask?.idClient == idUnkownClient)}
                                                                onClick={e => { 
                                                                    setCurrentTask(prev => {
                                                                        return {
                                                                            ...prev,
                                                                            idClient: !e.target.checked ? idUnkownClient : null, 
                                                                            clientName: ""
                                                                        }
                                                                    });
                                                                
                                                                    if(!e.target.checked && currentTask){
                                                                        TasksService.getClientsFiltered(currentTask.idEmployee, currentTask.idClientType, currentTask.idZone).then(f => {
                                                                                setCurrentEmployee(prev => {
                                                                                    return {
                                                                                        ...prev, 
                                                                                        clients: f
                                                                                    } 
                                                                            });
                                                                        });
                                                                    }
                                                                }}
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="switchClientMissing">
                                                                Nu gasesc clientul (nu exista in lista)
                                                            </label>
                                                        </div>

                                                        <div className="mb-3">
                                                            <span className="clock-icon">🕒</span>
                                                            <Label htmlFor="planificationDate">Planifica vizita in data de</Label>

                                                            <FlatPickr
                                                                className="form-control"
                                                                options={{ dateFormat: "d M, Y" }}
                                                                placeholder="Alege o data"
                                                                value={currentTask?.datePlanned ? new Date(currentTask?.datePlanned) : null}
                                                                onChange={(e) => {
                                                                    setCurrentTask(prev => { return { ...prev, datePlanned: new Date(e[0]).toISOString() } });
                                                                }}
                                                            />

                                                            {formSubmitted && (!currentTask.datePlanned || currentTask.datePlanned == null) ? (
                                                                <FormFeedback type="invalid" className="d-block">Acest camp este obligatoriu de completat.</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>

                                        </Row>

                                    </CardBody>
                                </Card>

                                <Row>
                                    <TaskNewDetailComponent
                                        employee={currentEmployee}
                                        updateTask={setCurrentTask}
                                        updateEmployee={setCurrentEmployee}
                                        task={currentTask}
                                        nomenclator={nomenclator}
                                        stockRequestModal={stockRequestModal}
                                        toggleNewPlanificationTaskModal={toggleNewPlanificationTaskModal} />
                                </Row>
                                {!currentTask || !currentTask.isEnded || currentTask.adminEdit ?
                                    (<Row>
                                        <Col lg={12}>
                                            <Card>
                                                <CardBody className="border-bottom">
                                                    <div className="hstack gap-2">
                                                        <div className='ms-auto'>
                                                            <Button disabled={disableSaveChanges} type="submit" className="btn btn-soft-success me-1"><i className="bx bx-user-voice align-middle me-1"></i> Salveaza</Button>
                                                            <Button hidden={parseInt(idTask) == -1} disabled={disableSaveChanges || idTask == -1 || currentTask?.isEnded} onClick={() => { setConfirmTaskEndDialog(true); }} className="btn btn-success me-1"><i className="bx bx-user-voice align-middle me-1"></i> Finalizeaza planificarea</Button>
                                                            <Button hidden={parseInt(idTask) == -1} disabled={disableSaveChanges} onClick={() => { setDeleteEntityDialog(true); }} className="btn btn-soft-danger me-1"><i className="bx bx-trash-alt align-middle me-1"></i> Sterge</Button>
                                                            <Button hidden={parseInt(idTask) == -1 || currentTask?.institutionClientType} disabled={disableSaveChanges} onClick={() => { setCreateIncidentDialog(true); }} className="btn btn-soft-warning me-1"><i className="bx bx-user-voice align-middle me-1"></i> Raporteaza incident</Button>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>) : (<></>)
                                }
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>

        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    user: state.Login.user,
});

export default connect(mapStateToProps)(TaskComponent);