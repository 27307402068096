import {get, post} from "../helpers/api_helper"


export const CommonService = {
  getZones() {
    return get('locations/zones');  },
  getSectors() {
    return get('locations/sectors');  }, 
  getClientTypes() {
    return get('nomenclator/clienttypes');  },
  getLocations() { 
    return get('nomenclator/locations/all');  },
  getMedicCompetences() {
    return get('nomenclator/medic/competences');  },
  getMedicSpecializations() {
    return get('nomenclator/medic/specializations');  },
  getJobTitles() {
    return get('nomenclator/jobtitles');  },
  getMedicalResourceCategories() {
    return get('nomenclator/medicalResourceCategories/data');  },
  getMedicalResourceTypes() {
    return get('nomenclator/medicalResourceTypes/data');  },
  getMedicalResourceDetails() {
    return get('nomenclator/medicalResourceDetails/data');  },
  getCities() {
    return get('locations/cities');  },
  getCounties() {
    return get('locations/counties');  },
  saveCity(entity) {
    return post(`locations/city/add`, entity); 
  },
  deleteCity(IdCity) {
    return get(`locations/city/delete/${IdCity}`);
  },
  getContests() {
    return get('nomenclator/contest/all');  },
  getTasksReasons() {
    return get('nomenclator/reasons/data');  },
  getMarketingData() {
    return get('nomenclator/marketing/data');  }
}