import React, { useState } from "react"
import { Col, Card, CardBody, Nav, NavItem, NavLink, Table, Input, Label } from "reactstrap"
import { Link } from "react-router-dom"
import classnames from "classnames"

//Simple bar
import SimpleBar from "simplebar-react"

const StockTotalsRequestsComponent = ({ props }) => {

  return (
    <React.Fragment>
      <Col>
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">Cereri de necesar</h4>

            <Nav pills className="bg-light rounded">
              <NavItem>
                <NavLink
                  style={{ cursor: "default" }}
                  className={classnames({
                    active: "1" === "1",
                  })}
                >
                  In analiza
                </NavLink>
              </NavItem>
            </Nav>

            <div className="mt-4">
              <SimpleBar style={{ maxHeight: "250px" }}>
                <div className="table-responsive">
                  <Table className="table table-nowrap align-middle table-hover mb-0">
                    <tbody>
                      {
                        (props || [])?.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <h5 className="text-truncate font-size-14 mb-1">
                                <Link to="#"
                                  style={{ cursor: "default" }}
                                  className="text-dark">
                                  {item.employeeName}
                                </Link>
                              </h5>
                              <p className="text-muted mb-0">Cerere {item.productsCount} produs(e), de acum {item.days} zile</p>
                            </td>
                            <td style={{ width: "90px" }}>
                              <div>
                                <ul style={{ "float": "right" }} className="list-inline mb-0 font-size-16">
                                  <li className="list-inline-item">
                                    <Link to={"/stock/requests"} className="text-info p-1">
                                      <i className="bx bx-search-alt-2" />
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                </div>
              </SimpleBar>

              {
                props && props.length ? (
                  <div className="text-center mt-4">
                    <Link to="/stock/requests" className="btn btn-primary waves-effect waves-light btn-sm">
                      Vezi mai multe <i className="mdi mdi-arrow-right ms-1" />
                    </Link>
                  </div>) : <></>
              }
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default StockTotalsRequestsComponent
