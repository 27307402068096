import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
//i18n
import { withTranslation } from "react-i18next";
import { DashboardService } from "services/DashboardService";

const NotificationDropdown = ({ props }) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [entities, setEntities] = useState([]);

  useEffect(() => {
    DashboardService.getNotifications().then(f => {
      setEntities(f);
    });
  }, []);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          <span className="badge bg-danger rounded-pill">1</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> Notificari </h6>
              </Col>
              <div className="col-auto">
                <a href="/dashboard/notifications" className="small">
                  {" "}
                  Vezi mai mult
                </a>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {entities.map((item, index) => (
              <Link to="/dashboard/notifications" className="text-reset notification-item" key={index}>
                <div className="d-flex">
                  <div className="avatar-xs me-3">
                    <span className="avatar-title bg-success font-size-16"></span>
                  </div>
                  <div className="flex-grow-1">
                    <h6 className="mt-0 mb-1">{item.label}</h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">{item.description}</p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline" /> {new Date(item.dateCreated).toLocaleDateString('en-GB')}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationDropdown;
