import { call,  put, takeEvery, takeLatest } from "redux-saga/effects";
import {get, post, login} from "../../../helpers/api_helper"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"; 
  
function* loginUser({ payload: { user, history } }) {
  try {  
    const response = yield call(login, "/api/auth/login", {
      email: user.email,
      password: user.password,
    }); 

    if(response.status == 200){ 
      const data = yield response.data;  
      localStorage.setItem("authUser", JSON.stringify(data));  

      yield put(loginSuccess(data)); 
      history('/dashboard'); 
    }else{
      throw new Error("Numele de utilizator sau parola sunt gresite");
    } 
  } catch (error) {
      console.log(error);
      yield put(apiError("Numele de utilizator sau parola sunt gresite"));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");  
    yield put(logoutUserSuccess({}));
    history('/login');
  } catch (error) {
    yield put(apiError(error));
  }
}
 
function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser); 
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
