import { get, post } from "../helpers/api_helper"

export const MedicService = { 
    getMedic(IdMedic) {
        return get(`medic/${IdMedic}`);
    }, 
    deleteMedic(IdMedic){
        return get(`medic/delete/${IdMedic}`); 
    },
    getMedics() { 
        return get('medics');
    }, 
    getMedicTypes() {
        return get('nomenclator/medic/types');
    },
    getMedicsByEmployee(p){ 
        return get(`medic/employee/${p.idEmployee}/${p.idHealthFacility}`);
    },
    getMedicalPrescriptions(){
        return get('medic/prescriptions'); 
    },
    updateMedic(entity){
        return post(`medic/update`, entity);
    },
    updateEmployeeMedic(entity){
        return post(`medic/employee/update`, entity);
    },
    createMedic(entity){
        return post(`medic/create`, entity);
    },
};