import { combineReducers } from "redux"; 
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Uses localStorage for persistence

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer"; 
import ForgetPassword from "./auth/forgetpwd/reducer"; 
// Persist configuration for redux-persist
const persistConfig = {
  key: "root", // Key for localStorage
  storage,     // Define where to store the state
  whitelist: ["Login"], // Only persist the `Login` reducer (add others if needed)
};

const rootReducer = combineReducers({
  // public
  Layout,
  Login, 
  ForgetPassword 
});
export default persistReducer(persistConfig, rootReducer);