
import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { Toolbar } from 'primereact/toolbar';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
import { IconField } from 'primereact/iconfield';
import { Row } from 'reactstrap';

import Breadcrumbs from "components/Common/Breadcrumb"
import { Container } from "reactstrap"

import { Card } from 'reactstrap';
import { CardBody } from 'reactstrap';
import { InputIcon } from 'primereact/inputicon'; 
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ProductService } from 'services/ProductService';
import { CommonService } from 'services/CommonService';
import {NomenclatorService} from 'services/NomenclatorService';

import Select from "react-select";

const StudiesNomenclatorComponent = () => {
    let emptyEntity = {
        id: null,
        label: '',
        description: '',
        dateEnded: null,
        available: false,
    };

    const [entities, setEntities] = useState(null);
    const [entityDialog, setEntityDialog] = useState(false);
    const [deleteEntityDialog, setDeleteEntityDialog] = useState(false);
    const [entity, setEntity] = useState(emptyEntity);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        NomenclatorService.getStudies().then(c=>{
            console.log("Medical studies:", c);
            setEntities(c);
        });
    }, []);

    const openNew = () => {
        setEntity(emptyEntity);
        setSubmitted(false);
        setEntityDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setEntityDialog(false);
    };

    const hideDeleteEntityDialog = () => {
        setDeleteEntityDialog(false);
    };

    const commitChanges = () => {
        setSubmitted(true);
        let _entities = [...entities];
        
        const successAction = () => {
            setEntities(_entities);
            setEntityDialog(false);
            setEntity(emptyEntity); 
            setSubmitted(false);
            toast.current.show({ severity: 'success', summary: 'Confirmare', detail: 'Datele au fost actualizate', life: 3000 });
                        
        }

        const failedAction = () => {
            toast.current.show({ severity: 'error', summary: 'Eroare', detail: 'Datele nu au fost actualizate', life: 3000 });
            setSubmitted(false);
        }

        console.log("Saving Entity:", entity);

        if (entity.label && entity.label.trim().length > 3 && entity.dateEnded && entity.description && entity.description.trim().length > 3) {
      
            let _entity = { ...entity };

            if (entity.id) {
                const index = findIndexById(entity.id); 
                _entities[index] = _entity;
  
                NomenclatorService.updateStudy(_entity).then(f=>{
                    if(f.success){ 
                        successAction();
                    } else { 
                        failedAction();
                    }
                });

             } else {
                _entity.id = createId();
                _entities.push(_entity);
 
                NomenclatorService.createStudy(_entity).then(f=>{
                    if(f.success){
                        successAction(); 
                    } else {
                        failedAction();
                    }
                });
            }
        }
    };

    const editEntity = (entity) => { 
        console.log("Entity:", entity);
        setEntity({ ...entity,
            dateEnded: entity.dateEnded ? new Date(entity.dateEnded) : null
         });
        setEntityDialog(true);
    };

    const confirmDeleteEntity = (entity) => {
        setEntity(entity);
        setDeleteEntityDialog(true);
    };

    const deleteEntity = () => {
        let _entities = entities.filter((val) => val.id !== entity.id);


        NomenclatorService.deleteStudy(entity.id).then(f=>{
            if(f.success){
                
        setEntities(_entities);
        setDeleteEntityDialog(false);
        setEntity(emptyEntity);
                toast.current.show({ severity: 'success', summary: 'Confirmare', detail: 'Datele au fost sterse', life: 3000 });
            } else {
                toast.current.show({ severity: 'error', summary: 'Eroare', detail: 'Datele nu au fost sterse', life: 3000 });
            }
        });
    };

    const findByLabel = (label) => {
        return entities.find(f=> f.label == label);
    }

    const findIndexById = (id) => {
        let index = -1;

        for (let i = 0; i < entities.length; i++) {
            if (entities[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        return id;
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _entity = { ...entity };

        _entity[`${name}`] = val;

        setEntity(_entity);
    };

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="Adauga" icon="bx bx-plus" severity="success"  onClick={openNew} />
            </div>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="bx bx-pencil" rounded className="mr-3" style={{"marginRight":"5px"}} onClick={() => editEntity(rowData)} />
                <Button icon="bx bx-trash" rounded  severity="danger" onClick={() => confirmDeleteEntity(rowData)} />
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <IconField iconPosition="left">
                <InputIcon className="bx bx-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cauta..." />
            </IconField>
        </div>
    );

    const entityDialogFooter = (
        <React.Fragment>
            <Button label="Inchide" icon="bx bx-x" outlined style={{"marginRight":"5px"}} onClick={hideDialog} />
            <Button label="Salveaza" icon="bx bx-check" onClick={commitChanges} />
        </React.Fragment>
    );

    const deleteentityDialogFooter = (
        <React.Fragment>
            <Button label="Nu" icon="bx bx-check" outlined style={{"marginRight":"5px"}} onClick={hideDeleteEntityDialog} />
            <Button label="Da" icon="bx bx-x" severity="danger" onClick={deleteEntity} />
        </React.Fragment>
    );

    const isActiveBodyTemplate = (rowData) => {
        return rowData.available ? <Badge severity="success" value="Disponibil" /> : <Badge severity="danger" value="Indisponibil" />;
    };

    const dateEndedBodyTemplate = (rowData) => {
        console.log("RowData:", rowData);
        if(rowData.expired)
            return <span className="text-danger">Expirat {new Date(rowData.dateEnded).toLocaleDateString()}</span>;
        else
            return rowData.dateEnded ? <span>{new Date(rowData.dateEnded).toLocaleDateString()}</span> : <span>N/A</span>;
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Administrare" breadcrumbItem="Nomenclator" />
                <Container fluid>
                    <Toast ref={toast} />
                    <Card>
                        <CardBody>
                            <Row>
                                <div>
                                    <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
                                    <DataTable ref={dt} value={entities}
                                        dataKey="id"
                                        paginator rows={5}
                                        rowsPerPageOptions={[5, 10, 25]}
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Afiseaza {first} pana la {last} din {totalRecords} inregistrari" globalFilter={globalFilter} header={header}>                                      
                                        <Column field="label" header="Denumire studiu" sortable style={{ minWidth: '16rem' }}></Column> 
                                        <Column field="description" header="Descriere" sortable style={{ minWidth: '16rem' }}></Column> 
                                        <Column body={dateEndedBodyTemplate} field="dateEnded" header="Valabil pana in data" sortable style={{ minWidth: '16rem' }}></Column> 
                                        <Column body={isActiveBodyTemplate} header="Disponibil" sortable style={{ minWidth: '16rem' }}></Column> 
                                        <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>                              
                                    </DataTable>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
 
                    <Dialog visible={entityDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                        header="Detalii" modal className="p-fluid" footer={entityDialogFooter} onHide={hideDialog}> 
                        <div className="field">
                            <label htmlFor="code" className="font-bold">
                               Denumirea studiului
                            </label>
                            <InputText id="label" minLength={3} value={entity.label} onChange={(e) => setEntity({...entity, label: e.target.value})} required />
                            {submitted && !entity.label && <small className="p-error">Camp obligatoriu.</small>}
                        </div> 

                        <div className="field">
                            <label htmlFor="description" className="font-bold">
                                Descriere
                            </label>
                            <InputText id="description" value={entity.description} onChange={(e) => {
                                setEntity({...entity, description: e.target.value});
                            }} required />
                            {submitted && !entity.description && <small className="p-error">Camp obligatoriu.</small>}
                        </div>

                        <div className="field">
                            <label htmlFor="label" className="font-bold">
                               Disponibil pana in data
                            </label>
                            <Calendar id="dateEnded"
                            dateFormat="dd/mm/yy"
                            value={entity.dateEnded} onChange={(e) => {
                                setEntity({...entity, dateEnded: e.target.value});
                            }} required />
                        </div> 

                        <div className="field">
                            <Checkbox className="me-2" id="available" 
                             onChange={(e) => {
                                setEntity({...entity, available: e.target.checked});
                                console.log(e.target.checked)}}
                             checked={entity.available}
                             required /> 
                            
                            <span htmlFor="available" className="font-bold">
                                Studiu disponibil angajatilor
                            </span>
                        </div> 
                    </Dialog>

                    <Dialog visible={deleteEntityDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirmare" modal footer={deleteentityDialogFooter}
                     onHide={hideDeleteEntityDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {entity && (
                                <span>
                                    Esti de acord sa stergi <b>{entity.label}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default StudiesNomenclatorComponent;