import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { FilterService } from "primereact/api";  
import { Card, CardBody, Col, Row } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { FilterMatchMode } from 'primereact/api';
import {FormGroup} from 'reactstrap'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Badge } from 'primereact/badge'
import { Calendar } from "primereact/calendar";  
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import { TasksService } from "services/TasksService";
import { date } from "yup";

const TasksOverview = ({ user }) => {
    document.title = "Planificare";

    const [entities, setEntities] = useState([]);
    const [tasksList, setTasksList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        address: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        healthFacility: { value: null, matchMode: FilterMatchMode.STARTS_WITH }
    });

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [dateRange, setDates] = useState(null);

    useEffect(() => {
        TasksService.getTasks().then(data => {
            setTasksList(data); 
            setEntities(data);
        });
    }, []);
 
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const viewTasksDetails = (options) => {
        return (
            <div className="list-unstyled hstack gap-1 mb-0">
                <li>
                    <Link
                        to={`/tasks/edit/${options.idTask}`}
                        className="btn btn-sm btn-soft-danger"
                        id={`${options.idTask}`}>
                        <i className="bx bx-pencil" />
                    </Link>
                </li>
            </div>
        );
    } 
     
    const applyDateFilter = (drg) => {  
        const [startDate, endDate] = drg;
        const filtered = entities.filter(row => {
            const datePlanned = new Date(row.datePlanned2);  
            return datePlanned >= startDate && datePlanned <= endDate;
        });
        setTasksList(filtered); 
    };
 
    const renderHeader = () => {
        return (
            <Row className="mb-4">
                <Col sm={6} className="col-xl">
                    <FormGroup className="mb-3">
                        <InputText className="me-2 mb-2" value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Cautare ..." />
                        <Calendar
                            placeholder="Planificarile din data ..."
                            value={dateRange} 
                            dateFormat="dd/mm/yy"
                            onChange={(e) => { 
                                setDates(e.value);

                                if(e.value[0] && e.value[1]){
                                    console.log(e.value);
                                    applyDateFilter(e.value);
                                }
                            }} 
                            selectionMode="range" 
                            readOnlyInput 
                            hideOnRangeSelection /> 
                    </FormGroup>
                </Col>
            </Row>
        )
    } 

    const clientNameColumnBody = (data) => {
        if (data.isUnknownClient)
            return <><Badge severity="danger" value={data.clientName} /></>
         return <>{data.clientName}</>
    }
 
    const header = renderHeader();

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Operational" breadcrumbItem="Planificari" />
                    {
                        isLoading ? <Spinners setLoading={setIsLoading} />
                            :
                            <Row>
                                <Col xs="12">
                                    <Card>
                                        <CardBody> 
                                            <DataTable columnResizeMode="expand"
                                                resizableColumns className='.p-datatable-lg' style={{ "fontSize": "15px" }}
                                                size='small' value={tasksList} paginator rows={10}
                                                rowsPerPageOptions={[5, 10, 25]}
                                                currentPageReportTemplate="Afiseaza {first} pana la {last} din {totalRecords} planificari"
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                dataKey="idTask" 
                                                filters={filters}
                                                filterDisplay="row" 
                                                loading={isLoading}
                                                globalFilterFields={['employeeName', 'clientName', 'clientType', 'clientZone']} header={header}
                                                emptyMessage="Nu au fost gasite rezultate">
                                                {
                                                    (user && user.isAdmin && (<Column sortable field="employeeName" header="Nume angajat" filter filterPlaceholder="Angajat" style={{ width: "fit-content" }} />))
                                                }
                                                <Column sortable field="datePlanned" header="Data planificarii" filter filterPlaceholder="Data" style={{ width: "fit-content" }} />
                                                <Column sortable body={clientNameColumnBody} header="Unitate" filter filterPlaceholder="Unitate" style={{ width: "fit-content" }} />
                                                <Column sortable field="clientType" filterStyle={{ minWidth: "100px" }} header="Tip unitate" filter filterPlaceholder="Tip unitate" style={{ width: "fit-content" }} />
                                                <Column sortable field="clientZoneCity" filterStyle={{ width: "100%" }} header="Oras" filter filterPlaceholder="Zona" style={{ width: "fit-content" }} />
                                                <Column sortable field="clientZone" header="Zona" filter filterPlaceholder="Oras" style={{ width: "fit-content" }} />
                                                <Column body={(data)=> { return <><Badge severity="success" value={data.tasksCount} /></>}} header="Vizite" style={{ width: "fit-content" }} />
                                                <Column body={viewTasksDetails} style={{ width: "fit-content" }} />
                                            </DataTable>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                    }
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}
TasksOverview.propTypes = {
    preGlobalFilteredRows: PropTypes.any
};


const mapStateToProps = (state) => ({
    user: state.Login.user, // Replace 'auth' with the correct slice name
});

export default connect(mapStateToProps)(TasksOverview);