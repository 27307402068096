import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

import { SelectButton } from 'primereact/selectbutton';
import { useRef } from "react";

import { Column } from 'primereact/column';
import { Badge } from "primereact/badge";
import { ReportingService } from "services/ReportingService";
import WorkScheduleManager from "pages/HealthFacilities/components/medicSchedule";
import { DataTable } from 'primereact/datatable';
import { Button, Card, CardBody, Col, Container, Input, Label, Row } from "reactstrap";
import { Toast } from 'primereact/toast';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";
// FlatPickr
import "flatpickr/dist/themes/material_blue.css";
import { MedicService } from "services/MedicService";

const MedicUpdateLimitedComponent = () => {
    document.title = "Administrare personal medical";

    const { idMedic } = useParams();
    const [entity, setEntity] = useState(null);
    const toast = useRef(null);
    const [scheduleDialogVisible, setScheduleDialogVisible] = useState(false);

    useEffect(() => {
        if (idMedic > 0)
            ReportingService.getMedicPlannificationHistory(idMedic).then(f => {
                setEntity(f);
            });
    }, []);

    const medicStatusBody = (options) => {
        if (options.active) return <Badge severity='success' value='Activ' />
        return <Badge severity='danger' value='Inactiv' />
    }

    const operationsBody = () => {
        return (<div className="list-unstyled hstack gap-1 mb-0">
            <li>
                <Button onClick={() => {
                    setCurrentMedic(options);
                    setScheduleDialogVisible(true);
                }} className="btn btn-sm btn-soft-info">Orar</Button></li>
        </div>)
    }

    const saveChanges = () => {

        if (idMedic > 0) {
            MedicService.updateEmployeeMedic({
                idMedic: entity.medic.idMedic,
                address: entity.medic.address,
                idMedicType: 1, // dummy
                name: "CRM", // dummy
                subscribedNewsletter: entity.medic.subscribedNewsletter,
                email: entity.medic.email,
                mobile: entity.medic.mobile
            }).then(e => {
                if (e.success) {
                    toast.current.show({
                        severity: 'success', summary: 'Succes',
                        detail: 'Informatiile au fost actualizate cu success.'
                    });
                } else {
                    toast.current.show({
                        severity: 'error', summary: 'Eroare',
                        detail: `Eroare la actualizarea informatiilor. Detalii: ${e?.details}`
                    });
                }
            });
        }
    }

    const scheduleDialogFooter = (
        <React.Fragment>
            <Button type="button" className="btn btn-success" icon="bx bx-check" onClick={() => {
                const updatedMedics = formik.values.medics.map(medic => {
                    if (medic.idMedic === currentMedic.idMedic) {
                        return {
                            ...medic,
                            schedule: currentMedic.schedule
                        };
                    }
                    return medic;
                }).filter(medic => medic !== null); // Remove any null entries (new medics marked for removal)
                formik.setFieldValue("medics", updatedMedics);
                setScheduleDialogVisible(false);
            }}>Salveaza</Button>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Administrare" breadcrumbItem="Personal medical" />
                    <Toast ref={toast} />
                    <Dialog header="Program de lucru"
                        footer={scheduleDialogFooter}
                        visible={scheduleDialogVisible}
                        style={{ width: '50vw' }}
                        onHide={() => {
                            if (!scheduleDialogVisible) return;
                            setScheduleDialogVisible(false);
                            setCurrentMedic(null);
                        }}>
                        <div>
                            <WorkScheduleManager onScheduleChange={handleScheduleChange} schedules={currentMedic?.schedule} />
                        </div>
                    </Dialog>

                    <Row>
                        <Col lg={8}>
                            <Card>
                                <CardBody>
                                    <div className="mb-3">
                                        <Label htmlFor="idMedicType">Categoria medicului</Label>
                                        <Select name="idMedicType" value={{ value: 1, label: entity?.medic?.medicType }} />
                                    </div>

                                    <div className="mb-3">
                                        <Label htmlFor="name">Numele medicului</Label>
                                        <Input type="text" value={entity?.medic?.name} />
                                    </div>

                                    <div className="mb-3">
                                        <Label htmlFor="email">Adresa e-Mail</Label>
                                        <Input
                                            id="email"
                                            name="email"
                                            value={entity?.medic?.email}
                                            type="email"
                                            onChange={(e) => {
                                                setEntity(prev => {
                                                    return {
                                                        ...prev,
                                                        medic: {
                                                            ...prev.medic,
                                                            email: e.target.value
                                                        }
                                                    };
                                                });

                                            }}
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <Label htmlFor="mobile">Telefon mobil</Label>
                                        <Input
                                            id="mobile"
                                            name="mobile"
                                            type="text"
                                            placeholder="Telefon contact..."
                                            onChange={(e) => {
                                                setEntity(prev => {
                                                    return {
                                                        ...prev,
                                                        medic: {
                                                            ...prev.medic,
                                                            mobile: e.target.value
                                                        }
                                                    };
                                                });

                                            }}
                                            value={entity?.medic?.mobile || ""}
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <Label htmlFor="address">Adresa medicului</Label>
                                        <Input
                                            id="address"
                                            name="address"
                                            type="textarea"
                                            value={entity?.medic?.address}
                                            onChange={(e) => {
                                                setEntity(prev => {
                                                    return {
                                                        ...prev,
                                                        medic: {
                                                            ...prev.medic,
                                                            address: e.target.value
                                                        }
                                                    };
                                                });
                                            }}
                                            placeholder="Adresa de lucru a medicului..."
                                        />
                                    </div>

                                    <Row className="mb-3">
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="competences">Competențe</Label>
                                                <Select
                                                    isMulti
                                                    name="competences"
                                                    placeholder="Lipsa competente"
                                                    value={entity?.medic?.competences || []}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-3">
                                                <Label htmlFor="specializations">Specializări</Label>
                                                <Select
                                                    isMulti
                                                    name="specializations"
                                                    value={entity?.medic?.specializations || []}
                                                    placeholder="Lipsa specializări"
                                                />
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="mb-3">
                                        <div style={{
                                            fontSize: "9px",
                                            transform: "scale(0.8)",
                                            transformOrigin: "left"
                                        }} className="card flex justify-content-center">
                                            <SelectButton
                                                value={entity?.medic?.subscribedNewsletter ? "Primeste newsletter" : "Nu este inregistrat la newsletter"}
                                                onChange={(e) => {
                                                    setEntity(prev => {
                                                        return {
                                                            ...prev,
                                                            medic: { ...prev.medic, subscribedNewsletter: (e.value == "Primeste newsletter") }
                                                        }
                                                    });
                                                }}
                                                options={["Nu este inregistrat la newsletter", "Primeste newsletter"]}
                                            />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={4}>
                            <Card>
                                <CardBody>
                                    <h5 className="card-title mb-3">Sumar activitate</h5>
                                    <div className="mb-3">
                                        {idMedic == -1 ? (
                                            <div>Nu exista momentan activitate pentru acest medic. Trebuie completat formularul alaturat pentru inregistrarea noului medic.</div>
                                        ) : (
                                            <div>De la data inregistrarii {entity?.medic?.dateCreated || "-"}, au fost planificate {entity?.tasks?.length || "0"} vizite catre acest medic.</div>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={8}>
                            <Card className="mt-4">
                                <CardBody>
                                    <h4 className="card-title">Unitati sanitare </h4>
                                    <DataTable
                                        value={entity?.healthFacilities || []}
                                        paginator
                                        rows={10}
                                        dataKey="idClient"
                                        emptyMessage="Nu au fost gasite rezultate">
                                        <Column body={operationsBody} style={{ minWidth: "12rem" }} />
                                        <Column field="name" header="Denumire unitate" style={{ minWidth: '12rem' }} />
                                        <Column body={medicStatusBody} header="Status activitate medic" style={{ minWidth: '12rem' }} />
                                        <Column field="healthFacilityType" header="Tip unitate" style={{ minWidth: '12rem' }} />
                                        <Column field="zone" header="Zona activitate" style={{ minWidth: '12rem' }} />
                                    </DataTable>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={8}>
                            <Card className="mt-4">
                                <CardBody>
                                    <h4 className="card-title">Istoric planificari</h4>
                                    <DataTable
                                        value={entity?.tasks || []}
                                        paginator
                                        rows={10}
                                        dataKey="idHealthFacility"
                                        emptyMessage="Nu au fost gasite rezultate">
                                        <Column field="clientName" header="Denumire client" style={{ minWidth: '12rem' }} />
                                        <Column field="datePlanned" header="Data planificarii" style={{ minWidth: '12rem' }} />
                                        <Column field="details" header="Observatii" style={{ minWidth: '12rem' }} />
                                    </DataTable>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={8}>
                            <div className="text-end mb-4">
                                <Button type="button" onClick={saveChanges} color="success">
                                    Salveaza
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default MedicUpdateLimitedComponent;
