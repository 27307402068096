import React, { useEffect, useState, useMemo } from "react";

import { Link } from "react-router-dom";
import { connect } from "react-redux";

import {Badge} from 'primereact/badge';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import { useParams } from "react-router-dom";
import TeamMembers from "./teamMembers";

//Import mini card widgets
import MiniCards from "./mini-card";

//Import Images
import profile1 from "assets/images/profile-img.png";

// import charts
import ApexRevenue from "./ApexRevenue";
import { EmployeeService } from "services/EmployeeService";

const EmployeeProfile = ({user}) => {

  document.title = "Profil utilizator";
  const { idEmployee } = useParams();

  const [employeeProfile, setEmployeeProfile] = useState({});
  const [employeeStatistics, setEmployeeStatistics] = useState([
  {
      name: "Vizite",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  }]);

  // eslint-disable-next-line no-unused-vars
  const [miniCards, setMiniCards] = useState([
    { id: 1, title: "Clienti activi", iconClass: "bx-check-circle", text: "-" },
    { id: 2, title: "Vizite efectuate", iconClass: "bx-hourglass", text: "-" },
    { id: 3, title: "Incidente raportate", iconClass: "bx-package", text: "-" },
  ]);

  useEffect(() => {
    EmployeeService.getEmployeeProfile(idEmployee).then(w => {
      setEmployeeProfile(w);
      setEmployeeStatistics([{ ...employeeStatistics, data: w.yearlyActivityStats }]);

      var updatedCards = miniCards.map(f => {
        let text = "-";
        if (f.id == 3) { text = w.incidentsCount }
        else if (f.id == 1) { text = w.activeClientsCount }
        else { text = w.tasksPlannedCount }
        return { ...f, text: (text + " ") }
      });
      setMiniCards(updatedCards);
    });
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl="4">
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs="7">
                      <div className="text-primary p-3">
                        <h5 className="text-primary">Bine ai revenit !</h5>
                        <p>Mult succes, astazi</p>
                      </div>
                    </Col>
                    <Col xs="5" className="align-self-end">
                      <img src={profile1} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <Row>
                    <Col sm="4">
                      <div className="avatar-md profile-user-wid mb-4">
                        <img
                          src={employeeProfile?.img}
                          alt=""
                          className="img-thumbnail rounded-circle"
                        />
                      </div>
                      <h5 className="font-size-15 text-truncate">
                        {employeeProfile?.employee?.fullName}
                      </h5>
                      <p className="text-muted mb-0 text-truncate">
                        {employeeProfile?.employee?.jobTitle}
                      </p>
                    </Col>
                    {
                      (user && user.isAdmin) ? (
                        <Col sm={8}>
                          <div className="pt-4">
                            <div className="mt-4">
                              <Link to={`/employee/edit/${employeeProfile?.employee?.id}`} className="btn btn-primary btn-sm">
                                Modifica profil{" "}
                                <i className="mdi mdi-arrow-right ms-1" />
                              </Link>
                            </div>
                          </div>
                        </Col>) : (<></>)
                    }
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Informatii personale</CardTitle>
                  <p className="text-muted mb-4">
                    Datele de inregistrare ale angajatului
                  </p>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">Numele :</th>
                          <td>{employeeProfile?.employee?.fullName}</td>
                        </tr>
                        <tr>
                          <th scope="row">Functia :</th>
                          <td>{employeeProfile?.employee?.jobTitle ?? "-"}</td>
                        </tr>
                        <tr>
                          <th scope="row">Status :</th>
                          <td>{employeeProfile?.employee?.active ? <Badge severity="success" value="Activ"></Badge> : <Badge severity="danger" value="Inactiv"></Badge>}</td>
                        </tr>
                        <tr>
                          <th scope="row">E-mail :</th>
                          <td>{employeeProfile?.employee?.email ?? "-"}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card> 
              <TeamMembers team={employeeProfile?.teamMembers} /> 
            </Col>

            <Col xl="8">
              <Row>
                {(miniCards || [])?.map((card, key) => (
                  <MiniCards
                    title={card.title}
                    text={card.text}
                    iconClass={card.iconClass}
                    key={"_card_" + key}
                  />
                ))}
              </Row>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Sumar activitate</CardTitle>
                  <div id="revenue-chart">
                    <ApexRevenue series={employeeStatistics} dataColors='["--bs-primary"]' />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.Login.user, // Replace 'auth' with the correct slice name
});

export default connect(mapStateToProps)(EmployeeProfile);
