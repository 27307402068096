import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from "primereact/dialog";
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { FilterMatchMode } from 'primereact/api';
import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import { HealthFacilityService } from "services/HealthFacilityService";

function HealthFacilitiesOverview({ user }) {
    document.title = "Administrare Unitati sanitare";

    const [healthfacilitiesList, setHealthfacilitiesList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        address: { value: null, matchMode: FilterMatchMode.CONTAINS },
        city: { value: null, matchMode: FilterMatchMode.CONTAINS },
        zone: { value: null, matchMode: FilterMatchMode.CONTAINS },
        healthFacility: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    useEffect(() => {
        HealthFacilityService.getHealthFacilities().then(data => {
            setHealthfacilitiesList(data);
        });
    }, []);

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const viewHealthFacilityDetails = (options) => {
        return (
            <div className="list-unstyled hstack gap-1 mb-0">
                <li>
                    <Link
                        to={`/healthfacility/${options.employeeEdit ? "employee" : "edit"}/${options.idHealthFacility}`}
                        className="btn btn-sm btn-soft-info"
                        id={`${options.idHealthFacility}`}>
                        <i className="bx bx-search-alt" />
                    </Link>
                </li>
            </div>
        );
    }

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Cautare ..." />
                </IconField>
            </div>
        );
    };

    const header = renderHeader();

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Gestiune clienti" breadcrumbItem="Unitati sanitare" />
                    {
                        (user && user.isAdmin) ? (
                            <Row className="d-flex justify-content-end" >
                                <Col xs="2" lg="2" md="2">
                                    <Card style={{ backgroundColor: 'transparent' }}>
                                        <Button color="success" className="btn-rounded "><Link style={{ color: 'white ' }} to="/healthfacility/create/-1">Adauga unitate sanitara</Link></Button>
                                    </Card>
                                </Col>
                            </Row>) : (<></>)
                    }
                    {
                        isLoading ? <Spinners setLoading={setIsLoading} />
                            :
                            <Row>
                                <Col xs="12">
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <DataTable columnResizeMode="expand"
                                                    resizableColumns className='.p-datatable-lg' style={{ "fontSize": "15px" }}
                                                    size='small' value={healthfacilitiesList || []} paginator rows={10}
                                                    rowsPerPageOptions={[5, 10, 25]}  
                                                    dataKey="idHealthFacility" filters={filters} filterDisplay="row"
                                                    globalFilterFields={['name', 'healthFacilityType', 'address', 'city', 'zone']}
                                                    currentPageReportTemplate="Afiseaza {first} pana la {last} din {totalRecords} unitati" 
                                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                    header={header}
                                                    emptyMessage="Nu au fost gasite rezultate">
                                                    <Column  sortable field="name" header="Unitatea sanitara" filter filterPlaceholder="Unitatea sanitara" />
                                                    <Column  sortable field="city" header="Oras" filter filterPlaceholder="Oras" />
                                                    <Column  sortable field="zone" header="Zona de lucru" filter filterPlaceholder="Zona de lucru" />
                                                    <Column  sortable body={viewHealthFacilityDetails} />
                                                </DataTable>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                    }
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}
HealthFacilitiesOverview.propTypes = {
    preGlobalFilteredRows: PropTypes.any
};

const mapStateToProps = (state) => ({
    user: state.Login.user,
});

export default connect(mapStateToProps)(HealthFacilitiesOverview);