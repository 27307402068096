
import React, { useState, useEffect, useRef } from 'react'; 
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar'; 
import { IconField } from 'primereact/iconfield';
import { Row } from 'reactstrap'; 
import Breadcrumbs from "components/Common/Breadcrumb"
import { Container } from "reactstrap" 
import { Card } from 'reactstrap';
import { CardBody } from 'reactstrap';
import { InputIcon } from 'primereact/inputicon';  
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';  
import {RoutesService} from 'services/RoutesService';
import {EmployeeService} from 'services/EmployeeService';

import Select from "react-select";

const RoutesNomenclatorComponent = () => {
    let emptyEntity = {
        id: null,
        idEmployee: null,
        idRoute: -1,
        label: '',
        description: '',
        idEmployee: ''
    };

    const [entities, setEntities] = useState(null);
    const [entityDialog, setEntityDialog] = useState(false);
    const [deleteEntityDialog, setDeleteEntityDialog] = useState(false);
    const [entity, setEntity] = useState(emptyEntity);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null); 
    const [employees, setEmployees] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        RoutesService.getRoutes("-1").then(f=>{
            setEntities(f); 

            EmployeeService.getEmployees().then(e=>{
                setEmployees(e);
            });
        });
    }, []);

    const openNew = () => {
        setEntity(emptyEntity);
        setSubmitted(false);
        setEntityDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setEntityDialog(false);
    };

    const hideDeleteEntityDialog = () => {
        setDeleteEntityDialog(false);
    };

    const commitChanges = () => {
        setSubmitted(true);

        let _entities = [...entities];
        const successAction = () => {
            setEntities(_entities);
            setEntityDialog(false);
            setEntity(emptyEntity); 
            setSubmitted(false);
            toast.current.show({ severity: 'success', summary: 'Confirmare', detail: 'Datele au fost actualizate', life: 3000 }); 
        }

        const failedAction = () => {
            toast.current.show({ severity: 'error', summary: 'Eroare', detail: 'Datele nu au fost actualizate', life: 3000 });
            setSubmitted(false);
        }
 
        if (entity.label && entity.label.trim().length > 3 && entity.idEmployee != '' && entity.description != '') {
        
            let _entity = { ...entity }; 
            
            if (entity.id) {
                const index = findIndexById(entity.id); 
                _entities[index] = _entity;
  
                RoutesService.updateRoute(_entity).then(f=>{
                    if(f.success){ 
                        successAction();
                    } else { 
                        failedAction();
                    }
                });

             } else {
                _entity.id = createId();
                _entities.push(_entity);
 
                RoutesService.createRoute(_entity).then(f=>{
                    if(f.success){
                        successAction(); 
                    } else {
                        failedAction();
                    }
                });
            }
        }
    };

    const editEntity = (entity) => {  
        setEntity({ ...entity });
        setEntityDialog(true);
    };

    const confirmDeleteEntity = (entity) => {
        setEntity(entity);
        setDeleteEntityDialog(true);
    };

    const deleteEntity = () => {

        RoutesService.deleteRoute(entity.idRoute).then(f=>{
            if(f.success){ 
                let _entities = entities.filter((val) => val.idRoute !== entity.idRoute);

                setEntities(_entities);
                setDeleteEntityDialog(false);
                setEntity(emptyEntity); 
                toast.current.show({ severity: 'success', summary: 'Confirmare', detail: 'Datele au fost sterse', life: 3000 });
            } else {
                toast.current.show({ severity: 'error', summary: 'Eroare', detail: 'Datele nu au fost sterse', life: 3000 });
            }
        });
    };
 
    const findIndexById = (id) => {
        let index = -1;

        for (let i = 0; i < entities.length; i++) {
            if (entities[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        return id;
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _entity = { ...entity };

        _entity[`${name}`] = val;

        setEntity(_entity);
    };

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="Adauga" icon="bx bx-plus" severity="success"  onClick={openNew} />
            </div>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="bx bx-pencil" rounded className="mr-3" style={{"marginRight":"5px"}} onClick={() => editEntity(rowData)} />
                <Button icon="bx bx-trash" rounded  severity="danger" onClick={() => confirmDeleteEntity(rowData)} />
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <IconField iconPosition="left">
                <InputIcon className="bx bx-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cauta..." />
            </IconField>
        </div>
    );

    const entityDialogFooter = (
        <React.Fragment>
            <Button label="Inchide" icon="bx bx-x" outlined style={{"marginRight":"5px"}} onClick={hideDialog} />
            <Button label="Salveaza" icon="bx bx-check" onClick={commitChanges} />
        </React.Fragment>
    );

    const deleteentityDialogFooter = (
        <React.Fragment>
            <Button label="Nu" icon="bx bx-check" outlined style={{"marginRight":"5px"}} onClick={hideDeleteEntityDialog} />
            <Button label="Da" icon="bx bx-x" severity="danger" onClick={deleteEntity} />
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Administrare" breadcrumbItem="Rute" />
                <Container fluid>
                    <Toast ref={toast} />
                    <Card>
                        <CardBody>
                            <Row>
                                <div>
                                    <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
                                    <DataTable ref={dt} value={entities}
                                        dataKey="id"
                                        paginator rows={5}
                                        rowsPerPageOptions={[5, 10, 25]} 
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Afiseaza {first} pana la {last} din {totalRecords} inregistrari" 
                                        globalFilterFields={['label', 'description', 'employee']}
                                        globalFilter={globalFilter} header={header}>                                      
                                        <Column field="label" header="Denumire ruta" filter filterPlaceholder="Denumire ruta" sortable style={{ minWidth: '16rem' }}></Column>
                                        <Column field="description" header="Descriere" filter filterPlaceholder="Descriere" sortable style={{ minWidth: '10rem' }}></Column> 
                                        <Column field="employee" header="Angajat" filter filterPlaceholder="Angajat" sortable style={{ minWidth: '10rem' }}></Column>
                                        <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>                              
                                    </DataTable>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>


                    <Dialog visible={entityDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                        header="Detalii" modal className="p-fluid" footer={entityDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="city" className="font-bold">
                                Numele angajatului
                            </label>
                            <Select placeholder="Alege angajatul" 
                            options={employees || []} 
                            styles={{
                                menu: base => ({
                                    ...base,
                                    zIndex: 100
                                })
                            }}
                            getOptionLabel={o=> o.label}
                            getOptionValue={o=> o.idEmployee}
                            value={employees?.find(o=> o.idEmployee == entity?.idEmployee)} 
                            onChange={(e) => setEntity(prev => { return {...prev, idEmployee: e.idEmployee }}) } required autoFocus/>
                            {submitted && entity.idEmployee == '' && <small className="p-error">Camp obligatoriu.</small>}
                        </div>
 
                        <div className="field">
                            <label htmlFor="label" className="font-bold">
                                Denumirea rutei
                            </label>
                            <InputText id="label" value={entity.label} onChange={(e) => onInputChange(e, 'label')} required />
                            {submitted && !entity.label && <small className="p-error">Camp obligatoriu.</small>}
                        </div>

                        <div className="field">
                            <label htmlFor="label" className="font-bold">
                                Descrierea ruta
                            </label>
                            <InputText id="label" value={entity.description} onChange={(e) => onInputChange(e, 'description')} required />
                            {submitted && !entity.description && <small className="p-error">Camp obligatoriu.</small>}
                        </div>


                    </Dialog>

                    <Dialog visible={deleteEntityDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirmare" modal footer={deleteentityDialogFooter}
                     onHide={hideDeleteEntityDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {entity && (
                                <span>
                                    Esti de acord sa stergi <b>{entity.label}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default RoutesNomenclatorComponent;