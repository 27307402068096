import { get, post } from "../helpers/api_helper"

export const PrescriptionService = {
    downloadPrescription(IdPrescription){ 
        return get(`prescriptions/download/${IdPrescription}`); 
    },
    getPrescription(IdPrescription){
        return get(`prescriptions/${IdPrescription}`); 
    },
    createPrescription(entity){
        return post(`prescriptions/create`, entity);
    },
    updatePrescription(entity){
        return post(`prescriptions/update`, entity);
    }
}