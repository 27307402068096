import React, { useEffect, useState, useRef } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"; 
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { DataTable } from 'primereact/datatable';
import {Badge} from "primereact/badge";
import { Column } from 'primereact/column';
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import { Link } from 'react-router-dom'
import Select from "react-select";
import { ReportingService } from "services/ReportingService";
import { CommonService } from "services/CommonService";
import { EmployeeService } from "services/EmployeeService";

const MedicsReportComponent = props => {
    document.title = "Raport planificari";

    const navigate = useNavigate();
    const [medics, setMedicsList] = useState([]);
    const [employeeList, setEmployeesList] = useState([]); 
    const [globalFilter, setGlobalFilter] = useState(null);
 
    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <IconField iconPosition="left">
                <InputIcon className="bx bx-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cauta..." />
            </IconField>
        </div>
    );

    const actionBodyTemplate = (options) => {
        return (
            <div className="list-unstyled hstack gap-1 mb-0">
                <li>
                    <Link
                        to={`/reports/medic/plannification/${options.idMedic}`}
                        className="btn btn-sm btn-soft-info"
                        id={`${options.idMedic}`}>
                        <i className="bx bx-show-alt" />
                    </Link>
                </li>
            </div>
        );
    }


    useEffect(()=> { 
        ReportingService.getMedicsPlanificationHistory().then(f=>{
          console.log("Medics:", f);
          setMedicsList(f);
      });
    },[]);

    const tasksCountBodyTemplaye = (x)=>{
        if (x.tasksCount > 0)
            return <Badge severity="success" value={x.tasksCount} />;
        else  
            return <Badge severity="danger" value="Lipsa activitate" />; 
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Rapoarte" breadcrumbItem="Planificari medici" />
                    <Row>
                        <Col xs={12}>
                            <Row>
                                <Col xl={12}>
                                    <Card>
                                        <CardBody>
                                            <DataTable value={medics}
                                                dataKey="idMedic"
                                                paginator rows={10}
                                                header={header}
                                                rowsPerPageOptions={[5, 10, 25]}
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                currentPageReportTemplate="Afiseaza {first} pana la {last} din {totalRecords} inregistrari" 
                                                globalFilter={globalFilter}>  
                                                <Column field="name" header="Numele medicului" sortable style={{ minWidth: '16rem' }}></Column>
                                                <Column field="specializations" header="Specializari" sortable style={{ minWidth: '10rem' }}></Column>
                                                <Column field="cities" header="Oras" sortable style={{ minWidth: '10rem' }}></Column>
                                                <Column field="zones" header="Cartier" sortable style={{ minWidth: '10rem' }}></Column> 
                                                <Column body={tasksCountBodyTemplaye}  header="Vizite" sortable style={{ minWidth: '10rem' }}></Column> 
                                                <Column field="prescriptionCount" header="Prescriptii" sortable style={{ minWidth: '10rem' }}></Column>  
                                                <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                                            </DataTable>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default MedicsReportComponent;
