import React, { useState, useEffect } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import Spinners from 'components/Common/Spinner';
import { Column } from 'primereact/column';
import { Badge } from 'primereact/badge';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Tag } from 'primereact/tag';
import {
    UncontrolledTooltip,
} from "reactstrap"
import { Link } from "react-router-dom"


const DataTableEmployees = ({ employeeData }) => {
    console.log("DataTableEmployees:", employeeData);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        secondName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        jobTitle: { value: null, matchMode: FilterMatchMode.STARTS_WITH }
    });

    const [isLoading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
 
    const employeeJobTitles = [
        { label: 'Reprezentant Medical' },
        { label: 'Agent vanzari' },
        { label: "AV & RM" }
    ];

    const [statuses] = useState(['Inchis', 'Activ']);

    const getSeverity = (status) => {
        switch (status) {
            case 'Activ':
                return 'success';
            case 'Inchis':
                return 'danger';
        }
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Cautare..." />
                </IconField>
            </div>
        );
    };

    const jobTitleBodyTemplate = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{rowData.jobTitle}</span>
            </div>
        );
    };

    const statusBodyTemplate = (rowData) => {
        // if active then show badge success
        if(rowData.active){
            return <Badge severity="success" value="Activ" />;
        }
        // if inactive then show badge danger
        return <Badge severity="danger" value="Inactiv" />;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={option} severity={getSeverity(option)} />;
    };

    const jobTitleFilterTemplate = (options) => {
   
        return (
            <MultiSelect
                value={options.value}
                options={employeeJobTitles}
                itemTemplate={jobTitlesItemTemplate}
                onChange={(e) => { options.filterApplyCallback(e.value) }}
                optionLabel="name"
                placeholder="Alege"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
            />
        );
    };

    const jobTitlesItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option}</span>
            </div>
        );
    };

    const statusRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={statuses}
                onChange={(e) => options.filterApplyCallback(e.value)}
                itemTemplate={statusItemTemplate} placeholder="Alege" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
        );
    };

    const operationsBodyTemplate = (options) => {
        return (
            <Link to={`/employee/profile/${options.idEmployee}`} className="btn btn-sm btn-soft-primary" id={`viewtooltip${options.idEmployee}`}>
                <i className="mdi mdi-eye-outline" />
                <UncontrolledTooltip placement="top" target={`viewtooltip${options.idEmployee}`}>
                    Vizualizare
                </UncontrolledTooltip>
            </Link>
        )
    }

    const header = renderHeader();

    return (
        <React.Fragment>
            {
                isLoading ? <Spinners setLoading={setLoading} /> : (
                    <DataTable
                        columnResizeMode="expand"
                        resizableColumns
                        className='.p-datatable-sm'
                        showGridlines={true }
                        value={employeeData} paginator rows={10}
                        dataKey="idEmployee" filters={filters} filterDisplay="row" loading={isLoading}
                        globalFilterFields={['name', 'jobTitle', 'secondName']} header={header}
                        emptyMessage="Nu au fost gasite rezultate">
                        <Column field="name" header="Nume" filter filterPlaceholder="Numele" style={{ minWidth: '12rem' }} />
                        <Column field="secondName" header="Prenume" filterField="secondName" style={{ minWidth: '12rem' }} filter filterPlaceholder="Prenumele" />
                        <Column field="jobTitle" header="Functie" filterField="jobTitle" filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '14rem' }} body={jobTitleBodyTemplate} filter filterElement={jobTitleFilterTemplate} />
                         <Column field="status" header="Status" filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '12rem' }} body={statusBodyTemplate} filter filterElement={statusRowFilterTemplate} />
                        <Column style={{ minWidth: '6rem' }} body={operationsBodyTemplate} />
                    </DataTable>
                )
            }
        </React.Fragment>
    );
}

export default DataTableEmployees;
