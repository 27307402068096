import React, { useEffect, useState, useRef} from "react";
import { Link, useParams } from "react-router-dom";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import BranchesComponent from "./branchesTable";
import {Toast} from 'primereact/toast'

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";
import * as yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { EmployeeService } from "services/EmployeeService";

const EmployeeEdit = () => {
  const { idEmployee = '' } = useParams();
  const toast = useRef(null);

  document.title = "Administrare personal";

  const [formData, setFormData] = useState({
    name: '',
    secondName: '',
    jobTitle: '',
    email: '',
    password: '',
    active: true
  });

  const [jobTypes, setJobTypes] = useState([]);
  const [branches, setBranches] = useState([]);
  const [zones, setZones] = useState([]);

  //Basic Information
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formData,
    validationSchema: yup.object().shape({
      name: yup.string().required('Acest camp este obligatoriu'),
      secondName: yup.string().required('Acest camp este obligatoriu'),
      jobTitle: yup.number().required('Acest camp este obligatoriu'),
      email: yup.string().email().required('Acest camp este obligatoriu')
    }),
    onSubmit: (x) => {
      console.log("DATA TO SEND:", x);
      let branchesTmp = [];
      if(x.branches && x.branches.length){
        branchesTmp = x.branches.map(f=> {return f.idZone});
      }
      let formData = {
        IdEmployee: "-1",//x.idEmployee,
        FirstName: x.name,
        LastName: x.secondName,
        Email: x.email,
        Password: x.password,
        IdJobType: x.jobTitle,
        Branches: branchesTmp
      };

      console.log("Sending...", formData);
      EmployeeService.updateEmployee(formData).then(f => {
        toast.current.show({ severity: 'success', summary: 'Confirmare', detail: 'Datele au fost actualizate', life: 3000 });
      });
    },
  });

  useEffect(() => {
    EmployeeService.getEmployee(idEmployee).then(w => {
      setJobTypes(w.jobTypes.map(w => {
        return {
          value: w.idJobType,
          label: w.label
        }
      }));
      setBranches(w.employee.branches);
      setZones(w.zones.map(x => { return { value: x.idZone, label: x.label } }));
      setFormData({ ...w.employee, jobTitle: w.employee.idJobType });
    }, []);

  }, []);

  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    city: { value: null, matchMode: FilterMatchMode.CONTAINS },
    zoneLabel: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Cautare..." />
        </IconField>
      </div>
    );
  };

  const header = renderHeader();

  return (
    <React.Fragment>
     <Toast ref={toast}></Toast>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <CardTitle>Datele de inregistrare</CardTitle>
                  <p className="card-title-desc mb-4">
                    Completeaza informatiile despre angajati
                  </p>

                  <Form onSubmit={formik.handleSubmit} autoComplete="off">
                    <Row>
                      <Col sm="6">
                        <div className="mb-3">
                          <Label htmlFor="name">Numele</Label>
                          <Input
                            id="name"
                            name="name"
                            type="text"
                            placeholder="Numele angajatului"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            invalid={formik.touched.name && formik.errors.name ? true : false}
                          />
                          {formik.errors.name && formik.touched.name ? (
                            <FormFeedback type="invalid">{formik.errors.name}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="secondName"> Prenumele </Label>
                          <Input
                            id="secondName"
                            name="secondName"
                            type="text"
                            placeholder="Prenumele angajatului"
                            value={formik.values.secondName}
                            onChange={formik.handleChange}
                            invalid={
                              formik.touched.secondName && formik.errors.secondName ? true : false
                            }
                          />
                          {formik.errors.secondName && formik.touched.secondName ? (
                            <FormFeedback type="invalid">{formik.errors.secondName}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="control-label"> Functia </Label>
                          <Select name="jobTitle" placeholder="Alege functia"
                            value={formik.values.jobTitle}
                            onChange={(e) => {
                              console.log(e, "DA");
                              formik.setFieldValue("jobTitle", e.value);
                            }}
                            options={jobTypes} className="select2" />

                          {formik.errors.jobTitle && formik.touched.jobTitle ? (
                            <span className="text-danger">{formik.errors.jobTitle}</span>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="price">Adresa e-mail</Label>
                          <Input
                            id="email"
                            name="email"
                            type="text"
                            placeholder="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            invalid={
                              formik.touched.email && formik.errors.email ? true : false
                            }
                          />
                          {formik.errors.email && formik.touched.email ? (
                            <FormFeedback type="invalid">{formik.errors.email}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="password">Parola e-mail</Label>
                          <Input
                            id="password"
                            name="password"
                            type="password"
                            placeholder="Parola email"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            invalid={
                              idEmployee == '' ? (
                                formik.touched.password && formik.errors.password ? true : false
                              ) : false
                            }
                          />
                          {formik.errors.password && formik.touched.password ? (
                            <FormFeedback type="invalid">{formik.errors.password}</FormFeedback>
                          ) : null}
                        </div>
                        <div className="">
                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-Input"
                              id="formrow-customCheck"
                              name="active"
                              value={formik.values.active}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              invalid={
                                formik.touched.active && formik.errors.active ? true : false
                              }
                            />
                            <Label
                              className="form-check-Label"
                              htmlFor="formrow-customCheck"
                            >
                              Angajatul este activ
                            </Label>
                          </div>
                          {
                            formik.errors.active && formik.touched.active ? (
                              <FormFeedback type="invalid">{formik.errors.active}</FormFeedback>
                            ) : null
                          }
                        </div>
                      </Col>

                    </Row>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary"> Salveaza  </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardTitle>Alocare zone de lucru</CardTitle>
                  <p className="card-title-desc">Administreaza zonele de lucru</p>
                  <div className="card">
                    <BranchesComponent model={branches} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EmployeeEdit
