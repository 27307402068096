import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
 
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// users 
import user1 from "../../../assets/images/biovitality/profile.png"

const ProfileMenu = ({user}) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false); 
  const [username, setusername] = useState("CRM");

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.user.name); 
    }
  },[]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end"> 
          <DropdownItem tag="a" href="#"> 
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            Setari
          </DropdownItem> 
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>Iesire</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  user: PropTypes.object, 
};

const mapStateToProps = (state) => ({
  user: state.Login.user, // Replace 'auth' with the correct slice name
});


export default withRouter(
  connect(mapStateToProps)(ProfileMenu)
);
