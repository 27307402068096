import React, { useEffect } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";  
import{ Nav, NavLink, NavItem}
 from "reactstrap";
import { useState } from "react";

const TasksActivityComponent = ({props}) => {

  const[data, setData] = useState(props);
  
  useEffect(()=>{ console.log(data,props, "D");}, []);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Urmatoarele planificari</CardTitle>
          <Nav pills className="bg-light rounded">
              <NavItem>
                <NavLink
                  style={{ cursor: "default" }}
                  className={"active bg-info"}>
                  Planificate
                </NavLink>
              </NavItem>
            </Nav>    <div className="mt-4">
          <ul className="verti-timeline list-unstyled">
            {
              (props || [])?.map((item, index) => (
                <li className={`event-list ${item.active && "active"}`} key={index}>
                  <div className="event-timeline-dot">
                    <Link to={"/tasks/" + item.idTask } className="text-info p-1">
                    <i className={`font-size-18 bx ${item.active ? "bxs-search-alt-2 bx-fade-right" : "bx-search-alt-2"}`} />
                    </Link>
                  </div>
                  <div className="flex-shrink-0 d-flex">
                    <div className="me-3">
                      <h5 className="font-size-14">
                        {item.datePlanned}
                      </h5>
                    </div>
                    <div className="flex-grow-1">
                      <div>{item.clientName}</div>
                      <div><small><b>{item.employeeName}</b></small></div>
                    </div>
                  </div>
                </li>
              ))
            }
          </ul></div>
            {
              props && props.length ? (
                <div className="text-center mt-4">
                  <Link to="/tasks" className="btn btn-primary waves-effect waves-light btn-sm">
                    Vezi mai multe <i className="mdi mdi-arrow-right ms-1" />
                  </Link>
                </div>) : <></>
            }
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default TasksActivityComponent;