
import React, { useState, useEffect } from 'react';
import DataTableEmployees from './components/DataTableEmployees'
import { Container, Row, Col, Button, Card, CardBody, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, UncontrolledTooltip } from "reactstrap";
import "primereact/resources/themes/lara-light-blue/theme.css";
import { Link } from 'react-router-dom';

// import services
import { EmployeeService } from 'services/EmployeeService';

export default function Employee() {
    const [viewModel, setViewModel] = useState(null); 

    const employeeStatistics = [
        { id: 1, title: "Agenti vanzari", iconClass: "bx-walk", value: 0 },
        { id: 2, title: "Reprezentanti medicali", iconClass: "bx-plus-medical", value: 0 },
        { id: 3, title: "AV & RM", iconClass: "bx bx-bar-chart-alt-2", value: 0 }
    ];

    useEffect(() => {
        try {
            EmployeeService.getEmployees().then(x => { 
                EmployeeService.getEmployeeStatistiscs().then(r=> { 
                    let statistics = [...employeeStatistics];
                    
                    statistics.forEach(f => {
                        if (f.id == 1) {
                            f.value = r.salesRepresentantsCount + " ";
                        } else if (f.id == 2) {
                            f.value = r.medicalRepresentantsCount + " ";
                        } else {
                            f.value = r.medicalSalesRepresentatsCount + " ";
                        }
                    });  
    
                    setViewModel({ 
                         statistics: statistics,
                         employees: x
                    }); 
                });
            });
        } catch { }
    }, []);

    return (
        <div className="page-content">
            <React.Fragment>
                <Container fluid>
                    <Row> {(viewModel?.statistics || []).map((item, key) => (
                        <Col md="4" key={"_col_" + key}>
                            <Card className="mini-stats-wid">
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">
                                                {item.title}
                                            </p>
                                            <h4 className="mb-0">{item.value}</h4>
                                        </div>
                                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle bg-primary">
                                                <i className={"bx " + item.iconClass + " font-size-24"}></i>
                                            </span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                    </Row>
                    
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom">
                                    <div className="d-flex align-items-center">
                                        <h5 className="mb-0 card-title flex-grow-1">Administrare personal</h5>
                                        <div className="flex-shrink-0">
                                            <Link to="/employee/edit/-1" className="btn btn-primary me-1">Angajat nou</Link>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody className="border-bottom"> 
                                    <DataTableEmployees employeeData={viewModel?.employees} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        </div>
    );
}
