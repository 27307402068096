import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import FlatPickr from "react-flatpickr";
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import { Toast } from 'primereact/toast';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";
import { Column } from 'primereact/column';
import "flatpickr/dist/themes/material_blue.css";
import { Helper } from "services/Helper";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CommonService } from "services/CommonService";
import { EmployeeService } from "services/EmployeeService";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { PrescriptionService } from "services/PrescriptionServices";
import { ProductService } from "services/ProductService";
import { MedicService } from "services/MedicService";
import { PharmacyService } from "services/PharmacyService";


const PrescriptionRegisterComponent = () => {
    document.title = "Administrare prescriptii medicale";

    const { idPrescription=-1 } = useParams();
    const toast = useRef(null);

    // States
    const [isLoading, setIsLoading] = useState(true);   
    const [globalFilterValue, setGlobalFilterValue] = useState(''); 
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: 'contains' }
    });
    const [saveDisabled, setSaveDisabled] = useState(false); 

    const [employees, setEmployees] = useState([]);
    const [medics, setMedics] = useState([]);
    const [products, setProducts] = useState([]);
    const [pharmacies, setPharmacies] = useState([]);
    const [currentEntityProductDialog, setCurrentEntityProductDialog] = useState(false);
    const [currentEntityProduct, setCurrentEntityProduct] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [emptyEntityProduct, setEmptyEntityProduct] = useState({
        idProduct: null,
        label: null,
        quantity: null,
        state: Helper.CONSTANTS.EntityState.New
    });
 
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            idPrescription: -9999,
            idMedic: null,
            idMedicalRepresentant: null,
            idPharmacy: null,
            datePrescription: null,
            comments: '',
            fileName: null,
            base64file: null,
            products: []    
        },
        validate: (values) => {
            console.log("VALUES:", values);
            let errors = {};
            if (values.products.length === 0) {
                errors.products = "Trebuie completat acest camp";
            }
            if (values.datePrescription == null || values.datePrescription.length === 0) {
                errors.datePrescription = "Trebuie completat acest camp";
            }

            console.log("ERRORS:", errors, " of ", values);
            if(Object.keys(errors).length > 0) {
                return errors; 
            }
        },
        validationSchema: Yup.object({
            idMedic: Yup.number().required("Trebuie completat acest camp"),
            idPharmacy: Yup.number().required("Trebuie completat acest camp"),
            idMedicalRepresentant: Yup.string().required("Trebuie completat acest camp"),
            datePrescription: Yup.date().required("Trebuie completat acest camp"),
            products: Yup.array().min(1, "Trebuie completat acest camp").required("Trebuie completat acest camp"),
        }), 
        onSubmit: async (values) => {

          console.log("VALUES submited:", values);
            setSaveDisabled(true);
            
            const entity = {
                ...values,
                datePrescription: values.datePrescription.length > 0 ? values.datePrescription[0] : null,
                idPrescription: (parseInt(idPrescription) || -9999),
                products: values.products.length > 0 ? 
                    ((parseInt(idPrescription) || -9999) > 0 
                        ? values.products
                            .map(x => ({
                                IdProduct: x.idProduct,
                                Label: "product",
                                Quantity: x.quantity,
                                State: x.state
                            }))
                        : values.products
                            .filter(f => f.state === Helper.CONSTANTS.EntityState.New)
                                .map(x => ({
                                IdProduct: x.idProduct,
                                Label: "product",
                                Quantity: x.quantity,
                                State: x.state
                            }))) 
                        : []
            };
            

            try {
 
                const response = parseInt(entity.idPrescription) > 0 
                    ? await PrescriptionService.updatePrescription(entity)
                    : await PrescriptionService.createPrescription(entity);
           
                if (response && response.success) {
                    toast.current.show({ 
                        severity: 'success',
                        summary: 'Info', 
                        detail: `Prescriptia a fost ${parseInt(entity.idPrescription) > 0 ? 'actualizata' : 'creata'} cu success`
                    });
                    setSaveDisabled(false);
                } else {
                    setSaveDisabled(false);
                    toast.current.show({ 
                        severity: 'warn',
                        summary: 'Info', 
                        detail: `Prescriptia nu a putut fi ${parseInt(entity.idPrescription) > 0 ? 'actualizata' : 'creata'}. Detalii: ${response.details}`
                    });
                }
            } catch (error) {
                console.log("Eroare la salvare:", error);
                setSaveDisabled(false);
                toast.current.show({ 
                    severity: 'error',
                    summary: 'Error', 
                    detail: 'A apărut o eroare la salvarea datelor'
                });
            }
        }
    });
 

    // Effects
    useEffect(() => {
        let isMounted = true;

        const initializeData = async () => {
            console.log("INITIALIZE DATA", idPrescription);
            setIsLoading(true);
            try {
                const [employeesData, medicsData, productsData, pharmaciesData] = await Promise.all([
                    EmployeeService.getEmployees(), 
                    MedicService.getMedics(),
                    ProductService.getAllProducts(),
                    PharmacyService.getPharmacies()
                ]); 

                setEmployees(employeesData); 
                setMedics(medicsData);
                setProducts(productsData);
                setPharmacies(pharmaciesData);
 
                if (!isMounted) return;

                if (parseInt(idPrescription) > 0) {
                    const prescriptionData = await PrescriptionService.getPrescription(idPrescription);
             
                    if (prescriptionData && isMounted) {
                        formik.setValues({
                            idPrescription: prescriptionData.idPrescription,
                            fileName: prescriptionData.fileName,
                            idMedic: prescriptionData.idMedic,
                            idMedicalRepresentant: prescriptionData.idMedicalRepresentant,
                            idPharmacy: prescriptionData.idPharmacy,
                            datePrescription: [prescriptionData.datePrescription],
                            comments: prescriptionData.comments, 
                            products: prescriptionData.products || [],
                        }); 
                    }
                }
            } catch (error) {
                if (isMounted && toast.current) { 
                    toast.current.show({ 
                        severity: 'error', 
                        summary: 'Error', 
                        detail: 'Nu s-au putut încărca datele' 
                    });
                }
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        };

        // Delay the initialization slightly to ensure Toast is mounted
        setTimeout(initializeData, 0);

        return () => {
            isMounted = false;
        };
    }, [idPrescription]);

    const handleDeletePrescription = () => {

        confirmDialog({
            message: 'Confirmi stergerea acestei prescriptii ?',
            header: 'Confirmare',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            acceptLabel: "Da",
            rejectLabel: "Nu",
            accept: async () => {
                try {
                    const response = await PrescriptionService.deletePrescription(idPrescription);
                    if (response.success) {
                        setIsDeleted(true);
                        setSaveDisabled(true);
                    }

                    toast.current.show({ 
                        severity: response.success ? 'success' : 'warn',
                        summary: 'Info', 
                        detail: response.success ? 'Prescriptia a fost stearsa' : 'Prescriptia nu a putut fi stearsa'
                    });
                } catch (error) {
                    toast.current.show({ 
                        severity: 'error',
                        summary: 'Error', 
                        detail: 'A apărut o eroare la ștergerea prescriptiei'
                    });
                }
            }
        });
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const operationsDetails = (rowData) => (
        <div className="list-unstyled hstack gap-1 mb-0">
            <Button
                onClick={() => {
                    console.log("Row data", rowData);
                    handleDeleteProduct(rowData.idProduct);
                }}
                className="btn btn-sm btn-danger"
                id={`btn-delete-${rowData.idProduct}`}>
                <i className="bx bx-trash" />
            </Button>
        </div>
    );

    const hideEntityDialog = () => {
        setCurrentEntityProductDialog(false);
        setCurrentEntityProduct(null);
    }
    
    const commitAddProduct = () => {  
        setSubmitted(false);

        if (!currentEntityProduct.idProduct || 
            !currentEntityProduct.label || !currentEntityProduct.quantity) {
            setSubmitted(true);
            return;
        }

        const isProductExists = formik.values.products.some(
            (product) => product.idProduct === currentEntityProduct.idProduct
        );
        
        if (isProductExists) { 
            return;
        }
 
        // set products array
        formik.setFieldValue('products', [...formik.values.products, currentEntityProduct]);

        setCurrentEntityProductDialog(false);
        setCurrentEntityProduct(emptyEntityProduct);
        setSubmitted(false); 
    }

    const handleAddProduct = () => {
        setCurrentEntityProductDialog(true);
        setCurrentEntityProduct(emptyEntityProduct);
        setSubmitted(false);
    }

    const handleDeleteProduct = (idProduct) => {

        if(parseInt(idPrescription) <= 0) {
            formik.setFieldValue('products', formik.values.products.filter(p => p.idProduct !== idProduct));
        }else{
            formik.setFieldValue('products', formik.values.products.map(p => p.idProduct === idProduct ?
                 { ...p, state: Helper.CONSTANTS.EntityState.Deleted } : p));
        }
    }
    
    const entityDialogFooter = () => {
        return (
            <div className="gap-2"> 
                <Button onClick={commitAddProduct} className="btn btn-success me-2">Salveaza</Button>
            </div>
        );
    }

    if (isLoading) {
        return (
            <div className="page-content">
                <Container fluid>
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Incarcare...</span>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <ConfirmDialog />
                <Container fluid>
                    <Breadcrumbs title="Medic" breadcrumbItem="Prescriptii medicale" />
                    <Toast ref={toast} />

                    <Dialog visible={currentEntityProductDialog}
                        style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirmare" modal
                        footer={entityDialogFooter}
                        onHide={hideEntityDialog}>

                        <div className="field">
                            <label htmlFor="label" className="font-bold">
                                Denumirea produsului
                            </label>
                            <Select
                                name="idProduct" 
                                value={products?.find(o => o.idProduct == currentEntityProduct?.idProduct) || null}
                                onChange={(selected) => {
                                    console.log("Selected", selected);
                                    setCurrentEntityProduct(p => {
                                        return {
                                            ...p,
                                            idProduct: selected.idProduct,
                                            label: selected.label,
                                            price: selected.price,
                                            category: selected.category
                                        }
                                    });
                                }}
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.idProduct}
                                options={products || []}
                                placeholder="Alege produsul"
                            />
                            {submitted && !currentEntityProduct?.idProduct && <small className="p-error">Camp obligatoriu.</small>}
                        </div>

                        <div className="field mt-3">
                            <label htmlFor="quantity" style={{ display: 'block', marginBottom: '0.5rem' }} className="font-bold">
                                Cantitatea (BUC)
                            </label>
                            <InputText maxLength={3} placeholder="Specifica cantitatea" id="quantity" value={currentEntityProduct?.quantity}
                                onChange={(e) => {
                                    if(parseInt(e.target.value) < 0) return;
                                    setCurrentEntityProduct(prev => { return { ...prev, 
                                        quantity: e.target.value,
                                        totalPrice: e.target.value * currentEntityProduct?.price
                                     } });
                                    setSubmitted(false);
                                }} />
                            {submitted && !currentEntityProduct.quantity && <small className="p-error" style={{ display: 'block', marginTop: '0.5rem' }}>Camp obligatoriu.</small>}
                        </div>
                    </Dialog>
     
                    <Form onSubmit={formik.handleSubmit}>
                        <Row>

                            <Col lg={8}>
                                <Card>
                                    <CardBody>
                                        <div className="mb-3">
                                            <Label htmlFor="idMedic">Medic prescriptor</Label>
                                            <Select
                                                name="idMedic"
                                                value={medics?.find(o => o.idMedic == formik.values.idMedic) || null}
                                                onChange={(selected) => {
                                                  formik.setFieldValue('idMedic', selected.idMedic);
                                                }}
                                                options={medics || []}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.idMedic}
                                                placeholder="Alege medicul prescriptor"
                                            />
                                            {formik.touched.idMedic && formik.errors.idMedic && (
                                                <FormFeedback type="invalid" className="d-block">
                                                    {formik.errors.idMedic}
                                                </FormFeedback>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="idPharmacy">Unitatea sanitara</Label>
                                            <Select
                                                name="idPharmacy"
                                                value={pharmacies?.find(o => o.idPharmacy == formik.values.idPharmacy) || null}
                                                onChange={(selected) => {
                                                  formik.setFieldValue('idPharmacy', selected.idPharmacy);
                                                }}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.idPharmacy}
                                                options={pharmacies || []}
                                                placeholder="Alege unitatea sanitara"
                                            />
                                            {formik.touched.idPharmacy && formik.errors.idPharmacy && (
                                                <FormFeedback type="invalid" className="d-block">
                                                    {formik.errors.idPharmacy}
                                                </FormFeedback>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                        <Label htmlFor="idMedicalRepresentant">Angajat (RM sau AVRM)</Label>
                                            <Select
                                                name="idMedicalRepresentant"
                                                value={employees?.find(o => o.idEmployee == formik.values.idMedicalRepresentant) || null}
                                                onChange={(selected) => {
                                                  formik.setFieldValue('idMedicalRepresentant', selected.idEmployee);
                                                }}
                                                getOptionLabel={(option) => option.name}
                                                getOptionValue={(option) => option.idEmployee}
                                                options={employees}
                                                placeholder="Alege angajatul"
                                            />
                                            {formik.touched.idMedicalRepresentant && formik.errors.idMedicalRepresentant && (
                                                <FormFeedback type="invalid" className="d-block">
                                                    {formik.errors.idMedicalRepresentant}
                                                </FormFeedback>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                        <Label htmlFor="planificationDate">Data prescriptiei</Label>
                                                <FlatPickr
                                                    className="form-control"
                                                    options={{ dateFormat: "d M, Y" }}
                                                    placeholder="Alege o data"
                                                    value={formik.values.datePrescription} 
                                                    onChange={(e) => { 
                                                        formik.setFieldValue('datePrescription', e);
                                                    }}
                                                />
                                            {formik.touched.datePrescription && formik.errors.datePrescription && (
                                                <FormFeedback type="invalid" className="d-block">{formik.errors.datePrescription}</FormFeedback>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="fileUpload">Incarcare fisier prescriptie ({formik.values.fileName ? 
                                            <b className="text-success">{formik.values.fileName}</b> : 'optional'})</Label>
                                            <Input 
                                            type="file" name="fileUpload" onChange={(e) => {
                                                // convert to base64 
                                                console.log("File:", e.target.files[0]);
                                                let file = e.target.files[0];
                                                // get file name
                                                let fileName = file.name;

                                                if (file) {
                                                    let reader = new FileReader();
                                                    reader.readAsDataURL(file);
                                                    reader.onload = () => {
                                                        console.log("File content:", reader.result);
                                                        formik.setFieldValue('base64file', reader.result);
                                                        formik.setFieldValue('fileName', fileName);
                                                    };
                                                    reader.onerror = (error) => {
                                                        console.error("Error reading file:", error);
                                                    };
                                                }
                                            }} />
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="comments">Observatii</Label>
                                            <Input
                                                id="comments"
                                                name="comments"
                                                type="textarea"
                                                placeholder="Observatii..."
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.comments}
                                                invalid={formik.touched.comments && formik.errors.comments}
                                            />
                                            {formik.touched.comments && formik.errors.comments && (
                                                <FormFeedback type="invalid">{formik.errors.comments}</FormFeedback>
                                            )}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg={8}>
                                <Card>
                                    <CardBody>
                                        <h5 className="card-title mb-3">Administrare produse</h5>
                                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginBottom: '1rem' }}>
                                            
                                            <Button
                                                type="button" 
                                                onClick={handleAddProduct}
                                                className="btn btn-soft-success waves-effect waves-light">
                                                Adauga
                                            </Button>
                                        </div>

                                        <DataTable
                                            value={formik.values.products?.filter(f => f.state !== Helper.CONSTANTS.EntityState.Deleted)}
                                            paginator
                                            rows={10}
                                            dataKey="idProduct" 
                                            globalFilterFields={['label']} 
                                            emptyMessage="Nu au fost gasite rezultate"
                                            className="p-datatable-lg"
                                            columnResizeMode="expand"
                                            resizableColumns
                                            style={{ fontSize: "15px" }}>
                                            <Column field="label" header="Denumire produs" />
                                            <Column field="category" header="Categoria" />
                                            <Column field="quantity" header="Cantitate (BUC)" />
                                            <Column field="price" header="Pret (UM)" />
                                            <Column field="totalPrice" header="Pret total" />
                                            <Column body={operationsDetails} style={{ minWidth: '12rem' }} />
                                        </DataTable>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg={8}>
                                <div className="text-end mb-4">
                                    
                                    {parseInt(idPrescription) > 0 && (
                                        <Button
                                            type="button"
                                            onClick={handleDeletePrescription}
                                            className="btn btn-soft-danger waves-effect waves-light me-2"
                                        >
                                            Sterge
                                        </Button>
                                    )}
                                    <Button 
                                        type="submit" 
                                        color="success" 
                                        className="btn btn-soft-success waves-effect waves-light me-2"
                                        onClick={formik.handleSubmit}
                                        disabled={saveDisabled}>
                                        Salveaza
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default PrescriptionRegisterComponent;
