import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useRef } from "react";
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, UncontrolledTooltip, Input, Label, Row } from "reactstrap";
import { Toast } from 'primereact/toast';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";
// FlatPickr
import "flatpickr/dist/themes/material_blue.css";

import { useFormik } from "formik";
import { ConfirmDialog } from 'primereact/confirmdialog';
import { PharmacyService } from "services/PharmacyService";

const PharmacyLimitedComponent = () => {
    document.title = "Administrare unitate";
    const { idPharmacy = -1 } = useParams();

    const toast = useRef(null);

    // formik
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            idPharmacy: -1,
            name: '',
            email: '',
            mobile: '',
            address: '',
            idClientType: null,
            idZone: null,
            website: ''
        },
        onSubmit: (e) => {
            console.log("Sumbitted:", e); 
            if (e.idPharmacy > 0) { 
                PharmacyService.updatePharmacyByEmployee(e).then((e) => {
                    if (e.success) {
                        toast.current.show({ severity: 'success', summary: 'Succes', detail: 'Unitatea  a fost actualizata cu succes.' });
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Eroare', detail: 'Eroare la actualizarea unitatii.' });
                    }
                });
            }
        }
    });

    useEffect(() => {
        PharmacyService.getPharmacyByEmployee(idPharmacy).then(data => {
            if (data) {
                console.log("{harma}:", data);
                // default values
                formik.setValues(data);
                console.log("Form values:", formik.values);
            }
        });
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Administrare" breadcrumbItem="Unitate sanitara" />
                    <Toast ref={toast} />
                    <ConfirmDialog />
                    <Form onSubmit={(e) => { e.preventDefault(); console.log("Formik values:", formik.values); formik.handleSubmit(); }}>
                        <Row>
                            <Col lg={8}>
                                <Card>
                                    <CardBody>
                                        <div className="mb-3">
                                            <Label htmlFor="clientType">Categoria unitatii</Label>
                                            <Select name="clientType" value={formik.values.clientType} />
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="name">Denumirea unitatii sanitare</Label>
                                            <Input
                                                id="name"
                                                readOnly={true}
                                                name="name"
                                                type="text"
                                                placeholder="Denumirea unitatii..."
                                                value={formik.values.name || ""}
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="email">Adresa e-Mail</Label>
                                            <Input
                                                id="email"
                                                name="email"
                                                type="email"
                                                placeholder="Adresa de email contact..."
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.email || ""}
                                                invalid={formik.touched.email && formik.errors.email}
                                            />
                                            {formik.touched.email && formik.errors.email && (
                                                <FormFeedback type="invalid">
                                                    {formik.errors.email}
                                                </FormFeedback>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="mobile">Telefon mobil</Label>
                                            <Input
                                                id="mobile"
                                                name="mobile"
                                                type="text"
                                                placeholder="Telefon contact..."
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.mobile || ""}
                                                invalid={formik.touched.mobile && formik.errors.mobile}
                                            />
                                            {formik.touched.mobile && formik.errors.mobile && (
                                                <FormFeedback type="invalid">
                                                    {formik.errors.mobile}
                                                </FormFeedback>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="website">Website</Label>
                                            <Input
                                                id="website"
                                                name="website"
                                                type="text"
                                                placeholder="Website-ul unitatii..."
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.website || ""}
                                                invalid={formik.touched.website && formik.errors.website}
                                            />
                                            {formik.touched.website && formik.errors.website && (
                                                <FormFeedback type="invalid">
                                                    {formik.errors.website}
                                                </FormFeedback>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="address">Adresa unitatii</Label>
                                            <Input
                                                id="address"
                                                name="address"
                                                type="textarea"
                                                placeholder="Adresa unitatii..."
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.address || ""}
                                                invalid={formik.touched.address && formik.errors.address}
                                            />
                                            {formik.touched.address && formik.errors.address && (
                                                <FormFeedback type="invalid">
                                                    {formik.errors.address}
                                                </FormFeedback>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="zone">Zona de activitate</Label>
                                            <Select name="zone" value={formik.values.zone} />
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <Button className="btn btn-success me-2" type="submit" color="success">
                                                Salveaza
                                            </Button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col> 
                        </Row>
                    </Form>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default PharmacyLimitedComponent;