
import { get, post } from "../helpers/api_helper"

export const NomenclatorService = {
    deleteMedicSpecialization(id) {
        return get(`nomenclator/medicspecialization/delete/${id}`);
    },
    getNomenclatorsOverview() {
        return get('nomenclator/overview');
    },
    getCodifications() {
        return get('nomenclator/codifications');
    },
    getMedicalResources() {
        return get('nomenclator/medicalresources');
    },
    getStudies() {
        return get('nomenclator/medicalstudies');
    },
    getPlanificationTypes() {
        return get('nomenclator/planificationreasons');
    },
    getMedicTypes() {
        return get('nomenclator/medictypes');
    },
    getMedicCompetences() {
        return get('nomenclator/mediccompetences');
    },
    createCounty(entity) {
        return post(`nomenclator/county/create`, entity);
    },
    createCity(entity) {
        return post(`nomenclator/city/create`, entity);
    },
    deleteZone(id) {
        return get(`nomenclator/zone/delete/${id}`);
    },
    deleteCity(id) {
        return get(`nomenclator/city/delete/${id}`);
    },
    updateCounty(entity) {
        return post(`nomenclator/county/update`, entity);
    },
    deleteCounty(id) {
        return get(`nomenclator/county/delete/${id}`);
    },
    createRouteDetails(entity) {
        return post(`nomenclator/routedetails/create`, entity);
    },
    updateRouteDetails(entity) {
        return post(`nomenclator/routedetails/update`, entity);
    },
    createPlanificationType(entity) {
        return post(`nomenclator/planificationreason/create`, entity);
    },
    deletePlanificationType(id) {
        return get(`nomenclator/planificationreason/delete/${id}`);
    },
    updatePlanificationType(entity) {
        return post(`nomenclator/planificationreason/update`, entity);
    },
    createMedicCompetence(entity) {
        return post(`nomenclator/mediccompetence/create`, entity);
    },
    updateMedicCompetence(entity) {
        return post(`nomenclator/mediccompetence/update`, entity);
    },
    createMedicSpecialization(entity) {
        return post(`nomenclator/medicspecialization/create`, entity);
    },
    updateMedicSpecialization(entity) {
        return post(`nomenclator/medicspecialization/update`, entity);
    },
    deleteMedicType(id) {
        return get(`nomenclator/medicjobtype/delete/${id}`);
    },
    createMedicType(entity) {
        return post(`nomenclator/medicjobtype/create`, entity);
    },
    updateMedicType(entity) {
        return post(`nomenclator/medicjobtype/update`, entity);
    },
    getClientTypes() {
        return get('nomenclator/clienttypes');
    },
    createClientType(entity) {
        return post(`nomenclator/clienttype/create`, entity);
    },
    updateClientType(entity) {
        return post(`nomenclator/clienttype/update`, entity);
    },
    getContests() {
        return get('nomenclator/contest/all');
    },
    createContest(entity) {
        return post(`nomenclator/contest/create`, entity);
    },
    updateContest(entity) {
        return post(`nomenclator/contest/update`, entity);
    },
    createStudy(entity) {
        return post(`nomenclator/medicalstudy/create`, entity);
    },
    deleteStudy(id) {
        return get(`nomenclator/medicalstudy/delete/${id}`);
    },
    updateStudy(entity) {
        return post(`nomenclator/medicalstudy/update`, entity);
    },
    createCodification(entity) {
        return post(`nomenclator/codification/create`, entity);
    },
    updateCodification(entity) {
        return post(`nomenclator/codification/update`, entity);
    },
    createMedicalResource(entity) {
        return post(`nomenclator/medicalresource/create`, entity);
    },
    updateMedicalResource(entity) {
        return post(`nomenclator/medicalresource/update`, entity);
    },
    createMedicalResourceDetails(entity) {
        return post(`nomenclator/medicalresourcedetails/create`, entity);
    },
    updateMedicalResourceDetails(entity) {
        return post(`nomenclator/medicalresourcedetails/update`, entity);
    },
    deleteMedicalResourceDetails(id) {
        return get(`nomenclator/medicalresourcedetails/delete/${id}`);
    },
    deleteMedicalResource(id) {
        return get(`nomenclator/medicalresource/delete/${id}`);
    },
    deleteCodification(id) {
        return get(`nomenclator/codification/delete/${id}`);
    },
    deleteStudy(id) {
        return get(`nomenclator/study/delete/${id}`);
    },
    deleteContest(id) {
        return get(`nomenclator/contest/delete/${id}`);
    },
    deleteClientType(id) {
        return get(`nomenclator/clientType/delete/${id}`);
    },
    deleteMedicCompetence(id) {
        return get(`nomenclator/mediccompetence/delete/${id}`);
    },
    deleteRouteDetails(id) {
        return get(`nomenclator/routedetail/delete/${id}`);
    },
    createRoute(entity) {
        return post(`nomenclator/route/create`, entity);
    },
    updateRoute(entity) {
        return post(`nomenclator/route/update`, entity);
    },
    getLocations() {
        return get('nomenclator/locations/all');
    },
    createZone(entity) {
        return post(`nomenclator/zone/create`, entity);
    },
    updateZone(entity) {
        return post(`nomenclator/zone/update`, entity);
    },
    updateCity(entity) {
        return post(`nomenclator/city/update`, entity);
    }
}
