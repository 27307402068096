import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";

import { SelectButton } from 'primereact/selectbutton';
import { useRef } from "react";

import { Column } from 'primereact/column';
import {Badge} from "primereact/badge";

import { DataTable } from 'primereact/datatable';
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, UncontrolledTooltip, Input, Label, Row } from "reactstrap";
import { Toast } from 'primereact/toast';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Select from "react-select";
// FlatPickr
import "flatpickr/dist/themes/material_blue.css";

import * as Yup from "yup";
import { useFormik } from "formik";
import { CommonService } from "services/CommonService";
import ZonesComponent from "components/core/zonesComponent";
import { EmployeeService } from "services/EmployeeService";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { MedicService } from "services/MedicService";

const MedicUpdateComponent = () => {
    document.title = "Administrare personal medical";
    const { idMedic } = useParams();

    const [medicTypes, setMedicTypes] = useState(null);
    const [zones, setZones] = useState(null);
    const [employeesList, setEmployeesList] = useState(null);
    const [selectedZone, setSelectedZone] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [competences, setCompetences] = useState([]);
    const [specializations, setSpecializations] = useState([]);
    const [competenceOptions, setCompetenceOptions] = useState([]);
    const [specializationOptions, setSpecializationOptions] = useState([]);

    const toast = useRef(null);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            idMedic: -1,
            name: '',
            email: '',
            mobile: '',
            address: '',
            idMedicType: null,
            employees: [],
            locations: [],
            dateCreated: null,
            createdBy: '',
            tasksCount: 0,
            competences: [],
            specializations: [],
            subscribedNewsletter: false
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required("Trebuie completat acest camp")
                .min(3, 'Textul este prea scurt')
                .max(100, "Textul este prea lung."),
                email: Yup.string()
                .nullable()
                .transform((curr, orig) => orig === '' ? null : curr)
                .email("Adresa de email nu este valida"),
            mobile: Yup.string()
                .required("Trebuie completat acest camp")
                .min(3, 'Textul este prea scurt')
                .max(20, "Textul este prea lung."),
            address: Yup.string()
                .required("Trebuie completat acest camp")
                .min(3, 'Textul este prea scurt')
                .max(100, "Textul este prea lung."),
            idMedicType: Yup.number()
                .required("Trebuie completat acest camp"),
            competences: Yup.array().nullable(),
            specializations: Yup.array().nullable()
        }),
        onSubmit: async (values) => {
            setSaveDisabled(true);
            console.log("Values:", values);
            try {
                const entity = {
                    IdMedic: parseInt(idMedic) || -1,
                    Name: values.name,
                    Email: values.email,
                    Mobile: values.mobile,
                    Address: values.address,
                    IdMedicType: values.idMedicType,
                    Competences: values.competences.length > 0
                        ? (parseInt(idMedic) > 0
                            ? values.competences
                                .map(x => ({
                                    Id: x.idAssignment || -1,
                                    Value: x.value,
                                    State: x.entityState || "Database"
                                }))
                            : values.competences
                                .filter(f => f.entityState === "New")
                                .map(x => x.value)
                        ) : [],
                    Specializations: values.specializations.length > 0
                        ? (parseInt(idMedic) > 0
                            ? values.specializations
                                .map(x => ({
                                    Id: x.idAssignment || -1,
                                    Value: x.value,
                                    State: x.entityState || "Database"
                                }))
                            : values.specializations
                                .filter(f => f.entityState === "New")
                                .map(x => x.value)
                        ) : [],
                    Employees: values.employees.length > 0
                        ? (parseInt(idMedic) > 0
                            ? values.employees
                                .map(x => ({
                                    Id: x.idAssignment || -1,
                                    Value: x.id,
                                    State: x.entityState || "Database"
                                }))
                            : values.employees
                                .filter(f => f.entityState === "New")
                                .map(x => x.id)
                        ) : [],
                    Locations: values.locations.length > 0
                        ? (parseInt(idMedic) > 0
                            ? values.locations
                                .map(x => ({
                                    Id: x.idAssignment || -1,
                                    Value: x.idZone,
                                    State: x.entityState || "Database"
                                }))
                            : values.locations
                                .filter(f => f.entityState === "New")
                                .map(x => x.idZone)
                        ) : []
                };
                console.log("Entity:", entity);
                let response;
                if (entity.IdMedic > 0) {
                    response = await MedicService.updateMedic(entity);
                } else {
                    response = await MedicService.createMedic(entity);
                }

                if (response && response.success) {
                    toast.current.show({
                        severity: 'success',
                        summary: 'Info',
                        detail: `Medicul a fost ${entity.IdMedic > 0 ? 'actualizat' : 'creat'} cu success`
                    });
                } else {
                    setSaveDisabled(false);
                    toast.current.show({
                        severity: 'warn',
                        summary: 'Info',
                        detail: `Medicul nu a putut fi ${entity.IdMedic > 0 ? 'actualizat' : 'creat'}`
                    });
                }
            } catch (error) {
                console.error(error);
                setSaveDisabled(false);
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'A apărut o eroare la salvarea datelor'
                });
            }
        }
    });

    // Load initial data
    useEffect(() => {
 
        Promise.all([
            CommonService.getMedicCompetences(),
            CommonService.getMedicSpecializations(),
            MedicService.getMedicTypes()
        ]).then(([competencesData, specializationsData, medicTypesData]) => { 

            setCompetenceOptions(competencesData || []);
            setSpecializationOptions(specializationsData || []);
            setMedicTypes(medicTypesData || []);

            if (parseInt(idMedic) > 0) {
                MedicService.getMedic(idMedic).then(data => {
                    if (data) {
                        // Map the competences and specializations to their full objects
                        const mappedCompetences = data.competences?.map(comp => {
                            const fullComp = competencesData.find(c => c.value === comp);
             
                            return {
                                ...fullComp,
                                entityState: "Database"
                            };
                        }) || [];

                        const mappedSpecializations = data.specializations?.map(spec => {
                            const fullSpec = specializationsData.find(s => s.value === spec);
                
                            return {
                                ...fullSpec,
                                entityState: "Database"
                            };
                        }) || [];

                        formik.setValues({
                            idMedic: data.idMedic,
                            name: data.name,
                            email: data.email,
                            mobile: data.mobile,
                            address: data.address,
                            idMedicType: data.idMedicType,
                            employees: data.employees || [],
                            locations: data.locations || [],
                            dateCreated: data.dateCreated,
                            createdBy: data.createdBy,
                            tasksCount: data.tasksCount || 0,
                            competences: mappedCompetences,
                            specializations: mappedSpecializations,
                            subscribedNewsletter: data.subscribedNewsletter || false,
                            clients: data.clients || []
                        });

                        if (data.locations?.length) { 
                            EmployeeService.getEmployeesByZones(data.locations.map(l => l.idZone)).then(emp => {
                                setEmployeesList(emp);
                            });
                        }
                    }
                });
            }
        });

        CommonService.getLocations().then(data => {
            setZones(data);
        });
    }, []);

    // Functions for handling employees and locations
    const addNewEmployee = () => {
        if (selectedEmployee && employeesList) {
            console.log("Selected employee:", selectedEmployee);
            console.log("Employees list:", employeesList);
            const employee = employeesList.find(f => f.id === selectedEmployee.id);

            if (employee) {
                const existingEmployee = formik.values.employees.find(f => f.id === employee.id);

                if (existingEmployee) {
                    if (existingEmployee.entityState === "Deleted") {
                        const updatedEmployees = formik.values.employees.map(e => {
                            if (e.id === existingEmployee.id) {
                                return {
                                    ...e,
                                    entityState: "Database"
                                };
                            }
                            return e;
                        });
                        formik.setFieldValue("employees", updatedEmployees);
                    }
                } else {
                    const updatedEmployees = [...formik.values.employees, {
                        ...employee,
                        entityState: "New",
                        idAssignment: -1,
                        dateCreated: "Recent..."
                    }];
                    formik.setFieldValue("employees", updatedEmployees);
                }
            }
        }
    };

    const btnAddNewWorkingZone = async () => {
        if (selectedZone) {
            // Check if zone already exists and is not marked as deleted
            const zoneExists = formik.values.locations.some(
                loc => loc.idZone === selectedZone && loc.entityState !== "Deleted"
            );

            if (zoneExists) {
                return;
            }
            console.log("Zones:", selectedZone, zones.zones);
            const zone = zones.zones.find(f => f.idZone === selectedZone);
            const city = zones.cities.find(f => f.idCity === zone.idCity);
            const county = zones.counties.find(f => f.idCounty === city.idCounty);

            if (zone) {
                const updatedLocations = [...formik.values.locations, {
                    ...zone,
                    city: city.label,
                    zone: zone.label,
                    county: county.label,
                    entityState: "New",
                    idAssignment: -1
                }];
                formik.setFieldValue("locations", updatedLocations);

                // Get all active zones including the new one
                const activeZones = updatedLocations
                    .filter(l => l.entityState !== "Deleted")
                    .map(l => l.idZone);

                // Update employees list based on all active zones
                try {
                    const employees = await EmployeeService.getEmployeesByZones(activeZones);
                    setEmployeesList(employees);
                } catch (error) {
                    console.error('Error loading employees:', error);
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'A apărut o eroare la încărcarea angajaților'
                    });
                }
            }
        }
    };

    const removeSelectedZone = (options) => {
        return (
            <div className="list-unstyled hstack gap-1 mb-0">
                <li>
                    <Link
                        to="#"
                        className="btn btn-sm btn-soft-danger"
                        onClick={async (e) => {
                            e.preventDefault();
                            const existingZone = formik.values.locations.find(f => f.idZone === options.idZone);

                            if (existingZone) {
                                let updatedLocations;
                                if (existingZone.entityState === "New") {
                                    // Remove new zone completely
                                    updatedLocations = formik.values.locations.filter(w => w.idZone !== existingZone.idZone);
                                } else {
                                    // Mark existing zone as deleted
                                    updatedLocations = formik.values.locations.map(e => {
                                        if (e.idZone === options.idZone) {
                                            return {
                                                ...e,
                                                entityState: "Deleted"
                                            };
                                        }
                                        return e;
                                    });
                                }

                                formik.setFieldValue("locations", updatedLocations);

                                // Get remaining active zones
                                const activeZones = updatedLocations
                                    .filter(l => l.entityState !== "Deleted")
                                    .map(l => l.idZone);

                                try {
                                    let employees = [];
                                    if (activeZones.length > 0) {
                                        // Update employees list based on remaining zones
                                        employees = await EmployeeService.getEmployeesByZones(activeZones);
                                        setEmployeesList(employees);
                                    } else {
                                        // Clear employees list if no zones remain
                                        setEmployeesList([]);
                                    }

                                    // Remove employees that are no longer in the active zones
                                    const updatedEmployees = formik.values.employees.map(emp => {
                                        if (emp.entityState !== "Deleted" && !employees.some(e => e.id === emp.id)) {
                                            return { ...emp, entityState: "Deleted" };
                                        }
                                        return emp;
                                    });
                                    formik.setFieldValue("employees", updatedEmployees);
                                } catch (error) {
                                    console.error('Error updating employees:', error);
                                    toast.current.show({
                                        severity: 'error',
                                        summary: 'Error',
                                        detail: 'A apărut o eroare la actualizarea angajaților'
                                    });
                                }
                            }
                        }}
                        id={`deletetooltip${options.idZone}`}
                    >
                        <i className="mdi mdi-delete-outline" />
                        <UncontrolledTooltip placement="top" target={`deletetooltip${options.idZone}`}>
                            Sterge
                        </UncontrolledTooltip>
                    </Link>
                </li>
            </div>
        );
    };

    const removeSelectedEmployee = (options) => {
        return (
            <div className="list-unstyled hstack gap-1 mb-0">
                <li>
                    <Link
                        to="#"
                        className="btn btn-sm btn-soft-danger"
                        onClick={(e) => {
                            e.preventDefault();
                            const existingEmployee = formik.values.employees.find(f => f.id === options.id);

                            if (existingEmployee) {
                                if (existingEmployee.entityState === "New") {
                                    // Remove new employee completely
                                    const updatedEmployees = formik.values.employees.filter(w => w.id !== existingEmployee.id);
                                    formik.setFieldValue("employees", updatedEmployees);
                                } else {
                                    // Mark existing employee as deleted
                                    const updatedEmployees = formik.values.employees.map(e => {
                                        if (e.id === options.id) {
                                            return {
                                                ...e,
                                                entityState: "Deleted"
                                            };
                                        }
                                        return e;
                                    });
                                    formik.setFieldValue("employees", updatedEmployees);
                                }
                            }
                        }}
                        id={`deletetooltip${options.id}`}
                    >
                        <i className="mdi mdi-delete-outline" />
                        <UncontrolledTooltip placement="top" target={`deletetooltip${options.id}`}>
                            Sterge
                        </UncontrolledTooltip>
                    </Link>
                </li>
            </div>
        );
    };

    const handleDeleteMedic = async () => {
        const confirmDelete = await new Promise((resolve) => {
            confirmDialog({
                message: 'Sunteti sigur ca doriti sa stergeti acest medic?',
                header: 'Confirmare stergere',
                icon: 'pi pi-exclamation-triangle',
                acceptClassName: 'p-button-danger',
                accept: () => resolve(true),
                reject: () => resolve(false)
            });
        });

        if (confirmDelete) {
            try {
                const response = await MedicService.deleteMedic(idMedic);

                if (response && response.success) {
                    setIsDeleted(true);
                    setSaveDisabled(true);
                    toast.current.show({
                        severity: 'success',
                        summary: 'Info',
                        detail: 'Medicul a fost sters'
                    });
                } else {
                    toast.current.show({
                        severity: 'warn',
                        summary: 'Info',
                        detail: 'Medicul nu a putut fi sters'
                    });
                }
            } catch (error) {
                console.error('Error deleting medic:', error);
                toast.current.show({
                    severity: 'error',
                    summary: 'Eroare',
                    detail: 'A apărut o eroare la ștergerea medicului'
                });
            }
        }
    };

    const medicStatusBody = (options) => {
        if(options.active)
            return <Badge severity='success' value='Activ'/>
            else
            return <Badge severity='danger' value='Inactiv'/>
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Administrare" breadcrumbItem="Personal medical" />
                    <Toast ref={toast} />
                    <ConfirmDialog />

                    <form onSubmit={formik.handleSubmit}>
                        <Row>
                            <Col lg={8}>
                                <Card>
                                    <CardBody>
                                        <div className="mb-3">
                                            <Label htmlFor="idMedicType">Categoria medicului</Label>
                                            <Select
                                                name="idMedicType"
                                                value={medicTypes?.find(type => type.idMedicJobType === formik.values.idMedicType) || null}
                                                onChange={(selectedOption) => {
                                                    formik.setFieldValue("idMedicType", selectedOption?.idMedicJobType || null);
                                                }}
                                                options={medicTypes || []}
                                                getOptionLabel={option => option.label}
                                                getOptionValue={option => option.idMedicJobType}
                                                placeholder="Alege categoria medicului"
                                                isClearable
                                            />
                                            {formik.touched.idMedicType && formik.errors.idMedicType && (
                                                <FormFeedback type="invalid" className="d-block">
                                                    {formik.errors.idMedicType}
                                                </FormFeedback>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="name">Numele medicului</Label>
                                            <Input
                                                id="name"
                                                name="name"
                                                type="text"
                                                placeholder="Numele medicului..."
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.name || ""}
                                                invalid={formik.touched.name && formik.errors.name}
                                            />
                                            {formik.touched.name && formik.errors.name && (
                                                <FormFeedback type="invalid">
                                                    {formik.errors.name}
                                                </FormFeedback>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="email">Adresa e-Mail</Label>
                                            <Input
                                                id="email"
                                                name="email"
                                                type="email"
                                                placeholder="Adresa de email contact..."
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.email || ""}
                                                invalid={formik.touched.email && formik.errors.email}
                                            />
                                            {formik.touched.email && formik.errors.email && (
                                                <FormFeedback type="invalid">
                                                    {formik.errors.email}
                                                </FormFeedback>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="mobile">Telefon mobil</Label>
                                            <Input
                                                id="mobile"
                                                name="mobile"
                                                type="text"
                                                placeholder="Telefon contact..."
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.mobile || ""}
                                                invalid={formik.touched.mobile && formik.errors.mobile}
                                            />
                                            {formik.touched.mobile && formik.errors.mobile && (
                                                <FormFeedback type="invalid">
                                                    {formik.errors.mobile}
                                                </FormFeedback>
                                            )}
                                        </div>

                                        <div className="mb-3">
                                            <Label htmlFor="address">Adresa medicului</Label>
                                            <Input
                                                id="address"
                                                name="address"
                                                type="textarea"
                                                placeholder="Adresa medicului..."
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.address || ""}
                                                invalid={formik.touched.address && formik.errors.address}
                                            />
                                            {formik.touched.address && formik.errors.address && (
                                                <FormFeedback type="invalid">
                                                    {formik.errors.address}
                                                </FormFeedback>
                                            )}
                                        </div>

                                        <Row className="mb-3">
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="competences">Competențe</Label>
                                                    <Select
                                                        isMulti
                                                        name="competences"
                                                        value={formik.values.competences?.filter(c => c.entityState !== "Deleted") || []}
                                                        onChange={(selectedOptions) => {
                                                            // Handle removed options
                                                            const removedOptions = formik.values.competences
                                                                .filter(existing => !selectedOptions.some(selected => selected.value === existing.value))
                                                                .map(option => ({
                                                                    ...option,
                                                                    entityState: "Deleted"
                                                                }));

                                                            // Handle new options
                                                            const newOptions = selectedOptions
                                                                .filter(selected => !formik.values.competences.some(existing => existing.value === selected.value))
                                                                .map(option => ({
                                                                    ...option,
                                                                    entityState: "New"
                                                                }));

                                                            // Combine existing (unchanged), new, and deleted options
                                                            const updatedOptions = [
                                                                ...formik.values.competences
                                                                    .filter(existing => selectedOptions.some(selected => selected.value === existing.value)),
                                                                ...newOptions,
                                                                ...removedOptions
                                                            ];

                                                            formik.setFieldValue("competences", updatedOptions);
                                                        }}
                                                        options={competenceOptions}
                                                        placeholder="Alege competențele"
                                                        isClearable
                                                        closeMenuOnSelect={false}
                                                        isSearchable={true}
                                                    />
                                                    {formik.touched.competences && formik.errors.competences && (
                                                        <FormFeedback type="invalid" className="d-block">
                                                            {formik.errors.competences}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="specializations">Specializări</Label>
                                                    <Select
                                                        isMulti
                                                        name="specializations"
                                                        value={formik.values.specializations?.filter(s => s.entityState !== "Deleted") || []}
                                                        onChange={(selectedOptions) => {
                                                            // Handle removed options
                                                            const removedOptions = formik.values.specializations
                                                                .filter(existing => !selectedOptions.some(selected => selected.value === existing.value))
                                                                .map(option => ({
                                                                    ...option,
                                                                    entityState: "Deleted"
                                                                }));

                                                            // Handle new options
                                                            const newOptions = selectedOptions
                                                                .filter(selected => !formik.values.specializations.some(existing => existing.value === selected.value))
                                                                .map(option => ({
                                                                    ...option,
                                                                    entityState: "New"
                                                                }));

                                                            // Combine existing (unchanged), new, and deleted options
                                                            const updatedOptions = [
                                                                ...formik.values.specializations
                                                                    .filter(existing => selectedOptions.some(selected => selected.value === existing.value)),
                                                                ...newOptions,
                                                                ...removedOptions
                                                            ];

                                                            formik.setFieldValue("specializations", updatedOptions);
                                                        }}
                                                        options={specializationOptions}
                                                        placeholder="Alege specializările"
                                                        isClearable
                                                        closeMenuOnSelect={false}
                                                        isSearchable={true}
                                                    />
                                                    {formik.touched.specializations && formik.errors.specializations && (
                                                        <FormFeedback type="invalid" className="d-block">
                                                            {formik.errors.specializations}
                                                        </FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
 
                                        <div className="mb-3">
                                            <div style={{ 
                                                fontSize: "9px", 
                                                transform: "scale(0.8)", 
                                                transformOrigin: "left"
                                            }} className="card flex justify-content-center">
                                                <SelectButton 
                                                    value={formik.values.subscribedNewsletter ? "Primeste newsletter" : "Nu este inregistrat la newsletter"} 
                                                    onChange={(e) => {
                                                        formik.setFieldValue('subscribedNewsletter', e.value === "Primeste newsletter");
                                                    }} 
                                                    options={["Nu este inregistrat la newsletter", "Primeste newsletter"]} 
                                                />
                                            </div> 
                                        </div> 
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg={4}>
                                <Card>
                                    <CardBody>
                                        <h5 className="card-title mb-3">Sumar activitate</h5>
                                        <div className="mb-3">
                                            {idMedic == -1 ? (
                                                <div>Nu exista momentan activitate pentru acest medic. Trebuie completat formularul alaturat pentru inregistrarea noului medic.</div>
                                            ) : (
                                                <div>De la data inregistrarii {formik.values.dateCreated || "-"}, au fost planificate {formik.values.tasksCount || "0"} vizite catre acest medic.</div>
                                            )}
                                        </div>
                                    </CardBody>
                                </Card>
                                
                                <Card>
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium">
                                                    Alte informatii
                                                </p>
                                                <h5 className="mb-0">
                                                    {formik.values.locations?.filter(e => e.entityState !== "deleted").length || "0"}
                                                    {" "}
                                                    {(formik.values.locations?.filter(e => e.entityState !== "deleted").length || 0) <= 1 
                                                        ? "locatie activa" 
                                                        : "locatii active"} si {formik.values.employees?.filter(m => m.entityState !== "deleted").length || "0"}
                                                    {" "}
                                                    {(formik.values.employees?.filter(m => m.entityState !== "deleted").length || 0) <= 1 
                                                        ? "reprezentant medical" 
                                                        : "reprezentanti medicali"}
                                                </h5>
                                            </div>
                                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                <span className="avatar-title rounded-circle bg-primary">
                                                    <i className={"bx bx-walk font-size-24"}></i>
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg={8}>
                                <Card>
                                    <CardBody>
                                        <h4 className="card-title">Zone de activitate</h4>
                                        <p className="card-title-desc">
                                            Selectati zonele in care medicul isi desfasoara activitatea
                                        </p>

                                        <Row>
                                            <Col lg={4}>
                                                <div className="mb-3">
                                                    <Label htmlFor="zone">Zona</Label>
                                                    <ZonesComponent
                                                        locations={zones}
                                                        handleZoneChange={(zoneId) => setSelectedZone(zoneId)}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={2}>
                                                <div className="mb-3">
                                                    <Label>&nbsp;</Label>
                                                    <div>
                                                        <Button
                                                            type="button"
                                                            color="primary"
                                                            className="btn-sm"
                                                            onClick={btnAddNewWorkingZone}
                                                        >
                                                            <i className="mdi mdi-plus me-1" />
                                                            Adauga zona
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <DataTable
                                            value={formik.values.locations?.filter(w => w.entityState !== "Deleted") || []}
                                            paginator
                                            rows={10}
                                            dataKey="idZone"
                                            emptyMessage="Nu au fost gasite rezultate"
                                        >
                                            <Column field="county" header="Judet" style={{ minWidth: '12rem' }} />
                                            <Column field="city" header="Localitate" style={{ minWidth: '12rem' }} />
                                            <Column field="zone" header="Locatia" style={{ minWidth: '12rem' }} />
                                            <Column style={{ minWidth: '6rem' }} body={removeSelectedZone} />
                                        </DataTable>
                                    </CardBody>
                                </Card>

                                <Card className="mt-4">
                                    <CardBody>
                                        <h4 className="card-title">Reprezentanti medicali</h4>
                                        <p className="card-title-desc">
                                            Selectati reprezentantii medicali care vor vizita medicul
                                        </p>

                                        <Row>
                                            <Col lg={4}>
                                                <div className="mb-3">
                                                    <Label htmlFor="employee">Reprezentant medical</Label>
                                                    <Select
                                                        value={selectedEmployee}
                                                        onChange={(e) => setSelectedEmployee(e)}
                                                        options={employeesList || []}
                                                        getOptionLabel={x => x?.name || ''}
                                                        getOptionValue={x => x?.id || ''}
                                                        placeholder="Alege reprezentantul medical"
                                                        noOptionsMessage={() => "Nu există reprezentanți disponibili"}
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={2}>
                                                <div className="mb-3">
                                                    <Label>&nbsp;</Label>
                                                    <div>
                                                        <Button
                                                            type="button"
                                                            color="primary"
                                                            className="btn-sm"
                                                            onClick={addNewEmployee}
                                                            disabled={!employeesList || employeesList.length === 0}
                                                        >
                                                            <i className="mdi mdi-plus me-1" />
                                                            Adauga reprezentant
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <DataTable
                                            value={formik.values.employees?.filter(w => w.entityState !== "Deleted") || []}
                                            paginator
                                            rows={10}
                                            dataKey="id"
                                            emptyMessage="Nu au fost gasite rezultate">
                                            <Column field="name" header="Numele reprezentantului" style={{ minWidth: '12rem' }} />
                                            <Column field="jobTitle" header="Functia" style={{ minWidth: '12rem' }} />
                                            <Column field="dateCreated" header="Data inregistrarii" style={{ minWidth: '12rem' }} />
                                            <Column style={{ minWidth: '6rem' }} body={removeSelectedEmployee} />
                                        </DataTable>
                                    </CardBody>
                                </Card>

                                <Card className="mt-4">
                                    <CardBody>
                                        <h4 className="card-title">Unitati sanitare </h4> 
                                        <DataTable
                                            value={formik.values?.clients || []}
                                            paginator
                                            rows={10}
                                            dataKey="idClient"
                                            emptyMessage="Nu au fost gasite rezultate">
                                            <Column field="name" header="Denumire unitate" style={{ minWidth: '12rem' }} />
                                            <Column body={medicStatusBody} header="Status activitate medic" style={{ minWidth: '12rem' }} />
                                            <Column field="clientType" header="Tip unitate" style={{ minWidth: '12rem' }} />
                                            <Column field="zone" header="Zona activitate" style={{ minWidth: '12rem' }} /> 
                                        </DataTable>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col lg={8}>
                                <div className="text-end mb-4">
                                    <Button
                                        type="button"
                                        hidden={idMedic == -1 || isDeleted}
                                        onClick={handleDeleteMedic}
                                        style={{ marginRight: "0.3%" }}
                                        color="danger"
                                    >
                                        Sterge
                                    </Button>
                                    <Button
                                        type="submit"
                                        color="success"
                                        disabled={saveDisabled || isDeleted}
                                    >
                                        Salveaza
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default MedicUpdateComponent;
