import React, { useEffect, useState, useRef } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import { Link } from 'react-router-dom'
import Select from "react-select";
import { ReportingService } from "services/ReportingService"; 
import { EmployeeService } from "services/EmployeeService";

const UnregisteredReportComponent = props => {
    document.title = "Raport clienti neinregistrati";

    const navigate = useNavigate();
    const [clients, setClients] = useState([]); 

    const [globalFilter, setGlobalFilter] = useState(null); 

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <IconField iconPosition="left">
                <InputIcon className="bx bx-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cauta..." />
            </IconField>
        </div>
    );

    const actionBodyTemplate = (options) => {
        return (
            <div className="list-unstyled hstack gap-1 mb-0">
                <li>
                    <Button
                        onClick={() => navigate(`/tasks/edit/${options.idTask}`)}
                        className="btn btn-sm btn-soft-info"
                        id={`btn-task-${options.idTask}`}>
                        <i className="bx bx-edit" />
                    </Button>
                </li>
            </div>
        );
    }
 
    useEffect(() => {
         ReportingService.getUnregisteredClients().then(f=>{
            setClients(f);
         });
    }, []);

    const clientStatusBodyTemplate = (options) => {
       // if value is Inregistrat then show a success text 
       // I want a badge span with a success class
       if (options.clientExists === "Inregistrat") {
        return <span className="badge bg-success">Inregistrat</span>;
       }else if (options.clientExists === "Inregistrat") {
        return <span className="badge bg-danger">Nu exista</span>;
       }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Rapoarte" breadcrumbItem="Clienti neinregistrati" /> 
                    <Row>
                        <Col xs={12}>
                            <Row>
                                <Col xl={12}>
                                    <Card>
                                        <CardBody> 
                                            <DataTable value={clients}
                                                dataKey="Task"
                                                paginator rows={10}
                                                header={header}
                                                rowsPerPageOptions={[5, 10, 25]}
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                currentPageReportTemplate="Afiseaza {first} pana la {last} din {totalRecords} inregistrari"
                                                globalFilter={globalFilter}>
                                                <Column field="client" header="Denumire client" sortable style={{ minWidth: '16rem' }}></Column>
                                                <Column field="zone" header="Zona de activitate" sortable style={{ minWidth: '16rem' }}></Column>
                                                <Column body={clientStatusBodyTemplate} header="Status nomenclator clienti" sortable style={{ minWidth: '16rem' }}></Column>
                                                <Column field="medics" header="Status nomenclator medici" sortable style={{ minWidth: '16rem' }}></Column>  
                                                <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                                            </DataTable>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default UnregisteredReportComponent;