import React, { useEffect, useState, useRef } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import { Link } from 'react-router-dom'
import Select from "react-select";
import { ReportingService } from "services/ReportingService"; 
import { EmployeeService } from "services/EmployeeService";

const IncidentsReportComponent = props => {
    document.title = "Raport incidente";

    const navigate = useNavigate();
    const [incidents, setIncidents] = useState([]);
    const [employeeList, setEmployeesList] = useState([]); 
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null);

    const header = (
        // add select employee here
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <Row>
                <Col lg="3"> 
                <Select
                    options={employeeList}
                    value={employeeList?.find(w=>w.idEmployee === selectedEmployee?.idEmployee)}
                    onChange={(e) => setSelectedEmployee(e)}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.idEmployee}
                    placeholder="Alege un angajat..."></Select>
                <Button onClick={() => {
                    if (!selectedEmployee) return;
                    ReportingService.getIncidents(selectedEmployee.idEmployee).then(f => { 
                        setIncidents(f);
                    });
                }} className="btn btn-sm btn-soft-info">
                  Cauta
                </Button>
                </Col>
            </Row> 
            <IconField iconPosition="left">
                <InputIcon className="bx bx-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cauta..." />
            </IconField>
        </div>
    );

    const actionBodyTemplate = (options) => {
        return (
            <div className="list-unstyled hstack gap-1 mb-0">
                <li>
                    <Button
                        onClick={() => navigate(`/tasks/incidents/${options.idIncident}`)}
                        className="btn btn-sm btn-soft-info"
                        id={`btn-incident-${options.idIncident}`}>
                        <i className="bx bx-show" />
                    </Button>
                </li>
            </div>
        );
    }

    useEffect(() => {
        EmployeeService.getEmployees().then(f => {
            try {   
                setEmployeesList(f);
            } catch (error) {
                console.error("Error fetching employees:", error);
            }

            if (f && f.length == 1) {
                console.log("change....", f[0].idEmployee);
                setSelectedEmployee(f[0]);
                ReportingService.getIncidents(f[0].idEmployee).then(f => {
                    console.log("Incidents:", f);
                    setIncidents(f);
                });
            }
        });
    }, []);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Rapoarte clienti" breadcrumbItem="Incidente" />
                    <Row>
                        <Col xs={12}>
                            <Row>
                                <Col xl={12}>
                                    <Card>
                                        <CardBody>
                                            <DataTable value={incidents}
                                                dataKey="idIncident"
                                                paginator rows={10}
                                                header={header}
                                                rowsPerPageOptions={[5, 10, 25]}
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                currentPageReportTemplate="Afiseaza {first} pana la {last} din {totalRecords} inregistrari"
                                                globalFilter={globalFilter}>
                                                <Column field="data" header="Data" sortable style={{ width: 'fit-content' }}></Column>
                                                <Column field="salesRepresentant" header="Reprezentant vanzari" sortable style={{ width: 'fit-content' }}></Column>
                                                <Column field="medicalRepresentant" header="Reprezentant medical" sortable style={{ width: 'fit-content' }}></Column>
                                                <Column field="client" header="Denumire client" sortable style={{ width: 'fit-content' }}></Column> 
                                                <Column field="status" header="Status incident" sortable style={{ minWidth: '10rem' }}></Column>
                                                <Column field="productsCount" header="Total produse" sortable style={{ minWidth: '10rem' }}></Column>
                                                <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                                            </DataTable>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default IncidentsReportComponent;