import React, { useEffect, useState } from "react";

import { Label } from "reactstrap";
import Select from "react-select";

const ZonesComponent = ({ locations, handleZoneChange, defaultZone }) => {
 
    const [cities, setCities] = useState([]);
    const [zones, setZones] = useState(null);
    const [counties, setCounties] = useState(null);

    const [selectedCounty, setSelectedCounty] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedZone, setSelectedZone] = useState(null);

    useEffect(() => {
        setCities(locations?.cities);
        setZones(locations?.zones);
        setCounties(locations?.counties);

        console.log("Defautl zone:", defaultZone);
        console.log("Locations:", locations);

        if (defaultZone && locations) {
            var zone = locations.zones.find(f => f.idZone == defaultZone);
            console.log("Found zone:", zone);

            if (zone) {
                var city = locations.cities.find(f => f.idCity == zone.idCity);
                var county = locations.counties.find(f => f.idCounty == city.idCounty);

                setSelectedZone(zone);
                setSelectedCity(city);
                setSelectedCounty(county);
            }
        }

    }, [locations, defaultZone]);

    return (
        <div className="mb-3" style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <div>
                <Label htmlFor="counties" className="mb-0">Judetul</Label>
                <Select name="counties" onChange={(e) => {
                    setSelectedCounty(e);
                    setSelectedZone(null);
                    setCities([...locations.cities].filter(f => f.idCounty == e.value));
                    setSelectedCity(null);
                    setZones([]);
                    handleZoneChange(null);
                }} value={selectedCounty} options={counties} placeholder="Judetul"></Select>
            </div>

            <div>
                <Label htmlFor="cities" className="mb-0">Orasul</Label>
                <Select name="cities" onChange={(e) => {
                    setSelectedCity(e);
                    setZones([...locations.zones].filter(f => f.idCity == e.idCity));
                    setSelectedZone(null);
                }} value={selectedCity} options={cities} placeholder="Orasul"></Select>
            </div>

            <div>
                <Label htmlFor="zones" className="mb-0">Zona de activitate</Label>
                <Select name="zones" onChange={(e) => { setSelectedZone(e); handleZoneChange(e.value); }}
                    value={selectedZone} options={zones} placeholder="Zona activitate"></Select>
            </div>
        </div>
    )
}

export default ZonesComponent;