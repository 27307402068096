import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

const SearchPageComponent = () => {
  document.title = "Cautare | CRM";
  const location = useLocation();

  // Define the entities with keys (keywords)
  const entities = [
    { id: 1, url: "/pharmacy/edit/-1", title: "Adauga farmacie", description: "Pagina dedicata pentru creearea unei noi farmacii", keys: ["farmacie", "farmacii"] },
    { id: 2,  url: "/healthfacility/create/-1",title: "Adauga unitate sanitara", description: "Pagina dedicata pentru creearea unei noi unitati sanitare", keys: ["unitate", "sanitara", "unitate sanitara"] },
    { id: 34,  url: "/medic/new/-1",title: "Adauga medic", description: "Pagina dedicata pentru creearea unui nou medic", keys: ["medic", "medici", "personal medical"] },
    { id: 33,  url: "/medic/prescriptions",title: "Prescriptii medicale", description: "Vizualizeaza prescriptiile medicale", keys: ["medic", "prescriptii", "medicale", "prescriptie", "medici", "personal medical"] },
    { id: 334,  url: "/reports/medics",title: "Planificari medici", description: "Raport planificari medici", keys: ["medic", "planificari", "medici", "prescriptii", "medicale", "prescriptie", "medici", "personal medical"] },
    { id: 334,  url: "/reports/inactivemedics",title: "Medici fara planificari", description: "Raport medici fara planificari", keys: ["medic", "planificari", "medici", "medicale",  "medici", 
        "personal medical"] },
    
    { id: 4,  url: "/tasks/new/-1",title: "Creeaza planificare", description: "Pagina dedicata pentru creearea unei noi planificari", keys: ["planificare", "vizita", "planificari", "vizite"] },
    { id: 5, url: "/tasks/new/route", title: "Creeaza o ruta", description: "Pagina dedicata pentru creearea unei noi rute", keys: ["rute", "ruta"] },
    { id: 6, url: "/tasks/incidents", title: "Vizualizare pagina incidente", description: "Pagina pentru vizualizarea tuturor incidentelor", keys: ["incidente", "incident"] },
    { id: 7,  url: "/reports/tasks/planifications",title: "Vizualizare pagina istoric planificari", description: "Pagina pentru vizualizarea tuturor planificarilor", 
        keys: ["planificari", "raport planificari", "planificare", "sarcini", "vizite", "vizita"] },
    { id: 8,  url: "/tasks",title: "Vizualizare pagina planificarile mele", description: "Pagina pentru vizualizarea planificarilor zilnice", keys: ["planificari"] },
    { id: 9, url: "/nomenclator", title: "Vizualizare pagina nomenclator", description: "Pagina pentru administrarea nomenclatoarelor", keys: ["nomenclator", "nomenclatoare"] },
    { id: 10, url: "/Employee", title: "Vizualizare pagina personal", description: "Pagina pentru administrarea personalului", keys: ["personal", "angajati", "angajat"] },
  ];

  const [results, setResults] = useState([]); // Initially show all results

  // Use effect to filter results when query changes
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get("query");
   
    if (query && query.trim()) {
        const lowerQuery = query.toLowerCase(); // Case insensitive search
        const queryWords = lowerQuery.split(" "); // Split query into individual words
  
        // Filter entities based on matching all query words with the keys
        const filtered = entities.filter((result) =>
          queryWords.every((word) => 
            result.keys.some((key) => key.toLowerCase().includes(word)) // Check if the word exists in any of the keys
          )
        ); 
        setResults(filtered);
    } 
  }, [location.search]); // Trigger effect when query changes

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="checkout-tabs">
            <Row>
              <Col lg="2">
                <Nav className="flex-column" pills>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "default" }}
                      className="active bg-success"
                    >
                      <i className="bx bx-search d-block check-nav-icon mt-4 mb-2" />
                      <p className="font-weight-bold mb-4">Rezultatele cautarii</p>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col lg="10">
                <Card>
                  <CardBody>
                    <TabContent activeTab={"1"}>
                      <TabPane tabId="1">
                        <CardTitle className="mb-5">
                          Au fost gasite urmatoarele articole dupa cuvintele cheie
                        </CardTitle> 
                        {results.length > 0 ? (
                          results.map((result) => (
                            <div key={result.id} className="faq-box d-flex mb-4">
                              <div className="flex-shrink-0 me-3 faq-icon">
                                <i className="bx bxs-file-find font-size-20 text-success" />
                              </div>
                              <div className="flex-grow-1">
                                <h5 className="font-size-15"><a href={result.url}>{result.title}</a></h5>
                                <p className="text-muted">{result.description || "Nu exista descriere"}</p>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div>0 rezultate...</div>
                        )}
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SearchPageComponent;
