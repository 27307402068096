import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { DataTable } from 'primereact/datatable';
import { Dialog } from "primereact/dialog";

import Spinners from "components/Common/Spinner";
import { Column } from 'primereact/column';
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import { ReportingService } from "services/ReportingService";
import { connect } from "react-redux";

const DistributedProductsReportComponent = ({ props, user }) => {

    const [entities, setEntities] = useState([]);
    const [taskDistributedProducts, setTaskDistributedProducts] = useState(null);
    const [viewDetailsModal, setViewDetailsModal] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
        ReportingService.getMedicsDistributedProducts().then(f => {
            setEntities(f);
        });
    }, []);

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <IconField iconPosition="left">
                <InputIcon className="bx bx-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cauta..." />
            </IconField>
        </div>
    );

    const actionBodyTemplate = (options) => {
        return (
            <div className="list-unstyled hstack gap-1 mb-0">
                <li>
                    <Button onClick={() => {
                        ReportingService.getTaskDistributedProducts(options.idTask).then(f => {

                            const totals = f.reduce((acc, product) => {
                                const key = product.idTaskDetail;
                                acc[key] = (acc[key] || 0) + (product.quantity * product.price);
                                return acc;
                            }, {});
                            
                            // Step 2: Assign TotalSum to each product
                            const result = f.map(product => ({
                                ...product,
                                totalSum: totals[product.idTaskDetail]
                            }));

                            setTaskDistributedProducts(result); 
                            setViewDetailsModal(true);
                        });
                    }}
                        className="btn btn-sm btn-soft-info"
                        id={`${options.idTask}`}>
                        <i className="bx bx-show-alt" />
                    </Button>
                </li>
            </div>
        );
    }
    const bodyNameTemplate = (options) => {
        if (options.medicName.includes("generic")) return <span><em>Vizita</em></span>
        return <span><b>{options.medicName}</b></span>
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Rapoarte" breadcrumbItem="Distribuire produse" />
                    <Row>
                        <Dialog breakpoints={{ '960px': '75vw', '641px': '100vw' }}
                            header="Detalii" visible={viewDetailsModal} style={{ width: '50vw' }}
                            onHide={() => { if (!viewDetailsModal) return; setViewDetailsModal(false); }}>
                            <div>
                                <DataTable
                                    value={taskDistributedProducts || []}
                                    dataKey="idTaskDetail"
                                    emptyMessage="Nu exista produse distribuite"
                                    paginator rows={10}
                                    rowsPerPageOptions={[5, 10, 25]}>
                                    <Column field="label" header="Produs"></Column> 
                                    <Column field="quantity" header="Cantitatea totala (buc)" sortable></Column>
                                    <Column field="price" header="Cost total (RON)" sortable></Column>
                                    <Column field="totalSum" header="Suma cost total planificare (RON)" sortable></Column>
                                </DataTable>
                            </div>
                        </Dialog>

                        <Col xs={12}>
                            <Row>
                                <Col xl={12}>
                                    {isLoading ? <Spinners setLoading={setIsLoading} />
                                        :
                                        <Card>
                                            <CardBody>
                                                <DataTable value={entities || []}
                                                    dataKey="idTask"
                                                    exportable
                                                    filterDisplay="row"
                                                    paginator
                                                    rows={10}
                                                    sortField="date"
                                                    sortOrder={-1}
                                                    header={header}
                                                    rowsPerPageOptions={[5, 10, 25]}
                                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                    currentPageReportTemplate="Afiseaza {first} pana la {last} din {totalRecords} inregistrari"
                                                    globalFilter={globalFilter}>
                                                    <Column field="date" header="Data planificarii" filter sortable style={{ width: 'fit-content' }}></Column>
                                                    {
                                                        (user && user.idJobType != 1 && (<Column field="medicName"
                                                            body={bodyNameTemplate}
                                                            header="Numele medicului" filter sortable style={{ width: 'fit-content' }}></Column>))
                                                    }
                                                    <Column field="clientName" header="Numele clientului" filter sortable style={{ width: 'fit-content' }}></Column>
                                                    <Column field="county" header="Judet" filter sortable style={{ width: 'fit-content' }}></Column>
                                                    <Column field="city" header="Oras" filter sortable style={{ width: 'fit-content' }}></Column>
                                                    <Column field="sector" header="Sector" filter sortable style={{ width: 'fit-content' }}></Column>
                                                    <Column body={actionBodyTemplate} exportable={false} style={{ width: 'fit-content' }}></Column>
                                                </DataTable>
                                            </CardBody>
                                        </Card>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    user: state.Login.user,
});

export default connect(mapStateToProps)(DistributedProductsReportComponent);