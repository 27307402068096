import React, { useEffect, useState, useRef } from 'react';
import { Container, Row } from 'reactstrap';
import { Dialog } from 'primereact/dialog';
import { Badge } from 'primereact/badge'
import Breadcrumbs from "components/Common/Breadcrumb";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Select from 'react-select';
import { format } from 'date-fns';
import { useParams } from "react-router-dom";
import { InputText } from 'primereact/inputtext';

import { connect } from "react-redux";

import { Toast } from 'primereact/toast';

import "flatpickr/dist/themes/material_blue.css";
import FlatPickr from "react-flatpickr";
import { Button } from 'primereact/button';
import {
    Card, CardBody, Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import {
    Form,

    Input,
    Label
} from "reactstrap"


import clientlogo from "../../assets/images/biovitality/client_incident.png";
import { IncidentsService } from 'services/IncidentsService';
import ChantBox from './chat-box';

const TaskIncidentEdit = ({ user }) => {
    document.title = "Administrare incident";
    const { idIncident = -1 } = useParams();

    const [currentIncident, setCurrentIncident] = useState({});
    const [currentEntityProduct, setCurrentEntityProduct] = useState({});
    const [currentEntityProductDialog, setCurrentEntityProductDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);
    const [allowOperations, setAllowOperations] = useState(false);
    const [date, setDate] = useState(null);
    const [deleteEntityDialog, setDeleteEntityDialog] = useState(false);

    const emptyEntityProduct = {
        idProduct: null,
        isNewEntity: true,
        label: "",
        initialQuantity: 0,
        quantity: 0,
        dateExpired: Date.now()
    }

    useEffect(() => {
        if (idIncident > 0) {
            IncidentsService.getIncident(idIncident).then(f => {
                let isAdmin = user && user.isAdmin;
                let allowOperations = ((isAdmin && f.idIncidentStatusType <= 4) || (user && user.id == f.idTaskEmployee && f.idIncidentStatusType <= 3));

                setAllowOperations(allowOperations); 
                setCurrentIncident(f);

                if(!isAdmin){
                    IncidentsService.setLastSeen(idIncident);
                }
            });
        }
    }, [idIncident]);


    const formatDateExpired = (e) => {
        try {
            let convertedDate = <>{new Date(e.dateExpired).toLocaleDateString('en-GB')}</>;
            return convertedDate;
        } catch {
            return <>{new Date(e.dateExpired).toISOString()}</>
        }
    } 

    const editProductRowDetails = (options) => {
        return (
            <div className="list-unstyled hstack gap-1 mb-0">
                <li>
                    <Button
                        disabled={!allowOperations}
                        onClick={() => {
                            console.log("Edit product", options);
                            setCurrentEntityProduct({
                                ...options,
                                isNewEntity: false
                            });
                            setCurrentEntityProductDialog(true);
                        }}
                        className="btn btn-sm btn-soft-danger"
                        id={`btn_edit_product_${options.idProduct}`}>
                        <i className="bx bx-edit" />
                    </Button>
                </li>
            </div>
        );
    }

    // salveaza modificarile
    const commitSaveChanges = (closeIncident = false, returnProducts = false) => {

        setSubmitted(true);

        if (closeIncident) {
            if (currentIncident.products.find(o => o.quantity != 0)) {
                setSubmitted(false);
                toast.current.show({
                    severity: 'error', summary: 'Atentie',
                    detail: 'Nu se poate inchide incidentul deoarece exista produse ramase'
                });
                return;
            }

            currentIncident.closed = true;
        } else if (returnProducts) {
            currentIncident.returned = true;
        }

        if (currentIncident.products.length == 0 || !currentIncident.products) {
            setSubmitted(false);
            toast.current.show({
                severity: 'error', summary: 'Atentie',
                detail: 'Nu exista produse adaugate in incident'
            });
            return;
        }

        if (!currentIncident?.comments || currentIncident?.comments?.length <= 5) {
            setSubmitted(false);
            toast.current.show({
                severity: 'error', summary: 'Atentie',
                detail: 'Nu exista observatiile incidentului'
            });
            return;
        }

        IncidentsService.updateIncident({
            idIncident: currentIncident.idIncident,
            idIncidentStatusType: currentIncident.idIncidentStatusType,
            comments: currentIncident.comments,
            closed: closeIncident,
            returned: returnProducts,
            products: currentIncident?.products.map(f => {

                let date = new Date();

                try {
                    //   date = new Date(currentEntityProduct.dateExpired);
                } catch {
                    date = new Date();
                } //todo
 
                return {
                    idProduct: f.idProduct,
                    quantity: f.quantity,
                    dateExpired: date
                }
            })
        }).then(f => {
            if (f && f.success) {
                toast.current.show({
                    severity: 'success', summary: 'Notificare',
                    detail: closeIncident ? 'Incidentul a fost inchis' : 'Modificarile au fost salvate'
                });

                if (!closeIncident && !returnProducts) {
                    setSubmitted(false);
                }
            } else {
                setSubmitted(false);
                toast.current.show({
                    severity: 'error', summary: 'Atentie',
                    detail: 'Eroare la salvarea modificarilor'
                });
            }
        });
    }


    // sterge incidentul
    const commitDeleteIncident = () => {
        setSubmitted(true);

        IncidentsService.deleteIncident(idIncident).then(f => {

            if (f && f.success) {
                toast.current.show({
                    severity: 'success', summary: 'Atentie',
                    detail: 'Incidentul a fost sters'
                });
                setDeleteEntityDialog(false);

            } else {
                setSubmitted(false);
                toast.current.show({
                    severity: 'error', summary: 'Atentie',
                    detail: 'Eroare la stergerea incidentului. Detalii: ' + f?.details
                });
            }
        });
    }

    const statusRowDataTableDetails = (options) => {

        let dateExpired = new Date(options.dateExpired);
        let monthsDiff = (new Date().getFullYear() - dateExpired.getFullYear()) * 12 + (new Date().getMonth() - dateExpired.getMonth());

        if (!dateExpired) return <span className="me-1 text-muted badge badge-soft-success">Disponibil</span>;
        else if (monthsDiff <= 0) return <span className="me-1 text-muted badge badge-soft-danger">Expirat</span>;
        else if (monthsDiff <= 3) return <span className="me-1 text-muted badge badge-soft-warning">Expira in mai putin de 3 luni</span>;
        else if (monthsDiff > 3 && monthsDiff <= 6) return <span className="me-1 text-muted badge badge-soft-info">Expirat in mai mult de 3 luni</span>;
        else
            return <span className="me-1 text-muted badge badge-soft-success">Disponibil</span>;
    }

    const hideEntityDialog = () => {
        setCurrentEntityProductDialog(false);
        setCurrentEntityProduct(emptyEntityProduct);
        setSubmitted(false);
    }

    // save incident details product
    // save product
    const commitEntitySaveChanges = () => {

        setSubmitted(false);

        if (!currentEntityProduct.idProduct || !currentEntityProduct.label ||
            !currentEntityProduct.initialQuantity || !currentEntityProduct.dateExpired) {
            setSubmitted(true);
            return;
        }

        console.log("Current entity product", currentEntityProduct);

        if (currentEntityProduct.isNewEntity) {

            setCurrentIncident(prev => {
                return {
                    ...prev, products: [...prev.products, {
                        ...currentEntityProduct,
                        dateExpired: currentEntityProduct.dateExpired
                    }]
                };
            });
        } else {

            console.log("Updat edate:...", currentEntityProduct);
            setCurrentIncident(prev => {
                return {
                    ...prev, products: [...prev.products].map(f => {
                        if (f.idProduct == currentEntityProduct.idProduct) {
                            return {
                                ...f,
                                quantity: currentEntityProduct.quantity,
                                dateExpired: currentEntityProduct.dateExpired
                            }
                        }
                        return f;
                    })
                };
            });
        }

        setCurrentEntityProductDialog(false);
        setCurrentEntityProduct(emptyEntityProduct);
        setSubmitted(false);
    }

    const operations = () => {
        if (!currentIncident)
            return <></>;

        if (!allowOperations)
            return <></>;

        let isAdmin = user && user.isAdmin;

        let returnProducts = currentIncident.idIncidentStatusType == 3;
        let closeIncident = (isAdmin && currentIncident.idIncidentStatusType == 4) || currentIncident.idIncidentStatusType == 3;
        let deleteAction = (<button disabled={submitted} onClick={() => { setDeleteEntityDialog(true); }} className="btn btn-soft-danger w-100">Sterge incidentul</button>);
        let saveActionEnabled = currentIncident.idIncidentStatusType <= 3; //doar daca inca e pana la in progress

        return (
            <>
                {
                    returnProducts ? (<button disabled={submitted} onClick={() => commitSaveChanges(false, true)} className="btn btn-soft-primary w-100 mb-1">Retur produse</button>) : (<></>)
                }
                {
                    saveActionEnabled ? (<button disabled={submitted} onClick={() => commitSaveChanges(false, false)} className="btn btn-soft-success w-100 mb-1">Salveaza modificarile</button>) : (<></>)
                }
                {
                    closeIncident ? (<button disabled={submitted} onClick={() => commitSaveChanges(true, false)} className="btn btn-success w-100 mb-1">Inchide incidentul</button>) : (<></>)
                }
                {
                    isAdmin ? deleteAction : (<></>)
                }
            </>
        )
    }

    const deleteEntityFooter = (
        <React.Fragment>
            <Button label="Nu" icon="bx bx-x" className='btn-info' style={{ "marginRight": "5px" }}
                onClick={() => { setDeleteEntityDialog(false); }}></Button>
            <Button icon="bx bx-check" className='btn-danger' onClick={commitDeleteIncident}>Da</Button>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Operational" breadcrumbItem="Detalii incident" />
                    <Toast ref={toast} />
                    <Dialog visible={deleteEntityDialog}
                        style={{ width: '32rem' }}
                        breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                        header="Confirmare" modal
                        footer={deleteEntityFooter}
                        onHide={() => { setDeleteEntityDialog(false); }}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {(
                                <span>
                                    Esti de acord sa <Badge severity="danger" value="stergi" /> acest incident ?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Modal
                        isOpen={currentEntityProductDialog}
                        toggle={() => {
                            hideEntityDialog();
                        }}
                        backdrop={'static'}
                        id="staticBackdrop">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Modifica</h5>
                            <button type="button" className="btn-close"
                                onClick={() => {
                                    hideEntityDialog();
                                }} aria-label="Close"></button>
                        </div>

                        <div className='modal-body'>
                            <div className="field mb-2">
                                <label htmlFor="label" className="font-bold">
                                    Denumirea produsului
                                </label>

                                <Select
                                    name="idProduct"
                                    isDisabled={!currentEntityProduct.isNewEntity}
                                    value={currentIncident?.productsNomenclator?.find(o => o.idProduct == currentEntityProduct.idProduct)}
                                    onChange={(selected) => {
                                        console.log("Selected", selected);
                                        setCurrentEntityProduct(p => {
                                            return {
                                                ...p,
                                                idProduct: selected.idProduct,
                                                label: selected.label,
                                            }
                                        });
                                    }}
                                    styles={
                                        {
                                            menu: base => ({
                                                ...base,
                                                zIndex: 100
                                            })
                                        }
                                    }
                                    getOptionLabel={(option) => option.label}
                                    getOptionValue={(option) => option.idProduct}
                                    options={currentIncident.productsNomenclator || []}
                                    placeholder="Alege produsul"
                                />
                                {submitted && !currentEntityProduct.label && <small className="p-error">Camp obligatoriu.</small>}
                            </div>

                            <div className="field mb-2">
                                <label htmlFor="initialQuantity" className="font-bold">
                                    Cantitatea
                                </label>
                                <div>
                                    <InputText maxLength={3} id="initialQuantity" value={currentEntityProduct?.initialQuantity}
                                        onChange={(e) => {
                                            if (parseInt(e.target.value) < 0) return;
                                            setCurrentEntityProduct(prev => {
                                                return {
                                                    ...prev,
                                                    quantity: parseInt(e.target.value),
                                                    initialQuantity: parseInt(e.target.value)
                                                }
                                            });
                                        }} />
                                    {submitted && !currentEntityProduct.initialQuantity && <small className="p-error">Camp obligatoriu.</small>}
                                </div>
                            </div>

                            <div>
                                <label htmlFor="dateExpired" className="font-bold">
                                    Data expirarii
                                </label>
                                <div>
                                    <FlatPickr
                                        className="form-control"
                                        options={{ dateFormat: "d M, Y" }}
                                        placeholder="Alege o data"
                                        value={currentEntityProduct?.dateExpired}
                                        onChange={(e) => {
                                            setCurrentEntityProduct(prev => {
                                                return {
                                                    ...prev,
                                                    dateExpired: new Date(e[0])
                                                }
                                            });
                                        }}
                                    />
                                    {submitted && !currentEntityProduct.dateExpired && <small className="p-error">Camp obligatoriu.</small>}
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <div className="gap-2">
                                <Button onClick={commitEntitySaveChanges} className="btn btn-success">Salveaza</Button>
                            </div>
                        </div>
                    </Modal>

                    <Row>
                        <Col xl={4}>
                            <Card>
                                <CardBody>
                                    <h5 className="fw-semibold">General</h5>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <tbody>
                                                <tr>
                                                    <th scope="row">Data incidentului:</th>
                                                    <td>{currentIncident?.dateCreated}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Creat de catre</th>
                                                    <td>{currentIncident?.employee}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Reprezentant medical</th>
                                                    <td>{currentIncident?.employeeRM}</td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Vechime incident</th>
                                                    <td><span className="badge badge-soft-success">{parseInt(currentIncident?.timeSpan)} zile</span></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">Status incident</th>
                                                    <td>
                                                        <span className={"badge badge-soft-info"}>{currentIncident?.status}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"></th>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <i className="bx bx-globe text-primary fs-4"></i>Website</th>
                                                    <td>
                                                        <span>{currentIncident?.website}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row">
                                                        <i className="bx bx-phone text-primary fs-4"></i>Telefon</th>
                                                    <td>
                                                        <span>{currentIncident?.mobile}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"><i className="bx bx-map text-primary fs-4"></i>Adresa</th>
                                                    <td>
                                                        <span>{currentIncident?.clientAddress}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {
                                        user && user.isAdmin && currentIncident && 
                                        currentIncident.idIncidentStatusType <= 4 ? (
                                            <div className="field mb-3">
                                                <Select
                                                    value={currentIncident?.incidentStatusTypes?.find(o => o.idStatusType == currentIncident?.idIncidentStatusType) || -1}
                                                    placeholder="Alege statusul incidentului"
                                                    options={currentIncident?.incidentStatusTypes || []}
                                                    getOptionLabel={(option) => option.label}
                                                    getOptionValue={(option) => option.idStatusType}
                                                    onChange={(selected) => {
                                                        setCurrentIncident(prev => { return { ...prev, idIncidentStatusType: selected.idStatusType } });
                                                        toast.current.show({
                                                            severity: 'success', summary: 'Atentie',
                                                            detail: 'Statusul incidentului a fost modificat'
                                                        });
                                                    }}
                                                />
                                            </div>) : (<></>)
                                    }

                                    <div className="gap-2">
                                        {
                                            operations()
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xl={8}>
                            <Card>
                                <CardBody className="border-bottom">
                                    <div className="d-flex">
                                        <img src={clientlogo} alt="" height="50" />
                                        <div className="flex-grow-1 ms-3">
                                            <h5 className="fw-semibold">{currentIncident?.client?.name}</h5>
                                            <ul className="list-unstyled hstack gap-2 mb-0">
                                                <li>
                                                    <i className="bx bx-info-circle"></i> <span className="text-muted">{currentIncident?.incidentsCount} incidente alocate clientului</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardBody>
                                    <h5 className="fw-semibold mb-3">Descriere</h5>
                                    <p>In vederea eficientizarii activitatii, dorim sa identificam si sa tratam neconformitatile aparute in stocurile de produse vandute clientilor. In acest sens, un incident trece prin urmatoarele etape:</p>
                                    <p className='text-muted'><i className="mdi mdi-chevron-right text-primary me-1" />{" "}Agentul de vanzari se autosesizeaza si aloca un incident clientului.</p>
                                    <p className='text-muted'><i className="mdi mdi-chevron-right text-primary me-1" />{" "}Reprezentantul medical din echipa agentului de vanzari analizeaza incidentul si stabileste o strategie de solutionare.</p>
                                    <p className='text-muted'><i className="mdi mdi-chevron-right text-primary me-1" />{" "}Solutia optima este returul produselor. Cand nu este posibil, personalul administrativ BioVitality este instiintat pentru a putea interveni.</p>

                                    <Form>
                                        <div className="mb-3">
                                            <Label htmlFor="details">
                                                Observatii incident
                                            </Label>
                                            <textarea
                                                className="form-control"
                                                id="details"
                                                placeholder="Observatii cu privire la incident..."
                                                rows="3"
                                                onChange={(e) => {
                                                    setCurrentIncident(prev => { return { ...prev, comments: e.target.value } });
                                                }}
                                                value={currentIncident?.comments}
                                            ></textarea>
                                        </div>
                                    </Form>
                                    {
                                        allowOperations && (
                                            <div className="mt-4">
                                                <Button
                                                    onClick={() => {
                                                        setCurrentEntityProduct(emptyEntityProduct);
                                                        setCurrentEntityProductDialog(true);
                                                    }}
                                                    className="btn btn-soft-info">
                                                    <i className="bx bx-plus" />
                                                    Adauga produs
                                                </Button>
                                            </div>)
                                    }

                                    <div>
                                        <DataTable columnResizeMode="expand"
                                            resizableColumns className='.p-datatable-lg' style={{ "fontSize": "15px" }}
                                            size='small' value={currentIncident?.products || []} paginator rows={10}
                                            dataKey="idProduct" filterDisplay="row"
                                            emptyMessage="Nu au fost gasite rezultate">
                                            <Column field="label" header="Produsul" />
                                            <Column body={statusRowDataTableDetails} header="Status BBD" />
                                            <Column field="initialQuantity" header="Cantitatea initiala" />
                                            <Column field="quantity" header="Cantitatea actuala" />
                                            <Column body={formatDateExpired} header="Data expirarii" />
                                            <Column body={editProductRowDetails} style={{ minWidth: '12rem' }} />
                                        </DataTable>
                                    </div>

                                    <div className="mt-4">
                                        <i className="bx bx-error text-danger" />
                                        <span className="me-1 text-muted ">Pentru a inchide incidentul trebuie sa existe cel putin o vizita efectuata catre acest client ulterior creeri incidentului.</span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col>
                            <ChantBox incident={currentIncident} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    user: state.Login.user, // Replace 'auth' with the correct slice name
});

export default connect(mapStateToProps)(TaskIncidentEdit);
