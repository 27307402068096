import React, { useState, useRef, useEffect } from "react"
import { Row, Col, Card, CardBody } from "reactstrap"
import avatarImage from "../../../assets/images/users/avatar-9.png"
import {Badge} from "primereact/badge"
import { connect } from "react-redux";
import withRouter from "components/Common/withRouter";

const ClientStatisticsComponent = ({model,user}) => { 
 
  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <Col lg="4">
                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <img src={avatarImage} alt="" className="avatar-md rounded-circle img-thumbnail" />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        {/* <p className="mb-2">Dashboard-ul aplicatiei Biovitality</p> */}
                        <h5 className="mb-1">{(user && user.isAdmin? "Administrator" : user.name) ?? "CRM.BIOVITALITY"}</h5>
                        <p className="mb-0">{(user && user.isAdmin? "- CRM -" : user.jobTitle)  ?? "- CRM - "}</p>
                      </div>
                    </div>
                  </div>
                </Col>

                <Col lg="4" className="align-self-center">
                  <div className="text-lg-center mt-4 mt-lg-0">
                    <Row>
                      <Col xs="4">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                          <Badge severity="info" value="Planificari"/> 
                          </p>
                          <h5 className="mb-0">{model?.tasksCount ?? 0}</h5>
                        </div>
                      </Col>
                      <Col xs="4">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                            <Badge severity="danger" value="Incidente"/> 
                          </p>
                          <h5 className="mb-0">{model?.incidentsCount ?? 0}</h5>
                        </div>
                      </Col>
                      <Col xs="4">
                        <div>
                          <p className="text-muted text-truncate mb-2">
                          <Badge severity="warning" value="Rapoarte"/> 
                          </p>
                          <h5 className="mb-0">{model?.stockRequestCount ?? 0}</h5>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {  
  return { user: state.Login.user };
};

export default withRouter(
  connect(mapStatetoProps, {})((ClientStatisticsComponent))
);
 