import React, { useEffect, useRef, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "components/Common/withRouter";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = ({ user }) => {
  const ref = useRef();

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();

  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  const [activeUser, setActiveUser] = useState(null);
  useEffect(() => {
    setActiveUser(JSON.parse(localStorage.getItem("authUser")));
  }, []);

  const isAdmin = () => {
    try {
      return (user && user.isAdmin);
    } catch {
      return false;
    }
  }

  const isAV = () => {
    try {
      console.log("USER:", user && user.isAdmin);
      if(user && user.isAdmin) return false; 
      return (user && user.idJobType == 1);
    } catch {
      return false;
    }
  }

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">Meniu </li>
            <li>
              <Link to="/#">
                <i className="bx bx-home-circle"></i>
                <span>Acasa</span>
              </Link>
            </li>

            <li className="menu-title">Administrare</li>
            {
              isAdmin() ? (
                <li>
                  <Link to="/Employee" >
                    <i className="bx bxs-user-detail"></i>
                    <span>Personal</span>
                  </Link>
                </li>) : (<></>)
            }

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-crown"></i>
                <span>Gestiune clienti</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/pharmacies">Farmacii</Link>
                </li>
                {
                  !isAV() ? (<>
                    <li>
                      <Link to="/healthfacilities">Unitati sanitare</Link>
                    </li>
                    <li>
                      <Link to="/medics">Medici</Link>
                    </li>
                  </>) : (<></>)
                }
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-bar-chart"></i>
                <span>Gestiune stoc</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/stock/products">Produse</Link>
                </li>
                <li>
                  <Link to="/stock/requests">
                    Rapoarte de necesitate
                  </Link>
                </li>
              </ul>
            </li>

            {
              isAdmin() ? (
                <li>
                  <Link to="/nomenclator">
                    <i className="bx bx-list-minus" />
                    <span>Nomenclator</span>
                  </Link>
                </li>) : (<></>)
            }

            <li className="menu-title">Operational</li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-compass"></i>
                <span>Planificare</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/tasks">Planificarile mele</Link>
                </li>
                <li>
                  <Link to="/tasks/new/-1">Planifica vizita</Link>
                </li>
                <li>
                  <Link to="/tasks/new/route">Planifica ruta</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/tasks/incidents">
                <i className="bx bxs-analyse"></i>
                <span>Incidente</span>
              </Link>
            </li>
            {
              !isAV() ? (
                <li>
                  <Link to="/medic/prescriptions">
                    <i className="bx bx-columns"></i>
                    <span>Prescriptii medicale</span>
                  </Link>
                </li>) : <></>
            }  
            <li className="menu-title">Rapoarte</li>
            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-report"></i>
                <span>Clienti</span>
              </Link>
              <ul className="sub-menu">
                {
                  !isAV() ? (<>
                  
                <li>
                  <Link to="/reports/medics">Planificari medici</Link>
                </li>
                <li>
                  <Link to="/reports/medicalprescriptions">Prescriptii medicale</Link>
                </li>
                  </>) : (<></>)
                }
                <li>
                  <Link to="/reports/incidents">Incidente</Link>
                </li>
                {
                  isAdmin() ? (
                    <li>
                      <Link to="/reports/unregistered">Neinregistrati in baza de date</Link>
                    </li>) : (<></>)
                }
                {
                  !isAV() ? (<>
                    <li>
                      <Link to="/reports/inactivemedics">Medici fara planificari</Link>
                    </li>
                    <li>
                      <Link to="/reports/inactiveclients">Unitati fara planificari</Link>
                    </li>
                    </>) : (<></>)
                }
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-report"></i>
                <span>Planificari</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/reports/tasks/calendar">Calendar planificari</Link>
                </li>
                <li>
                  <Link to="/reports/tasks/planifications">Planificari</Link>
                </li>
                <li>
                  <Link to="/reports/clients">Planificari per client</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-report"></i>
                <span>Produse</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/reports/stock">Rapoarte de necesitate</Link>
                </li>
                <li>
                  <Link to="/reports/distributedproducts">Produse distribuite</Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.func.isRequired, // Prefer `func` for translation functions
  user: PropTypes.object, // Assuming user is an object
};

const mapStateToProps = (state) => ({
  user: state.Login.user, // Replace 'Login' with the correct slice name
});

// Combine HOCs
export default connect(mapStateToProps)(
  withRouter(withTranslation()(SidebarContent))
);