import { get, post } from "../helpers/api_helper"

export const RoutesService = {
    getRoutes(idEmployee) {
        return get(`tasks/routes/employee/${idEmployee}`);
    },
    createRoute(entity) {
        return post(`nomenclator/route/create`, entity);
    },
    updateRoute(entity) {
        return post(`nomenclator/route/update`, entity);
    },
    deleteRoute(idRoute) {
        return get(`nomenclator/route/delete/${idRoute}`);
    },
    deleteRouteDetails(idRouteDetails) {
        return get(`nomenclator/routedetail/delete/${idRouteDetails}`);
    },
    getAllRouteDetails() {
        return get('tasks/routedetails');
    },
    createRouteDetails(entity) {
        return post(`nomenclator/routedetail/create`, entity);
    },
    updateRouteDetails(entity) {
        return post(`nomenclator/routedetail/update`, entity);
    },
    saveRoutePlanification(entity) {
        return post(`tasks/route/create`, entity);
    },
}