import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar'; 
import { IconField } from 'primereact/iconfield';
import { Row } from 'reactstrap';

import Breadcrumbs from "components/Common/Breadcrumb"
import { Container } from "reactstrap"

import { Card } from 'reactstrap';
import { CardBody } from 'reactstrap';
import { InputIcon } from 'primereact/inputicon'; 
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext'; 
import {NomenclatorService} from 'services/NomenclatorService';

import Select from "react-select";

const CodificationsNomenclatorComponent = () => {
    let emptyEntity = {
        id: null,
        code: '',
        category: null,
        value: null,
        details: null
    };

    const [entities, setEntities] = useState([]);
    const [entityDialog, setEntityDialog] = useState(false);
    const [deleteEntityDialog, setDeleteEntityDialog] = useState(false);
    const [entity, setEntity] = useState(emptyEntity);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);

    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        NomenclatorService.getCodifications().then(c=>{
            setEntities(c);
        });
    }, []);

    const openNew = () => {
        setEntity(emptyEntity);
        setSubmitted(false);
        setEntityDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setEntityDialog(false);
    };

    const hideDeleteEntityDialog = () => {
        setDeleteEntityDialog(false);
    };

    const commitChanges = () => {
        setSubmitted(true);

        console.log("Entity:", entities);
        let _entities = [...entities];

        const successAction = () => {
            setEntities(_entities);
            setEntityDialog(false);
            setEntity(emptyEntity); 
            setSubmitted(false);
            toast.current.show({ severity: 'success', summary: 'Confirmare', detail: 'Datele au fost actualizate', life: 3000 });
                        
        }

        const failedAction = () => {
            toast.current.show({ severity: 'error', summary: 'Eroare', detail: 'Datele nu au fost actualizate', life: 3000 });
            setSubmitted(false);
        }

        console.log("Saving Entity:", entity);

        if (entity.code && entity.code.trim().length > 2 && entity.category && entity.details && entity.details.trim().length > 2) {
            console.log("Saving Entity 2:", entity);
            let _entity = { ...entity };

            if (entity.id) {
                const index = findIndexById(entity.id); 
                _entities[index] = _entity;
  
                NomenclatorService.updateCodification(_entity).then(f=>{
                    if(f.success){ 
                        successAction();
                    } else { 
                        failedAction();
                    }
                });

             } else {
                _entity.id = -1;//todo
                _entities.push(_entity);
                console.log("Creating Entity:", _entity);
                NomenclatorService.createCodification(_entity).then(f=>{
                    if(f.success){
                        successAction(); 
                    } else {
                        failedAction();
                    }
                });
            }
        }
    };

    const editEntity = (entity) => { 
        console.log("Entity:", entity);
        setEntity({ ...entity });
        setEntityDialog(true);
    };

    const confirmDeleteEntity = (entity) => {
        setEntity(entity);
        setDeleteEntityDialog(true);
    };

    const deleteEntity = () => {
        let _entities = entities.filter((val) => val.id !== entity.id);


        NomenclatorService.deleteCodification(entity.id).then(f=>{
            if(f.success){
                
        setEntities(_entities);
        setDeleteEntityDialog(false);
        setEntity(emptyEntity);
                toast.current.show({ severity: 'success', summary: 'Confirmare', detail: 'Datele au fost sterse', life: 3000 });
            } else {
                toast.current.show({ severity: 'error', summary: 'Eroare', detail: 'Datele nu au fost sterse', life: 3000 });
            }
        });
    };

    const findByCodification = (codification) => {
        return entities.find(f=> f.code == codification);
    }

    const findIndexById = (id) => {
        let index = -1;

        for (let i = 0; i < entities.length; i++) {
            if (entities[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const createId = () => {
        let id = '';
        let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        return id;
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _entity = { ...entity };

        _entity[`${name}`] = val;

        setEntity(_entity);
    };

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="Adauga" icon="bx bx-plus" severity="success"  onClick={openNew} />
            </div>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="bx bx-pencil" rounded className="mr-3" style={{"marginRight":"5px"}} onClick={() => editEntity(rowData)} />
                <Button icon="bx bx-trash" rounded  severity="danger" onClick={() => confirmDeleteEntity(rowData)} />
            </React.Fragment>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <IconField iconPosition="left">
                <InputIcon className="bx bx-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cauta..." />
            </IconField>
        </div>
    );

    const entityDialogFooter = (
        <React.Fragment>
            <Button label="Inchide" icon="bx bx-x" outlined style={{"marginRight":"5px"}} onClick={hideDialog} />
            <Button label="Salveaza" icon="bx bx-check" onClick={commitChanges} />
        </React.Fragment>
    );

    const deleteentityDialogFooter = (
        <React.Fragment>
            <Button label="Nu" icon="bx bx-check" outlined style={{"marginRight":"5px"}} onClick={hideDeleteEntityDialog} />
            <Button label="Da" icon="bx bx-x" severity="danger" onClick={deleteEntity} />
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumbs title="Administrare" breadcrumbItem="Nomenclator" />
                <Container fluid>
                    <Toast ref={toast} />
                    <Card>
                        <CardBody>
                            <Row>
                                <div>
                                    <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
                                    <DataTable ref={dt} value={entities}
                                        dataKey="id"
                                        paginator rows={5}
                                        rowsPerPageOptions={[5, 10, 25]}
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Afiseaza {first} pana la {last} din {totalRecords} inregistrari" globalFilter={globalFilter} header={header}>                                      
                                        <Column field="code" header="Codificare" sortable style={{ minWidth: '16rem' }}></Column> 
                                        <Column field="category" header="Categoria" sortable style={{ minWidth: '16rem' }}></Column> 
                                        <Column field="value" header="Valoare" sortable style={{ minWidth: '16rem' }}></Column> 
                                        <Column field="details" header="Descriere" sortable style={{ minWidth: '16rem' }}></Column> 
                                        <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>                              
                                    </DataTable>
                                </div>
                            </Row>
                        </CardBody>
                    </Card>
 
                    <Dialog visible={entityDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                        header="Detalii" modal className="p-fluid" footer={entityDialogFooter} onHide={hideDialog}> 
                        <div className="field">
                            <label htmlFor="label" className="font-bold">
                                Codificare
                            </label>
                            <InputText id="codification" minLength={3} value={entity.code} onChange={(e) => onInputChange(e, 'code')} required />
                            {submitted && !entity.code && <small className="p-error">Camp obligatoriu.</small>}
                        </div> 

                            <div className="field">
                                <label htmlFor="category" className="font-bold">
                                    Categoria
                                </label>
                                <InputText id="category" minLength={3} value={entity.category} onChange={(e) => onInputChange(e, 'category')} required />
                                {submitted && !entity.category && <small className="p-error">Camp obligatoriu.</small>}
                            </div> 

                            <div className="field">
                                <label htmlFor="value" className="font-bold">
                                    Valoare
                                </label>
                                <InputText id="value" minLength={3} value={entity.value} onChange={(e) => onInputChange(e, 'value')} required />
                                {submitted && !entity.value && <small className="p-error">Camp obligatoriu.</small>}
                            </div> 

                            <div className="field">
                                <label htmlFor="details" className="font-bold">
                                    Descriere
                                </label>
                                <InputText id="details" minLength={3} value={entity.details} onChange={(e) => onInputChange(e, 'details')} required />
                                {submitted && !entity.details && <small className="p-error">Camp obligatoriu.</small>}
                            </div> 
                    </Dialog>

                    <Dialog visible={deleteEntityDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Confirmare" modal footer={deleteentityDialogFooter}
                     onHide={hideDeleteEntityDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {entity && (
                                <span>
                                    Esti de acord sa stergi <b>{entity.label}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default CodificationsNomenclatorComponent;