
import { get,post } from "../helpers/api_helper"

export const TasksService = {
    createIncident(idTask){
        return get(`tasks/incident/create/${idTask}`);
    },
    saveTask(task){
        return post(`tasks/update`, task);
    },
    createTask(entity){
        return post(`tasks/create`, entity);
    },
    getRouteDetails(idRoute){
        return get(`tasks/route/${idRoute}`);
    },
    getClientsFiltered(IdEmployee, IdClientType, IdZone) {
        return post(`tasks/employee/clients`, { IdEmployee: IdEmployee, IdClientType: IdClientType, IdZone: IdZone });
    },
    deleteTask(IdTask){
        return get(`tasks/delete/${IdTask}`);
    },
    getTask(idTask){
        return get(`tasks/${idTask}`);
    },
    getTasks(){
        return get('tasks/all'); 
    },
    getRoutesByEmployee(idEmployee){
        return get(`tasks/routes/employee/${idEmployee}`);
    }
}