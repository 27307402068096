import { get, post } from "../helpers/api_helper"

export const IncidentsService = {
    getIncident(idIncident) {
        return get(`incidents/${idIncident}`);
    },
    setMessage(message, idIncident){
        return post("incidents/incident/message", {
            IdIncident: idIncident,
            Message: message
        });
    },
    setLastSeen(idIncident){
        return get(`incidents/${idIncident}/seen`);
    },
    deleteIncident(idIncident){
        return get(`incidents/delete/${idIncident}`);
    },
    updateIncident(entity){
        return post('incidents/update', entity);
    },
    getIncidents() {
        return get('incidents');
    }
}