import {get, post} from "../helpers/api_helper"


export const HealthFacilityService = {
    getHealthFacilityByClientType(idClientType){ 
        return get(`healthfacility/clienttype/${idClientType}`); 
    },
    getHealthFacilityByEmployee(idHealthFacility){ 
       return get(`healthfacility/employee/${idHealthFacility}`)
    }, 
    updateHealthFacilityByEmployee(entity){
      return post("healthfacility/employee/update", entity);
    },
    getHealthFacilities() {  
        return get('healthfacilities');
    },
    createHealthFacility(entity){
        return post("healthfacility/create", entity);
    },
    updateHealthFacility(entity){
        return post("healthfacility/update", entity);
    },
    updateHealthFacilityByEmployee(entity){
        return post("healthfacilities/employee/update", entity);
    },
    deleteHealthFacility(idHealthFacility)
    { 
        return get(`healthfacility/delete/${idHealthFacility}`); 
    },
    getHealthFacility(IdHealthFacility){ 
      return get(`healthfacility/${IdHealthFacility}`); 
    },
    getHealthFacilityTypes(){
        return get('nomenclator/healthfacilitytypes');
    },
    getHealthFacilityMedics(IdZone){
        return get(`healthfacility/medics/${IdZone}`); 
    }
}