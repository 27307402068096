import React, { useEffect, useState, useRef } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from '@fullcalendar/list';
import { Dialog } from "primereact/dialog";
import interactionPlugin from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from "../../components/Common/Breadcrumb";
 
import { ReportingService } from "services/ReportingService";

const TasksReportComponent = props => { 
  document.title = "Raport planificari";

  const navigate  = useNavigate();
  const [tasks, setTasks] = useState([]);

  const eventsTypes = [
    { type: "bg-info", id: 2, title: "Vizita noua", label: "In lucru" },
    { type: "bg-success", id: 1, title: "Vizita incheiata", label: "Finalizat" }
  ]

  useEffect(() => { 
    ReportingService.getTaskHistory().then(f => {
      console.log("Got tasks:", f);

      try {
        setTasks(f.map(c => {

          let event = eventsTypes.find(f => f.label == c.status);

        return {
          ...c,
          start: new Date(c.start),
          end: new Date(c.end),
          title: `${c.title}`,
          className: `${event.type} text-white`
          }
        }));
      } catch (error) {
        console.error("Error getting tasks:", error);
      }
    });
  }, []);

  const enLocal = {
    "code": "en-nz",
    "week": {
      "dow": 1,
      "doy": 4
    },
    "buttonHints": {
      "prev": "Previous $0",
      "next": "Next $0",
      "today": "This $0"
    },
    "viewHint": "$0 view",
    "navLinkHint": "Go to $0"
  };

  const toast = useRef(null);
  const [informationModal, setInformationModal] = useState(false);
  const [currentTask, setcurrentTask] = useState({ });

  const handleEventClick = arg => {
    const eventData = arg.event;  

    if(eventData.id) {
      const eventEntity = tasks.find(f=> f.id == eventData.id);

      setcurrentTask(eventEntity);
      setInformationModal(!informationModal);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Dialog header="Vizita" visible={informationModal} style={{ width: '30vw' }}
            onHide={() => { if (!informationModal) return; setInformationModal(false); }}>
            <div>
              <p>
                Mergi mai departe pentru a vedea mai multe informatii cu privire la aceasta vizita catre clientul <b>{currentTask.title}</b>
              </p>
            </div> 
            <div style={{float:"right"}}>
              <Button onClick={() => { navigate(`/tasks/edit/${currentTask.id}`)}}>Mergi mai departe</Button>
            </div> 
          </Dialog>

          <Breadcrumbs title="Raportare" breadcrumbItem="Planificari" />
          <Row>
            <Col xs={12}>
              <Row>
                <Col xl={3}>
                  <Card>
                    <CardBody>
                      <div id="external-events" className="mt-2">
                        {eventsTypes &&
                          (eventsTypes || [])?.map((category) => (
                            <div
                              className={`${category.type} external-event fc-event text-white`}
                              key={"cat-" + category.id}>
                              <i className="mdi mdi-checkbox-blank-circle font-size-11 me-2" />
                              {category.title}
                            </div>
                          ))}
                      </div>
                 
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={12}>
                  <Card>
                    <CardBody>
                      <FullCalendar
                        plugins={[
                          BootstrapTheme,
                          dayGridPlugin,
                          listPlugin,
                          interactionPlugin,
                        ]}
                        slotDuration={"00:15:00"}
                        handleWindowResize={true}
                        themeSystem="bootstrap"
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "dayGridMonth,dayGridWeek,dayGridDay",
                        }}
                        buttonText={{
                          today: "Astazi",
                          month: "Luna",
                          week: "Saptamana",
                          day: "Zi"
                        }}
                        locale={enLocal}
                        events={tasks || []}
                        displayEventTime={false}
                        editable={false}
                        droppable={false}
                        selectable={true}
                        eventClick={handleEventClick}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TasksReportComponent;
