import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

// Create an axios instance
const axiosApi = axios.create({
  baseURL: API_URL,
});


// Add an interceptor to dynamically set the token for each request
axiosApi.interceptors.request.use(
  (config) => {
    // Get the token from localStorage
    const token = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).token
      : null;

    if (token) {
      // Set the Authorization header
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    config.withCredentials = true;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Handle responses or errors globally
axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    // You can add logic here for handling specific errors (e.g., token expiration)
    return Promise.reject(error);
  }
);

// Define API functions
export async function login(url, data, config = {}) {
  return await axiosApi.post(url, data, config);
}

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, config)
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return await axiosApi
    .post(url, data, config)
    .then((response) => response.data);
}

export default axiosApi;
