import React from "react";
import { Navigate } from "react-router-dom";
import "primereact/resources/themes/md-light-deeppurple/theme.css" 

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout"; 
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Business pages
import PrescriptionRegisterComponent from "pages/Medics/createPrescription";
import Employee from "pages/Employee";
import Dashboard from "../pages/Dashboard/index";
import EmployeeProfile from  "../pages/Profile/profile" 
import PharmacyReportComponent from "pages/Reporting/pharmacy";
import EmployeeEdit from "pages/Profile/edit";
import PharmacyRegisterComponent from "pages/Pharmacy/edit"; 
import JobList from "pages/Stock/Products";
import EmployeeRegisterComponent from "pages/Employee/edit";
import Calendar from "pages/TaskIncidents/index"
import StockNewProductsComponent from "pages/Stock/Products/update";
import StockEmployeeRequest from "pages/Stock/StockEmployeeRequests";
import PharmacyOverview from "pages/Pharmacy/index";
import MedicsOverview from "pages/Medics";
import MedicsPrescriptionComponent from "pages/Medics/prescriptions";
import HealthFacilityOverview from "pages/HealthFacilities/index";
import Nomenclator from "pages/Nomenclator"; 
import HealthFacilityRegisterComponent from "pages/HealthFacilities/edit"
import HealthFacilityLimitedUpdatedComponent from "pages/HealthFacilities/userEdit";
import PharmacyLimitedComponent from "pages/Pharmacy/userEdit";
import MedicUpdateComponent from "pages/Medics/edit";
import CitiesNomenclatorComponent from "pages/Nomenclator/citiesNomenclator";
import TaskPlanificationComponent from "pages/Tasks/plannification";
import TasksOverview from "pages/Tasks/index";
import TaskRoutePlanificationComponent from "pages/Tasks/routes";
import TaskIncidentEdit from "pages/TaskIncidents/edit";
import TasksReportComponent from "pages/Reporting/tasksHistoryCalendar";
import ClientsReportComponent from "pages/Reporting/clients";
import TasksReport2Component from "pages/Reporting/tasksHistory";
import IncidentsReportComponent from "pages/Reporting/incidents";
import UnregisteredReportComponent from "pages/Reporting/unregistered";
import MedicsReportComponent from "pages/Reporting/medics";
import MedicPrescriptionsReportComponent from "pages/Reporting/medicalPrescriptions";
import DistributedProductsReportComponent from "pages/Reporting/distributedProducts";
import StockRequestsReportComponent from "pages/Reporting/stockRequests";
import InactiveMedicReportComponent from "pages/Reporting/inactiveMedics";
import InactiveClientsReportComponent from "pages/Reporting/inactiveClients";  
import ZonesNomenclatorComponent from "pages/Nomenclator/zonesNomenclator";
import CountiesNomenclatorComponent from "pages/Nomenclator/countiesNomenclator";
import ContestsNomenclatorComponent from "pages/Nomenclator/contestsNomenclator";
import ClientTypesNomenclatorComponent from "pages/Nomenclator/clientTypesNomenclator";
import MedicalResourceNomenclatorComponent from "pages/Nomenclator/medicalResource";
import StudiesNomenclatorComponent from "pages/Nomenclator/studiesNomenclator";
import SectorsNomenclatorComponent from "pages/Nomenclator/sectors";
import RoutesNomenclatorComponent from "pages/Nomenclator/routes";
import PlanificationTypesNomenclatorComponent from "pages/Nomenclator/planificationTypes";
import MedicTypesNomenclatorComponent from "pages/Nomenclator/medicTypesNomenclator";
import MedicCompetencesNomenclatorComponent from "pages/Nomenclator/medicCompetencesNomenclator";
import MedicSpecialitiesNomenclatorComponent from "pages/Nomenclator/medicSpecializationsNomenclator";
import MedicalResourceDetailsNomenclatorComponent from "pages/Nomenclator/medicalResourceDetails";
import RouteDetailsNomenclatorComponent from "pages/Nomenclator/routeDetails";
import MedicSpecializationsNomenclatorComponent from "pages/Nomenclator/medicSpecializationsNomenclator";
import CodificationsNomenclatorComponent from "pages/Nomenclator/codificationsNomenclator";
import MedicPlanificationHistoryComponent from "pages/Reporting/medicPlanificationHistory";
import MedicUpdateLimitedComponent from "pages/Medics/userEdit";
import ClientReportDetailsComponent from "pages/Reporting/healthfacility"
import NotificationsComponenet from "pages/Global/notifications"
import SearchPageComponent from "pages/Global/search" 

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> }, 
  { path: "/dashboard/notifications", component: <NotificationsComponenet/> },
  { path: "/nomenclator/cities", component: <CitiesNomenclatorComponent/> },
  { path: "/search", component: <SearchPageComponent/> }, 
  // //profile
  { path: "/employee/profile/:idEmployee", component: <EmployeeProfile /> },
  { path: "/employee", component: <Employee /> }, 
  { path: "/employee/edit/:idEmployee", component: <EmployeeRegisterComponent/>},
   {path: "/stock/requests", component: <StockEmployeeRequest/>},
   {path: "/pharmacies", component: <PharmacyOverview/>},
   {path: "/healthfacilities", component: <HealthFacilityOverview/>},
   {path: "/healthfacility/create/:idHealthFacility", component: <HealthFacilityRegisterComponent/>},
   {path: "/healthfacility/edit/:idHealthFacility", component: <HealthFacilityRegisterComponent/>},
   { path: "/healthfacility/employee/:idHealthFacility", component: <HealthFacilityLimitedUpdatedComponent /> },  
   {path: "/medics", component: <MedicsOverview/>},
   {path: "/medic/edit/:idMedic", component: <MedicUpdateComponent/>},
   {path: "/medic/new/:idMedic", component: <MedicUpdateComponent/>},
   //{path: "/tasks", component: <>},
   {path: "/tasks/new/-1", component: <TaskPlanificationComponent/>}, 
   {path: "/tasks/new/route", component: <TaskRoutePlanificationComponent/>}, 
   {path: "/tasks/edit/:idTask", component: <TaskPlanificationComponent/>},
   {path: "/nomenclator", component: <Nomenclator/>},
   {path: "/tasks", component: <TasksOverview/>},
   {path: "/tasks/incidents", component: <Calendar/>},
   {path: "/tasks/incidents/:idIncident", component: <TaskIncidentEdit/>},
   
   {path: "/reports/tasks/calendar", component: <TasksReportComponent/>},
   {path: "/reports/tasks/planifications", component: <TasksReport2Component/>},
   {path: "/reports/clients", component: <ClientsReportComponent/>},
   {path: "/reports/pharmacy/:idPharmacy", component: <PharmacyReportComponent/>},
   {path: "/reports/medics", component: <MedicsReportComponent/>},
   {path: "/reports/incidents", component: <IncidentsReportComponent/>},
   {path: "/reports/stock", component:<StockRequestsReportComponent/>},

   {path: "/reports/clients/institution/:idClient", component: <ClientReportDetailsComponent/>},
   {path: "/reports/clients/:idClient", component: <ClientReportDetailsComponent/>},

   {path: "/medic/prescriptions", component:<MedicsPrescriptionComponent/>},
   {path: "/reports/medic/plannification/:idMedic", component: <MedicPlanificationHistoryComponent/>},
   {path: "/medic/employee/:idMedic", component:<MedicUpdateLimitedComponent/>},
   {path: "/reports/inactiveclients", component:<InactiveClientsReportComponent/>},
   {path: "/reports/inactivemedics", component:<InactiveMedicReportComponent/>},
  // path for distributed products
  {path: "/reports/distributedproducts", component: <DistributedProductsReportComponent/>},
   {path: "/reports/medicalprescriptions", component: <MedicPrescriptionsReportComponent/>}, 
   {path: "/reports/unregistered", component: <UnregisteredReportComponent/>},
  { path: "/stock/products", component: <JobList/> }, 
  { path: "/stock/products/add", component: <StockNewProductsComponent/>},
  { path: "/medic/prescriptions/add", component: <PrescriptionRegisterComponent/>},
  { path: "/medic/prescriptions/:idPrescription", component: <PrescriptionRegisterComponent/>},
  { path: "/stock/products/edit/:idProduct", component: <StockNewProductsComponent/>},
  //{ path: "/stock/employee/requests", component: <JobGrid/> }, 
  { path: "/pharmacy/edit/:idPharmacy", component: <PharmacyRegisterComponent /> }, 
  { path: "/pharmacy/employee/:idPharmacy", component: <PharmacyLimitedComponent /> },  

  { path: "/employee/profile/edit/:idEmployee", component: <EmployeeEdit/>},
   {path: "/nomenclator/zones", component: <ZonesNomenclatorComponent/>},
   {path: "/nomenclator/counties", component: <CountiesNomenclatorComponent/>},
   // this RoutesNomenclatorComponent
   {path: "/nomenclator/routes", component: <RoutesNomenclatorComponent/>},
   // RouteDetailsNomenclatorComponent
   {path: "/nomenclator/routedetails", component: <RouteDetailsNomenclatorComponent/>},
   // for PlanificationTypesNomenclatorComponent
   {path: "/nomenclator/planificationtypes", component: <PlanificationTypesNomenclatorComponent/>},
   {path: "/nomenclator/mediccompetences", component: <MedicCompetencesNomenclatorComponent/>},
   {path: "/nomenclator/medicspecializations", component: <MedicSpecializationsNomenclatorComponent/>},
   {path: "/nomenclator/clienttypes", component: <ClientTypesNomenclatorComponent/>},
   {path: "/nomenclator/studies", component: <StudiesNomenclatorComponent/>},
   {path: "/nomenclator/contests", component: <ContestsNomenclatorComponent/>},
   {path: "/nomenclator/medictypes", component: <MedicTypesNomenclatorComponent/>},
   //materials
   {path: "/nomenclator/materials", component: <MedicalResourceNomenclatorComponent/>},
   //codification
   {path: "/nomenclator/codification", component: <CodificationsNomenclatorComponent/>},

   //MedicTypesNomenclatorComponent
   {path: "/nomenclator/medicjobtypes", component: <MedicTypesNomenclatorComponent/>},
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  {
    path: "*", 
    component: <Navigate to="/dashboard" />,
  }
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> } 
];

export { authProtectedRoutes, publicRoutes };
