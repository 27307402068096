import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore } from "redux-persist";  // Import persistStore
import rootReducer from "./reducers";  // This should be your persisted rootReducer
import rootSaga from "./sagas"; // Import your sagas

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// Setup Redux DevTools extension support
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Create the store with the persisted reducer and middleware
const store = createStore(
  rootReducer, // This should be your persisted rootReducer from redux-persist
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

// Create the persistor to manage the rehydration of persisted state
const persistor = persistStore(store);

persistor.subscribe(() => {
  console.log("Persisted State:", persistor.getState());
});

// Run your sagas
sagaMiddleware.run(rootSaga);

// Export the store as default and persistor as named export
export { persistor };  // Export persistor as named export
export default store;   // Export store as default export
