import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types'; 
import { Link } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FilterMatchMode } from 'primereact/api';

import { Button, Card, CardBody, Col, Row } from 'reactstrap';
//import components

import Breadcrumbs from '../../components/Common/Breadcrumb';

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";

import Spinners from "components/Common/Spinner";
import { ToastContainer } from "react-toastify";
import { PharmacyService } from "services/PharmacyService"; 

function PharmacyOverview({ user }) {
    document.title = "Administrare Farmacie";

    const [pharmaciesList, setPharmaciesList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        city: { value: null, matchMode: FilterMatchMode.CONTAINS },
        zone: { value: null, matchMode: FilterMatchMode.CONTAINS },
        type: { value: null, matchMode: FilterMatchMode.CONTAINS },
        address: { value: null, matchMode: FilterMatchMode.CONTAINS }, 
        pharmacy: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
 
    const [globalFilterValue, setGlobalFilterValue] = useState('');
 
    useEffect(() => {
        PharmacyService.getPharmacies().then(data => {
            setPharmaciesList(data); 
            setIsLoading(false); 
        }); 
    }, []); 

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const viewPharmacyDetails = (options) => {
        return (
            <div className="list-unstyled hstack gap-1 mb-0">
                <li>
                    <Button
                        className="btn btn-sm btn-soft-info"
                        id={`${options.idPharmacy}`}
                        onClick={() => {
                            navigate(`/pharmacy/${options.employeeEdit ? "employee" : "edit"}/${options.idPharmacy}`);
                        }}>
                        <i className="bx bx-search-alt" />
                    </Button>
                </li>
            </div>
        );
    }

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Cautare ..." />
                </IconField>
            </div>
        );
    }; 
    
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Gestiune clienti" breadcrumbItem="Farmacii" />
                    {
                        (user && user.isAdmin) ? (
                            <Row className="d-flex justify-content-end" >
                                <Col xs="2" lg="2" md="2">
                                    <Card style={{ backgroundColor: 'transparent' }}>
                                        <Button color="success" className="btn-rounded "><Link style={{ color: 'white ' }} to="/pharmacy/edit/-1">Adauga Farmacie</Link></Button>
                                    </Card>
                                </Col>
                            </Row>) : (<></>)
                    }
                    {
                        isLoading ? <Spinners setLoading={setIsLoading} />
                            :
                            <Row>
                                <Col xs="12">
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Card>
                                                    <CardBody>
                                                        <DataTable 
                                                              tableStyle={{ minWidth: '30rem' }} 
                                                              showGridlines
                                                              stripedRows  
                                                              rowsPerPageOptions={[5, 10, 25]}
                                                            value={pharmaciesList || []} paginator rows={10} 
                                                            size='small' 
                                                            dataKey="idPharmacy" filters={filters} filterDisplay="row"
                                                            currentPageReportTemplate="Afiseaza {first} pana la {last} din {totalRecords} unitati" 
                                                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                            globalFilterFields={['name', 'type', 'address', 'city','zone']} 
                                                            header={renderHeader}
                                                            emptyMessage="Nu au fost gasite rezultate"> 
                                                            <Column sortable filterHeaderStyle={{minWidth:"30%"}} field="name" header="Denumire unitate" filter filterPlaceholder="Unitatea sanitara" />
                                                            <Column sortable filterHeaderStyle={{minWidth:"20%"}}field="type" header="Tip unitate" filter filterPlaceholder="Tip unitate" />
                                                            <Column sortable field="address" header="Adresa" filter filterPlaceholder="Adresa" />
                                                            <Column sortable field="city" header="Oras" filter filterPlaceholder="Oras" />
                                                            <Column sortable field="zone" header="Zona de lucru" filter filterPlaceholder="Zona" />
                                                            <Column sortable body={viewPharmacyDetails}/>
                                                        </DataTable>
                                                    </CardBody>
                                                </Card>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                    }
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}
PharmacyOverview.propTypes = {
    preGlobalFilteredRows: PropTypes.any
};

const mapStateToProps = (state) => ({
    user: state.Login.user, // Replace 'auth' with the correct slice name
});

export default connect(mapStateToProps)(PharmacyOverview);