import React, { useEffect, useRef, useState } from "react";
import { useMemo } from "react";
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, UncontrolledTooltip, Input, Label, Row } from "reactstrap"; 
import { Link, redirect, useParams } from "react-router-dom";
import { Toast } from 'primereact/toast';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column'; 
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
 
import Select from "react-select";
import { ProductService } from "services/ProductService";

const StockNewProductsComponent = () => {
    document.title = "Administrare produse";

    const { idProduct = -1 } = useParams();
    const toast = useRef(null);
    const [saveDisabled, setSaveDisabled] = useState(false); 
    const [productCategories, setProductCategories] = useState([]);
    const [newProductsList, setNewProductsList] = useState([]);

    const emptyProduct = {
        idProduct: -1,
        label: "",
        quantity: null,
        price: null,
        idProductCategory: -1,
        errors: 0,
        errorDetails: ""
    }

    const [Product, setProduct] = useState({
        idProduct: -1,
        label: "",
        quantity:null,
        price: null,
        idProductCategory: 1,
        errors: 0,
        errorDetails: ""
    });

    useEffect(() => {
        ProductService.getProductCategories().then(p => { 
            setProductCategories(p);
 
            if (idProduct > 0) {
                ProductService.getProductById(idProduct).then(f => {
                    setProduct(f);
                });
            }
        });
    }, []);

    const handleDelete = () => {
        if(!Product || parseInt(Product.idProduct) <= 0)
            return;
        setSaveDisabled(true);
        ProductService.deleteProduct(Product.idProduct).then(response => {
            if (response && response.success) {
                toast.current.show({
                    severity: 'success',
                    summary: 'Info',
                    detail: `Produsul a fost sters cu success`
                });
            } else {
                setSaveDisabled(false);
                toast.current.show({
                    severity: 'warn',
                    summary: 'Info',
                    detail: `Produsul nu a putut fi sters`
                });
            }
        });
    }

    const submitChanges = () => { 
        if (parseInt(idProduct) <= 0 || idProduct == undefined) { 

            if(newProductsList.length <= 0) return;
            setSaveDisabled(true);
            ProductService.saveProductsList({ Products: newProductsList }).then(response => {
                if (response && response.success) {
                    toast.current.show({
                        severity: 'success',
                        summary: 'Info',
                        detail: `Produsele au fost adaugate cu success`
                    });
                } else { 
                    setSaveDisabled(false);
                    toast.current.show({
                        severity: 'warn',
                        summary: 'Info',
                        detail: `Produsele nu au fost adaugate`
                    });
                }
            });
        } else {
            if (Product.quantity <= 0 || Product.price <= 0 || (Product.label?.length || 0) <= 3) { 
                setProduct(prev => {
                    return {
                        ...prev,
                        errors: 1,
                        errorDetails: "Unul dintre campurile obligatorii nu este completat corect."
                    }
                });
                return;
            }
            setSaveDisabled(true);
            ProductService.updateProduct(Product).then(response => {
                if (response && response.success) {
                    toast.current.show({
                        severity: 'success',
                        summary: 'Info',
                        detail: `Produsul a fost actualizat cu success`
                    });
                } else {
                    toast.current.show({
                        severity: 'warn',
                        summary: 'Info',
                        detail: `Produsul nu au fost actualizat`
                    });
                }
                setSaveDisabled(false);
            });
        }
    }

    const addNewProduct = () => { 
        setProduct(prev => { return { ...prev, errors: 0, errorDetails: "" } });
        if (Product.quantity <= 0 || Product.price <= 0 || (Product.label?.length || 0) <= 3) {
            //heror
            setProduct(prev => {
                return {
                    ...prev,
                    errors: 1,
                    errorDetails: "Unul dintre campurile obligatorii nu este completat corect."
                }
            });
            return;
        }
        setProduct(prev => {
            return {
                ...prev,
                errors: 0,
                errorDetails: ""
            }
        });

        const productExists = newProductsList.find(f => f.label == Product.label);

        if (productExists) { 
            setProduct(prev => {
                return {
                    ...prev,
                    errors: 1,
                    errorDetails: "Exista deja un produs adaugat cu acelasi nume."
                }
            });
            return;
        } else {  
            const dbProduct = ProductService.getProductByName(Product.label).then(f => { 
                if (!dbProduct) { 
                    setProduct(prev => {
                        return {
                            ...prev, errors: 1,
                            errorDetails: "Exista deja un produs adaugat cu acelasi nume in baza de date a produselor."
                        }
                    });
                } else { 
                    setNewProductsList(prev => {
                        return [...prev, {
                            ...Product,
                            category: selectedProductCategory?.label,
                            idProductCategory: selectedProductCategory?.id
                        }]
                    });
                    setProduct(emptyProduct);
                    setSelectedProductCategory([]);
                }
            });
        }
    }

    const handleChange = (e) => {
        // Get the current input value
        let value = e.target.value;

        // Allow only numbers and prevent leading zeros
        if (/^\d+$/.test(value) && (value === '' || value[0] !== '0' || value === '0')) {
            return true;
        }
        return false;
    };

    const viewProductDetails = (row) => {
        return <Button className="btn btn-sm btn-soft-danger" onClick={() => {
            setNewProductsList(prev => prev.filter(f => f.label !== row.label));
        }}>Sterge</Button>
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Administrare produse" breadcrumbItem={idProduct > 0 ? "Modifica produs" : "Adauga produs nou"} />
                    <Toast ref={toast} />
                    <Row>
                        <Col lg={8}>
                            <Card>
                                <CardBody>
                                    <div className="mb-3">
                                        <Label htmlFor="category">Categoria produslui</Label>
                                        <Select
                                            name="category"
                                            value={productCategories?.find(f => f.id == Product?.idProductCategory) || []}
                                            onChange={(e) => {
                                                console.log("E:", e);
                                                setProduct(prev => ({
                                                    ...prev,
                                                    idProductCategory: e?.id || -1
                                                }));
                                            }}
                                            options={productCategories}
                                            getOptionLabel={option => option.label}
                                            getOptionValue={option => option.id}
                                            placeholder="Alege categoria produslui"
                                            isClearable
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <Label htmlFor="Name">Denumirea produsului</Label>
                                        <Input
                                            id="label"
                                            name="label"
                                            maxLength={30}
                                            type="text"
                                            placeholder="Denumire produs ..."
                                            onChange={(e) => setProduct(prev => {
                                                console.log("E:", e.target.value);
                                                return { ...prev, label: e.target.value }
                                            })}
                                            value={Product.label}
                                        />
                                    </div>
                                    <Row>
                                        <Col sm="6"><div className="mb-3">
                                            <Label htmlFor="price">Pret per bucata (RON)</Label>
                                            <Input
                                                id="price"
                                                maxLength={4}
                                                name="price"
                                                placeholder="Pretul produsului ..."
                                                onChange={(e) => {
                                                    if (handleChange(e)) {
                                                        setProduct(prev => { return { ...prev, price: e.target.value } })
                                                    }
                                                }
                                                }
                                                value={Product.price}
                                            />
                                        </div>
                                        </Col>
                                        <Col sm="6">
                                            <div className="mb-3">
                                                <Label htmlFor="quantity">Cantitatea totala</Label>
                                                <Input
                                                    id="quantity"
                                                    name="quantity"
                                                    maxLength={4}
                                                    placeholder="numar bucati ..."
                                                    onChange={(e) => {
                                                        if (handleChange(e)) {
                                                            setProduct(prev => {
                                                                console.log(e.target.value);
                                                                return { ...prev, quantity: e.target.value }
                                                            })
                                                        }
                                                    }
                                                    }
                                                    value={Product.quantity}
                                                />
                                            </div>
                                        </Col>
                                    </Row>

                                    {Product.errors == 1 ? (<div>
                                        <label style={{ color: "red" }} className="d-block mb-3">Toate campurile sunt obligatorii pentru noul produs.</label>
                                    </div>
                                    ) : null}

                                    <div className="mb-3">
                                        <Button hidden={parseInt(idProduct) > 0} onClick={() => addNewProduct()} className="btn-info" color="success">Adauga produs in lista</Button>
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        {<>
                                            <div className="flex-grow-1">
                                                <p className="text-muted fw-medium">
                                                    Sumar lista produse noi
                                                </p>
                                                <h4 className="mb-0">{newProductsList?.length || 0} produse noi adaugate</h4>
                                            </div>
                                            <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                                <span className="avatar-title rounded-circle bg-primary">
                                                    <i className={"bx bx-box font-size-24"}></i>
                                                </span>
                                            </div>
                                        </>
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        {(parseInt(idProduct) <= 0 || idProduct == undefined) && (
                            <Col lg={8}>
                                <Card>
                                    <CardBody>
                                        <h5 className="card-title mb-1">Administreaza lista produselor</h5>
                                        <div className="mb-1">
                                            <DataTable columnResizeMode="expand"
                                                resizableColumns className='.p-datatable-lg'
                                                size='small' value={newProductsList || []} paginator rows={10}
                                                style={{
                                                    maxWidth: '100%',
                                                    fontSize: '0.8rem'
                                                }}
                                                tableStyle={{
                                                    maxHeight: '400px',  // Adjust this value as needed
                                                    overflow: 'auto'
                                                }}
                                                dataKey="label"
                                                globalFilterFields={['label']}
                                                emptyMessage="Nu au fost gasite rezultate">
                                                <Column field="label" header="Denumire produs" />
                                                <Column field="category" header="Categorie" />
                                                <Column field="price" header="Pret per bucata (RON)" />
                                                <Column field="quantity" header="Canitata totala" />
                                                <Column body={viewProductDetails} style={{ minWidth: '12rem' }} />
                                            </DataTable>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                        <Col lg={8}>
                            <div className="text-end mb-4">
                                <Button disabled={saveDisabled} onClick={submitChanges} className="btn-primary me-2" color="success">Salveaza</Button>
                                <Button hidden={parseInt(idProduct) <= 0} disabled={saveDisabled} onClick={handleDelete} className="btn-danger" color="success">Sterge</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default StockNewProductsComponent;