import React, { useEffect, useState, useRef } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";
import { Badge } from "primereact/badge";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { IconField } from "primereact/iconfield";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import { Toast } from 'primereact/toast';
import { Dialog } from "primereact/dialog";
import { ReportingService } from "services/ReportingService";
import { InputTextarea } from "primereact/inputtextarea";
import { StockRequestService } from "services/StockRequestService";

const StockRequestsReportComponent = ({ user }) => {
    document.title = "Rapoarte necesitate";

    const [stockRequests, setStockRequests] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [currentStockRequest, setCurrentStockRequest] = useState(null);
    const [informationModal, setInformationModal] = useState(false);
    const [updateStockRequestModal, setUpdateStockRequestModal] = useState(false);
    const toast = useRef(null);
    const [statuses] = useState(['Aprobat','Respins']);

    const [statistics, setStatistics] = useState([ 
        { id: 3, title: "Total suma rapoarte aprobate", iconClass: "bx bx-bar-chart-alt-2", value: 0 }
    ]);
 
    useEffect(() => {
        ReportingService.getStockRequests().then(f => {
            setStockRequests(f);
        });

        ReportingService.getStockStatistics().then(f=> {
            let stats = [...statistics]; 
            stats[0].value = (f.sum.toLocaleString() + " ron") ?? 0; 
            setStatistics(prev => { return stats });
        });
    }, []);


    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <IconField iconPosition="left">
                <InputIcon className="bx bx-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Cauta..." />
            </IconField>
        </div>
    );

    const statusBodyTemplate = (options) => {
        if (options.status == 'Anulata' || options.status == 'Respinsa')
            return <Badge severity="danger" value={options.status} />
        else if (options.status == 'Aprobata')
            return <Badge severity="success" value="Aprobat" />
        else
            return <Badge severity="info" value="In analiza" />
    }

    const statusItemTemplate = (option) => {
        return <Tag value={option} severity={"info"}/>;
    };

    const actionBodyTemplate = (options) => {
        return (
            <div className="list-unstyled hstack gap-1 mb-0">
                <li>
                    <Button onClick={() => {
                        setCurrentStockRequest(options);
                        setInformationModal(true);
                    }}
                        className="btn btn-sm btn-soft-info"
                        id={`${options.idStockRequest}`}>
                        <i className="bx bx-show-alt" />
                    </Button>
                </li>
                {
                    (user && user.isAdmin && options.idStatus == 2) ? (
                        <li>
                            <Button
                                visible={!options?.cancelled || options.idStatus == 2}
                                onClick={() => {
                                    setCurrentStockRequest(options);
                                    setUpdateStockRequestModal(true);
                                }}
                                className="btn btn-sm btn-soft-danger"
                                id={`${options.idStockRequest}`}>
                                <i className="bx bx-trash" />
                            </Button>
                        </li>) : (<></>)
                }
            </div>
        );
    }

    const statusRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={statuses}
            onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} 
            placeholder="Cauta..." className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
        );
    };

    const footerCancelRequest = (
        <div className="flex justify-content-end gap-2">
            <Button className="btn btn-danger" onClick={() => {
                if (currentStockRequest
                    && currentStockRequest.idStockRequest > 0
                    && (currentStockRequest.observation && currentStockRequest.observation.length > 2)
                    && !currentStockRequest.cancelled) {
                    StockRequestService.cancelStockRequest({
                        idRequest: currentStockRequest.idStockRequest,
                        details: currentStockRequest.observation
                    }).then(f => {
                        if (f.success) {
                            toast.current.show({
                                severity: 'success',
                                summary: 'Info',
                                detail: `Raportul de necesitate a fost actualizat cu succes`
                            });
                            setStockRequests(prev => {
                                return [...prev].map(x => {
                                    if (x.idStockRequest === currentStockRequest.idStockRequest) {
                                        return { ...x, cancelled: true }
                                    }
                                    return x;
                                })
                            })
                            // add a toast message 
                            setUpdateStockRequestModal(false);
                            setCurrentStockRequest(null);
                        } else {
                            toast.current.show({
                                severity: 'warn',
                                summary: 'Info',
                                detail: `Raportul nu a putut fi actualizat. Detalii: ${f?.details}`
                            });
                        }
                    });
                }
            }}>Anuleaza</Button>
        </div>
    );

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="Rapoarte" breadcrumbItem="Rapoarte de necesitate" />
                    <Toast ref={toast} />

                    <Dialog  
                    breakpoints={{ '960px': '75vw', '641px': '100vw' }} 
                    header="Detalii" 
                    visible={informationModal} style={{ width: '50vw' }}
                        onHide={() => { if (!informationModal) return; setInformationModal(false); }}>
                        <div>
                            <DataTable
                                value={currentStockRequest?.products || []}
                                dataKey="id"
                                paginator rows={10}
                                rowsPerPageOptions={[5, 10, 25]}>
                                <Column field="label" header="Produs"></Column>
                                <Column field="quantity" header="Cantitate" sortable></Column>
                                <Column field="price" header="Cost total" sortable></Column>
                                <Column body={(x) => {
                                    if(x.new)  return <Badge severity="info" value={"-"}></Badge>
                                    if(x.status) return <Badge severity="success" value={"Aprobat"}></Badge>
                                    return <Badge severity="danger" value={"Nu a fost aprobat"}></Badge>
                                }} field="status" header="Status" sortable></Column>
                            </DataTable>
                        </div>
                    </Dialog>

                    <Dialog header="Anulare"
                        footer={footerCancelRequest}
                        visible={updateStockRequestModal} style={{ width: '50vw' }}
                        onHide={() => { if (!updateStockRequestModal) return; setUpdateStockRequestModal(false); }}>
                        <div>
                            <label>Ca urmare a anularii cererii, produsele vor fi sterse din lista de produse distribuite alocate angajatului si vor fi aduse inapoi in stocul general de produse.</label>
                            <div className="row">
                                <div className="col-md-12">
                                    <InputTextarea type="textarea" 
                                        minLength={5}
                                        maxLength={255}
                                        required
                                        placeholder="Observatii pentru anulare"
                                        onChange={(e) => setCurrentStockRequest({ ...currentStockRequest, observation: e.target.value })} />
                                </div>
                            </div>
                        </div>
                    </Dialog>

                    <Row> {(statistics || []).map((item, key) => (
                        <Col md="4" key={"_col_" + key}>
                            <Card className="mini-stats-wid">
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-grow-1">
                                            <p className="text-muted fw-medium">
                                                {item.title}
                                            </p>
                                            <h4 className="mb-0">{item.value}</h4>
                                        </div>
                                        <div className="avatar-sm rounded-circle bg-primary align-self-center mini-stat-icon">
                                            <span className="avatar-title rounded-circle bg-primary">
                                                <i className={"bx " + item.iconClass + " font-size-24"}></i>
                                            </span>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <Row>
                                <Col xl={12}>
                                    <Card>
                                        <CardBody>
                                            <DataTable value={stockRequests || []}
                                                dataKey="idStockRequest"
                                                paginator
                                                rows={10} filterDisplay="row"
                                                globalFilterFields={['date', 'observation', 'status', 'productsCount', 'name']}
                                                sortField="date" sortOrder={-1}
                                                header={header}
                                                rowsPerPageOptions={[5, 10, 25]}
                                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                currentPageReportTemplate="Afiseaza {first} pana la {last} din {totalRecords} inregistrari"
                                                globalFilter={globalFilter}>
                                                    {
                                                        (user && user.isAdmin && <Column field="name" header="Numele angajatului" sortable style={{ width: 'fit-content' }}></Column> )
                                                    }
                                                <Column body={statusBodyTemplate}
                                                 header="Status" 
                                                 filter
                                                 sortable 
                                                 showFilterMenu={true} filterMenuStyle={{ width: '14rem' }}  
                                                 filterElement={statusRowFilterTemplate}
                                                 style={{ width: 'fit-content' }}></Column> 
                                                <Column field="date" header="Data raportului" sortable style={{ width: 'fit-content' }}></Column>
                                                <Column field="observation" header="Observatii" sortable style={{ width: 'fit-content' }}></Column>
                                                <Column field="productsCount" header="Total produse" sortable style={{ width: 'fit-content' }}></Column>
                                                <Column field="price" header="Cost total" sortable style={{ width: 'fit-content' }}></Column>
                                                <Column body={actionBodyTemplate} exportable={false} style={{ width: 'fit-content' }}></Column>
                                            </DataTable>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    user: state.Login.user, // Replace 'auth' with the correct slice name
});

export default connect(mapStateToProps)(StockRequestsReportComponent);