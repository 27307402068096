import React, { useEffect, useState } from "react"
import { useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { StockRequestService } from "services/StockRequestService";
import { Badge } from "primereact/badge";
import {
  Col,
  Card,
  Nav,
  FormFeedback,
  CardBody,
  NavItem,
  NavLink,
  TabContent,
  ModalHeader,
  ModalBody,
Form,
Row,
Label,
Input,
  TabPane,
  Modal,
  Table,
} from "reactstrap"
import classnames from "classnames"
import { connect } from "react-redux";
import { Button } from "primereact/button"

import { Toast } from 'primereact/toast';
import SimpleBar from "simplebar-react"

const StockRequestsComponent = ({ user, entities }) => {
  const [currentRequest, setCurrentRequest] = useState(null); 
  const toast = useRef(null);
  const [stockRequests, setStockRequests] = useState(null);
  const [reportSubmitted, setReportSubmitted] = useState(false);
  const [stockRequestDetailsModal, setStockRequestModal] = useState(false);
  const [reportValidationSubmitted, setReportValidationSubmitted] = useState(false);

  useEffect(()=>{ 
    setStockRequests(entities); 
  }, [entities]);

  const cancelRequestApproval = () => {
    if(!currentRequest) return;

    setReportValidationSubmitted(true); 
    
    if (!currentRequest.details.length) {
      return;
    }
 
    setReportSubmitted(true);

    StockRequestService.denyStockRequest({
      idRequest: currentRequest.idRequest,
      details: currentRequest.details
    }).then(f => {
      if (f.success) { 
        toast.current.show({ severity: 'success', summary: 'Info', detail: 'Raportul a fost refuzat cu success' });

        setStockRequests(prev => {
          return [...prev].filter(f=> f.idRequest != currentRequest.idRequest)
        });
        // execute below code after 2 seconds
        setTimeout(() => {
          setStockRequestModal(false);
        }, 1000);

      } else {
        toast.current.show({ severity: 'warn', summary: 'Info', detail: 'Raportul nu a putut fi refuzat' });
      }
    });
  }

  const approveRequest = () => {
    if(!currentRequest) return;

    setReportValidationSubmitted(true); 

    if (!currentRequest.details.length) {
      return;
    }
 
    setReportSubmitted(true);

    StockRequestService.approveStockRequest(currentRequest).then(f => {
      if (f.success) {
        toast.current.show({ severity: 'success', summary: 'Info', detail: 'Raportul a fost aprobat cu success' });

        setStockRequests(prev => {
          return [...prev].filter(f=> f.idRequest != currentRequest.idRequest)
        });
        // execute below code after 2 seconds
        setTimeout(() => {
            setStockRequestModal(false);
        }, 1000);

      } else {
        setReportSubmitted(false);
        setReportValidationSubmitted(false);
        toast.current.show({ severity: 'warn', summary: 'Info', detail: 'Raportul nu a putut fi aprobat. Detalii: ' + f?.details });
      }
    });
  }

  return (
    <React.Fragment>
      <Col>
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">Rapoartele de necesitate</h4>
            <Nav pills className="bg-light rounded" role="tablist">
              <NavItem>
                <NavLink
                  className={classnames({ active: true })}>
                  In analiza
                </NavLink>
              </NavItem>
            </Nav>
            <Toast ref={toast} />
            <Modal
              isOpen={stockRequestDetailsModal}
              role="dialog"
              size="lg"
              autoFocus={true}
              centered
              toggle={() => { setStockRequestModal(!stockRequestDetailsModal)}}>
              <div className="modal-content">
                <ModalHeader toggle={() => { setStockRequestModal(!stockRequestDetailsModal)}}>
                  Detalii cerere
                </ModalHeader>
                <ModalBody>
                  <div id="kyc-verify-wizard-details" className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem>
                          <NavLink
                            disabled={true}>
                            <span style={{ backgroundColor: '#1dd1a1', color: 'white', borderColor: '#1dd1a1' }} className="number">1.</span>
                            Transmiterea raportului
                          </NavLink>
                        </NavItem>

                        <NavItem className="current">
                          <NavLink
                            className="active"
                            disabled={false}>
                            <span className="number">2.</span>
                            Validarea raportului
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink disabled={true}>
                            <span className="number">3.</span>
                            Livrarea produselor
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <Toast ref={toast} />
                      <TabContent
                        activeTab={1}
                        className="twitter-bs-wizard-tab-content">
                        <TabPane tabId={1}>
                          <Form onSubmit={(e) => { e.preventDefault(); return false; }}>
                            

                          {
                              (user && user?.isAdmin) ? (
                                <Row className="mt-3">
                                  <Col lg={12}>
                                    <Label htmlFor="reason">Observatiile transmise de angajat</Label>
                                    <Input 
                                    readOnly={true}
                                      value={currentRequest?.observations}  
                                      type="textarea"
                                      name="detailsemployee"  /> 
                                  </Col>
                                </Row>) : (<></>)
                            }


                            {
                              (user && user.isAdmin) ? (
                                <Row className="mt-3">
                                  <Col lg={12}>
                                    <Label htmlFor="reason">Observatii aprobare raport</Label>
                                    <Input
                                      minLength={3}
                                      maxLength={1000}
                                      value={currentRequest?.details}
                                      onChange={(e) => { 
                                        setCurrentRequest(prev => {
                                           return {...prev,
                                            details: e.target.value
                                           }
                                        }); 
                                      }}
                                      placeholder="Informatii privind aprobarea sau respingerea raportului, minim 3 caractere"
                                      type="textarea"
                                      name="details"
                                      id="details" />
                                    {reportValidationSubmitted &&
                                      (!currentRequest.details.length) && (
                                        <FormFeedback type="invalid" className="d-block">
                                          Camp obligatoriu
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Row>) : (<></>)
                            }
                            <Row>
                              <DataTable size='small' value={currentRequest?.products || []} paginator rows={10}
                                style={{
                                  maxWidth: '100%',
                                  fontSize: '0.8rem'
                                }}
                                tableStyle={{
                                  maxHeight: '400px',  // Adjust this value as needed
                                  overflow: 'auto'
                                }}
                                emptyMessage="Nu au fost gasite produse">
                                <Column field="label" header="Denumire" />
                                <Column field="quantity" header="Cantitate (buc)" />
                                <Column field="price" header="Pret (UM)" />
                                <Column body={(x) => {
                                  return <>{x.hasQuantity ? 
                                  <Badge severity="success" value={"Stoc disponibil"}></Badge> : 
                                  <Badge severity="danger" value={"Lipsa stoc"}></Badge>}</>
                                }} field="approved" header="Status" />
                              </DataTable>
                            </Row>
                            {
                              (user && user.isAdmin) ? (<Row className="mt-3 d-flex justify-content-end">
                                <Col lg={6} className="d-flex justify-content-end gap-2">
                                  <Button disabled={reportSubmitted} className="btn btn-danger" type="button" onClick={cancelRequestApproval}>Refuza raportul</Button>
                                  <Button disabled={reportSubmitted} className="btn btn-success" type="button" onClick={approveRequest}>Aproba raportul</Button>
                                </Col>
                              </Row>) : <></>
                            }
                          </Form>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </ModalBody>
              </div>
            </Modal>

            <TabContent activeTab={"1"} className="mt-4">
              <TabPane tabId="1">
                <SimpleBar style={{ maxHeight: "330px" }}>
                  <div className="table-responsive">
                    <Table className="table align-middle table-nowrap">
                      <tbody>
                        {
                          stockRequests?.length > 0 ? (
                            (stockRequests || [])
                              .slice()
                              .sort((a, b) => new Date(b?.dateRequested) - new Date(a?.dateRequested))
                              .map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <div>
                                      <h5 className="font-size-14 mb-1">{item.employeeName}</h5>
                                      <p className="text-muted mb-0">{item.dateRequested}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="text-end">
                                      <h5 className="font-size-14 mb-0">{item.products?.length} produse</h5>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="text-end">
                                      <h5 className="font-size-14 text-muted mb-0">
                                        {item.price} RON
                                      </h5>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="text-end">
                                      <Button
                                        onClick={
                                          () => {
                                          
                                            setCurrentRequest(item)
                                            setReportValidationSubmitted(false);
                                            setReportSubmitted(false);
                                            setStockRequestModal(true);
                                          }
                                        }
                                        color="primary" className="btn btn-sm btn-soft-info">Vezi detalii</Button>
                                    </div>
                                  </td>
                                </tr>
                              ))
                          ) : (
                            <tr>
                              <td colSpan="4" className="text-center">
                                <p className="text-muted mb-0">Nu exista rapoarte de necesitate</p>
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </Table>
                  </div>
                </SimpleBar>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  user: state.Login.user
});

export default connect(mapStateToProps)(StockRequestsComponent);